import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import UsersApi from "api/users";
import { Idam_Contracts_Enums_OrganisationRole } from "api/idam";

const useInviteUser = () => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const Api = new UsersApi();

  const inviteUser = async (email: string) => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await Api.invite({
      emails: [email],
      groups: [],
      userType: Idam_Contracts_Enums_OrganisationRole.USER
    });

    if (!status) {
      dispatch({
        type: RequestActions.SetError,
        errorMessage:
          message ||
          "There was an error sending the invite. Try again in a moment."
      });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const resetError = () => {
    dispatch({ type: RequestActions.ResetError });
  };

  return { inviteUser, resetError, fetching, error, errorMessage };
};

export default useInviteUser;
