import { RiskSummary, RiskSummaryTopic } from "api/insights";

export interface RiskSummariesContextValue {
  hasRiskSummaries: boolean;
  status: RiskSummariesStatus;
  getSummary: (topic: RiskSummaryTopic) => RiskSummary | undefined;
  updateRiskByTopic: (topic: RiskSummaryTopic, muted: boolean) => void;
}

export enum RiskSummariesStatus {
  NotLoaded,
  Loading,
  Loaded,
  Error,
  MutingRiskIcon
}
