import React, { FC, useState, type ReactNode } from "react";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
// @ts-ignore
import theme from "theme";
import RequestAccess from "components/molecules/RequestAccess";
import LoginBox from "components/molecules/LoginBox";
import LoginHeader from "components/molecules/LoginHeader";
import S from "./styles";

type LandingPageProps = {
  children?: ReactNode;
};

const LandingPage: FC<LandingPageProps> = ({ children }) => {
  const [subtitle, setSubtitle] = useState<string | undefined>(undefined);
  const [signIn, setSignIn] = useState(false);
  const [requestAccess, setRequestAccess] = useState(false);

  const onRequestAccess = () => {
    setRequestAccess(true);
  };

  const reRouteToSignIn = () => {
    setSubtitle("Please sign in");
    setSignIn(true);
    setRequestAccess(false);
  };

  const showButtons = signIn || requestAccess;

  return (
    <S.WrapContainer>
      <S.LoginContainer>
        <S.SwitchContainer>
          <S.BalancerDiv />
          <S.SignInContainer>
            {!signIn && <LoginHeader subtitle={subtitle} />}
            {!showButtons && (
              <S.ButtonContainer>
                <S.RequestAccessButton
                  onClick={onRequestAccess}
                  type={ButtonType.Filled}
                  size={ButtonSize.Medium}
                  text="Request access"
                />
                <S.SignInButton
                  onClick={() => setSignIn(true)}
                  type={ButtonType.Filled}
                  size={ButtonSize.Medium}
                  text="Sign in"
                />
              </S.ButtonContainer>
            )}
            {signIn && <LoginBox />}
            {requestAccess && (
              <RequestAccess reRouteToSignIn={reRouteToSignIn} />
            )}
            <S.LogoContainer>{theme.login.footerLogo}</S.LogoContainer>
          </S.SignInContainer>
        </S.SwitchContainer>
        {children}
      </S.LoginContainer>
    </S.WrapContainer>
  );
};

export default LandingPage;
