import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const Container = styled.div`
  padding: 20px;
  background-color: ${standardColors.white};
  width: 319px;
  border-radius: 12px;
  color: ${grey.dark};
  font-family: ${family.interRegular};
  font-size: ${fontSize.sm};
  line-height: 20px; /* 142.857% */

  span {
    font-family: ${family.interSemiBold};
    font-weight: 600;
  }
`;

const S = {
  Container
};

export default S;
