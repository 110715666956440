import { styled } from "@linaria/react";

import { grey } from "styles/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${grey.rule};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const S = {
  Container,
  Header
};

export default S;
