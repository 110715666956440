import { OverallScore, OverviewResponse } from "api/risk";
import { RiskFactorName } from "api/risk-service";
import { getKeys } from "util/getKeys";

export const updateRiskServiceDataForMuting =
  (name: RiskFactorName, mute: boolean) =>
  (currentRiskServiceData: OverviewResponse) => {
    const { overallScore, ...currentRiskData } = structuredClone(
      currentRiskServiceData
    );

    const sectionToUpdate = getKeys(currentRiskData).find(key => {
      return currentRiskData[key].find(
        ({ name: nameAsRiskFactor }) => nameAsRiskFactor === name
      );
    });

    if (!sectionToUpdate) {
      return { overallScore, ...currentRiskData };
    }

    const riskSectionToModify = currentRiskData[sectionToUpdate].map(risk =>
      risk.name === name
        ? {
            ...risk,
            dateMuted: mute ? new Date().toISOString() : null,
            isMuted: mute
          }
        : risk
    );

    const updatedStructure = {
      overallScore,
      ...currentRiskData,
      [sectionToUpdate]: riskSectionToModify
    };

    return updatedStructure;
  };

export const updateRiskServiceDataForScoreChange =
  (overallScore: OverallScore) =>
  (currentRiskServiceData: OverviewResponse) => {
    const { screeningFactors, webAndMediaFactors, keyDetailsFactors } =
      structuredClone(currentRiskServiceData);

    const updatedStructure = {
      overallScore,
      screeningFactors,
      webAndMediaFactors,
      keyDetailsFactors
    };

    return updatedStructure;
  };
