import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  type MouseEventHandler
} from "react";
import { Placement } from "@floating-ui/react";
import Popover from "components/atoms/Popover";
import { standardColors } from "styles/colors";
import useRiskService from "util/hooks/useRiskService";
import { RiskServiceStatus } from "util/hooks/useRiskService/types";

interface RiskIconWrapperProps {
  children: ReactNode;
  tooltipAlignment?: Placement;
  renderTooltipContent: (
    onMouseEnter: MouseEventHandler,
    onMouseLeave: MouseEventHandler,
    onClose?: () => void
  ) => ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export const RiskIconWrapper = ({
  children,
  containerRef,
  tooltipAlignment = "bottom",
  renderTooltipContent
}: RiskIconWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initiateCloseOnceMuted, setInitiateCloseOnceMuted] = useState(false);
  const { status } = useRiskService();
  const closeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const isMuting = status === RiskServiceStatus.MutingRiskIcon;

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  const preventClose = useCallback(() => {
    setInitiateCloseOnceMuted(false);
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  }, []);

  const initiateCloseTooltip = useCallback(() => {
    if (isMuting) {
      setInitiateCloseOnceMuted(true);
      return;
    }
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      setInitiateCloseOnceMuted(false);
    }, 400);
  }, [isMuting]);

  const closeTooltip = () => {
    if (!isMuting) {
      setIsOpen(false);
    }
  };

  const handleMouseEnter = () => {
    if (isOpen) {
      preventClose();
    }
  };

  const handleMouseLeave = () => {
    if (isOpen) {
      initiateCloseTooltip();
    }
  };

  const handleMouseLeaveTooltip: MouseEventHandler = () => {
    initiateCloseTooltip();
  };

  const handleClick = () => {
    if (isMuting && !isOpen) {
      return;
    }
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    if (!isMuting && initiateCloseOnceMuted) {
      initiateCloseTooltip();
    }
  }, [isMuting, initiateCloseOnceMuted, initiateCloseTooltip]);

  return (
    <Popover
      hideArrow
      isOpenOverride={isOpen}
      arrowColor={standardColors.white}
      maxWidth="100%"
      arrowSize="22px"
      alignment={tooltipAlignment}
      fallbackAxisSideDirection="end"
      fixPopover
      content={renderTooltipContent(
        preventClose,
        handleMouseLeaveTooltip,
        closeTooltip
      )}
      containerRef={containerRef}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {children}
      </div>
    </Popover>
  );
};
