import { styled } from "@linaria/react";

import { ReactComponent as InfoSvg } from "img/icons/info-icon.svg";
import { yellow, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const Container = styled.div`
  padding-left: 8vw;
  padding-right: 8vw;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const InnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const Heading = styled.p`
  font-size: ${fontSize["2xl"]};
  text-align: center;
  cursor: default;
  font-family: ${family.interLight};
  margin: 0;
  padding: 25px 0;
`;

const DetailText = styled.p`
  max-width: 700px;
  padding-bottom: 25px;

  a {
    color: ${standardColors.white};
    text-decoration: underline;
  }
`;

const SubjectName = styled.span`
  color: ${yellow.amaStars};
`;

const InfoIcon = styled(InfoSvg)`
  margin-top: 2rem;
  width: 48px;
  height: 48px;
`;

const S = {
  InfoIcon,
  SubjectName,
  Container,
  InnerContainer,
  DetailsContainer,
  Heading,
  DetailText
};

export default S;
