import React from "react";

import { isPDX } from "static-config";
import { makePlural } from "util/riskService";

import S from "./styles";

interface RiskReviewLabelProps {
  count?: number;
  isMuted?: boolean;
  isFlagged?: boolean;
  showInRed?: boolean;
  isForReview?: boolean;
}

export const RiskReviewLabel = ({
  count = 0,
  isMuted,
  isFlagged,
  showInRed,
  isForReview
}: RiskReviewLabelProps) => {
  const getLabel = () => {
    if (isMuted) return "Marked as not risk";

    if (isForReview) {
      const prefix = isFlagged ? "+ " : "";
      const suffix = !isPDX
        ? "for review"
        : `near ${makePlural("match", count, "es")}`;
      return `${prefix}${count} ${suffix}`;
    }

    if (isFlagged) return "Identified";

    return "Not identified";
  };

  return <S.Label showInRed={showInRed}>{getLabel()}</S.Label>;
};
