import React, { useState, type FC } from "react";
import { Collapse } from "reactstrap";

import SectionHeader from "pages/report/SectionHeader";
import EntityList from "components/molecules/EntityList";
import EntityListEmpty from "components/molecules/EntityListEmpty";
import EntityListHeader from "components/organisms/EntityListHeader";
import useEntityList from "util/hooks/useEntityList";

import S from "./styles";

interface Props {
  title: string;
}

const EntitySection: FC<Props> = ({ title }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const onToggleExpanded = () => setIsExpanded(prev => !prev);

  const { filteredEntityList, filteredCommissionedEntityList } =
    useEntityList();

  const hasEntities =
    filteredEntityList.length > 0 || filteredCommissionedEntityList.length > 0;

  return (
    <S.Container className="report-section">
      <SectionHeader
        title={title}
        isContractable
        toggleExpanded={onToggleExpanded}
        isExpanded={isExpanded}
      />
      <Collapse isOpen={isExpanded}>
        {hasEntities ? (
          <>
            <EntityListHeader />
            <EntityList />
          </>
        ) : (
          <EntityListEmpty />
        )}
      </Collapse>
    </S.Container>
  );
};

export default EntitySection;
