import React from "react";

import { grey, red } from "styles/colors";

import S from "./styles";

interface RiskCarouselButtonProps {
  direction: "right" | "left";
  hasRisk?: boolean;
  handleClick: () => void;
}

export const RiskCarouselButton = ({
  direction,
  hasRisk,
  handleClick
}: RiskCarouselButtonProps) => {
  const isLeft = direction === "left";

  return (
    <S.CarouselButton
      color={hasRisk ? red.directRiskFill : grey.dark}
      onClick={handleClick}
    >
      <S.ChevronLeftIcon isHidden={!isLeft} />
      <span>more</span>
      <S.ChevronRightIcon isHidden={isLeft} />
    </S.CarouselButton>
  );
};
