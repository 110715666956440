import React, {
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmSignIn, signIn, updateMFAPreference } from "aws-amplify/auth";
import config from "config";
import { OpenAPI } from "api/idam";

import {
  AuthenticationConfig,
  apiToken,
  getAuthenticatedUser,
  setAuthenticationConfig
} from "util/authentication";

import {
  HubAuthenticationStatus,
  HubAuthenticationAction,
  HubAuthenticationActions
} from "./types";

import {
  initialState,
  hubAuthenticationReducer,
  HubAuthenticationContext
} from "./context";

interface Props {
  loginPath: string;
  children: ReactNode;
}

OpenAPI.TOKEN = apiToken;

export const HubAuthenticationContextProvider: FC<Props> = ({
  children,
  loginPath
}) => {
  setAuthenticationConfig(AuthenticationConfig.HUB);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, reducerDispatch] = useReducer(
    hubAuthenticationReducer,
    initialState
  );

  const dispatch = useCallback((action: HubAuthenticationAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.unknown) {
      getAuthenticatedUser()
        .then(({ session }) => {
          const jwtPayload = session?.idToken?.payload;
          dispatch({
            type: HubAuthenticationActions.authenticated,
            jwtPayload
          });
        })
        .catch(() => {
          dispatch({ type: HubAuthenticationActions.unauthenticated });
        });
    }
  }, [state, dispatch, navigate, loginPath]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.verifyingPassword) {
      // Try-catch is also needed here because Amplify will throw an error if
      // login is already pending. Otherwise, it will return a promise.
      try {
        signIn({
          username: `${state.email.toLowerCase()}+${config.tenantId}`,
          password: state.password
        })
          .then(async signInOutput => {
            dispatch({
              type: HubAuthenticationActions.passwordVerified,
              nextSignInStep: signInOutput.nextStep.signInStep,
              getSetupUri: (signInOutput.nextStep as any)?.totpSetupDetails
                ?.getSetupUri
            });
          })
          .catch(({ message: error }) => {
            dispatch({ type: HubAuthenticationActions.unauthenticated, error });
          });
      } catch (e) {
        console.error(e);
      }
    }
  }, [state, dispatch, navigate, searchParams, loginPath]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.authenticating) {
      confirmSignIn({ challengeResponse: state.mfaCode })
        .then(() => {
          dispatch({
            type: HubAuthenticationActions.authenticated
          });
        })
        .catch(({ message: error }) => {
          dispatch({ type: HubAuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (state.status === HubAuthenticationStatus.mfaSetup) {
      confirmSignIn({ challengeResponse: state.mfaCode })
        .then(async () => {
          await updateMFAPreference({ totp: "PREFERRED" });
          dispatch({
            type: HubAuthenticationActions.authenticated
          });
        })
        .catch(({ message: error }) => {
          dispatch({ type: HubAuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (
      state.status === HubAuthenticationStatus.authenticated &&
      window.location.pathname === loginPath
    ) {
      navigate("/hub/organisations");
    }

    if (
      state.status === HubAuthenticationStatus.unauthenticated &&
      window.location.pathname !== loginPath &&
      window.location.pathname !== "/hub/reset-password"
    ) {
      navigate(loginPath);
    }
  }, [state, dispatch, navigate, loginPath]);

  return (
    <HubAuthenticationContext.Provider value={providerValue}>
      {children}
    </HubAuthenticationContext.Provider>
  );
};
