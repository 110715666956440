import { styled } from "@linaria/react";
import { red, blue } from "styles/colors";
import { Info } from "react-feather";
import { hexToRgb } from "styles/hexToRgb";

const Container = styled.div`
  display: flex;
  gap: 12px;
  padding: 20px;
`;

const InfoIconBase = styled(Info)`
  border-color: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(red.directRiskFill);
      return `${r}, ${g}, ${b}`;
    }},
    0.1
  );
  border-radius: 100%;
  background: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(red.directRiskFill);
      return `${r}, ${g}, ${b}`;
    }},
    0.1
  );
  border-style: solid;
`;

const InfoIcon = styled(InfoIconBase)`
  cursor: pointer;
  border-width: 6px;
  min-width: 30px;
  min-height: 30px;
`;

const ContentInfoIcon = styled(InfoIconBase)`
  border-width: 12px;
  min-width: 48px;
  min-height: 48px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;

    a {
      color: ${blue.link};
    }
  }
`;

const S = {
  Container,
  Content,
  ContentInfoIcon,
  InfoIcon
};

export default S;
