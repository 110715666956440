import React from "react";
import { observer } from "mobx-react";

import SectionHeader from "pages/report/SectionHeader";
import BasicDetails, {
  BasicDetailsType
} from "components/molecules/BasicDetails";
import { RiskFramework } from "components/organisms/RiskFramework";
import useRiskService from "util/hooks/useRiskService";

import S from "./styles";

const LegalEntityOverview = React.forwardRef(
  (
    {
      title,
      // Key details props
      legalName,
      otherNames,
      companyStatus,
      incorporationDate,
      registeredAddresses,
      companyTypes,
      jurisdiction,
      registrationNumber,
      otherIdentifiers,
      legalEntityIdentifier,
      businessPurposeData,
      publicListingInfo,
      operatingRevenue
    },
    ref
  ) => {
    const {
      riskData: { keyDetailsFactors }
    } = useRiskService();

    const getRisk = riskName => {
      if (!keyDetailsFactors) return undefined;

      const risk = keyDetailsFactors.find(({ name }) => name === riskName);

      if (risk) {
        return {
          level: risk.level,
          countryCode: risk.summary[0]?.countryCode,
          content: risk.summary[0]?.content,
          // TODO: This is what the code is doing elsewhere and seems wrong. We should probably show the full summary
          reason: risk.summary[1]?.content ?? ""
        };
      }

      return undefined;
    };

    const incorporationDateRisk = getRisk("incorporation_date");
    const businessSectorRisk = getRisk("business_sector");
    const countryOfIncorporationRisk = getRisk("country_of_incorporation");

    const hasIncorporationDateContent =
      !!incorporationDate || !!incorporationDateRisk?.content;
    const hasBusinessSectorContent = !!businessSectorRisk?.content;
    const hasCountryOfIncorporationContent =
      !!jurisdiction || !!countryOfIncorporationRisk?.content;

    const keyDetails = [
      [
        {
          title: "Legal name",
          risk: getRisk("legal_name"),
          itemType: BasicDetailsType.text,
          items: legalName
            ? [{ value: legalName.text, sources: legalName.sources }]
            : []
        },
        {
          title: "Other names",
          risk: getRisk("other_names"),
          itemType: BasicDetailsType.text,
          items:
            otherNames?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company types",
          risk: getRisk("company_types"),
          itemType: BasicDetailsType.text,
          items:
            companyTypes?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company status",
          risk: getRisk("company_status"),
          itemType: BasicDetailsType.text,
          items: companyStatus
            ? [
                {
                  value: companyStatus.statusTag,
                  sources: companyStatus.sources
                }
              ]
            : []
        }
      ],
      [
        {
          title: "Date of incorporation",
          risk: incorporationDateRisk,
          itemType: BasicDetailsType.text,
          items: hasIncorporationDateContent
            ? [
                {
                  value:
                    incorporationDateRisk?.content ||
                    `${incorporationDate?.day ?? ""} ${
                      incorporationDate?.monthShort ?? ""
                    } ${incorporationDate?.year}`,
                  sources: incorporationDate?.sources
                }
              ]
            : []
        },
        {
          title: "Business sector",
          risk: businessSectorRisk,
          itemType: BasicDetailsType.text,
          items: hasBusinessSectorContent
            ? [
                {
                  value: businessSectorRisk?.content,
                  sources: businessPurposeData?.sources ?? []
                }
              ]
            : []
        },
        {
          title: "Country of incorporation",
          risk: countryOfIncorporationRisk,
          itemType: BasicDetailsType.country,
          items: hasCountryOfIncorporationContent
            ? [
                {
                  code:
                    countryOfIncorporationRisk?.countryCode ||
                    jurisdiction?.countryCode,
                  name:
                    countryOfIncorporationRisk?.content ??
                    jurisdiction?.countryName,
                  sources: jurisdiction?.sources
                }
              ]
            : []
        },
        {
          title: "Reported revenue",
          risk: getRisk("operating_revenue"),
          itemType: BasicDetailsType.text,
          items: operatingRevenue
            ? [
                {
                  value: operatingRevenue.text,
                  sources: operatingRevenue.sources
                }
              ]
            : []
        },
        {
          title: "Publicly listed",
          itemType: BasicDetailsType.stock,
          items:
            publicListingInfo && publicListingInfo.stockExchanges
              ? publicListingInfo.stockExchanges
                  .filter(({ ticker }) => !!ticker)
                  .map(
                    ({ exchangeName, listingDate, delistingDate, ticker }) => ({
                      name: exchangeName,
                      startDate: listingDate,
                      endDate: delistingDate,
                      ticker,
                      sources: publicListingInfo.sources ?? []
                    })
                  )
              : []
        },
        {
          title: "Other exchanges",
          itemType: BasicDetailsType.stock,
          items:
            publicListingInfo && publicListingInfo.stockExchanges
              ? publicListingInfo.stockExchanges
                  .filter(({ ticker }) => !ticker)
                  .map(({ exchangeName }) => ({
                    name: exchangeName,
                    sources: publicListingInfo.sources ?? []
                  }))
              : []
        }
      ],
      [
        {
          title: "Company number",
          risk: getRisk("registration_number"),
          itemType: BasicDetailsType.text,
          items:
            registrationNumber?.map(({ value, sources }) => ({
              value,
              sources
            })) ?? []
        },
        {
          title: "Legal Entity Identifier (LEI)",
          risk: getRisk("legal_entity_identifier"),
          itemType: BasicDetailsType.text,
          items: legalEntityIdentifier
            ? legalEntityIdentifier.map(({ value, sources }) => ({
                value,
                sources
              }))
            : []
        },
        {
          title: "Other Identifiers",
          risk: getRisk("other_identifiers"),
          itemType: BasicDetailsType.text,
          items: otherIdentifiers
            ? otherIdentifiers.map(({ value, label, sources }) => ({
                value,
                label: `${label}: ${value}`,
                sources
              }))
            : []
        }
      ],
      [
        {
          title: "Registered address",
          risk: getRisk("registered_address"),
          itemType: BasicDetailsType.address,
          items: registeredAddresses
            ? registeredAddresses.map(({ address, sources }) => ({
                values: address,
                sources
              }))
            : []
        }
      ]
    ];

    return (
      <S.LegalEntityOverviewSection ref={ref} className="report-section">
        <SectionHeader title={title} isContractable={false} />
        <RiskFramework showOverallScore />
        <BasicDetails title="Key details" details={keyDetails} />
      </S.LegalEntityOverviewSection>
    );
  }
);

export default observer(LegalEntityOverview);
