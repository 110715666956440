import React from "react";

import Heading from "components/atoms/Heading";

import S from "./styles";

const RelatedPartiesEntitySectionHelpText = () => {
  return (
    <S.Container>
      <Heading level={4}>Related parties research</Heading>
      <p>
        An Ultimate Beneficial Owner (UBO) is a person with a share ownership of
        25% or greater. These are recommended for new Xapien reports.
      </p>
      <p>A Shareholder is any entity with a shareholding of 10% or greater.</p>
      <p>A Director is any current or previously registered director.</p>
    </S.Container>
  );
};

export default RelatedPartiesEntitySectionHelpText;
