// import config from "config";
import { stage } from "services/stage";
import { ApiRequestOptions } from "api/risk-service/core/ApiRequestOptions";

// temp hack to get around the fact we have one OpenAPI spec but it's spread accross two APIs
export const customBaseUrl = (_: ApiRequestOptions) => {
  const envUrl = `${stage}.xapien.com`;
  const portalUrl = `https://api.risk.${envUrl}`;

  return portalUrl;
};
