import React, {
  FC,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo
} from "react";
import { isPDX } from "static-config";
import { useLocation } from "react-router-dom";
import {
  ReportsService,
  type RiskSummary,
  RiskSummaryTopic,
  ShareTokenService
} from "api/insights";
import { apm } from "@elastic/apm-rum";
import { useInsightReport } from "util/hooks/useInsightReport";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import { GetReportErrorCodes } from "api/insight-reports";
import { RiskSummariesContextValue, RiskSummariesStatus } from "./types";
import RiskSummariesContext from "./context";
import { usePersonaId } from "../usePersonaId";

export interface RiskSummariesProviderProps {
  children: ReactNode;
}

function riskSummariesCall(
  enquiryId: string,
  token: string | null,
  personaId: string | null
) {
  if (personaId) {
    if (token) {
      return ShareTokenService.getReportsPersonasRiskSummaries({
        reportId: enquiryId,
        personaId,
        shareToken: token
      });
    }
    return ReportsService.getReportsPersonasRiskSummaries({
      reportId: enquiryId,
      personaId
    });
  }
  if (token) {
    return ShareTokenService.getReportsRiskSummaries({
      reportId: enquiryId,
      shareToken: token
    });
  }
  return ReportsService.getReportsRiskSummaries({ reportId: enquiryId });
}

const RiskSummariesProvider: FC<RiskSummariesProviderProps> = ({
  children
}) => {
  const { state: reportState } = useInsightReport();
  const [summaries, setSummaries] = useState<RiskSummary[]>([]);
  const [hasRiskSummaries, setHasRiskSummaries] = useState(false);
  const [status, setStatus] = useState(
    isPDX ? RiskSummariesStatus.Loaded : RiskSummariesStatus.NotLoaded
  );
  const location = useLocation();
  const enquiryId = useEnquiryId();
  const personaId = usePersonaId();

  useEffect(() => {
    if (
      !reportState.report &&
      reportState.status === InsightReportStatus.idle
    ) {
      return;
    }

    if (reportState.status === InsightReportStatus.fetching) {
      return;
    }

    if (
      reportState.status === InsightReportStatus.errorFetchingReport &&
      reportState.errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR
    ) {
      return;
    }

    if (status === RiskSummariesStatus.Loading) {
      return;
    }

    if (status === RiskSummariesStatus.Error) {
      return;
    }

    if (hasRiskSummaries) {
      return;
    }

    setStatus(RiskSummariesStatus.Loading);

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    riskSummariesCall(enquiryId, token, personaId ?? null)
      .then(results => {
        setHasRiskSummaries(true);
        setSummaries(results);
        setStatus(RiskSummariesStatus.Loaded);
      })
      .catch(err => {
        apm.captureError(err);
        console.error("Unable to load Risk Summaries", err);
        setStatus(RiskSummariesStatus.Error);
      });
  }, [
    location,
    enquiryId,
    hasRiskSummaries,
    status,
    personaId,
    reportState.report,
    reportState.status,
    reportState.errorMessage
  ]);

  const getSummary = useCallback(
    (topic: RiskSummaryTopic) =>
      summaries.find(summary => summary.topic === topic),
    [summaries]
  );

  const updateRiskByTopic = useCallback(
    (topic: RiskSummaryTopic, muted: boolean) => {
      setStatus(RiskSummariesStatus.MutingRiskIcon);

      ReportsService.patchReportsRiskSummariesMuted({
        reportId: enquiryId,
        topic,
        requestBody: {
          muted
        }
      })
        .then(() => {
          setStatus(RiskSummariesStatus.Loaded);
          setSummaries(currentSummaries =>
            currentSummaries.map(summary =>
              summary.topic === topic
                ? { ...summary, muted: muted ? new Date().toISOString() : null }
                : summary
            )
          );
        })
        .catch(err => {
          apm.captureError(err);
          console.error("Unable to mute a Risk Icon", err);
          setStatus(RiskSummariesStatus.Error);
        });
    },
    [enquiryId]
  );

  const value: RiskSummariesContextValue = useMemo(
    () => ({
      hasRiskSummaries,
      status,
      getSummary,
      updateRiskByTopic
    }),
    [hasRiskSummaries, getSummary, status, updateRiskByTopic]
  );

  return (
    <RiskSummariesContext.Provider value={value}>
      {children}
    </RiskSummariesContext.Provider>
  );
};

export default RiskSummariesProvider;
