import React, { MouseEventHandler } from "react";

import { isPDX } from "static-config";
import { RiskFactorName } from "api/risk-service";
import { FormattedText } from "components/atoms/FormattedText";
import {
  RISK_FACTOR_CONFIG,
  RISK_REVIEW_CONFIG,
  makePlural
} from "util/riskService";

import S from "./styles";

interface RiskReviewCardProps {
  factor:
    | RiskFactorName.PEPsRiskFactorName
    | RiskFactorName.WatchlistsRiskFactorName
    | RiskFactorName.SanctionsRiskFactorName;
  unconfirmedSourceCount: number;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const RiskReviewCard = ({
  factor,
  unconfirmedSourceCount,
  onMouseEnter,
  onMouseLeave
}: RiskReviewCardProps) => {
  const { svg: Icon, description } = RISK_FACTOR_CONFIG[factor];
  const { label, firstText, getSecondText } = RISK_REVIEW_CONFIG[factor];

  return (
    <S.Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.Header>
        <S.IconContainer>
          <Icon />
        </S.IconContainer>
        <S.Title>
          {unconfirmedSourceCount}{" "}
          {!isPDX
            ? `${makePlural(label, unconfirmedSourceCount)} for review`
            : `near ${makePlural("match", unconfirmedSourceCount, "es")}`}
        </S.Title>
      </S.Header>
      <S.Description>{description}</S.Description>
      <S.TextContainer>
        <S.Text>
          <FormattedText text={firstText} />
        </S.Text>
        <S.Text boldTextIsRisk>
          <FormattedText text={getSecondText(unconfirmedSourceCount)} />
        </S.Text>
      </S.TextContainer>
      <S.Footer>
        You may wish to review these in the Screening section.
      </S.Footer>
    </S.Card>
  );
};
