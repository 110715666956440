import React, { useContext } from "react";
import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { SourceSection } from "./SourceSection";

const SourcingInformation = React.forwardRef((props, ref) => {
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  if (isPDX && !diagnosticsModeEnabled) {
    return null;
  }

  return (
    <SourceSection
      {...props}
      ref={ref}
      isDisregarded
      description="All sources processed by Xapien"
    />
  );
});

export default SourcingInformation;
