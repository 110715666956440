import { styled } from "@linaria/react";
import { grey, green } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div<{ isActive: boolean }>`
  max-width: 317px;
  padding: 16px;
  display: flex;
  color: ${grey.dark};
  border: 1px solid ${grey.rule};
  border-top: ${({ isActive }) =>
    isActive ? `3px solid ${green.xapienGreen}` : `1px solid ${grey.rule}`};
  border-radius: 3px;
  gap: 16px;
  font-size: ${fontSize.sm};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subtitle = styled.div``;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const S = {
  Actions,
  Container,
  Content,
  Title,
  Subtitle
};

export default S;
