import React from "react";
import { FormattedText } from "components/atoms/FormattedText";

import S from "./styles";

interface SourceCardEntryProps {
  title: string;
  subtitle: string;
  content?: string;
}

export const SourceCardEntry = ({
  title,
  subtitle,
  content
}: SourceCardEntryProps) => {
  return (
    <S.Container>
      <S.Title>
        <FormattedText text={title} />
      </S.Title>
      <S.Subtitle>
        <FormattedText text={subtitle} />
      </S.Subtitle>
      {!!content && (
        <S.Content>
          <FormattedText text={content} />
        </S.Content>
      )}
    </S.Container>
  );
};
