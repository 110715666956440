import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  KeyboardEvent
} from "react";

import { AnimatePresence, motion } from "framer-motion";
import DeleteAnimationWrapper from "components/atoms/DeleteAnimationWrapper";

import type {
  ContentNode as ContentNodeType,
  TextContentNode
} from "api/insight-reports";

import { useInsightReport } from "util/hooks/useInsightReport";
import useDebounce from "util/hooks/useDebounce";
import {
  InsightReportActions,
  InsightReportStatus
} from "util/hooks/useInsightReport/types";
import threeBallGif from "img/3-slow-balls.gif";
import TextNode from "components/organisms/InsightReport/TextNode";
import WithContextMenu from "components/organisms/InsightReport/WithContextMenu";
import useViewerMode from "util/hooks/useViewerMode";
import useIsMobile from "util/hooks/useIsMobile";

import {
  renderAsRichText,
  renderAsPlainText,
  parseListString,
  getHasBeenDeletedOrMoved,
  getHasBeenReordered,
  getHasBeenDisplacedFromAbove,
  getHasBeenDisplacedFromBelow,
  getReorderedSubSection
} from "components/organisms/InsightReport/utils";

import { isPDX } from "static-config";
import ReactTooltip, { TooltipVariant } from "components/atoms/ReactTooltip";
import S from "./styles";

const MAX_TITLE_LENGTH = 50;

const ContentNode = ({
  id,
  nodeType,
  content,
  value,
  supportingSentences,
  subject,
  section,
  subSection,
  parentIds = [],
  isParentHeading = false,
  isSourced,
  isModalText = false,
  isUnknown,
  supportLevel,
  isTemporaryReorderedSubsection = false,
  hidePlaceholder = false,
  isDisclaimer = false
}: ContentNodeType & {
  subject?: string;
  section?: string;
  parentIds?: string[];
  isParentHeading?: boolean;
  isModalText?: boolean;
  isTemporaryReorderedSubsection?: boolean;
  hidePlaceholder?: boolean;
  isDisclaimer?: boolean;
}) => {
  const { isViewerModeEnabled } = useViewerMode();
  const { debounce } = useDebounce();
  const titleElementRef = useRef<HTMLHeadingElement | null>(null);
  const [isLoadingSources, setIsLoadingSources] = useState(false);
  const [hasNewSources, setHasNewSources] = useState(false);
  const [hasErrorFetchingSources, setHasErrorFetchingSources] = useState(false);
  const [isAnimatingIn, setIsAnimatingIn] = useState(false);
  const [isHidingPlaceholder, setIsHidingPlaceholder] =
    useState(hidePlaceholder);
  const { state, dispatch } = useInsightReport();
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsHidingPlaceholder(hidePlaceholder);
  }, [hidePlaceholder]);

  useEffect(() => {
    if (isTemporaryReorderedSubsection) {
      setTimeout(() => {
        setIsAnimatingIn(true);
      }, 500);
    }
  }, [isTemporaryReorderedSubsection]);

  useEffect(() => {
    if (state.status === InsightReportStatus.editingTitle) {
      titleElementRef?.current?.focus();
    }
  }, [state.status]);

  const debounceEditTitle = debounce((title: string) => {
    dispatch({
      type: InsightReportActions.updateTitle,
      title
    });
  }, 400);

  const onStopEditing = () => {
    const title = titleElementRef.current?.textContent ?? "";

    dispatch({
      type: InsightReportActions.updateTitle,
      title
    });
    dispatch({ type: InsightReportActions.stopEditingTitle });
  };

  const onEditTitleKeyDown = (e: KeyboardEvent<HTMLHeadingElement>) => {
    if (!e.currentTarget.textContent) {
      setIsHidingPlaceholder(false);
      return;
    }

    setIsHidingPlaceholder(true);

    const title = e.currentTarget.textContent;

    if (e.key === "Enter") {
      dispatch({
        type: InsightReportActions.updateTitle,
        title
      });
      onStopEditing();
    }

    if (e.key === "Backspace") {
      return;
    }

    if (
      titleElementRef?.current?.textContent &&
      titleElementRef?.current?.textContent?.length > MAX_TITLE_LENGTH
    ) {
      e.preventDefault();
    }

    debounceEditTitle(title);
  };

  const onStartEditingTitle = () => {
    if (isViewerModeEnabled || isPDX || isMobile) {
      return;
    }

    const [sectionId, subsectionId] = parentIds;

    if ((!sectionId && !subsectionId) || sectionId === id) {
      dispatch({
        type: InsightReportActions.startEditingTitle,
        sectionId: id
      });

      return;
    }

    if (!subsectionId || subsectionId === id) {
      dispatch({
        type: InsightReportActions.startEditingTitle,
        sectionId,
        subsectionId: id
      });

      return;
    }

    dispatch({
      type: InsightReportActions.startEditingTitle,
      sectionId,
      subsectionId,
      elementId: id
    });
  };

  const moveCursorToEndOfTitle = () => {
    const titleElement = titleElementRef?.current;

    if (!titleElement) return;

    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(titleElement, titleElement.childNodes.length);
    range.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  useEffect(() => {
    if (
      state.fetchedSourcesNodeIds.includes(id) &&
      state.status === InsightReportStatus.fetchingSources
    ) {
      setIsLoadingSources(true);
      setHasErrorFetchingSources(false);
    }
  }, [state, id]);

  useEffect(() => {
    if (isLoadingSources && isSourced) {
      setIsLoadingSources(false);
      setHasNewSources(true);
      setHasErrorFetchingSources(false);
      return;
    }

    if (isSourced) {
      setIsLoadingSources(false);
      setHasErrorFetchingSources(false);
    }
  }, [state.fetchedSourcesNodeIds, isSourced, isLoadingSources, id]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.errorFetchingSources &&
      state.errorFetchingSourcesId === id
    ) {
      setIsLoadingSources(false);
      setHasErrorFetchingSources(true);
    } else {
      setHasErrorFetchingSources(false);
    }
  }, [state, id]);

  const isReordering =
    state.status === InsightReportStatus.reorderingSubSections;

  const hasBeenDeletedOrMoved = getHasBeenDeletedOrMoved({
    nodeType,
    state,
    parentIds,
    id
  });

  const hasBeenReordered = getHasBeenReordered({ nodeType, state, id });
  const hasBeenDisplacedFromAbove = getHasBeenDisplacedFromAbove({
    nodeType,
    state,
    id,
    parentIds
  });
  const hasBeenDisplacedFromBelow = getHasBeenDisplacedFromBelow({
    nodeType,
    state,
    id,
    parentIds
  });

  const reorderedSubsection =
    hasBeenDisplacedFromAbove || hasBeenDisplacedFromBelow
      ? getReorderedSubSection({ state })
      : null;

  const renderSubContent = (
    isHeading?: boolean,
    contentToBeParsed = content
  ) => {
    return (
      value ||
      contentToBeParsed?.map((subContent, index) => (
        <Fragment key={`SubContent-${nodeType}-${index}`}>
          <ContentNode
            parentIds={[...parentIds, id]}
            subject={subject}
            section={section}
            subSection={subSection}
            isSourced={isSourced}
            isParentHeading={
              isHeading !== undefined ? isHeading : isParentHeading
            }
            hidePlaceholder={isHidingPlaceholder}
            {...subContent}
          />
        </Fragment>
      ))
    );
  };

  const renderModalSubContent = () => {
    const sanitizedForModalContent = content?.map(item => {
      if (item.nodeType === "p") {
        return { ...item, isModalText: true };
      }
      return item;
    });
    return renderSubContent(false, sanitizedForModalContent);
  };

  const renderSourcingAnimation = () => {
    if (isModalText) return null;

    return (
      <>
        {hasNewSources && (
          <S.SourcesFetchedSuccessIconContainer>
            <ReactTooltip
              tooltip="Sources ready to view"
              minWidth="140px"
              variant={TooltipVariant.DARK}
              layoutPosition="absolute"
              arrow={false}
            >
              <S.SourcesFetchedSuccessIcon aria-label="Sourcing confirmation icon" />
            </ReactTooltip>
          </S.SourcesFetchedSuccessIconContainer>
        )}
        {hasErrorFetchingSources && (
          <S.SourcesFetchedErrorIconContainer>
            <ReactTooltip
              tooltip="Failed to generate sources"
              minWidth="180px"
              variant={TooltipVariant.DARK}
              layoutPosition="absolute"
              arrow={false}
            >
              <S.SourcesFetchedErrorIcon />
            </ReactTooltip>
          </S.SourcesFetchedErrorIconContainer>
        )}
        {isLoadingSources && (
          <S.ThreeBallsContainer>
            <ReactTooltip
              tooltip="Generating sources"
              minWidth="140px"
              variant={TooltipVariant.DARK}
              layoutPosition="absolute"
              arrow={false}
            >
              <S.ThreeBalls
                aria-label="Source generating icon"
                src={threeBallGif}
              />
            </ReactTooltip>
          </S.ThreeBallsContainer>
        )}
      </>
    );
  };

  switch (nodeType) {
    case "h1": {
      return (
        <S.Heading1
          ref={titleElementRef}
          contentEditable={
            state.status === InsightReportStatus.addingNewSection ||
            (state.status === InsightReportStatus.editingTitle &&
              state.updatedTitleSectionId === id &&
              !state.updatedTitleSubsectionId)
          }
          onBlur={onStopEditing}
          onClick={onStartEditingTitle}
          onFocus={moveCursorToEndOfTitle}
          onKeyDown={onEditTitleKeyDown}
          suppressContentEditableWarning
          spellCheck={false}
        >
          {renderSubContent(true)}
        </S.Heading1>
      );
    }
    case "h2": {
      return (
        <S.Heading2
          ref={titleElementRef}
          contentEditable={
            state.status === InsightReportStatus.editingTitle &&
            state.updatedTitleSubsectionId === id &&
            !state.updatedTitleElementId
          }
          onBlur={onStopEditing}
          onFocus={moveCursorToEndOfTitle}
          onClick={onStartEditingTitle}
          onKeyDown={onEditTitleKeyDown}
          suppressContentEditableWarning
          spellCheck={false}
        >
          {renderSubContent(true)}
        </S.Heading2>
      );
    }
    case "h3": {
      return (
        <S.Heading3
          aria-label="Insights Subheading"
          ref={titleElementRef}
          contentEditable={
            state.status === InsightReportStatus.editingTitle &&
            state.updatedTitleElementId === id
          }
          onBlur={onStopEditing}
          onFocus={moveCursorToEndOfTitle}
          onClick={onStartEditingTitle}
          onKeyDown={onEditTitleKeyDown}
          suppressContentEditableWarning
          spellCheck={false}
        >
          {renderSubContent(true)}
        </S.Heading3>
      );
    }
    case "h4": {
      return <S.Heading4>{renderSubContent(true)}</S.Heading4>;
    }
    case "h5": {
      return <S.Heading5>{renderSubContent(true)}</S.Heading5>;
    }
    case "div": {
      const richTextContent = content
        ? content.map(subContent => renderAsRichText(subContent)).join("")
        : value ?? "";

      const plainTextContent = content
        ? content.map(subContent => renderAsPlainText(subContent)).join("")
        : value ?? "";

      if (
        isDisclaimer ||
        (content.length && (content[0] as TextContentNode)?.isDisclaimer)
      ) {
        return <S.Division>{renderSubContent()}</S.Division>;
      }

      return (
        <>
          {hasBeenDisplacedFromBelow && reorderedSubsection && (
            <S.Division>
              <ContentNode
                parentIds={[...parentIds, id]}
                subject={subject}
                section={section}
                subSection={subSection}
                isSourced={isSourced}
                isParentHeading={false}
                isTemporaryReorderedSubsection
                {...reorderedSubsection}
              />
            </S.Division>
          )}
          <WithContextMenu
            id={id}
            subject={subject}
            richText={richTextContent}
            plainText={plainTextContent}
            sectionTitle={section}
            sanitizedChildren={renderModalSubContent()}
            onEdit={onStartEditingTitle}
          >
            <AnimatePresence initial={false}>
              {!hasBeenDeletedOrMoved && !hasBeenReordered && (
                <DeleteAnimationWrapper isReordering={isReordering}>
                  <S.Division>{renderSubContent()}</S.Division>
                </DeleteAnimationWrapper>
              )}
              {isAnimatingIn && (
                <motion.div
                  transition={{
                    opacity: { duration: 0.5 },
                    height: { duration: 0.5 }
                  }}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                >
                  <S.Division>{renderSubContent()}</S.Division>
                </motion.div>
              )}
            </AnimatePresence>
          </WithContextMenu>
          {hasBeenDisplacedFromAbove && reorderedSubsection && (
            <S.Division>
              <ContentNode
                parentIds={[...parentIds, id]}
                subject={subject}
                section={section}
                subSection={subSection}
                isSourced={isSourced}
                isParentHeading={false}
                isTemporaryReorderedSubsection
                {...reorderedSubsection}
              />
            </S.Division>
          )}
        </>
      );
    }
    case "p": {
      return (
        <S.ParagraphContainer>
          {renderSourcingAnimation()}
          <S.Paragraph aria-label="Insights Paragraph Content">
            {renderSubContent()}
          </S.Paragraph>
        </S.ParagraphContainer>
      );
    }
    case "text": {
      if (!value) {
        return <S.Placeholder isHidingPlaceholder={isHidingPlaceholder} />;
      }

      return (
        <TextNode
          id={id}
          showSourcing={!isParentHeading && !isUnknown}
          parentIds={parentIds}
          supportingSentences={supportingSentences}
          supportLevel={supportLevel}
          isDisclaimer={isDisclaimer}
        >
          {parseListString(value)}
        </TextNode>
      );
    }
    default: {
      return value;
    }
  }
};

export default ContentNode;
