import { apm } from "@elastic/apm-rum";
import {
  Idam_Contracts_Enums_UsagePeriod,
  IdentityTenantApiService,
  Idam_Contracts_Tenants_UpdateOrganisationRequest,
  Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest,
  Idam_Contracts_Enums_OrganisationRole,
  Idam_Contracts_Organisations_UpdateOrganisationUserRequest,
  Idam_Contracts_Enums_UserState,
  Idam_Contracts_Tenants_CreateOrganisationRequest_SamlConfigData
} from "api/idam";
import { Idam_Contracts_Tenants_CloneReportRequest } from "api/idam/models/Idam_Contracts_Tenants_CloneReportRequest";
import { FetchResult, UserManagementApi } from "api/types";
import { getErrorMessage } from "api/util";

import {
  AssumeRoleResponse,
  Group,
  GroupReportRole,
  Organisation,
  User,
  OrganisationDetails,
  OrganisationUsage,
  OrganisationFeatures,
  OrganisationSsoStatus
} from "./types";

export default class Tenant implements UserManagementApi {
  async createOrganisation(
    name: string,
    description?: string,
    users?: string[],
    admins?: string[],
    samlConfig?: Idam_Contracts_Tenants_CreateOrganisationRequest_SamlConfigData
  ): Promise<FetchResult> {
    try {
      // TODO: Backend to modify this endpoint to accept users and admins
      const requestBody = {
        name,
        description,
        users,
        admins,
        samlConfig
      };

      await IdentityTenantApiService.postTenantsOrganisations({ requestBody });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrganisations(): Promise<FetchResult<Organisation[]>> {
    try {
      const response = await IdentityTenantApiService.getTenantsOrganisations();

      return {
        status: true,
        response: response.map(org => ({
          organisationId: org.organisationId ?? "unknown",
          name: org.name ?? "unknown",
          description: org.description ?? ""
        }))
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getUsersFromOrganisation(
    organisationId: string
  ): Promise<FetchResult<User[]>> {
    try {
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsUsers({
          organisationId
        });

      return {
        status: true,
        response: response.map(user => ({
          userId: user.userId ?? "unknown",
          name: `${user.firstName} ${user.lastName ? user.lastName : ""}`,
          email: user.email ?? "unknown",
          jobTitle: user.jobTitle ?? "",
          organisationId,
          state: user.state ?? Idam_Contracts_Enums_UserState.ENABLED
        }))
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getGroupsFromOrganisation(
    organisationId: string
  ): Promise<FetchResult<Group[]>> {
    try {
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsGroups({
          organisationId
        });

      return {
        status: true,
        response: response.map(group => ({
          groupId: group.groupId ?? "unknown",
          title: group.name ?? "unknown",
          description: group.description ?? ""
        }))
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async sendCopyOfReport({
    orgId,
    reportId,
    newOwnerId,
    newGroupId,
    newGroupAccessPermission,
    notifyOwner
  }: {
    orgId: string;
    reportId: string;
    newOwnerId: string;
    newGroupId?: string;
    newGroupAccessPermission?: GroupReportRole;
    notifyOwner?: boolean;
  }): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Tenants_CloneReportRequest = {
        reportId,
        newOwnerId,
        notifyNewOwner: notifyOwner,
        groups:
          newGroupId && newGroupAccessPermission
            ? [{ id: newGroupId, role: newGroupAccessPermission }]
            : undefined
      };

      await IdentityTenantApiService.postTenantsOrganisationsReportsClone({
        organisationId: orgId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      console.error(e);
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrganisationUsage(
    organisationId: string
  ): Promise<FetchResult<OrganisationUsage>> {
    try {
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsUsage({
          organisationId
        });

      return {
        status: true,
        response: {
          reportsRunPast30Days: response.reportsRunPast30Days ?? 0,
          reportsRunPast7Days: response.reportsRunPast7Days ?? 0,
          reportsRunPastYear: response.reportsRunPastYear ?? 0,
          reportsRunSinceOrgCreated:
            response.reportsRunSinceOrganisationCreation ?? 0
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async downloadOrganisationUsage(orgId: string): Promise<FetchResult<string>> {
    try {
      const requestBody = {
        organisationId: orgId,
        usagePeriod:
          Idam_Contracts_Enums_UsagePeriod.REPORTS_RUN_SINCE_ORGANISATION_CREATION
      };
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsUsageExport(
          requestBody
        );

      if (response && response.url) {
        return { status: true, response: response.url };
      }

      return { status: false };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getOrganisationDetails(
    organisationId: string
  ): Promise<FetchResult<OrganisationDetails>> {
    try {
      const response = await IdentityTenantApiService.getTenantsOrganisations1({
        organisationId
      });

      return {
        status: true,
        response: {
          organisationId: response.organisationId!,
          name: response.name!,
          description: response.description ?? "",
          admins: response.admins ?? 0,
          users: response.users ?? 0,
          groups: response.groups ?? 0,
          enabled: response.state === "Enabled",
          features: {
            xapienInsights: response.features?.xapienInsights ?? false,
            adverseMediaMonitoring:
              response.features?.adverseMediaMonitoring ?? false,
            sanctionMonitoring: response.features?.sanctionMonitoring ?? false,
            mfa: response.features?.mfa ?? false,
            legalEntityReports: response.features?.legalEntityReports ?? false
          },
          ssoStatus: (response.ssoStatus ??
            OrganisationSsoStatus.NotConfigured) as OrganisationSsoStatus
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateOrganisationDetails(
    orgId: string,
    name: string,
    description: string
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Tenants_UpdateOrganisationRequest = {
        name,
        description
      };

      await IdentityTenantApiService.putTenantsOrganisations({
        organisationId: orgId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateOrganisationFeatures(
    orgId: string,
    features: OrganisationFeatures
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest =
        features;

      await IdentityTenantApiService.putTenantsOrganisationsFeatures({
        organisationId: orgId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async assumeRole(orgId: string): Promise<FetchResult<AssumeRoleResponse>> {
    try {
      const response =
        await IdentityTenantApiService.postTenantsOrganisationsAssumeRole({
          organisationId: orgId
        });

      return {
        status: true,
        response: { token: response.idToken!, expires: response.expires! }
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async enableOrganisation(organisationId: string): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.postTenantsOrganisationsEnable({
        organisationId
      });

      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async disableOrganisation(organisationId: string): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.postTenantsOrganisationsDisable({
        organisationId
      });

      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async changeRole({
    userId,
    role
  }: {
    userId: string;
    role: Idam_Contracts_Enums_OrganisationRole;
  }): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.putTenantsUsersRole({
        userId,
        role
      });
      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateUser({
    userId,
    firstName,
    lastName,
    jobTitle
  }: {
    userId: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
  }): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_UpdateOrganisationUserRequest =
        {
          firstName,
          lastName,
          jobTitle
        };

      await IdentityTenantApiService.putTenantsUsers({
        userId,
        requestBody
      });

      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async deleteUser(
    userId: string,
    deleteReports?: boolean,
    newReportOwnerId?: string
  ): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.deleteTenantsUsers({
        userId,
        deleteReports,
        newReportOwnerId
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }
}
