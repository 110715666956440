import React from "react";
import { Minus, Plus } from "react-feather";

import S from "./styles";

export enum MinusPlusIconType {
  minus,
  plus
}

interface MinusIconProps {
  type: MinusPlusIconType;
  variant?: "small" | "medium";
  isDisabled?: boolean;
}

export const MinusPlusIcon = ({
  type,
  variant = "small",
  isDisabled = false
}: MinusIconProps) => {
  return (
    <S.IconWrapper variant={variant} isDisabled={isDisabled}>
      {type === MinusPlusIconType.plus ? <Plus /> : <Minus />}
    </S.IconWrapper>
  );
};
