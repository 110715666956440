export const grey = {
  dark: "#474952",
  darkish: "#808080",
  mid42: "#6b6b6b",
  hover: "#848895",
  mid: "#858996",
  ghost: "#b3b5bd",
  midLight: "#cccdd2",
  rule: "#d2d3d8",
  lightRule: "#d9d9d9",
  light: "#dfe1e3",
  faint: "#eaebec",
  faintish: "#E4E4E4",
  panel: "#EDEDED",
  panelHover: "#B8B8B8",
  panelLight: "#F2F2F2",
  lightest: "#fafafa",
  lighterPanel: "#fbfbfb",
  panelDark: "#36373e",
  disabled: "#D2D3D8"
};

export const purple = {
  lighter: "#EBE5F2",
  light: "#DBCFE8",
  lightish: "#CEB5E9",
  mid: "#8e70b8",
  dark: "#6a4394",
  darkLightened: "#ded8e7",
  // XI Purple
  darker: "#7439B3",
  darkerHover: "#4C0F8D",
  darkerLightened: "#f4f1f9",
  darkest: "#542487",
  beta: "#AD59E0"
};

export const blue = {
  xapienDark: "#0E1A29",
  blueGreen: "#005572",
  blueDark: "#2f5597",
  blueGrey: "#8796ae",
  blueDiamond: "#28e4fa",
  mid: "#4674ba",
  xapienBrandBlue: "#4b7a94",
  menu: "#4f94b4",
  hover: "#0095cc",
  icon: "#007AFF",
  xapienLight: "#28effa",
  xapienSecondaryLight: "#1F7DD9",
  portalBlue: "#243c59",
  portalPanel: "#244F6B",
  portalMenuDrawer: "#254e6b",
  panel: "#174E78",
  link: "#1F7DD9",
  boxShadow: "#1FCCC0"
};

export const red = {
  dark: "#df0000",
  shallow: "#9a2e41",
  directRisk: "#ef7273",
  directRiskFill: "#f45b5b",
  directRiskFillHover: "#AF2E2E",
  directRiskOutline: "#f14b4b",
  muted: "#f56e6e",
  badgeRed: "#e51010",
  directRiskOpaque: "#fbeeed"
};

export const orange = {
  amber: "#ff9d00",
  di: "#ff5400",
  indirectRisk: "#ff7a41",
  indirectRiskFill: "#ff8c5a",
  light: "#FDBD6D"
};

export const green = {
  pale: "#DEF8F6",
  light: "#75cf64",
  xapienGreen: "#19a49b",
  hover: "#E8F6F5",
  mid: "#007c89",
  secondary: "#338c50",
  dark: "#158880",
  secondaryLight: "#61b232"
};

export const yellow = {
  mud: "#9e5e22",
  slime: "#FFD600",
  amaStars: "#FFD268"
};

export const standardColors = {
  black: "#000000",
  white: "#ffffff"
};

export const black = {
  notQuiteBlack: "#424242"
};
