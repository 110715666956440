/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RiskFactor } from "../models/RiskFactor";
import type { ToggleMuteRequest } from "../models/ToggleMuteRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class RiskFactorsService {
  /**
   * Get Risk Factors
   * used to fetch all risk factors for a report.
   * @returns RiskFactor Success
   * @throws ApiError
   */
  public static getV1ReportsFactors({
    reportId
  }: {
    /**
     * Report ID
     */
    reportId: string;
  }): CancelablePromise<Array<RiskFactor>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/reports/{reportId}/factors",
      path: {
        reportId: reportId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Bulk Add Risk Factors
   * Used to add risk factors to a report in bulk.
   * @returns any Success
   * @throws ApiError
   */
  public static postV1ReportsFactorsBulk({
    reportId,
    request
  }: {
    /**
     * Report ID
     */
    reportId: string;
    /**
     * Risk factors to add
     */
    request: Array<RiskFactor>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/reports/{reportId}/factors/bulk",
      path: {
        reportId: reportId
      },
      body: request,
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Mute or unmute a risk factor
   * Used to either mute or unmute a risk factor.
   * @returns void
   * @throws ApiError
   */
  public static patchV1ReportsFactorsMute({
    reportId,
    name,
    request
  }: {
    /**
     * Report ID
     */
    reportId: string;
    /**
     * Name of the risk factor
     */
    name: string;
    /**
     * Mute status
     */
    request: ToggleMuteRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/reports/{reportId}/factors/{name}/mute",
      path: {
        reportId: reportId,
        name: name
      },
      body: request,
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Risk factor not found`,
        500: `Internal server error`
      }
    });
  }
}
