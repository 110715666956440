import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { grey, red, standardColors } from "styles/colors";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${standardColors.white};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 478px;
  min-width: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Title = styled.div<{ color?: string }>`
  color: ${({ color }) => color || red.directRiskOutline};
  font-size: ${fontSize.lg};
  font-weight: bold;
  margin: 0;
`;

const CardBody = styled.div`
  display: flex;
  gap: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding: 24px 24px 20px;
`;

const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 9px;
  background: ${red.directRiskOpaque};
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ color }) => color || red.directRiskOutline};
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S = {
  Card,
  CardBody,
  ColumnContainer,
  Title,
  IconContainer
};

export default S;
