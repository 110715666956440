import { grey } from "styles/colors";
import { styled } from "@linaria/react";

const FrameworkContainer = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${grey.disabled};

  > div:not(:last-child) {
    border-right: 1px solid ${grey.disabled};
  }
`;

const S = { FrameworkContainer };

export default S;
