import React, { type ReactNode } from "react";

import S from "./styles";

const Pill = ({
  children,
  rightAddon,
  isActive,
  isDisabled,
  onClick
}: {
  children: ReactNode;
  rightAddon?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <S.Container onClick={onClick} isActive={isActive} isDisabled={isDisabled}>
      <S.Content>{children}</S.Content>
      <S.RightAddon isActive={isActive}>{rightAddon}</S.RightAddon>
    </S.Container>
  );
};

export default Pill;
