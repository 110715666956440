import { RiskSummaryTopic } from "api/insights";
import { ReactComponent as ESGSvg } from "img/icons/esg-icon.svg";
import { ReactComponent as RiskFlag } from "img/icons/flag-icon.svg";
import { ReactComponent as MajorCrimesSvg } from "img/icons/law-icon.svg";
import { ReactComponent as FinCrimeRiskFlag } from "img/icons/fin-crime.svg";
import { ReactComponent as SanctionsSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as SpecialInterestSvg } from "img/icons/special-interest-icon.svg";
import {
  ReactComponent as PepsSvg,
  ReactComponent as StateOwnedSvg
} from "img/icons/state-owned.svg";

export const getRiskSummaryConfig = (topic: RiskSummaryTopic) => {
  const riskSummaryAttributes = {
    [RiskSummaryTopic.SANCTIONS]: { icon: SanctionsSvg, title: "Sanctions" },
    [RiskSummaryTopic.WATCHLISTS]: { icon: WatchlistsSvg, title: "Watchlists" },
    [RiskSummaryTopic.PEPS]: { icon: PepsSvg, title: "PEPs" },
    [RiskSummaryTopic.FINANCIAL_CRIME]: {
      icon: FinCrimeRiskFlag,
      title: "Financial Crime"
    },
    [RiskSummaryTopic.ESG]: { icon: ESGSvg, title: "ESG" },
    [RiskSummaryTopic.PROCEEDINGS]: {
      icon: MajorCrimesSvg,
      title: "Proceedings"
    },
    [RiskSummaryTopic.SPECIAL_INTEREST]: {
      icon: SpecialInterestSvg,
      title: "Special Interest"
    },
    [RiskSummaryTopic.STATE_OWNED]: {
      icon: StateOwnedSvg,
      title: "State owned"
    },
    [RiskSummaryTopic.OTHER_FLAGS]: {
      icon: RiskFlag,
      title: "Other"
    }
  };

  return riskSummaryAttributes[topic];
};
