/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReportState {
  IN_PROGRESS = "InProgress",
  DONE = "Done",
  FAILED = "Failed",
  REGENERATING = "Regenerating",
  DELETED = "Deleted"
}
