import React, { FC, useState, useEffect } from "react";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import ModalContainer, {
  Variant as ModalVariant
} from "components/molecules/ModalContainer";
import LoadingScreen from "components/organisms/UBODiagram/LoadingScreen";
import EnquiryApi from "api/enquiry/Enquiry";
import { SubjectType, ContextType } from "api/enquiry/types";
import { NewReportProps } from "util/hooks/useEntityList/types";
import config from "config";
import S from "./styles";

enum NewReportState {
  idle = "idle",
  success = "success",
  loading = "loading",
  error = "error"
}

interface Props {
  id?: string;
  label: string;
  subjectType: SubjectType;
  contexts: { type: ContextType; value: string }[];
  isOpen: boolean;
  onClose: () => void;
  onNewReport?: (props: NewReportProps) => void;
}

const NewReportModal: FC<Props> = ({
  id,
  label,
  subjectType,
  contexts,
  isOpen,
  onClose,
  onNewReport
}) => {
  const [newReportEnquiryId, setNewReportEnquiryId] = useState<
    string | undefined
  >(undefined);
  const [newReportState, setNewReportState] = useState(NewReportState.idle);

  useEffect(() => {
    if (newReportState !== NewReportState.success) return;
    if (!newReportEnquiryId) return;

    window.open(`/report/preparing/${newReportEnquiryId}`, "_blank");
  }, [newReportState, newReportEnquiryId]);

  const handleOnNewReport = () => {
    setNewReportState(NewReportState.loading);
    if (onNewReport) {
      onNewReport({
        subjectType,
        label: id || label,
        contexts,
        successCallback: () => setNewReportState(NewReportState.success),
        failureCallback: () => setNewReportState(NewReportState.error)
      });
    } else {
      const enquiryApi = new EnquiryApi(false);

      const createRequestBody = {
        subject: {
          type: subjectType,
          value: label
        },
        contexts,
        activeFeatures: {
          OrgSocialProfileContradiction: true,
          CROrgMatchingOnlyAgainstSubject: false
        },
        projectReference: ""
      };

      enquiryApi
        .create(createRequestBody)
        .then(response => {
          if (response) {
            setNewReportState(NewReportState.success);
            setNewReportEnquiryId(response.enquiryId);
          } else {
            setNewReportState(NewReportState.error);
          }
        })
        .catch(() => {
          setNewReportState(NewReportState.error);
        });
    }
  };

  const renderModalContent = () => {
    switch (newReportState) {
      case NewReportState.loading: {
        return (
          <>
            <Heading level={4}>
              <strong>Initiating report</strong>
            </Heading>
            <LoadingScreen text="" />
            <p>This won&apos;t take long</p>
          </>
        );
      }
      case NewReportState.success: {
        return (
          <>
            <Heading level={4}>
              <strong>Report generation started</strong>
            </Heading>
            <p>
              Your new report will open in a new tab shortly. If it doesn’t, you
              can{" "}
              <a
                href={`/report/${newReportEnquiryId}`}
                target="_blank"
                rel="noreferrer"
              >
                open it manually
              </a>
              .
            </p>
            <p>The new report will also appear in your report centre.</p>

            <S.ModalButtonsContainer>
              <ButtonNew
                type={ButtonType.FilledLight}
                size={ButtonSize.Medium}
                text="Got it"
                onClick={onClose}
              />
            </S.ModalButtonsContainer>
          </>
        );
      }
      case NewReportState.error: {
        return (
          <>
            <Heading level={4}>
              <strong>Something&apos;s not right</strong>
            </Heading>
            <p>
              We were unable to run a new report on
              <br />
              <strong>{label}</strong>
            </p>
            <p>
              You can try again, or for assistance, contact{" "}
              <a href={`mailto:${config.supportEmail}`}>Customer Success</a>
            </p>

            <S.ModalButtonsContainer>
              <ButtonNew
                type={ButtonType.OutlinedLight}
                size={ButtonSize.Medium}
                text="Cancel"
                onClick={onClose}
              />
              <ButtonNew
                type={ButtonType.FilledLight}
                size={ButtonSize.Medium}
                text="Try again"
                onClick={handleOnNewReport}
              />
            </S.ModalButtonsContainer>
          </>
        );
      }
      default: {
        return (
          <>
            <Heading level={4}>
              <strong>New report</strong>
            </Heading>
            <p>
              Would you like to run a new{" "}
              <S.SubjectType>{subjectType}</S.SubjectType> report on
              <br />
              <strong>{label}</strong>
            </p>
            <p>
              The report will open in a new tab and will count towards your
              report usage.
            </p>

            <S.ModalButtonsContainer>
              <ButtonNew
                type={ButtonType.OutlinedLight}
                size={ButtonSize.Medium}
                text="Cancel"
                onClick={onClose}
              />
              <ButtonNew
                type={ButtonType.FilledLight}
                size={ButtonSize.Medium}
                text="Run report"
                onClick={handleOnNewReport}
              />
            </S.ModalButtonsContainer>
          </>
        );
      }
    }
  };

  return (
    <ModalContainer
      width={420}
      isOpen={isOpen}
      toggleOpen={onClose}
      onExitClick={onClose}
      variant={ModalVariant.LIGHT}
    >
      <S.ModalContainer>{renderModalContent()}</S.ModalContainer>
    </ModalContainer>
  );
};

export default NewReportModal;
