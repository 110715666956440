import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { ChevronRight, ChevronLeft } from "react-feather";

const CarouselControls = styled.div<{ color: string }>`
  width: auto;
  display: flex;
  gap: 3px;
  align-items: baseline;
  font-size: ${fontSize.xs};
  background: transparent;
  color: ${({ color }) => color};
  justify-content: space-between;
  white-space: nowrap;
`;

const CarouselButton = styled.button<{
  color: string;
}>`
  border: none;
  display: flex;
  cursor: pointer;
  background: transparent;
  transition: background 0.3s;
  color: ${({ color }) => color};

  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  width: auto;
  gap: 4px;
  font-size: ${fontSize.xs};
  font-weight: bold;

  :focus,
  :active {
    border: none;
    outline: none;
  }

  span {
    color: ${({ color }) => color};
  }

  :disabled {
    visibility: hidden;
  }
`;

const CountDisplay = styled.div`
  padding-left: 2px;
`;

const ChevronLeftIcon = styled(ChevronLeft)<{ isHidden?: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
  width: 16px;
  height: 16px;

  path {
    fill: currentColor;
  }
`;

const ChevronRightIcon = styled(ChevronRight)<{ isHidden?: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
  width: 16px;
  height: 16px;

  path {
    fill: currentColor;
  }
`;

const S = {
  CountDisplay,
  CarouselButton,
  ChevronLeftIcon,
  ChevronRightIcon,
  CarouselControls
};

export default S;
