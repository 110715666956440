import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { CollectionSearchContextProvider } from "util/hooks/useCollectionSearch";
import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";
import CollectionSearch from "components/organisms/CollectionSearch";
import CollectionList from "components/organisms/CollectionList";
import CollectionNavigation from "components/molecules/CollectionNavigation";
import ReportCard from "components/organisms/ReportCard";
import Api from "api/reports";
import LoadingPage from "components/molecules/LoadingPage";
import { useAuthentication } from "util/hooks/useAuthentication";
import PageTitle from "components/atoms/PageTitle";
import ReportFilters from "components/molecules/ReportFilters";

import { ReportCollectionId } from "api/reports/types";
import {
  CollectionInputType,
  ListCollectionInput
} from "util/hooks/useCollectionList/types";
import S from "./styles";

const SillyDummyEffectComponent = () => {
  // just exists to be able to run this useEffect inside the collection list context
  // a daft way of doing things
  const { dispatch } = useCollectionList();

  useEffect(() => {
    dispatch({
      type: CollectionListActions.showCollection,
      id: ReportCollectionId.Recent
    });
  }, [dispatch]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

type ReportListProps = {
  showAllReports: boolean;
  setShowAllReports: Dispatch<SetStateAction<boolean>>;
};

const ReportList: React.FC<ReportListProps> = ({
  showAllReports,
  setShowAllReports
}) => {
  const {
    state: { collections },
    dispatch
  } = useCollectionList();

  const updateReportInput = () => {
    const excludeGroupSharedReports = showAllReports;
    const excludeUserSharedReports = showAllReports;
    const excludeAllAccessibleReports = showAllReports;

    collections.forEach(({ id, input }) => {
      const updatedInput: ListCollectionInput = {
        ...input,
        type: CollectionInputType.list,
        excludeGroupSharedReports,
        excludeUserSharedReports,
        excludeAllAccessibleReports
      };

      dispatch({
        type: CollectionListActions.updateCollectionInput,
        id,
        input: updatedInput
      });
    });

    setShowAllReports(prev => !prev);
  };

  return (
    <CollectionList
      sortable
      showCardIndicators
      CardComponent={ReportCard}
      emptyCollectionText="No reports"
      toggles={<ReportFilters />}
      navigationComponent={
        <CollectionNavigation
          items={[
            {
              title: "My reports",
              onClick: () => showAllReports && updateReportInput(),
              isActive: !showAllReports
            },
            {
              title: "All reports",
              onClick: () => !showAllReports && updateReportInput(),
              isActive: showAllReports
            }
          ]}
        />
      }
    />
  );
};

const Reports = () => {
  const {
    state: {
      isPermissionsLoaded,
      permissions: { canListReports }
    }
  } = useAuthentication();
  const navigate = useNavigate();
  const [showAllReports, setShowAllReports] = useState(false);

  if (!isPermissionsLoaded) {
    return <LoadingPage />;
  }

  if (!canListReports) {
    navigate("/");
    return <LoadingPage />;
  }

  return (
    <>
      <PageTitle title="Xapien | Reports" />
      <CollectionListContextProvider Api={Api}>
        <CollectionSearchContextProvider Api={Api}>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <S.ViewContainer>
                <S.ViewInnerContainer>
                  <CollectionSearch
                    showAllReports={showAllReports}
                    placeholder="Search reports..."
                  />
                  <SillyDummyEffectComponent />
                  <ReportList
                    showAllReports={showAllReports}
                    setShowAllReports={setShowAllReports}
                  />
                </S.ViewInnerContainer>
              </S.ViewContainer>
            </motion.div>
          </AnimatePresence>
        </CollectionSearchContextProvider>
      </CollectionListContextProvider>
    </>
  );
};

export default Reports;
