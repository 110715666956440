import React, { type FC, type ComponentType } from "react";
import { Clipboard as ClipboardIcon } from "react-feather";

import { EntityList, Entity } from "api/entities/types";

import { NewReportProps } from "util/hooks/useEntityList/types";
import S from "./styles";

export enum EntityCollectionDisplayStyle {
  grid,
  list
}

type CardComponentProps = Entity & {
  onNewReport?: (props: NewReportProps) => void;
};

interface Props {
  title?: string;
  entities?: EntityList;
  onNewReport?: (props: NewReportProps) => void;
  displayStyle: EntityCollectionDisplayStyle;
  emptyEntitiesText?: string;
  CardComponent: ComponentType<CardComponentProps>;
}

const EntityCollectionGrid: FC<{
  entities?: EntityList;
  onNewReport?: (props: NewReportProps) => void;
  CardComponent: ComponentType<CardComponentProps>;
}> = ({ entities, onNewReport, CardComponent }) => {
  return (
    <S.GridContainer>
      {entities?.map(entity => (
        <CardComponent
          key={`EntityCard-${entity.id}`}
          {...entity}
          onNewReport={onNewReport}
        />
      ))}
    </S.GridContainer>
  );
};

const EntityCollectionList: FC<{
  entities?: EntityList;
  onNewReport?: (props: NewReportProps) => void;
  CardComponent: ComponentType<CardComponentProps>;
}> = ({ entities, onNewReport, CardComponent }) => {
  return (
    <S.ListContainer>
      {entities?.map(entity => (
        <CardComponent
          key={`EntityCard-${entity.id}`}
          {...entity}
          onNewReport={onNewReport}
        />
      ))}
    </S.ListContainer>
  );
};

const EntityCollection: FC<Props> = ({
  title,
  entities,
  displayStyle,
  onNewReport,
  emptyEntitiesText,
  CardComponent
}) => {
  const renderEntities = () => {
    switch (displayStyle) {
      case EntityCollectionDisplayStyle.list: {
        return (
          <EntityCollectionList
            entities={entities}
            onNewReport={onNewReport}
            CardComponent={CardComponent}
          />
        );
      }

      case EntityCollectionDisplayStyle.grid:
      default: {
        return (
          <EntityCollectionGrid
            entities={entities}
            onNewReport={onNewReport}
            CardComponent={CardComponent}
          />
        );
      }
    }
  };

  const renderEmptyEntities = () => {
    return (
      <S.EmptyEntitiesContainer>
        <ClipboardIcon />
        {emptyEntitiesText}
      </S.EmptyEntitiesContainer>
    );
  };

  return (
    <>
      {title && <S.Heading level={5}>{title}</S.Heading>}
      {entities && entities.length > 0
        ? renderEntities()
        : renderEmptyEntities()}
    </>
  );
};

export default EntityCollection;
