export { Idam_Contracts_Enums_ReportRole as GroupReportRole } from "api/idam";

export interface Organisation {
  organisationId: string;
  name: string;
  description: string;
}

export interface User {
  userId: string;
  name: string;
  email: string;
  jobTitle: string;
  organisationId: string;
}

export interface Group {
  groupId: string;
  title: string;
  description?: string;
}

export interface OrganisationUsage {
  reportsRunPastYear: number;
  reportsRunPast30Days: number;
  reportsRunPast7Days: number;
  reportsRunSinceOrgCreated: number;
}

export interface OrganisationDetails {
  organisationId: string;
  name: string;
  description?: string;
  admins: number;
  users: number;
  groups: number;
  enabled: boolean;
  features: OrganisationFeatures;
  ssoStatus: OrganisationSsoStatus;
}

export enum OrganisationSsoStatus {
  NotConfigured = "NotConfigured",
  Configuring = "Configuring",
  Configured = "Configured",
  Error = "Error"
}

export interface OrganisationFeatures {
  xapienInsights: boolean;
  adverseMediaMonitoring: boolean;
  sanctionMonitoring: boolean;
  mfa: boolean;
  legalEntityReports: boolean;
}

export interface AssumeRoleResponse {
  token: string;
  expires: number;
}
