import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import { standardColors } from "styles/colors";

// @ts-ignore
import theme from "theme";

const LoginContainer = styled.div`
  max-height: 900px;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const LogoContainer = styled.div`
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SignInContainer = styled.div`
  padding-top: 150px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PoweredByXapienLogo = styled.img`
  width: 100px;
  height: 64px;
  margin-top: auto;
`;

const BalancerDiv = styled.div`
  // this is used to balance the flexbox around the login screen
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  gap: 1.5rem;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  gap: 24px;
`;

const RequestAccessButton = styled(ButtonNew)`
  background-color: ${theme.primaryColor};
  color: ${standardColors.white};
  height: 30px;
  width: 200px;
`;

const SignInButton = styled(ButtonNew)`
  background-color: transparent;
  color: ${standardColors.white};
  border-radius: 100px;
  border: 1px solid var(--Xapien-White, ${standardColors.white});
  height: 30px;
  width: 200px;
`;

const S = {
  SignInButton,
  RequestAccessButton,
  BalancerDiv,
  ButtonContainer,
  SwitchContainer,
  PoweredByXapienLogo,
  SignInContainer,
  LogoContainer,
  WrapContainer,
  LoginContainer
};

export default S;
