import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import { ReactComponent as DJLogoSvg } from "themes/projectdx/img/dj-logo.svg";
import { ReactComponent as ProjectDXSvg } from "themes/projectdx/img/project-dx-logo.svg";
import { ReactComponent as PoweredByXapienSVG } from "themes/projectdx/img/powered-by-xapien-logo.svg";
import { ReactComponent as NavLogoSVG } from "themes/projectdx/img/dj-nav-logo.svg";
import { ReactComponent as DJICLogoSVG } from "themes/projectdx/img/dj-ic-logo.svg";

import { blue } from "themes/projectdx/colors";
import { fontSize, family } from "styles/fonts";
import { standardColors, grey } from "styles/colors";

export const globals = css`
  :global() {
    body {
      background: ${blue.indigo};

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;

      overflow: auto;
      overflow-x: hidden;

      /* remove these? tweaking to trying and fix firefox backround issue */
      background-origin: border-box;
      display: block;

      .shell-outer {
        position: relative;
      }
    }
  }
`;

// Theme components

const LogoContainer = styled.div`
  display: flex;
  padding: 3px 12px 0 5px;
`;

const LogoLabel = styled.div`
  font-size: ${fontSize.sm};
  font-family: ${family.interLight};
  color: ${grey.mid};
  padding-left: 10px;
  padding-top: 2px;
`;

const DJLogoWhite = styled(DJLogoSvg)`
  width: 10px;
  height: 12px;

  path {
    fill: ${standardColors.white};
  }
`;

const DJLogo = styled(DJLogoSvg)`
  width: 26px;
  height: 22px;
`;

const ProjectDXLogo = styled(ProjectDXSvg)`
  width: 300px;
`;

const PoweredByXapienLogo = styled(PoweredByXapienSVG)``;

const DJICLogo = styled(DJICLogoSVG)`
  width: 300px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px;
  margin-bottom: 18px;

  @media print {
    display: none;
  }
`;

const PoweredBy = styled.span`
  font-size: ${fontSize.sm};
  margin-right: 16px;
`;

const NavLogo = styled(NavLogoSVG)`
  width: 359px;
  height: 44px;
  margin-top: 10px;
`;

const Slogan = styled.div`
  color: ${blue.berg};
  span {
    color: ${standardColors.white};
  }
`;

const S = {
  LogoContainer,
  LogoLabel,
  DJLogoWhite,
  DJLogo,
  ProjectDXLogo,
  FooterContainer,
  NavLogo,
  PoweredByXapienLogo,
  DJICLogo,
  PoweredBy,
  Slogan
};

export default S;
