import React, { FC } from "react";

import Popover from "components/atoms/Popover";

import { RISK_FACTOR_CONFIG } from "util/riskService";
import S from "./styles";

const RiskIcons: FC<{
  id: string;
  riskFactors: Record<string, boolean>;
  iconBackgroundColor?: string;
}> = ({ id, riskFactors, iconBackgroundColor }) => {
  const sortedRiskFactors = Object.keys(riskFactors).sort((a, b) => {
    const orderA = RISK_FACTOR_CONFIG[a]?.order ?? Infinity;
    const orderB = RISK_FACTOR_CONFIG[b]?.order ?? Infinity;

    return orderA - orderB;
  });

  return (
    <S.RiskIcons>
      {sortedRiskFactors
        .map(riskFactor => {
          const riskConfig = RISK_FACTOR_CONFIG[riskFactor];

          if (!riskConfig || !riskConfig.showOnSummary) {
            return null;
          }

          const isActive = riskFactors[riskFactor];
          const { activeTooltip, inactiveTooltip, svg: Icon } = riskConfig;

          return (
            <Popover
              content={
                <S.TooltipContent>
                  {isActive ? activeTooltip : inactiveTooltip}
                </S.TooltipContent>
              }
              disableHideOnClip={undefined}
              className={undefined}
              style={undefined}
              key={`Risk-${id}-${riskFactor}`}
            >
              <S.RiskIcon
                isActive={isActive}
                backgroundColor={iconBackgroundColor}
              >
                <Icon />
              </S.RiskIcon>
            </Popover>
          );
        })
        .filter(Boolean)}
    </S.RiskIcons>
  );
};

export default RiskIcons;
