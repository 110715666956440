import { v4 as uuidv4 } from "uuid";
import { InformationSource } from "api/report/report-types";
import {
  InfoListType,
  LocationList,
  EntityType,
  TagList,
  DateList
} from "api/entities/types";
import { type model_RelatedParty } from "api/related-parties";

import { getEntityTagItems } from "./getEntityTagItems";

export const transformCompanyEntity = ({
  bvdId,
  informationSources,
  name,
  relatedPartyId: id,
  relationships,
  companyType,
  locations: locationsResponse
}: model_RelatedParty) => {
  const sources = informationSources
    ? (informationSources as unknown as InformationSource[])
    : [];

  const dates: DateList = {
    type: InfoListType.dates,
    items: []
  };

  const locations: LocationList = {
    type: InfoListType.locations,
    items:
      locationsResponse?.map(({ city, country }) => ({
        id: uuidv4(),
        value: [city, country?.countryName].filter(Boolean).join(", ")
      })) ?? []
  };

  // TODO: This business logic should live on the BE
  const hasReportableData = !!bvdId;

  const tags: TagList = {
    type: InfoListType.tags,
    items: getEntityTagItems({ relationships })
  };

  return {
    id,
    hasReportableData,
    entityType: EntityType.organisation,
    title: name,
    subtitle: companyType,
    sources,
    lists: [dates, locations, tags]
  };
};
