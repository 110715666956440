import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { RiskFactor } from "api/risk-service";
import useDebounce from "util/hooks/useDebounce";
import { hasRiskIconsInRange } from "util/riskService";
import { RiskCarouselButton } from "components/atoms/RiskCarouselButton";
import { RiskIconContainer } from "components/molecules/RiskIconContainer";

import S from "./styles";

interface IconCarouselProps {
  title: string;
  icons: ReactNode[];
  isCarousel?: boolean;
  iconData: Array<RiskFactor>;
}

export const RiskFrameworkSection = ({
  title,
  icons,
  isCarousel,
  iconData
}: IconCarouselProps) => {
  const { debounce } = useDebounce();
  const iconRef = useRef<HTMLDivElement>(null);
  const iconContainerRef = useRef<HTMLDivElement>(null);

  const [index, setIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [iconsConfig, setIconsConfig] = useState({
    iconsToShow: icons.length,
    justifyContent: "",
    paddingLeft: 0,
    gap: 0
  });

  const [hasTransition, setHasTransition] = useState(false);
  const { iconsToShow, gap } = iconsConfig;

  const totalItems = icons.length;
  const notDisplayedIconsCount = totalItems - iconsToShow;

  const showLeftButton = index > 0;
  const showRightButton = index + iconsToShow < totalItems;
  const indexOffset = Math.min(iconsToShow, notDisplayedIconsCount);
  const animationSpeed = indexOffset > 1 ? 0.6 : 0.3;

  const transform = `translateX(-${index * (itemWidth + gap)}px)`;
  const transition = hasTransition
    ? `transform ${animationSpeed}s ease-in-out`
    : "";

  const updateIconsToShow = useCallback(() => {
    if (!iconContainerRef.current) return;

    const iconContainerWidth = iconContainerRef.current.offsetWidth;
    const numIconsToShow = Math.min(
      Math.floor(iconContainerWidth / itemWidth),
      totalItems
    );
    const leftPadding =
      numIconsToShow === 1 ? (iconContainerWidth - itemWidth) / 2 : 0;

    const calculatedGap =
      numIconsToShow === 1
        ? leftPadding
        : Math.ceil(
            (iconContainerWidth - numIconsToShow * itemWidth) /
              (numIconsToShow - 1)
          );

    const showAllAvailableIcons = numIconsToShow === totalItems;

    setIconsConfig({
      iconsToShow: numIconsToShow,
      gap: showAllAvailableIcons ? 0 : calculatedGap,
      paddingLeft: leftPadding,
      justifyContent: showAllAvailableIcons ? "space-around" : ""
    });

    if (showAllAvailableIcons) {
      setIndex(0);
      return;
    }

    const expandIconsToLeft = index > 0 && numIconsToShow + index > totalItems;

    if (expandIconsToLeft) {
      setIndex(totalItems - numIconsToShow);
    }
  }, [totalItems, itemWidth, index]);

  useEffect(() => {
    setItemWidth(iconRef.current?.offsetWidth || 0);
  }, []);

  useEffect(() => {
    if (!isCarousel) return;

    updateIconsToShow();

    const resizeObserver = new ResizeObserver(() => updateIconsToShow());

    if (iconContainerRef.current) {
      resizeObserver.observe(iconContainerRef.current);
    }

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, [icons.length, isCarousel, updateIconsToShow]);

  const handleNext = () => {
    setHasTransition(true);
    if (showRightButton) setIndex(index + indexOffset);
  };

  const handlePrev = () => {
    setHasTransition(true);
    if (showLeftButton) setIndex(Math.max(0, index - indexOffset));
  };

  const onMouseLeave = debounce(() => setHasTransition(false), 400);

  if (!isCarousel) {
    return (
      <S.SectionContainer>
        <S.HeaderContainer>
          <S.Title>{title}</S.Title>
        </S.HeaderContainer>
        <S.FixedIconContainer showMultipleIcons={icons.length > 1}>
          {icons.map((icon, idx) => (
            <S.Icon key={`icon-${idx}`}>{icon}</S.Icon>
          ))}
        </S.FixedIconContainer>
      </S.SectionContainer>
    );
  }

  const RightCarouselControl = (
    <RiskCarouselButton
      direction="right"
      handleClick={handleNext}
      hasRisk={hasRiskIconsInRange(
        iconData,
        index + iconsToShow,
        iconData.length
      )}
    />
  );

  const LeftCarouselControl = (
    <RiskCarouselButton
      direction="left"
      handleClick={handlePrev}
      hasRisk={hasRiskIconsInRange(iconData, 0, index)}
    />
  );

  const swapTitleWithButton = !(showLeftButton && showRightButton);

  return (
    <S.SectionContainer isCarousel>
      <S.HeaderContainer onMouseLeave={onMouseLeave}>
        {swapTitleWithButton ? <S.Title>{title}</S.Title> : LeftCarouselControl}
        {showRightButton && RightCarouselControl}
        {showLeftButton && !showRightButton && LeftCarouselControl}
      </S.HeaderContainer>
      <RiskIconContainer
        {...iconsConfig}
        icons={icons}
        iconRef={iconRef}
        transform={transform}
        transition={transition}
        containerRef={iconContainerRef}
      />
    </S.SectionContainer>
  );
};
