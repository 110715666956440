import React from "react";

import { useNavigate } from "react-router-dom";

import { CollectionListView } from "components/molecules/CollectionListControls";
import { CardVariant } from "components/molecules/Card/types";
import { ChipVariant } from "components/atoms/Chip/types";
import CardSkeleton from "components/atoms/CardSkeleton";
import UserCardMenu from "components/organisms/UserCardMenu";
import { UserActionsProvider } from "util/hooks/useUserActions";
import { formatCardTags } from "util/formatCardTags";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { getInitials } from "util/getInitials";

import { collectionCardComponents } from "util/collectionCardComponents";

import type { User } from "api/users";
import { Idam_Contracts_Enums_UserState } from "api/idam";

import { classNameOverrides } from "./styles";

interface Props {
  item?: User;
  view: CollectionListView;
  loading?: boolean;
  variant: CardVariant;
  onClick?: () => void;
}

const UserCard = ({ item, view, loading, variant, onClick }: Props) => {
  const { C } = collectionCardComponents[view];
  const navigate = useNavigate();

  if (loading) {
    return <CardSkeleton view={view} />;
  }

  if (!item) {
    return null;
  }

  const isEnabled =
    item.resource.state === Idam_Contracts_Enums_UserState.ENABLED;
  const userTags = isEnabled
    ? formatCardTags(item.tags)
    : [
        {
          name: "Disabled",
          variant: ChipVariant.FilledLight,
          helpText: "This user has been disabled."
        }
      ];
  const canClickThrough = item.permissions.canViewReports && isEnabled;

  return (
    <C.Card
      onClick={
        canClickThrough
          ? () =>
              onClick
                ? onClick()
                : navigate({
                    pathname: `/users/${item.id}`,
                    search: `?name=${item.title}&description=${
                      item.jobTitle ?? ""
                    }`
                  })
          : undefined
      }
      variant={variant}
      control={
        <UserActionsProvider>
          <UserCardMenu user={item} />
        </UserActionsProvider>
      }
      className={
        canClickThrough
          ? classNameOverrides.clickableCard
          : classNameOverrides.card
      }
    >
      <C.CardHeader
        variant={variant}
        title={item.title}
        subtitle={item.jobTitle}
        icon={
          <Avatar avatarSize={AvatarSize.Large} hasBorder>
            {getInitials(item.title)}
          </Avatar>
        }
      />
      <C.CardBody variant={variant} tags={userTags} />
    </C.Card>
  );
};

export default UserCard;
