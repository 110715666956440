import React, { useState, useRef, useEffect } from "react";
import numeral from "numeral";
import { observer } from "mobx-react";

import { Resizable } from "re-resizable";

import { AnimatePresence, motion } from "framer-motion";

import { orgReportSectionTitles } from "util/reportSectionTitles";
import SectionHeader from "pages/report/SectionHeader";
import Datalist from "components/atoms/Datalist";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";
import { getAgeFromDob } from "util/getAgeFromDob";
import TruncateLength from "util/TruncateLength";
import { emboldenKeyWords } from "util/emboldenKeyWords";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { ButtonKind } from "components/atoms/Button/types";
import WithInspector from "components/organisms/WithInspector";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import { DiImage } from "components/molecules/DiImage";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import InfoIcon from "components/atoms/InfoIcon";
import { scrollToTopOfComponent } from "util/common";
import Pages, { DIRECTION } from "components/molecules/Pages";
import BackButton from "components/atoms/BackButton";
import { grey } from "styles/colors";
import OrgInfoSummaryRow from "components/molecules/OrgInfoSummaryRow";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import ReactTooltip from "components/atoms/ReactTooltip";
import { useReports } from "util/hooks/useReports";
import { useInsightsEnabled } from "util/hooks/useInsightsEnabled";
import { showInsightReport } from "util/insightReportHelpers";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { RiskFramework } from "components/organisms/RiskFramework";

import theme from "theme";

import S, { classNameOverrides } from "./styles";

const MAX_DEFAULT_MEDIA_LINKS_TO_SHOW = 4;
const MAX_INITIAL_FOUNDERS_TO_SHOW = 2;
const MAX_SOCIAL_PROFILE_WIDTH = 180;

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

const OrganisationOverview = React.forwardRef(
  (
    {
      organisationType,
      industry,
      founders,
      imageOfLogo,
      foundedDate,
      employeeCount = {},
      socialMediaReach,
      summary,
      descriptorSentences,
      onlineProfiles = [],
      strapline,
      homepage,
      relatedOrganisationIds,
      relatedCountries,
      sectionRefs,
      relatedOrgsMap,
      displaySubjectImage = true,
      infographicLayoutTemplate,
      title
    },
    ref
  ) => {
    const reportStore = useReports();
    const isPrintMode = usePrintModeEnabled();

    // All reference IDs should be accounted for, but in case they're not we should fail safely
    // Note this silently ignores the issue, but we'll put some logging in place for it on the backend.
    const relatedCountriesWithFixedIds = relatedCountries.map(country => ({
      ...country,
      referenceIds: country.referenceIds.filter(id => id in relatedOrgsMap)
    }));

    const { report } = reportStore;
    const isShowingInsightReport = report
      ? showInsightReport(report.preparedAtUtc, isPrintMode)
      : false;

    const [isShowingAllMediaLInks, setIsShowingAllMediaLinks] = useState(false);
    const [hasLogoErrored, setLogoErrored] = useState(false);

    const [selectedFounderFilter, setSelectedFounderFilter] = useState();

    const [relatedOrgsCurrentPage, setRelatedOrgsCurrentPage] = useState(0);

    const [relatedCountriesCurrentPage, setRelatedCountriesCurrentPage] =
      useState(0);

    const [selectedRelatedOrgData, setSelectedRelatedOrgData] = useState();

    const [selectedRelatedCountryData, setSelectedRelatedCountryData] =
      useState();

    const [selectedRelatedCountryOrgData, setSelectedRelatedCountryOrgData] =
      useState();

    const [selectedRelatedCountryOrgs, setSelectedRelatedCountryOrgs] =
      useState();

    const [isScaleIconInspectorOpen, setIsScaleIconInspectorOpen] =
      useState(false);

    const orgDescriptionRef = useRef(null);
    const relatedOrgPagesRef = useRef(null);
    const relatedCountriesPagesRef = useRef(null);

    const insightsEnabled = useInsightsEnabled();

    // Keep track of the "active" country data (the country "card" that was clicked) and with that,
    // build up the org data from the ids
    useEffect(() => {
      // Build up orgs from ids
      const orgsFromIds = selectedRelatedCountryData?.referenceIds?.map(id => {
        return relatedOrgsMap[id];
      });
      setSelectedRelatedCountryOrgs(orgsFromIds);
    }, [relatedOrgsMap, selectedRelatedCountryData]);

    const getProfiles = onlineProfilesArr => {
      if (!isShowingAllMediaLInks) {
        // eslint-disable-next-line no-param-reassign
        onlineProfilesArr = onlineProfilesArr.slice(
          0,
          MAX_DEFAULT_MEDIA_LINKS_TO_SHOW
        );
      }

      // If any of the profiles currently visible contain a follower count,
      // then allow SocialMediaIcons to render elements for followers. This
      // will allow for a consistent overflow boundary.
      const showFollowers = onlineProfilesArr.some(profile => {
        return profile.numberOfFollowers > 0;
      });

      return (
        <S.MediaIconsContainer isInspectorEnabled>
          <SocialMediaIcons
            profileData={onlineProfilesArr.map(profile => ({
              data: profile
            }))}
            showFollowerCount={showFollowers}
            isInspectorEnabled
            maxLinkWidth={MAX_SOCIAL_PROFILE_WIDTH}
          />
        </S.MediaIconsContainer>
      );
    };

    const getDurationOfOrganisationString = () => {
      if (!foundedDate || !foundedDate.year) {
        return null;
      }

      const durationOfOrganisation = getAgeFromDob(
        new Date(foundedDate?.year?.toString())
      );

      if (durationOfOrganisation === 0) {
        return null;
      }

      return (
        <S.OrgDuration>
          {" "}
          ({durationOfOrganisation}{" "}
          {durationOfOrganisation === 1 ? "year" : "years"} ago)
        </S.OrgDuration>
      );
    };

    const renderFounded = () => {
      return {
        title: "Founded",
        value: foundedDate ? (
          <WithInspector sources={foundedDate?.sources}>
            <span>
              {foundedDate.monthShort ?? ""} {foundedDate.year}
              {getDurationOfOrganisationString()}
            </span>
          </WithInspector>
        ) : (
          <S.NoneIdentified>None identified</S.NoneIdentified>
        )
      };
    };

    const getEmployeeCountRange = () => {
      const upperCount = employeeCount?.upper;
      const lowerCount = employeeCount?.lower;

      if (upperCount && lowerCount) {
        const formattedUpperCount = numeral(upperCount).format("0a");
        const formattedLowerCount = numeral(lowerCount).format("0a");

        if (formattedUpperCount === formattedLowerCount) {
          return formattedLowerCount;
        }

        return `${formattedLowerCount}–${formattedUpperCount}`;
      }

      if (upperCount) {
        return `${numeral(upperCount).format("0a")}`;
      }

      if (lowerCount) {
        return `${numeral(lowerCount).format("0a")}+`;
      }

      return "–";
    };

    const onShowAllMediaLinksClick = () => {
      setIsShowingAllMediaLinks(prevState => !prevState);
    };

    const renderOrgDetailsGroup1 = () => {
      return (
        <Datalist
          shouldDataValuesWrap
          fieldList={[
            {
              title: "Principal nature of business",
              value: industry ? (
                industry.text && (
                  <WithInspector sources={industry?.sources}>
                    <div aria-label="Principal nature of business">
                      {industry?.text}
                    </div>
                  </WithInspector>
                )
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              )
            },
            {
              title: "Organisation type",
              value: organisationType ? (
                organisationType.text && (
                  <WithInspector sources={organisationType.sources}>
                    {organisationType.text}
                  </WithInspector>
                )
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              )
            }
          ]}
        />
      );
    };

    const renderFounders = () => {
      const foundersAsString = founders
        ?.slice(0, MAX_INITIAL_FOUNDERS_TO_SHOW)
        ?.map(founder => founder.name)
        .join(", ");

      const transformedFounders = founders.map(founder => {
        const date = getDurationStringFromDates({
          startDate: founder.dates.startDate,
          endDate: founder.dates.endDate,
          isKnownToBeOngoing: founder.dates.isKnownToBeOngoing
        });

        return {
          topSectionElement: (
            <S.FounderPill>
              <TruncateLength>{founder.name}</TruncateLength>
              <S.Duration isSelected={selectedFounderFilter === founder.name}>
                {date ?? <S.NoDate>No date</S.NoDate>}
              </S.Duration>
            </S.FounderPill>
          ),
          sources: founder?.sources,
          id: founder.name
        };
      });

      return (
        <WithInspector
          dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
          filterableSources={transformedFounders}
          onFilterSelected={setSelectedFounderFilter}
          selectedFilterOverride={selectedFounderFilter}
          isInspectorOpen={isOpen => {
            if (!isOpen) {
              setSelectedFounderFilter(null);
            }
          }}
        >
          <S.Founders>
            {foundersAsString}{" "}
            {founders.length > MAX_INITIAL_FOUNDERS_TO_SHOW && (
              <S.Info>
                <span>{`+${
                  founders.length - MAX_INITIAL_FOUNDERS_TO_SHOW
                }`}</span>
              </S.Info>
            )}
          </S.Founders>
        </WithInspector>
      );
    };

    const renderOrgDetailsGroup2 = () => {
      const foundersTitleString =
        founders?.length === 1 ? "Founder" : "Founders";

      return (
        <Datalist
          shouldDataValuesWrap
          fieldList={[
            renderFounded(),
            {
              title: foundersTitleString,
              value:
                founders?.length > 0 ? (
                  renderFounders()
                ) : (
                  <S.NoneIdentified>None identified</S.NoneIdentified>
                )
            }
          ]}
        />
      );
    };

    const renderSocialReachInfoGraphic = () => {
      const isCountPresent = socialMediaReach?.level?.length;

      const socialReachExplainer = (
        <>
          {!isCountPresent && (
            <S.NoSocialPresenceLabel>
              No social presence identified.
            </S.NoSocialPresenceLabel>
          )}
          Ranked by the volume of the organisation&apos;s social media
          followers: <br />
          <S.SocialReachTooltipList>
            <li>Low &lt;10k</li>
            <li>Medium 10k–1m</li>
            <li>High &gt;1m</li>
          </S.SocialReachTooltipList>
        </>
      );

      const getSocialReachElements = () => {
        return (
          <>
            <S.SocialReachIcon
              color={isCountPresent ? theme.primaryColor : grey.ghost}
            />
            <S.OrgScaleDetail color={isCountPresent ? "inherit" : grey.mid}>
              <span>{socialMediaReach?.level}</span>
              Social reach
            </S.OrgScaleDetail>
          </>
        );
      };

      return (
        <WithInspector
          isInspectorOpen={value => setIsScaleIconInspectorOpen(value)}
          disabled={!socialMediaReach?.level?.length}
          sources={socialMediaReach?.sources}
          topSectionElement={
            <S.SmallSocialReachIconInfographic>
              {getSocialReachElements(false)}
            </S.SmallSocialReachIconInfographic>
          }
          popoverSubtext={socialReachExplainer}
        >
          <ReactTooltip
            disabled={isScaleIconInspectorOpen}
            tooltip={<div>{socialReachExplainer}</div>}
            delay={600}
            maxWidth="300px"
          >
            <S.OrgScaleItem>{getSocialReachElements()}</S.OrgScaleItem>
          </ReactTooltip>
        </WithInspector>
      );
    };

    const renderEmployeeCountInfoGraphic = () => {
      const isCountPresent = employeeCount?.upper || employeeCount?.lower;
      const employeeCountElements = (
        <>
          <S.EmployeeCardIcon
            color={isCountPresent ? theme.primaryColor : grey.ghost}
          />
          <S.OrgScaleDetail color={isCountPresent ? "inherit" : grey.mid}>
            <span>{getEmployeeCountRange()}</span>
            Employees
          </S.OrgScaleDetail>
        </>
      );

      return (
        <WithInspector
          isInspectorOpen={value => setIsScaleIconInspectorOpen(value)}
          disabled={!isCountPresent}
          sources={employeeCount?.sources}
          topSectionElement={
            <S.SmallEmployeeIconInfographic>
              {employeeCountElements}
            </S.SmallEmployeeIconInfographic>
          }
        >
          <ReactTooltip
            disabled={isScaleIconInspectorOpen}
            delay={600}
            maxWidth="300px"
            tooltip={<div>Number of employees your subject has.</div>}
          >
            <S.OrgScaleItem>{employeeCountElements}</S.OrgScaleItem>
          </ReactTooltip>
        </WithInspector>
      );
    };

    const renderOrgScale = () => {
      const relatedOrganisationsFromIds = relatedOrganisationIds?.map(
        id => relatedOrgsMap[id]
      );
      const numRelatedCompanies = relatedOrganisationsFromIds?.length;
      const relatedCompaniesWithRisk = relatedOrganisationsFromIds?.filter(
        org => {
          return org.orgStatus?.statusTag === "Warning" || org.risk?.length;
        }
      );
      const numRelatedCompaniesWithRisk = relatedCompaniesWithRisk?.length;
      const formattedNumCompaniesWithRisk =
        numRelatedCompaniesWithRisk > 99 ? "99+" : numRelatedCompaniesWithRisk;

      const numCountriesActive = relatedCountriesWithFixedIds?.length;
      const relatedCountriesWithRisk = relatedCountriesWithFixedIds?.filter(
        country => country.riskFlags?.length > 0
      );
      const numRelatedCountriesWithRisk = relatedCountriesWithRisk?.length;
      const formattedNumCountriesWithRisk =
        numRelatedCountriesWithRisk > 99 ? "99+" : numRelatedCountriesWithRisk;
      const countryString = numCountriesActive > 1 ? "countries" : "country";
      const organisationString =
        numRelatedCompanies > 1 ? "organisations" : "organisation";

      const getDuplicateOrgs = orgs => {
        const duplicateOrgNames = new Set();
        orgs?.forEach((org, index) => {
          const firstFoundIndex = orgs.findIndex(
            org2 => org2.name.toLowerCase() === org.name.toLowerCase()
          );
          // If the org that is found with a matching name to the org at our current position, then
          // there is an org before our current position, with the same name - a duplicate
          if (firstFoundIndex !== index) {
            duplicateOrgNames.add(org.name.toLowerCase());
          }
        });
        return duplicateOrgNames;
      };

      const renderRelatedOrganisations = () => {
        // Bring organisations that contain risk to the top
        relatedOrganisationsFromIds?.slice()?.sort(a => {
          if (
            a.orgStatus?.statusTag === "Warning" ||
            a.risk?.length ||
            a.locationRisk?.length
          ) {
            return -1;
          }
          return 0;
        });

        const duplicateOrgNames = getDuplicateOrgs(relatedOrganisationsFromIds);

        return (
          <Resizable
            handleStyles={{ bottom: { bottom: -25 } }}
            bounds="window"
            minHeight={275}
            defaultSize={{ height: 240 }}
            enable={{
              top: false,
              right: false,
              bottom: true,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
          >
            <Pages
              ref={relatedOrgPagesRef}
              onPageChange={currentPage =>
                setRelatedOrgsCurrentPage(currentPage)
              }
              pages={[
                relatedOrganisationsFromIds?.map(org => (
                  <OrgInfoSummaryRow
                    key={org.id}
                    orgName={org.name}
                    previousNames={org.previousNames}
                    industry={org.industry}
                    companyStatus={org.orgStatus}
                    sources={org.sources}
                    reasonsForRisk={org.risk}
                    companyLocation={org.registeredAddress}
                    addressFullDisplay={org.registeredAddress?.address}
                    addressShortDisplay={org.registeredAddress?.shortAddress}
                    companyIdentifiers={
                      duplicateOrgNames.has(org.name.toLowerCase())
                        ? org.companyIdentifiers
                        : null
                    }
                    onClick={() => {
                      setSelectedRelatedOrgData(org);
                      relatedOrgPagesRef.current?.switchPageByDirection(
                        DIRECTION.forward
                      );
                    }}
                  />
                )),
                <S.CustomSourceList
                  hideSourceCount
                  sources={selectedRelatedOrgData?.sources}
                />
              ]}
            />
          </Resizable>
        );
      };

      const renderRelatedCountries = () => {
        // Bring locations that have risk to the top
        relatedCountriesWithFixedIds?.slice()?.sort(a => {
          if (a.riskFlags?.length) {
            return -1;
          }
          return 0;
        });

        return (
          <Resizable
            handleStyles={{ bottom: { bottom: -25 } }}
            bounds="window"
            minHeight={275}
            defaultSize={{ height: 240 }}
            enable={{
              top: false,
              right: false,
              bottom: true,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
          >
            <Pages
              ref={relatedCountriesPagesRef}
              onPageChange={currentPage =>
                setRelatedCountriesCurrentPage(currentPage)
              }
              pages={[
                relatedCountriesWithFixedIds?.map(country => {
                  const groupedCompanies = country.referenceIds;
                  return (
                    <S.InfoSummaryRow
                      key={country.countryName}
                      onClick={() => {
                        setSelectedRelatedCountryData(country);
                        relatedCountriesPagesRef.current?.switchPageByDirection(
                          DIRECTION.forward
                        );
                      }}
                      interactive={groupedCompanies?.length}
                      icon={
                        <S.CountryFlag
                          code={country.countryCode}
                          showCodeString={false}
                        />
                      }
                      heading={
                        <S.RelatedCountryHeader>
                          {country.countryName}{" "}
                        </S.RelatedCountryHeader>
                      }
                      subheading={
                        groupedCompanies?.length
                          ? `${groupedCompanies?.length} ${
                              groupedCompanies?.length > 1
                                ? "companies"
                                : "company"
                            }`
                          : null
                      }
                      content={
                        <div>
                          {country.riskFlags?.length ? (
                            <S.RiskAssigners>
                              {country.riskFlags?.map(risk => (
                                <LocationRiskTag
                                  key={risk.assigner}
                                  riskAssigner={risk.assigner}
                                  className={classNameOverrides.locationRiskTag}
                                />
                              ))}
                            </S.RiskAssigners>
                          ) : null}
                          {groupedCompanies.length > 0 && (
                            <S.List>
                              {groupedCompanies.map(id => {
                                const org = relatedOrgsMap[id];
                                return (
                                  <S.ListItem key={id}>
                                    <b>{org.name}</b>
                                    <S.CompanyLocation>
                                      {org.registeredAddress?.shortAddress?.join(
                                        ", "
                                      )}
                                    </S.CompanyLocation>
                                  </S.ListItem>
                                );
                              })}
                            </S.List>
                          )}
                        </div>
                      }
                    />
                  );
                }),
                <div>
                  {selectedRelatedCountryData?.referenceIds?.map(id => {
                    const org = relatedOrgsMap[id];
                    const duplicateOrgNames = getDuplicateOrgs(
                      selectedRelatedCountryOrgs
                    );

                    return (
                      <OrgInfoSummaryRow
                        key={id}
                        onClick={() => {
                          setSelectedRelatedCountryOrgData(org);
                          relatedCountriesPagesRef.current?.switchPageByDirection(
                            DIRECTION.forward
                          );
                        }}
                        orgName={org.name}
                        previousNames={org.previousNames}
                        industry={org.industry}
                        companyStatus={org.orgStatus}
                        sources={org.sources}
                        reasonsForRisk={org.risk}
                        companyLocation={org.registeredAddress}
                        addressFullDisplay={org.registeredAddress?.address}
                        addressShortDisplay={
                          org.registeredAddress?.shortAddress
                        }
                        companyIdentifiers={
                          duplicateOrgNames.has(org.name.toLowerCase())
                            ? org.companyIdentifiers
                            : null
                        }
                      />
                    );
                  })}
                </div>,
                <S.CustomSourceList
                  sources={selectedRelatedCountryOrgData?.sources}
                  hideSourceCount
                />
              ]}
            />
          </Resizable>
        );
      };

      const relatedOrgSourcesLength = [
        ...new Set(selectedRelatedOrgData?.sources?.map(source => source.id))
      ]?.length;

      const relatedCountriesSourcesLength = [
        ...new Set(
          selectedRelatedCountryOrgData?.sources?.map(source => source.id)
        )
      ]?.length;

      return (
        <>
          <S.OrgScaleTitle>Scale</S.OrgScaleTitle>
          <WithInspector
            isInspectorOpen={value => {
              setIsScaleIconInspectorOpen(value);
              if (!value) setRelatedOrgsCurrentPage(0);
            }}
            dataFormat={INSPECTOR_DATA_FORMATS.custom}
            customContent={renderRelatedOrganisations()}
            popoverTitle={
              <AnimatePresence initial={false} exitBeforeEnter>
                {
                  [
                    <motion.div {...animationProps} key="related org summaries">
                      {`${numRelatedCompanies} related organisations ${
                        numRelatedCompanies > 1 ? "summaries" : "summary"
                      }`}
                    </motion.div>,
                    <motion.div {...animationProps} key="sourcing">
                      Sourcing
                    </motion.div>
                  ][relatedOrgsCurrentPage]
                }
              </AnimatePresence>
            }
            disabled={!numRelatedCompanies}
            popoverSubtext={
              <AnimatePresence initial={false} exitBeforeEnter>
                {
                  [
                    <motion.div {...animationProps} key="related org summaries">
                      We have identified {numRelatedCompanies} related{" "}
                      {organisationString} for your subject. For full details,
                      go to the{" "}
                      <S.SectionLinkButton
                        kind={ButtonKind.tertiary}
                        onClick={() =>
                          scrollToTopOfComponent(
                            sectionRefs[orgReportSectionTitles.RELATED_ORGS],
                            true
                          )
                        }
                      >
                        Related organisations section
                      </S.SectionLinkButton>
                      .
                    </motion.div>,
                    <BackButton
                      animationProps={animationProps}
                      onClick={() => {
                        relatedOrgPagesRef.current?.switchPageByDirection(
                          DIRECTION.backward
                        );
                      }}
                      label={
                        <>
                          <b>
                            Showing {relatedOrgSourcesLength}{" "}
                            {relatedOrgSourcesLength > 1 ? "sources" : "source"}{" "}
                          </b>
                          for {selectedRelatedOrgData?.name}
                        </>
                      }
                    />
                  ][relatedOrgsCurrentPage]
                }
              </AnimatePresence>
            }
          >
            <ReactTooltip
              disabled={isScaleIconInspectorOpen}
              maxWidth="300px"
              delay={600}
              tooltip={
                <div>
                  {numRelatedCompanies} number of organisations found to be
                  connected to your subject.
                </div>
              }
            >
              <S.OrgScaleItem aria-label="Open Related Organisations summary">
                <S.OrgScaleIconContainer>
                  <S.RelatedOrgsIcon
                    color={
                      numRelatedCompanies ? theme.primaryColor : grey.ghost
                    }
                  />
                  {numRelatedCompaniesWithRisk > 0 && (
                    <S.RiskCountIcon
                      isMaxTextLength={numRelatedCompaniesWithRisk > 99}
                    >
                      {formattedNumCompaniesWithRisk}
                    </S.RiskCountIcon>
                  )}
                </S.OrgScaleIconContainer>
                <S.OrgScaleDetail
                  color={numRelatedCompanies ? "inherit" : grey.mid}
                >
                  <span>
                    {numRelatedCompanies >= 0 ? numRelatedCompanies : "-"}
                  </span>
                  Related {organisationString}
                </S.OrgScaleDetail>
              </S.OrgScaleItem>
            </ReactTooltip>
          </WithInspector>
          <WithInspector
            disabled={!numCountriesActive}
            isInspectorOpen={value => {
              setIsScaleIconInspectorOpen(value);
              if (!value) setRelatedCountriesCurrentPage(0);
            }}
            popoverSubtext={
              <AnimatePresence initial={false} exitBeforeEnter>
                {
                  [
                    <motion.div
                      {...animationProps}
                      key="related countries summaries"
                    >
                      Using corporate records, Xapien has identified companies
                      relating to your subject registered in{" "}
                      {numCountriesActive} {countryString}.
                    </motion.div>,
                    <BackButton
                      key="company summaries"
                      animationProps={animationProps}
                      onClick={() => {
                        relatedCountriesPagesRef.current?.switchPageByDirection(
                          DIRECTION.backward
                        );
                      }}
                      label={
                        <>
                          <b>
                            Showing{" "}
                            {selectedRelatedCountryData?.referenceIds?.length}{" "}
                            {selectedRelatedCountryData?.referenceIds?.length >
                            1
                              ? "organisations"
                              : "organisation"}
                          </b>{" "}
                          in {selectedRelatedCountryData?.countryName}
                        </>
                      }
                    />,
                    <BackButton
                      key="sourcing"
                      animationProps={animationProps}
                      onClick={() => {
                        relatedCountriesPagesRef.current?.switchPageByDirection(
                          DIRECTION.backward
                        );
                      }}
                      label={
                        <>
                          <b>
                            Showing {relatedCountriesSourcesLength} corporate
                            record{" "}
                            {relatedCountriesSourcesLength > 1
                              ? "sources"
                              : "source"}
                          </b>{" "}
                          for {selectedRelatedCountryOrgData?.name}
                        </>
                      }
                    />
                  ][relatedCountriesCurrentPage]
                }
              </AnimatePresence>
            }
            dataFormat={INSPECTOR_DATA_FORMATS.custom}
            customContent={renderRelatedCountries()}
            popoverTitle={
              <AnimatePresence initial={false} exitBeforeEnter>
                {
                  [
                    <motion.div
                      {...animationProps}
                      key="related countries summaries"
                    >
                      {numCountriesActive} related{" "}
                      {numCountriesActive > 1 ? "countries" : "country"}
                    </motion.div>,
                    <motion.div {...animationProps} key="country companies">
                      {selectedRelatedCountryData?.countryName}
                    </motion.div>,
                    <motion.div {...animationProps} key="company sources">
                      Sources
                    </motion.div>
                  ][relatedCountriesCurrentPage]
                }
              </AnimatePresence>
            }
          >
            <ReactTooltip
              disabled={isScaleIconInspectorOpen}
              delay={600}
              maxWidth="300px"
              tooltip={
                <div>
                  Known countries Xapien has found that are related to your
                  subject.
                </div>
              }
            >
              <S.OrgScaleItem aria-label="Open Countries summary">
                <S.OrgScaleIconContainer>
                  <S.GlobeIcon
                    color={numCountriesActive ? theme.primaryColor : grey.ghost}
                  />
                  {numRelatedCountriesWithRisk > 0 && (
                    <S.RiskCountIcon
                      isMaxTextLength={numRelatedCountriesWithRisk > 99}
                    >
                      {formattedNumCountriesWithRisk}
                    </S.RiskCountIcon>
                  )}
                </S.OrgScaleIconContainer>
                <S.OrgScaleDetail
                  color={numCountriesActive ? "inherit" : grey.mid}
                >
                  <span>
                    {numCountriesActive >= 0 ? numCountriesActive : "-"}
                  </span>
                  {numCountriesActive === 1 ? "Country" : "Countries"}
                </S.OrgScaleDetail>
              </S.OrgScaleItem>
            </ReactTooltip>
          </WithInspector>
          {renderEmployeeCountInfoGraphic()}
          {renderSocialReachInfoGraphic()}
        </>
      );
    };

    const renderOrgProfiles = () => {
      const hasHomepage = homepage?.link?.url !== undefined;
      const totalProfiles = onlineProfiles.length + (hasHomepage ? 1 : 0);

      return (
        <>
          <S.OnlineProfilesTitle>Online presence</S.OnlineProfilesTitle>
          <S.OnlineProfilesContainer>
            {!hasHomepage && !onlineProfiles?.length ? (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            ) : null}
            {hasHomepage && (
              <S.HomepageProfile>
                <SocialMediaIcons
                  profileData={[
                    {
                      data: {
                        profileType: "website",
                        link: homepage.link.url,
                        label: homepage.link?.label ?? homepage.link.url
                      }
                    }
                  ]}
                  maxLinkWidth={MAX_SOCIAL_PROFILE_WIDTH - 10}
                  showFollowerCount={false}
                />
              </S.HomepageProfile>
            )}
            {getProfiles(onlineProfiles)}
            {onlineProfiles.length > MAX_DEFAULT_MEDIA_LINKS_TO_SHOW && (
              <S.ShowAllButton
                kind="tertiary"
                onClick={onShowAllMediaLinksClick}
              >
                Show {isShowingAllMediaLInks ? "less" : `all ${totalProfiles}`}
              </S.ShowAllButton>
            )}
          </S.OnlineProfilesContainer>
        </>
      );
    };

    const formatStraplineText = () => {
      let modifiedStrapline = strapline?.text;

      if (modifiedStrapline) {
        // Remove any present punctuation
        if (strapline?.text.match(/[.,:!?]$/)) {
          modifiedStrapline = strapline.text.slice(0, -1);
        }
        // If the first character in the summary is capitalised, then the strapline is its own entitiy.
        // Therefore, we need to place a period after the strapline. Otherwise the strapline and summary
        // is one entity i.e. the strapline flows directly into the summary.
        if (
          summary?.text &&
          summary.text.charAt(0) === summary.text.charAt(0).toUpperCase()
        ) {
          modifiedStrapline = `${modifiedStrapline}.`;
        }
        return modifiedStrapline;
      }

      return null;
    };

    const renderOrgSummary = () => {
      const modifiedStrapline = formatStraplineText();
      const summarySources = summary?.sources ?? [];
      const straplineSources = strapline?.sources ?? [];
      const sources = [...summarySources, ...straplineSources];

      return (
        <WithInspector
          popoverTitle="How they describe themselves"
          sources={sources}
          topSectionElement={
            <S.SummaryText>
              <b>{modifiedStrapline}</b> {summary?.text}
            </S.SummaryText>
          }
        >
          <S.OrgSummaryContainer>
            <S.SectionTitle aria-label="How they describe themselves">
              {modifiedStrapline || summary?.text ? (
                <S.QuotesIcon color={theme.primaryColor} />
              ) : null}{" "}
              How they describe themselves
            </S.SectionTitle>

            {modifiedStrapline || summary?.text ? (
              <S.SummaryText>
                <b>{modifiedStrapline}</b> {summary?.text}
              </S.SummaryText>
            ) : (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
          </S.OrgSummaryContainer>
        </WithInspector>
      );
    };

    const renderOrgDescription = () => {
      const titleString = "How they are described by others";
      const sectionTitle = (
        <S.OrgDescriptionTitleContainer>
          <S.OrgDescriptionTitle>{titleString}</S.OrgDescriptionTitle>
          <InfoIcon
            infoContent="This section automatically summarises how other sources and
              publications describe this organisation."
          />
        </S.OrgDescriptionTitleContainer>
      );

      if (!descriptorSentences?.length) {
        return (
          <div>
            {sectionTitle} <S.NoneIdentified>None identified</S.NoneIdentified>
          </div>
        );
      }

      return descriptorSentences?.length ? (
        <S.OrgDescriptionContainer ref={orgDescriptionRef}>
          <div>
            <S.QuotesIcon color={theme.primaryColor} />
            {sectionTitle}
            <br />
            <S.OrgDescriptionText>
              {descriptorSentences.map(sentence => {
                const emboldenedSentence = sentence?.descriptorHighlights
                  ? emboldenKeyWords(
                      sentence.descriptorSentence,
                      sentence.descriptorHighlights
                    )
                  : sentence.descriptorSentence;

                return (
                  <S.DescriptorSentenceContainer
                    key={sentence.descriptorSentence}
                  >
                    <>
                      <WithInspector
                        display="inline"
                        highlightPadding="1px"
                        popoverTitle={titleString}
                        sources={sentence.sources}
                        topSectionElement={emboldenedSentence}
                      >
                        {emboldenedSentence}
                      </WithInspector>
                      <S.DescriptionSentenceSeparator />
                    </>
                  </S.DescriptorSentenceContainer>
                );
              })}
            </S.OrgDescriptionText>
          </div>
        </S.OrgDescriptionContainer>
      ) : null;
    };

    const renderOrgLogo = () => {
      return (
        <S.OrgLogoImageContainer>
          {imageOfLogo && !hasLogoErrored ? (
            <DiImage
              alt="Organisation logo"
              image={imageOfLogo}
              imageSizeToUse={ImageSizeToUse.Small}
              className={classNameOverrides.orgLogoImage}
              onError={() => setLogoErrored(true)}
              lazyLoad={false}
              width="88px"
              height="94px"
              isCopyrighted
            />
          ) : (
            <S.BuildingIconContainer>
              <S.BuildingIcon />
            </S.BuildingIconContainer>
          )}
        </S.OrgLogoImageContainer>
      );
    };

    return (
      <S.OrganisationOverviewSection ref={ref} className="report-section">
        <SectionHeader title={title} isContractable={false} />

        <S.Topbar>
          <RiskFramework />
          {displaySubjectImage && (
            <S.OrgLogoContainer>
              <S.OrgLogoSectionTitle>Logo</S.OrgLogoSectionTitle>
              <S.OrgLogo>{renderOrgLogo()}</S.OrgLogo>
            </S.OrgLogoContainer>
          )}
        </S.Topbar>
        <S.OverviewContainer
          infographicLayoutTemplate={infographicLayoutTemplate}
        >
          <S.OrgPresence>{renderOrgScale()}</S.OrgPresence>
          <S.OrgProfiles>{renderOrgProfiles()}</S.OrgProfiles>
          <S.OrgDetails1Cell>{renderOrgDetailsGroup1()}</S.OrgDetails1Cell>
          <S.OrgDetails2Cell>{renderOrgDetailsGroup2()}</S.OrgDetails2Cell>

          {!insightsEnabled && !isShowingInsightReport && (
            <>
              <S.OrgSummary>{renderOrgSummary()}</S.OrgSummary>
              <S.OrgDescription>{renderOrgDescription()}</S.OrgDescription>
            </>
          )}
        </S.OverviewContainer>
        <S.CustomSectionFooter />
      </S.OrganisationOverviewSection>
    );
  }
);

export default observer(OrganisationOverview);
