import React, { FC } from "react";
// @ts-ignore
import theme from "theme";

import S from "./styles";

type LoginHeaderProps = {
  subtitle?: string;
};

const LoginHeader: FC<LoginHeaderProps> = ({ subtitle }) => {
  return (
    <S.Container>
      {theme.login?.logo ?? <S.XapienLogo />}
      <S.Title level={5}>{theme.slogan}</S.Title>
      {subtitle && <S.Subtitle level={4}>{subtitle}</S.Subtitle>}
    </S.Container>
  );
};

export default LoginHeader;
