/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Idam_Contracts_Reports_CreateReportAccessRequestRequest } from "../models/Idam_Contracts_Reports_CreateReportAccessRequestRequest";
import type { Idam_Contracts_Reports_GenerateShareLinkResponse } from "../models/Idam_Contracts_Reports_GenerateShareLinkResponse";
import type { Idam_Contracts_Reports_GetReportAccessRequestResponse } from "../models/Idam_Contracts_Reports_GetReportAccessRequestResponse";
import type { Idam_Contracts_Reports_GetReportPersonasResponse } from "../models/Idam_Contracts_Reports_GetReportPersonasResponse";
import type { Idam_Contracts_Reports_GetReportResponse } from "../models/Idam_Contracts_Reports_GetReportResponse";
import type { Idam_Contracts_Reports_GetReportSharingPreferences } from "../models/Idam_Contracts_Reports_GetReportSharingPreferences";
import type { Idam_Contracts_Reports_GetReportsResponse } from "../models/Idam_Contracts_Reports_GetReportsResponse";
import type { Idam_Contracts_Reports_GetShareLinkResponse } from "../models/Idam_Contracts_Reports_GetShareLinkResponse";
import type { Idam_Contracts_Reports_SearchRequest } from "../models/Idam_Contracts_Reports_SearchRequest";
import type { Idam_Contracts_Reports_SearchResponse } from "../models/Idam_Contracts_Reports_SearchResponse";
import type { Idam_Contracts_Reports_SetReportSharingPreferences } from "../models/Idam_Contracts_Reports_SetReportSharingPreferences";
import type { Idam_Contracts_Reports_TransferOwnershipRequest } from "../models/Idam_Contracts_Reports_TransferOwnershipRequest";
import type { Idam_Contracts_Reports_UpdateReportAccessRequestRequest } from "../models/Idam_Contracts_Reports_UpdateReportAccessRequestRequest";
import type { Idam_Contracts_Reports_UpdateReportMonitoringRequest } from "../models/Idam_Contracts_Reports_UpdateReportMonitoringRequest";
import type { PortalMockAPI_Controllers_GetReportToPDFMetaResponse } from "../models/PortalMockAPI_Controllers_GetReportToPDFMetaResponse";
import type { PortalMockAPI_Controllers_SearchAsYouTypeRequest } from "../models/PortalMockAPI_Controllers_SearchAsYouTypeRequest";
import type { PortalMockAPI_Controllers_SearchAsYouTypeResponse } from "../models/PortalMockAPI_Controllers_SearchAsYouTypeResponse";
import type { PortalMockAPI_Controllers_SetReportToPDFMetaRequest } from "../models/PortalMockAPI_Controllers_SetReportToPDFMetaRequest";
import type { PortalMockAPI_Controllers_SetReportToPDFMetaResponse } from "../models/PortalMockAPI_Controllers_SetReportToPDFMetaResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ReportService {
  /**
   * @returns Idam_Contracts_Reports_GetReportSharingPreferences Success
   * @throws ApiError
   */
  public static getReportsSharingPreferences({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportSharingPreferences> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/sharing-preferences",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putReportsSharingPreferences({
    reportId,
    requestBody
  }: {
    reportId: string;
    requestBody?: Idam_Contracts_Reports_SetReportSharingPreferences;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/sharing-preferences",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportPersonasResponse Success
   * @throws ApiError
   */
  public static getReportsPersonas({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<
    Array<Idam_Contracts_Reports_GetReportPersonasResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/personas",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportPersonasResponse Success
   * @throws ApiError
   */
  public static getReportsPersonas1({
    reportId,
    token
  }: {
    reportId: string;
    token: string;
  }): CancelablePromise<
    Array<Idam_Contracts_Reports_GetReportPersonasResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/{token}/personas",
      path: {
        reportId: reportId,
        token: token
      }
    });
  }

  /**
   * @returns string|null Subject Persona Id if applicable
   * @throws ApiError
   */
  public static getSubjectPersonaId({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<string | null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/subject-persona-id",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns string|null Subject Persona Id if applicable
   * @throws ApiError
   */
  public static getSubjectPersonaIdWithToken({
    reportId,
    token
  }: {
    reportId: string;
    token: string;
  }): CancelablePromise<string | null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/{token}/subject-persona-id",
      path: {
        reportId: reportId,
        token: token
      }
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putReportsMarkRead({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/mark-read",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putReportsMarkUnread({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/mark-unread",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postReportsAccessRequest({
    reportId,
    requestBody
  }: {
    reportId: string;
    requestBody?: Idam_Contracts_Reports_CreateReportAccessRequestRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/access-request",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportAccessRequestResponse Success
   * @throws ApiError
   */
  public static getReportsAccessRequest({
    reportId,
    requesterId
  }: {
    reportId: string;
    requesterId: string;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportAccessRequestResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/access-request/{requesterId}",
      path: {
        reportId: reportId,
        requesterId: requesterId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putReportsAccessRequest({
    reportId,
    requesterId,
    requestBody
  }: {
    reportId: string;
    requesterId: string;
    requestBody?: Idam_Contracts_Reports_UpdateReportAccessRequestRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/access-request/{requesterId}",
      path: {
        reportId: reportId,
        requesterId: requesterId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns PortalMockAPI_Controllers_GetReportToPDFMetaResponse Success
   * @throws ApiError
   */
  public static getReportsPdfMetadata({
    requestId
  }: {
    requestId: string;
  }): CancelablePromise<PortalMockAPI_Controllers_GetReportToPDFMetaResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/pdf-metadata/{requestId}",
      path: {
        requestId: requestId
      }
    });
  }

  /**
   * @returns PortalMockAPI_Controllers_SetReportToPDFMetaResponse Success
   * @throws ApiError
   */
  public static postReportsPdfMetadata({
    requestBody
  }: {
    requestBody?: PortalMockAPI_Controllers_SetReportToPDFMetaRequest;
  }): CancelablePromise<PortalMockAPI_Controllers_SetReportToPDFMetaResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/pdf-metadata",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteReports({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportResponse Success
   * @throws ApiError
   */
  public static getReports({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportResponse Success
   * @throws ApiError
   */
  public static getReports1({
    reportId,
    token,
    isForPdfExport = false
  }: {
    reportId: string;
    token: string;
    isForPdfExport?: boolean;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/{token}",
      path: {
        reportId: reportId,
        token: token
      },
      query: {
        isForPDFExport: isForPdfExport
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportResponse Success
   * @throws ApiError
   */
  public static getReportsPersonas2({
    reportId,
    personaId
  }: {
    reportId: string;
    personaId: string;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/personas/{personaId}",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportResponse Success
   * @throws ApiError
   */
  public static getReportsPersonas3({
    reportId,
    personaId,
    token,
    isForPdfExport = false
  }: {
    reportId: string;
    personaId: string;
    token: string;
    isForPdfExport?: boolean;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/personas/{personaId}/{token}",
      path: {
        reportId: reportId,
        personaId: personaId,
        token: token
      },
      query: {
        isForPDFExport: isForPdfExport
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static postReportsTransferOwnership({
    reportId,
    userId,
    requestBody
  }: {
    reportId: string;
    userId: string;
    requestBody?: Idam_Contracts_Reports_TransferOwnershipRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/transfer-ownership/{userId}",
      path: {
        reportId: reportId,
        userId: userId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetReportsResponse Success
   * @throws ApiError
   */
  public static getReports2({
    sortByMostRecent,
    filterByRead,
    filterByUnread,
    excludeOwnedReports,
    excludeUserSharedReports,
    excludeGroupSharedReports,
    excludeAllAccessibleReports,
    start,
    limit = 25
  }: {
    sortByMostRecent?: boolean;
    filterByRead?: boolean;
    filterByUnread?: boolean;
    excludeOwnedReports?: boolean;
    excludeUserSharedReports?: boolean;
    excludeGroupSharedReports?: boolean;
    excludeAllAccessibleReports?: boolean;
    start?: number;
    limit?: number;
  }): CancelablePromise<Idam_Contracts_Reports_GetReportsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports",
      query: {
        sortByMostRecent: sortByMostRecent,
        filterByRead: filterByRead,
        filterByUnread: filterByUnread,
        excludeOwnedReports: excludeOwnedReports,
        excludeUserSharedReports: excludeUserSharedReports,
        excludeGroupSharedReports: excludeGroupSharedReports,
        excludeAllAccessibleReports: excludeAllAccessibleReports,
        start: start,
        limit: limit
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_SearchResponse Success
   * @throws ApiError
   */
  public static postReportsSearch({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Reports_SearchRequest;
  }): CancelablePromise<Idam_Contracts_Reports_SearchResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/search",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`
      }
    });
  }

  /**
   * @returns PortalMockAPI_Controllers_SearchAsYouTypeResponse Success
   * @throws ApiError
   */
  public static postReportsSearchastype({
    requestBody
  }: {
    requestBody?: PortalMockAPI_Controllers_SearchAsYouTypeRequest;
  }): CancelablePromise<PortalMockAPI_Controllers_SearchAsYouTypeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/searchastype",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GenerateShareLinkResponse Success
   * @throws ApiError
   */
  public static postReportsShareLink({
    reportId,
    isForPdfExport = false
  }: {
    reportId: string;
    isForPdfExport?: boolean;
  }): CancelablePromise<Idam_Contracts_Reports_GenerateShareLinkResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/share-link",
      path: {
        reportId: reportId
      },
      query: {
        isForPDFExport: isForPdfExport
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteReportsShareLink({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/share-link",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_GetShareLinkResponse Success
   * @throws ApiError
   */
  public static getReportsShareLink({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<Idam_Contracts_Reports_GetShareLinkResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/share-link",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putReportsMonitoring({
    reportId,
    requestBody
  }: {
    reportId: string;
    requestBody?: Idam_Contracts_Reports_UpdateReportMonitoringRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/monitoring",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`
      }
    });
  }
}
