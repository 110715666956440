import { styled } from "@linaria/react";
import { family, fontSize, lineHeight, size } from "styles/fonts";
import { blue, grey, red, standardColors, yellow } from "styles/colors";
import { ReactComponent as SparklesSvg } from "img/icons/sparkles.svg";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${standardColors.white};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 478px;
  position: relative;
  overflow: hidden;
`;

const Title = styled.div<{ color?: string }>`
  color: ${({ color }) => color || red.directRiskOutline};
  font-size: ${fontSize.lg};
  font-weight: bold;
  margin: 0;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding: 16px 20px 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 8px;
`;

const ReviewLabel = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.md};
  font-family: ${family.interLight};
  line-height: ${lineHeight.xl};
`;

const Description = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  color: ${grey.darkish};
  background: ${grey.panelLight};
  padding: 12px 20px;
  font-size: ${fontSize["2xs"]};
  line-height: ${lineHeight["2xl"]};
  font-family: ${family.interDisplayMedium};
  font-weight: bold;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 250px;
  overflow-y: scroll;
`;

const Footer = styled.div`
  padding: 12px 24px;
  color: ${grey.dark};
  font-size: ${size.sm};
  font-family: ${family.interLight};
  background: ${grey.panelLight};

  a {
    color: inherit;
        
    :hover {
      color: ${blue.link};
    }
`;

const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  margin-right: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 9px;
  background: ${red.directRiskOpaque};
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ color }) => color || red.directRiskOutline};
    }
  }
`;

const Disclaimer = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${grey.lightRule};

  span {
    font-weight: bold;
    color: ${red.directRiskFill};
  }
`;

const DisclaimerText = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-family: ${family.interSemiBold};
  font-size: ${size["2xs"]};
  font-weight: bold;
  line-height: ${lineHeight.md};
  color: ${grey.dark};
`;

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 0;
`;

const DisclaimerStars = styled(SparklesSvg)`
  width: 9px;
  fill: ${yellow.amaStars};
`;

const S = {
  Card,
  Title,
  Header,
  Content,
  CardBody,
  Description,
  ReviewLabel,
  Disclaimer,
  Footer,
  IconContainer,
  DisclaimerText,
  DisclaimerStars,
  DisclaimerContainer
};

export default S;
