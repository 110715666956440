import React from "react";

// @ts-ignore
import loadingVideo from "./loading.webm";

import S from "./styles";

const Loading = () => {
  return (
    <S.Container>
      <video autoPlay muted loop width="190">
        <source src={loadingVideo} type="video/webm" />
      </video>
      <S.Overlay />
    </S.Container>
  );
};

export default Loading;
