import React, { FC, ReactNode } from "react";
import Heading from "components/atoms/Heading";
import {
  useCollectionList,
  CollectionListActions
} from "util/hooks/useCollectionList";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import S from "./styles";

export enum CollectionListView {
  grid = "grid",
  list = "list"
}

interface Props {
  id: string;
  title?: string;
  sortable: boolean;
  createItemComponent?: ReactNode;
  toggles?: ReactNode;
}

const CollectionListControls: FC<Props> = ({
  id,
  title,
  sortable,
  createItemComponent,
  toggles
}) => {
  const { dispatch } = useCollectionList();

  const handleOnOrderChange = (order: string) =>
    dispatch({
      type: CollectionListActions.updateCollectionOrder,
      id,
      order
    });

  return (
    <S.Container>
      {title && (
        <S.Heading>
          <Heading level={5}>{title}</Heading>
        </S.Heading>
      )}
      {createItemComponent && (
        <S.CreateItem>{createItemComponent}</S.CreateItem>
      )}
      {toggles}
      {false && sortable && (
        <Popover
          hideArrow
          trigger="click"
          alignment="bottom-end"
          className={undefined}
          disableHideOnClip={undefined}
          style={undefined}
          content={
            <Menu>
              <MenuItem
                text="Most recent first"
                onMenuItemClick={() => handleOnOrderChange("desc")}
                IconLeading={S.RecentFirstIcon}
                disabled={undefined}
                IconTrailing={undefined}
                BadgeTrailing={undefined}
                showSeparator={undefined}
              />
              <MenuItem
                text="Most recent last"
                onMenuItemClick={() => handleOnOrderChange("asc")}
                IconLeading={S.RecentLastIcon}
                disabled={undefined}
                IconTrailing={undefined}
                BadgeTrailing={undefined}
                showSeparator={undefined}
              />
            </Menu>
          }
        >
          <ButtonNew
            type={ButtonType.Outlined}
            size={ButtonSize.Small}
            text="Sort"
          />
        </Popover>
      )}
    </S.Container>
  );
};

export default CollectionListControls;
