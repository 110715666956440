import React from "react";

import InsightReport from "components/organisms/InsightReport";

import { EnquiryIdContext } from "util/hooks/useEnquiryId";

import { PersonaIdContext } from "util/hooks/usePersonaId";

import { InsightReportProvider } from "util/hooks/useInsightReport";
import { RequestQueueContextProvider } from "util/hooks/useRequestQueue";
import { InsightsTimelineProvider } from "util/hooks/useInsightsTimeline";
import { InsightsAmaContextProvider } from "util/hooks/useInsightsAma";
import { UserVerificationProvider } from "util/hooks/useUserVerification";
import useIsMobile from "util/hooks/useIsMobile";
import { useParams, useNavigate } from "react-router-dom";

const InsightReportPage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const params = useParams();
  const { enquiryId, personaId } = params;

  if (!isMobile) {
    navigate(`/report/${enquiryId}`);
  }

  return (
    <UserVerificationProvider>
      <EnquiryIdContext.Provider value={enquiryId}>
        <PersonaIdContext.Provider value={personaId}>
          <RequestQueueContextProvider>
            <InsightReportProvider>
              <InsightsTimelineProvider>
                <InsightsAmaContextProvider>
                  <InsightReport />
                </InsightsAmaContextProvider>
              </InsightsTimelineProvider>
            </InsightReportProvider>
          </RequestQueueContextProvider>
        </PersonaIdContext.Provider>
      </EnquiryIdContext.Provider>
    </UserVerificationProvider>
  );
};

export default InsightReportPage;
