import React from "react";

import { ReactComponent as InformationIconSvg } from "img/icons/information-icon.svg";

import config from "config";

import S from "./styles";

export const SnagCard = () => {
  return (
    <S.Card>
      <S.CardBody>
        <S.IconContainer>
          <InformationIconSvg />
        </S.IconContainer>
        <S.ColumnContainer>
          <S.Title>We hit a snag</S.Title>
          <S.Text>
            We could not update the risk status. Please try again by clicking
            below. If the issue persists, please contact{" "}
            <a href={`mailto:${config.supportEmail}`}>Customer Success</a>
          </S.Text>
        </S.ColumnContainer>
      </S.CardBody>
    </S.Card>
  );
};
