/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ApiError,
  IdentityUserApiService,
  Idam_Contracts_Enums_ReportRole,
  Idam_Contracts_Users_UpdateUserRequest,
  Idam_Contracts_Users_UserPreferencesRequest
} from "api/idam";
import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";
import { apm } from "@elastic/apm-rum";

import {
  UserDetails,
  UserPreferencesConfig,
  AutoSharePermission,
  Group,
  UserPermissionsResult,
  UserPermissionsResultType
} from "./types";

export default class User {
  async getUserPermissions(): Promise<UserPermissionsResult> {
    try {
      const response = await IdentityUserApiService.getUsersPermissions();

      if (!response) {
        return { type: UserPermissionsResultType.Failed };
      }

      const {
        canInviteUsers,
        canInviteAdmins,
        canChangeUserRole,
        canGetOrganisationUsage,
        canEditMfa,
        canExportOrganisationUsage,
        canGetOrganisation,
        canUpdateOrganisation,
        canGetOrganisationPreferences,
        canUpdateOrganisationPreferences,
        canListUsers,
        canListGroups,
        canCreateGroup,
        canListReports,
        canStartReports,
        canResetPassword,
        canStartLegalEntityReports
      } = response;

      return {
        permissions: {
          canInviteUsers: !!canInviteUsers,
          canInviteAdmins: !!canInviteAdmins,
          canChangeUserRole: !!canChangeUserRole,
          canGetOrganisationUsage: !!canGetOrganisationUsage,
          canExportOrganisationUsage: !!canExportOrganisationUsage,
          canEditMfa: !!canEditMfa,
          canGetOrganisation: !!canGetOrganisation,
          canUpdateOrganisation: !!canUpdateOrganisation,
          canGetOrganisationPreferences: !!canGetOrganisationPreferences,
          canUpdateOrganisationPreferences: !!canUpdateOrganisationPreferences,
          canListUsers: !!canListUsers,
          canListGroups: !!canListGroups,
          canCreateGroup: !!canCreateGroup,
          canListReports: !!canListReports,
          canStartReports: !!canStartReports,
          canResetPassword: !!canResetPassword,
          canStartLegalEntityReports: !!canStartLegalEntityReports
        },
        type: UserPermissionsResultType.Success
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      let message: string | undefined;
      if (e instanceof ApiError && e.body && e.body.message) {
        message = e.body.message;
      }
      return { type: UserPermissionsResultType.Failed, message };
    }
  }

  async getUserDetails(): Promise<FetchResult<UserDetails>> {
    try {
      const response = await IdentityUserApiService.getUsers();

      if (!response) {
        return { status: false };
      }

      return {
        response: {
          userId: response.userId ?? "unknown",
          firstName: response.firstName ?? "unknown",
          lastName: response.lastName ?? "unknown",
          email: response.email ?? "unknown",
          jobTitle: response.jobTitle ?? "",
          groups:
            response.groups?.map(group => ({
              groupId: group.groupId ?? "unknown",
              name: group.name ?? "unknown",
              role: group.role ?? "unknown"
            })) ?? [],
          organisationId: response.organisationId ?? "unknown",
          organisationRole: response.organisationRole ?? "unknown"
        },
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async setUserDetails(
    firstName: string,
    lastName: string,
    jobTitle: string
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Users_UpdateUserRequest = {
        firstName,
        lastName,
        jobTitle
      };

      await IdentityUserApiService.putUsers({ requestBody });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false };
    }
  }

  async getUserPreferences(): Promise<FetchResult<UserPreferencesConfig>> {
    try {
      const response = await IdentityUserApiService.getUsersPreferences();

      if (!response) {
        return { status: false };
      }

      return {
        status: true,
        response: {
          sharingPreferences: {
            autoshare: response.sharingPreferences?.autoShare ?? false,
            sharedWithUsers:
              response.sharingPreferences?.sharedWithUsers?.map(share => ({
                userId: share.userId ?? "",
                firstName: share.firstName ?? "",
                lastName: share.lastName ?? "",
                email: share.email ?? "",
                avatarUrl: share.avatarUrl ?? "",
                permission:
                  (share.permission as unknown as AutoSharePermission) ??
                  AutoSharePermission.Read
              })) ?? [],
            sharedWithGroups:
              response.sharingPreferences?.sharedWithGroups?.map(share => ({
                groupId: share.groupId ?? "",
                name: share.name ?? "",
                permission:
                  (share.permission as unknown as AutoSharePermission) ??
                  AutoSharePermission.Read
              })) ?? []
          }
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async setUserPreferences(
    updatedPreferences: UserPreferencesConfig
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Users_UserPreferencesRequest = {
        sharingPreferences: {
          autoShare: updatedPreferences.sharingPreferences.autoshare,
          sharedWithUsers:
            updatedPreferences.sharingPreferences.sharedWithUsers.map(
              shareItem => ({
                userId: shareItem.userId,
                permission:
                  shareItem.permission as unknown as Idam_Contracts_Enums_ReportRole
              })
            ),
          sharedWithGroups:
            updatedPreferences.sharingPreferences.sharedWithGroups.map(
              shareItem => ({
                groupId: shareItem.groupId,
                permission:
                  shareItem.permission as unknown as Idam_Contracts_Enums_ReportRole
              })
            )
        }
      };

      await IdentityUserApiService.putUsersPreferences({ requestBody });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false };
    }
  }

  async getUserGroups(): Promise<Group[]> {
    try {
      const response = await IdentityUserApiService.getUsersGroups();
      return response.map(group => ({
        groupId: group.groupId ?? "",
        name: group.name ?? "",
        role: group.role ?? ""
      }));
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return [];
    }
  }
}
