import React, { FC, SVGProps, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChevronDown } from "react-feather";
import { Spinner } from "reactstrap";
import { AnimatePresence, motion } from "framer-motion";

import ErrorBanner from "components/atoms/ErrorBanner";
import ModalContainer from "components/molecules/ModalContainer";
import Api from "api/reports";
import { ReportMeta, RequestAccess, Requester } from "api/reports/types";
import Heading from "components/atoms/Heading";
import TitleCard from "components/molecules/TitleCard";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import { Idam_Contracts_Enums_ReportRole } from "api/idam";
import Popover from "components/atoms/Popover";
import MenuItem from "components/molecules/Menu/MenuItem";
import Menu from "components/molecules/Menu";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import LoadingSkeleton from "./LoadingSkeleton";

import S from "./styles";

const accessOptions = [
  { id: Idam_Contracts_Enums_ReportRole.READ, label: "Viewer" },
  { id: Idam_Contracts_Enums_ReportRole.WRITE, label: "Editor" }
];

const GrantAccessModal = () => {
  const [accessDropdownOpen, setAccessDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestHandled, setRequestHandled] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState(accessOptions[0]);
  const [reportDetails, setReportDetails] = useState<ReportMeta | undefined>();
  const [requesterDetails, setRequesterDetails] = useState<
    Requester | undefined
  >();

  const [searchParams] = useSearchParams();
  const { enquiryId } = useParams();
  const navigate = useNavigate();
  const requesterId = searchParams.get("user") ?? undefined;
  const ReportsApi = useMemo(() => new Api(), []);
  const [
    { fetching, error, success: grantedAccessSuccess, errorMessage },
    dispatch
  ] = useFetchReducer();

  useEffect(() => {
    const getRequestDetails = async () => {
      setLoading(true);
      const requestDetails: RequestAccess | null =
        await ReportsApi.getRequestAccess(enquiryId, requesterId);

      if (requestDetails) {
        setReportDetails(requestDetails.report);
        setRequesterDetails(requestDetails.requester);
      } else {
        // Either the request has already been handled or the user has somehow found themselves here.
        // In either case, we'll fallback to a generic message and allow the user to proceed to the report.
        setRequestHandled(true);
      }
      setLoading(false);
    };

    getRequestDetails();
  }, [ReportsApi, enquiryId, navigate, requesterId]);

  const onViewReport = () => {
    navigate(`/report/${enquiryId}`);
  };

  const onGrantAccess = async () => {
    if (fetching) {
      return;
    }

    dispatch({ type: RequestActions.SendRequest });
    const { status, message } = await ReportsApi.grantAccess(
      selectedAccess.id,
      enquiryId,
      requesterId
    );

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return;
    }

    dispatch({ type: RequestActions.SetSuccess });
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (requestHandled) {
      return (
        <Heading level={6}>
          It looks like this request has already been handled.
        </Heading>
      );
    }

    if (grantedAccessSuccess) {
      return (
        <motion.div
          initial={{ height: 270, opacity: 0 }}
          animate={{ height: 24, opacity: 1 }}
          transition={{ opacity: { duration: 0.8 }, duration: 0.5 }}
          key="granted_access"
        >
          <Heading level={6}>
            Access granted to {requesterDetails?.firstName}{" "}
            {requesterDetails?.lastName} (
            <S.RequesterEmail>{requesterDetails?.email}</S.RequesterEmail>).
          </Heading>
        </motion.div>
      );
    }

    return (
      <S.ModalInnerContent
        initial={{ height: 180, opacity: 0 }}
        animate={{ height: 270, opacity: 1 }}
        transition={{ opacity: { duration: 0.8 }, duration: 0.5 }}
        key="grant_access"
      >
        <Heading level={6}>
          Grant {requesterDetails?.firstName} {requesterDetails?.lastName} (
          <S.RequesterEmail>{requesterDetails?.email}</S.RequesterEmail>) to
        </Heading>
        <TitleCard
          title={reportDetails?.subjectItem}
          subtitle={reportDetails?.contextItems.join(", ")}
          imageSrc={reportDetails?.imageUrl}
        />
        <Heading level={6}>Choose the level of access</Heading>
        <S.AccessContainer>
          <div>Access level</div>
          <Popover
            // @ts-ignore
            isOpenOverride={accessDropdownOpen}
            // @ts-ignore
            onRequestClose={() => setAccessDropdownOpen(false)}
            content={
              <Menu>
                {accessOptions.map(option => (
                  <MenuItem
                    key={option.id}
                    text={option.label}
                    onMenuItemClick={() => {
                      setSelectedAccess(option);
                      setAccessDropdownOpen(false);
                    }}
                    disabled={option.id === selectedAccess.id}
                  />
                ))}
              </Menu>
            }
            disableHideOnClip={undefined}
            className={undefined}
            style={undefined}
            hideArrow
            alignment="bottom-end"
          >
            <ButtonNew
              text={selectedAccess.label}
              type={ButtonType.Text}
              size={ButtonSize.Medium}
              IconTrailing={ChevronDown}
              onClick={() => setAccessDropdownOpen(prev => !prev)}
            />
          </Popover>
        </S.AccessContainer>
      </S.ModalInnerContent>
    );
  };

  const renderPrimaryAction = () => {
    let primaryActionText = "Grant access";
    let primaryAction: () => any = onGrantAccess;

    if (requestHandled) {
      primaryActionText = "View report";
      primaryAction = onViewReport;
    } else if (grantedAccessSuccess) {
      primaryActionText = "Done";
      primaryAction = onViewReport;
    }

    return (
      <S.Button
        text={primaryActionText}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={primaryAction}
        IconTrailing={
          fetching
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
        disabled={fetching}
      />
    );
  };

  return (
    <ModalContainer
      title="Grant access"
      onExitClick={() => navigate("/login")}
      isOpen
      toggleOpen={() => {}}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {renderContent()}
      </AnimatePresence>
      <S.ModalActions>
        <S.Button
          text="Cancel"
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          onClick={() => !fetching && navigate("/login")}
        />
        {renderPrimaryAction()}
      </S.ModalActions>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an issue granting access. Try again in a moment."
          }
          onClick={() => dispatch({ type: RequestActions.Reset })}
        />
      )}
    </ModalContainer>
  );
};

export default GrantAccessModal;
