import { green } from "styles/colors";
import { styled } from "@linaria/react";
import { family, size, headingLetterSpacing } from "styles/fonts";

const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  margin-bottom: 6px;

  > svg {
    width: 100%;
  }
`;

const Container = styled.div<{ color: string; active: boolean }>`
  gap: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 102px;
  height: 126px;
  justify-content: center;
  background: transparent;
  border-radius: 12px;
  color: ${({ color }) => color};

  &:hover {
    cursor: ${({ active }) => (active ? "pointer" : "auto")};
    background: ${({ active }) => (active ? green.hover : "transparent")};
  }

  ${IconContainer} > svg {
    path {
      fill: ${({ color }) => color};
    }
  }
`;

const Title = styled.h5`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  text-align: center;
  font-family: ${family.interSemiBold};
  font-size: ${size.sm};
  font-style: normal;
  font-weight: 600;
  padding: 0 4px;
  letter-spacing: ${headingLetterSpacing.lg};
  margin-bottom: 0;
`;

const EmptyPlaceholder = styled.div`
  height: 16px;
`;

const S = {
  Title,
  Container,
  IconContainer,
  EmptyPlaceholder
};

export default S;
