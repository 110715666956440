import { styled } from "@linaria/react";

import { motion } from "framer-motion";

import { grey, purple, standardColors } from "styles/colors";
import { fontSize, family, headingLetterSpacing } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

import { isPDX } from "static-config";

// @ts-ignore
import theme from "theme";

// Important is needed as Menu item is styling svg colours itself
const Menu = styled.ul`
  border-bottom-left-radius: 10px;
  width: 255px;
  list-style-type: none;
  padding: 10px 10px 40px 10px;
  margin: 0 0 auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${grey.rule};
  background: ${standardColors.white};
  box-shadow: inset 12px 6px 27px 0px rgba(255, 255, 255, 1),
    inset 12px -6px 27px 0px rgba(255, 255, 255, 1),
    inset -12px 0px 27px 0px rgba(0, 0, 0, 0.1);
  min-height: 600px;

  @media (max-width: 768px) {
    position: absolute;
    z-index: 1;
    height: 100%;
    min-height: 0;
    overflow-y: scroll;
  }
`;

const MenuItemDivider = styled.li`
  margin: 10px 10px 10px 15px;
  border-top: 1px solid ${grey.rule};
`;

const MenuItem = styled.li<{ active?: boolean }>`
  font-size: ${fontSize.xs};
  background-color: ${({ active }) => {
    if (!active) {
      return "transparent";
    }

    if (isPDX) {
      return theme.primaryColor;
    }

    return purple.darker;
  }};
  color: ${({ active }) => (active ? standardColors.white : grey.dark)};
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;
  border-radius: 4px;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ active }) => (active ? standardColors.white : grey.dark)};

    path {
      fill: ${({ active }) => (active ? standardColors.white : grey.dark)};
    }
  }

  &:hover {
    color: ${({ active }) => (active ? standardColors.white : grey.dark)};
    background-color: ${({ active }) => {
      if (!active) {
        return "transparent";
      }

      if (isPDX) {
        const { r, g, b } = hexToRgb(theme.primaryColor);
        return `rgba(${r}, ${g}, ${b}, 0.5)`;
      }

      return purple.darker;
    }};
  }
`;

const MenuItemContent = styled.div`
  padding: 13px 14px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: ${family.interRegular};
  letter-spacing: ${headingLetterSpacing.md};
  font-weight: inherit;
`;

const AnimatedMenuItemContent = styled(motion.div)`
  padding: 13px 14px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const WarningPopover = styled.div`
  position: absolute;
  left: 5%;
  bottom: 18%;
`;

const S = {
  Menu,
  MenuItem,
  MenuItemContent,
  MenuItemDivider,
  WarningPopover,
  AnimatedMenuItemContent
};

export default S;
