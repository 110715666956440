import { FetchResult } from "api/types";
import { apm } from "@elastic/apm-rum";
import { getHeaders } from "services/http";
import config from "config";

import { InformationSource } from "api/report/report-types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

export interface UpcResponse {
  name?: {
    text: string;
    sources: InformationSource[];
  };
  natureOfBusiness?: {
    sources: InformationSource[];
    text: string;
  };
  jurisdiction?: {
    countryCode: string;
    countryName: string;
    sources: InformationSource[];
  };
  anyParentStateOwned?: {
    value: boolean;
    sources: InformationSource[];
  };
  operatingRevenue?: {
    sources: InformationSource[];
    text: string;
    totalSourceCount: number;
  };
}

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

export default class UpcReports {
  async getDetails({
    enquiryId,
    shareToken
  }: {
    enquiryId: string;
    shareToken?: string | null;
  }): Promise<FetchResult<UpcResponse>> {
    try {
      const { portalApiUrl } = config;
      let endpoint = `${portalApiUrl}/reports/${enquiryId}/upc-data`;
      if (shareToken) {
        endpoint += `/${shareToken}`;
      }
      const response = await fetch(endpoint, {
        headers: await getHeaders(!shareToken)
      });

      if (!response) {
        throw new Error("No response from UPC");
      }

      const data = (await response.json()) as UpcResponse;

      if (!data) {
        throw new Error("No data from UPC");
      }

      return { status: true, response: data };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: "Failed to fetch UPC data" };
    }
  }
}
