import { createContext } from "react";
import { RiskServiceContextValue, RiskServiceStatus } from "./types";

const defaultValue: RiskServiceContextValue = {
  status: RiskServiceStatus.NotLoaded,
  riskData: {
    screeningFactors: [],
    webAndMediaFactors: [],
    keyDetailsFactors: []
  },
  overallScoreData: {
    bucket: "low",
    score: 0,
    summary: []
  },
  getRiskDataByFactorName: () => undefined,
  onMute: () => {},
  reload: () => {}
};

const RiskServiceContext = createContext(defaultValue);

export default RiskServiceContext;
