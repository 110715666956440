import React from "react";

import { motion } from "framer-motion";

import { CardVariant } from "./types";
import type { Props } from "./types";

import S from "./styles";

const Card = ({
  control,
  variant = CardVariant.default,
  children,
  loading,
  className,
  onClick = () => {}
}: Props) => {
  return loading ? (
    <S.Skeleton>{children}</S.Skeleton>
  ) : (
    <motion.div
      initial={{
        opacity: 0,
        width: 0,
        overflow: "hidden",
        maxHeight: "258px"
      }}
      animate={{
        opacity: 1,
        width: "100%",
        overflow: "visible",
        maxHeight: "auto"
      }}
      exit={{ opacity: 0, width: 0, overflow: "hidden", maxHeight: "258px" }}
      transition={{ ease: "linear" }}
      style={{
        display: "flex"
      }}
    >
      <S.Card
        variant={variant}
        className={className}
        onClick={onClick}
        onKeyDown={e => (e.key === "Enter" ? onClick() : undefined)}
        tabIndex={0}
      >
        {control && <S.ControlContainer>{control}</S.ControlContainer>}
        {children}
      </S.Card>
    </motion.div>
  );
};

export default Card;
