import React from "react";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { useNavigate } from "react-router-dom";
import ButtonNew from "components/atoms/ButtonNew";
import DiagnosticOverview from "pages/diagnostics/DiagnosticsOverview";
import withMountTransition from "pages/define/withMountTransition";
import Loading from "components/atoms/Loading";
import { REPORT_TYPES } from "util/reportTypes";

import config from "config";

import S from "./styles";

const PreparingReport = ({
  enquiryId,
  messages,
  isLoadingInsights,
  notFound,
  hasError,
  unauthorised
}) => {
  const navigate = useNavigate();
  // HACK HACK HACK
  // Extract the first status message as this message contains the
  // Subject along with its type (Person/Organisation).
  const firstMessage = messages && messages[0];
  let _;
  let entityType;
  let subjectName;

  if (firstMessage) {
    // Looking for the string "Subject: (Person)" for example
    const reportTypePattern = Object.values(REPORT_TYPES).join("|");
    const reportRegex = new RegExp(
      `Subject: \\((${reportTypePattern})\\) (.+)`
    );

    // Extract the entity type from the message
    const reportRegexOutput = firstMessage.match(reportRegex);

    // If we fail to find a type then just fallback to Person.
    if (reportRegexOutput) {
      [_, entityType, subjectName] = reportRegexOutput;
    } else {
      entityType = REPORT_TYPES.person;
      subjectName = "your subject";
    }
  }

  const getTitle = () => {
    if (notFound) {
      return "We've hit a snag";
    }

    if (hasError) {
      return "Report could not be generated";
    }

    if (unauthorised) {
      return "Session expired";
    }

    return "Generating your report";
  };

  const getDetailText = () => {
    if (!firstMessage) return null;

    if (notFound) {
      return (
        <S.DetailText>
          We tried our best but we were unable to proceed with this request.
          <br />
          If the problem persists please contact{" "}
          <a href={`mailto:${config.supportEmail}`}>Customer Success</a> for
          assistance.
        </S.DetailText>
      );
    }

    if (hasError) {
      return (
        <S.DetailText>
          Your report on <S.SubjectName>{subjectName}</S.SubjectName> could not
          be generated.
          <br />
          Please try again or contact{" "}
          <a href={`mailto:${config.supportEmail}`}>Customer Success</a> for
          assistance.
        </S.DetailText>
      );
    }

    if (unauthorised) {
      return (
        <S.DetailText>
          It looks like your session has expired before your report finished
          generating.
          <br />
          Please log in and your report will be waiting for you.
        </S.DetailText>
      );
    }

    return (
      <S.DetailText>
        Your report on <S.SubjectName>{subjectName}</S.SubjectName> is expected
        to take approximately{" "}
        {entityType === REPORT_TYPES.organisation ? "20-30" : "5-10"} minutes to
        complete
      </S.DetailText>
    );
  };

  const onNewReport = () => {
    navigate("/search");
  };

  const onLogin = () => {
    navigate("/login");
  };

  return (
    <S.Container>
      <S.InnerContainer>
        <div style={{ maxHeight: "15%" }} />
        <S.DetailsContainer>
          {hasError || notFound || unauthorised ? <S.InfoIcon /> : <Loading />}
          <S.Heading className="large" style={{ marginTop: 0 }}>
            {getTitle()}
          </S.Heading>
          {getDetailText()}
          {(hasError || notFound) && (
            <ButtonNew
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              onClick={onNewReport}
              text="Let's try again"
            />
          )}
          {unauthorised && (
            <ButtonNew
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              onClick={onLogin}
              text="Log in"
            />
          )}
          <DiagnosticOverview
            enquiryId={enquiryId}
            isLoadingInsights={isLoadingInsights}
          />
        </S.DetailsContainer>
      </S.InnerContainer>
    </S.Container>
  );
};

export default withMountTransition(PreparingReport);
