import React from "react";

import S, { classNameOverrides } from "./styles";

import { ButtonType, Props } from "./types";

const ButtonNew = ({
  type,
  size,
  IconLeading,
  IconTrailing,
  BadgeTrailing,
  text,
  disabled,
  selected,
  onClick,
  className,
  style
}: Props) => {
  const buttonProps = {
    ariaPressed: selected,
    onClick,
    selected,
    size,
    IconLeading,
    IconTrailing,
    disabled,
    className,
    style
  };

  const renderButtonContents = () => {
    return (
      <>
        {IconLeading && (
          <IconLeading
            isDisabled={disabled}
            role="presentation"
            className={classNameOverrides.buttonIcons}
          />
        )}
        {text}
        {BadgeTrailing && BadgeTrailing}
        {IconTrailing && !BadgeTrailing ? (
          <IconTrailing
            role="presentation"
            className={classNameOverrides.buttonIcons}
          />
        ) : null}
      </>
    );
  };

  switch (type) {
    case ButtonType.Filled:
      return (
        <S.FilledButton {...buttonProps}>
          {renderButtonContents()}
        </S.FilledButton>
      );
    case ButtonType.FilledLight:
      return (
        <S.FilledLightButton {...buttonProps} data-selected={selected}>
          {renderButtonContents()}
        </S.FilledLightButton>
      );
    case ButtonType.Outlined:
      return (
        <S.OutlinedButton {...buttonProps} data-selected={selected}>
          {renderButtonContents()}
        </S.OutlinedButton>
      );
    case ButtonType.OutlinedLight:
      return (
        <S.OutlinedLightButton {...buttonProps} data-selected={selected}>
          {renderButtonContents()}
        </S.OutlinedLightButton>
      );
    case ButtonType.OutlinedBlue:
      return (
        <S.OutlinedBlueButton {...buttonProps} data-selected={selected}>
          {renderButtonContents()}
        </S.OutlinedBlueButton>
      );
    case ButtonType.Text:
      return (
        <S.TextButton {...buttonProps}>{renderButtonContents()}</S.TextButton>
      );
    default:
      return null;
  }
};

export default ButtonNew;
