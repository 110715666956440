import React from "react";

import S from "./style";

const getFormattedSummary = (input: string) =>
  input
    .replaceAll(/\*\*([^*]+)\*\*/g, "<span>$1</span>")
    .replaceAll(/\n/g, "<br />");

interface FormattedTextProps {
  text: string;
}

export const FormattedText = ({ text }: FormattedTextProps) => {
  return (
    <S.Text
      dangerouslySetInnerHTML={{
        __html: getFormattedSummary(text)
      }}
    />
  );
};
