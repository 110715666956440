import React from "react";

import { ReactComponent as XapienLogo } from "img/logo.svg";
import { ReactComponent as PoweredBy } from "themes/projectdx/img/dj-footer-logo.svg";
import { ReactComponent as ReportFooterLogo } from "themes/projectdx/img/dj-report-footer-logo.svg";
import { ENTITY_TYPE } from "pages/define/utils";

import { standardColors } from "styles/colors";
import { blue } from "themes/projectdx/colors";

import S from "themes/projectdx/styles";

// Custom logo components

const footerLogo = <PoweredBy />;

const navLogo = <S.NavLogo />;
const slogan = (
  <S.Slogan>
    A <span>fully-automated</span> research platform
  </S.Slogan>
);

const reportHeaderLogo = (
  <S.LogoContainer>
    <S.DJLogo />
    <S.LogoLabel>
      POWERED BY XAPIEN<sup>&reg;</sup>
    </S.LogoLabel>
  </S.LogoContainer>
);

const reportFooterLogo = (
  <S.FooterContainer>
    <ReportFooterLogo />
    <div>
      <S.PoweredBy>POWERED BY</S.PoweredBy>{" "}
      <a
        href="https://www.xapien.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <XapienLogo />
      </a>
    </div>
  </S.FooterContainer>
);

const newReportButtonLogo = <S.DJLogoWhite />;

const newReportModalLogo = <S.ProjectDXLogo viewBox="278 0 485 36" />;

const notificationLogo = <S.DJLogo />;

const PoweredByXapienLogo = <S.PoweredByXapienLogo />;

const DJICLogo = <S.DJICLogo />;

// Theme config

const theme = {
  brand: "DX",
  primaryColor: blue.berg,
  darkPrimaryColor: blue.berg,
  alternativePrimaryColor: blue.berg,
  dropdownColor: "#293846",
  button: {
    primary: {
      color: standardColors.white
    },
    tertiary: {
      color: blue.berg
    },
    boxShadowColor: "rgba(0, 162, 212, 0.33)",
    alternativeBoxShadowColor: "rgba(0, 162, 212, 0.33)",
    logo: newReportButtonLogo
  },
  modal: {
    logo: newReportModalLogo
  },
  reportHeader: {
    logo: reportHeaderLogo
  },
  reportFooter: {
    logo: reportFooterLogo
  },
  notification: {
    logo: notificationLogo
  },
  navigation: {
    logo: navLogo
  },
  footer: {
    logo: footerLogo
  },
  login: {
    footerLogo: PoweredByXapienLogo,
    logo: DJICLogo
  },
  backgroundColor: blue.indigo,
  defaultSubjectType: ENTITY_TYPE.Organisation,
  slogan
};

export default theme;
