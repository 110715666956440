import { grey } from "styles/colors";
import { styled } from "@linaria/react";
import Flag from "components/atoms/Flag";
import { family, fontSize, lineHeightPx } from "styles/fonts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  gap: 4px;
  line-height: ${lineHeightPx["20px"]};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
`;

const Text = styled.div`
  font-family: ${family.interLight};
  font-size: ${fontSize.sm};
  line-height: ${lineHeightPx["20px"]};
  color: ${grey.dark};
`;

const CountryFlag = styled(Flag)`
  width: 32px;
  img {
    width: 24px;
    height: 16px;
    margin: 0;
    margin-right: 5px;
    border-radius: 2px;
  }
`;

const S = {
  Container,
  Title,
  Text,

  CountryFlag
};

export default S;
