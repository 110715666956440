import {
  OverallScoreData,
  RiskData,
  RiskFactor,
  RiskFactorName
} from "api/risk";

export interface RiskServiceContextValue {
  status: RiskServiceStatus;
  riskData: RiskData;
  overallScoreData: OverallScoreData;
  getRiskDataByFactorName: (
    factorName: RiskFactorName
  ) => RiskFactor | undefined;
  onMute: (name: RiskFactorName, mute: boolean) => void;
  reload: () => void;
}

export enum RiskServiceStatus {
  NotLoaded,
  Loading,
  Loaded,
  MutingRiskIcon,
  MutingRiskError,
  LoadingOverallScore,
  Error
}
