/* eslint-disable @typescript-eslint/no-unused-vars */
import type { CollectionListState } from "util/hooks/useCollectionList/types";
import type { CollectionSearchState } from "util/hooks/useCollectionSearch/types";
import {
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";
import type { SearchResult } from "api/search";
import { apm } from "@elastic/apm-rum";

import {
  Idam_Contracts_Reports_CreateReportAccessRequestRequest,
  Idam_Contracts_Enums_ReportRole,
  ReportService as IdamReportService,
  Idam_Contracts_Reports_SearchRequest,
  Idam_Contracts_Reports_SetReportSharingPreferences,
  Idam_Contracts_Reports_UpdateReportAccessRequestRequest,
  IdentityOrganisationApiService,
  Idam_Contracts_Reports_UpdateReportMonitoringRequest
} from "api/idam";
import { ReportService as PortalReportService, UsersService } from "api/portal";
import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";

import {
  Report,
  ReportSharePreferences,
  ReportSharePermission,
  ShareLinkToken,
  RequestAccess,
  ReportCollectionId
} from "./types";

import { parseReportItem } from "./utils";

export type { Report };

export default class Reports {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: ReportCollectionId.Recent,
          title: "Recent",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.list,
          pollingEnabled: true,
          items: [],
          totalItemCount: 0,
          status: CollectionStatus.stale,
          hidden: true,
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list,
            filterByRead: true,
            excludeGroupSharedReports: true,
            excludeUserSharedReports: true,
            excludeAllAccessibleReports: true
          }
        }
      ]
    };
  }

  async search({
    query,
    offset,
    limit,
    searchTags,
    userId,
    filterByUserId,
    filterAllAccessible
  }: {
    query: string;
    offset: number;
    limit: number;
    searchTags?: SearchResult[];
    userId: string;
    filterByUserId?: string;
    filterAllAccessible?: boolean;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    const filters =
      searchTags && searchTags.length > 0
        ? searchTags.reduce(
            (acc: { [key: string]: boolean | string[] }, tag) => {
              switch (tag.queryType) {
                case "filter": {
                  acc[tag.id] = true;
                  return acc;
                }

                case "users": {
                  if (typeof acc.users === "object") {
                    acc.users = [...acc.users, tag.id];
                    return acc;
                  }
                  acc.users = [tag.id];

                  return acc;
                }

                case "groups": {
                  if (typeof acc.groups === "object") {
                    acc.groups = [...acc.groups, tag.id];
                    return acc;
                  }
                  acc.groups = [tag.id];

                  return acc;
                }

                default: {
                  return acc;
                }
              }
            },
            {}
          )
        : {};

    const requestBody: Idam_Contracts_Reports_SearchRequest = {
      ...filters,
      queryString: query,
      start: offset,
      limit,
      filterAllAccessible
    };

    try {
      let reports;
      let totalItemCount = 0;

      if (filterByUserId) {
        const response = await UsersService.postOrganisationsUsersReportsSearch(
          { userId: filterByUserId, requestBody }
        );
        reports = response.reports;
        totalItemCount = response.total ?? 0;
      } else {
        const response = await PortalReportService.postReportsSearch({
          requestBody
        });
        reports = response.reports;
        totalItemCount = response.total ?? 0;
      }

      if (!reports) {
        return { items: [], totalItemCount: 0 };
      }

      const items = reports.map(report => parseReportItem(userId, report));
      return { items, totalItemCount };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async list({
    id,
    limit,
    offset,
    excludeGroupSharedReports,
    excludeUserSharedReports,
    excludeAllAccessibleReports,
    userId,
    filters
  }: {
    id: string;
    limit: number;
    offset: number;
    excludeGroupSharedReports?: boolean;
    excludeUserSharedReports?: boolean;
    excludeAllAccessibleReports?: boolean;
    userId: string;
    filters?: string[];
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    try {
      const read = filters?.includes("unread") ? false : undefined;

      const { reports, total } = await PortalReportService.postReportsSearch({
        requestBody: {
          start: offset,
          limit,
          filterAllAccessible: !excludeAllAccessibleReports,
          read
        }
      });

      if (!reports) return { items: [], totalItemCount: 0 };

      const items = reports
        .filter(Boolean)
        .map(report => parseReportItem(userId, report));

      return { items, totalItemCount: total ?? reports.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async markRead(reportId: string) {
    try {
      await IdamReportService.putReportsMarkRead({ reportId });
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  }

  async markUnread(reportId: string) {
    try {
      await IdamReportService.putReportsMarkUnread({ reportId });
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  }

  async getReportSharePreferences(
    reportId: string
  ): Promise<FetchResult<ReportSharePreferences>> {
    try {
      const response = await IdamReportService.getReportsSharingPreferences({
        reportId
      });

      return {
        status: true,
        response: {
          sharedWithUsers:
            response.sharedWithUsers?.map(share => ({
              userId: share.userId ?? "",
              firstName: share.firstName ?? "",
              lastName: share.lastName ?? "",
              email: share.email ?? "",
              avatarUrl: share.avatarUrl ?? "",
              permission: share.permission as unknown as ReportSharePermission
            })) ?? [],
          sharedWithGroups:
            response.sharedWithGroups?.map(share => ({
              groupId: share.groupId ?? "",
              name: share.name ?? "",
              permission: share.permission as unknown as ReportSharePermission
            })) ?? []
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        response: { sharedWithUsers: [], sharedWithGroups: [] },
        message: getErrorMessage(e)
      };
    }
  }

  async setReportSharePreferences(
    reportId: string,
    preferences: ReportSharePreferences
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Reports_SetReportSharingPreferences = {
        sharedWithUsers: preferences.sharedWithUsers.map(share => ({
          userId: share.userId,
          permission:
            share.permission as unknown as Idam_Contracts_Enums_ReportRole
        })),
        sharedWithGroups: preferences.sharedWithGroups.map(share => ({
          groupId: share.groupId,
          permission:
            share.permission as unknown as Idam_Contracts_Enums_ReportRole
        }))
      };

      await IdamReportService.putReportsSharingPreferences({
        reportId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async generateShareToken(
    reportId: string,
    isForPDFExport?: boolean
  ): Promise<FetchResult<ShareLinkToken>> {
    try {
      const response = await IdamReportService.postReportsShareLink({
        reportId,
        isForPdfExport: isForPDFExport
      });

      if (!response.token) {
        return { status: false };
      }

      return {
        status: true,
        response: {
          token: response.token
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getShareToken(reportId: string): Promise<FetchResult<ShareLinkToken>> {
    try {
      const response = await IdamReportService.getReportsShareLink({
        reportId
      });

      if (!response.token) {
        return { status: false };
      }

      return {
        status: true,
        response: { token: response.token }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async deleteShareToken(reportId: string): Promise<FetchResult> {
    try {
      await IdamReportService.deleteReportsShareLink({ reportId });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  mapReportSharePermissonToReportRole(
    permisson: ReportSharePermission.Read | ReportSharePermission.Write
  ): Idam_Contracts_Enums_ReportRole {
    const mapping: Record<
      ReportSharePermission.Read | ReportSharePermission.Write,
      Idam_Contracts_Enums_ReportRole
    > = {
      [ReportSharePermission.Write]: Idam_Contracts_Enums_ReportRole.WRITE,
      [ReportSharePermission.Read]: Idam_Contracts_Enums_ReportRole.READ
    };

    return mapping[permisson];
  }

  async transferReportOwnership(
    reportId: string,
    userId: string,
    retainAccessRole: ReportSharePermission.Write | ReportSharePermission.Read
  ): Promise<FetchResult> {
    try {
      await IdamReportService.postReportsTransferOwnership({
        reportId,
        userId,
        requestBody: {
          retainAccess:
            this.mapReportSharePermissonToReportRole(retainAccessRole)
        }
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async deleteReport(reportId: string): Promise<FetchResult> {
    try {
      await IdamReportService.deleteReports({ reportId });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async changeMonitoringPermission(request: {
    reportId: string;
    adverseMedia: boolean;
    sanctions: boolean;
  }): Promise<FetchResult> {
    try {
      await IdamReportService.putReportsMonitoring({
        reportId: request.reportId,
        requestBody: {
          adverseMedia: request.adverseMedia,
          sanctions: request.sanctions
        }
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async requestAccess(
    reportId?: string,
    role?: Idam_Contracts_Enums_ReportRole
  ): Promise<FetchResult> {
    if (!reportId) {
      console.error("requestAccess: No report id given");
      return { status: false };
    }

    try {
      const requestBody: Idam_Contracts_Reports_CreateReportAccessRequestRequest =
        {
          role: role ?? Idam_Contracts_Enums_ReportRole.READ
        };
      await IdamReportService.postReportsAccessRequest({
        reportId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getRequestAccess(
    reportId?: string,
    requesterId?: string
  ): Promise<RequestAccess | null> {
    if (!reportId || !requesterId) {
      console.error(
        "getRequestAccess: Need both reportId and requesterId parameters"
      );
      return null;
    }

    try {
      const { requester, report, role } =
        await IdamReportService.getReportsAccessRequest({
          reportId,
          requesterId
        });
      return {
        requester: {
          userId: requester?.userId ?? "",
          firstName: requester?.firstName ?? "",
          lastName: requester?.lastName ?? "",
          email: requester?.email ?? ""
        },
        report: {
          reportId: report?.reportId ?? "",
          imageUrl: report?.imageUrl ?? "",
          subjectItem: report?.subjectItem ?? "",
          contextItems: report?.contextItems ?? []
        },
        permission: role ?? Idam_Contracts_Enums_ReportRole.READ
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return null;
    }
  }

  async grantAccess(
    permission: Idam_Contracts_Enums_ReportRole,
    reportId?: string,
    requesterId?: string
  ): Promise<FetchResult> {
    if (!reportId || !requesterId) {
      console.error(
        "grantAccess: Need both reportId and requesterId parameters"
      );
      return { status: false };
    }

    try {
      const requestBody: Idam_Contracts_Reports_UpdateReportAccessRequestRequest =
        {
          approved: true,
          role: permission
        };
      await IdamReportService.putReportsAccessRequest({
        reportId,
        requesterId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async setPDFMetadata(
    reportId: string,
    state: Map<string, any>
  ): Promise<string | undefined> {
    try {
      const response = await IdamReportService.postReportsPdfMetadata({
        requestBody: {
          reportId,
          viewState: JSON.stringify(Object.fromEntries(state))
        }
      });
      return response.requestId!;
    } catch (e) {
      apm.captureError(e as Error);
      console.error("Error setting PDF metadata", {
        e,
        reportId,
        stateLength: state.size
      });
      return undefined;
    }
  }

  async getPDFMetadata(requestId: string): Promise<Map<string, any>> {
    try {
      const response = await IdamReportService.getReportsPdfMetadata({
        requestId
      });
      return new Map(Object.entries(response.viewState ?? {}));
    } catch (e) {
      apm.captureError(e as Error);
      console.error("Error getting PDF metadata", { e, requestId });
      return new Map();
    }
  }
}
