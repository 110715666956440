import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey, red, standardColors } from "styles/colors";

const RiskIcon = styled.div<{ isActive: boolean; backgroundColor?: string }>`
  svg {
    path {
      fill: ${({ isActive }) =>
        isActive ? red.directRiskFill : grey.disabled};
    }
  }

  &.loading {
    path {
      animation: loadingIcon 1.5s ease-in-out 0.5s infinite;
    }
  }

  @keyframes loadingIcon {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    background: ${({ backgroundColor }) =>
      backgroundColor || standardColors.white};
    border-radius: 50%;
  }
`;

const RiskIcons = styled.div`
  display: flex;
  & > div {
    margin: -4px;
  }
`;

const TooltipContent = styled.p`
  margin: 0;
  padding: 0.875rem;
  font-size: ${fontSize.sm};
  max-width: 23.125rem;
`;

const S = {
  RiskIcons,
  TooltipContent,
  RiskIcon
};

export default S;
