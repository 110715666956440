import React, { useState } from "react";

import LoginForm from "components/molecules/LoginModal/LoginForm";
import ForgottenPassword from "components/molecules/LoginModal/ForgottenPassword";

const LoginBox = () => {
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);

  const onForgottenPasswordToggle = () =>
    setShowForgottenPassword(prev => !prev);

  if (showForgottenPassword) {
    return <ForgottenPassword onBackClick={onForgottenPasswordToggle} />;
  }

  return <LoginForm onForgottenPasswordClick={onForgottenPasswordToggle} />;
};

export default LoginBox;
