import {
  Eye,
  ChevronDown as ChevronDownSvg,
  ChevronUp as ChevronUpSvg
} from "react-feather";
import { styled } from "@linaria/react";
import Flag from "components/atoms/Flag";
import { fontSize, family } from "styles/fonts";
import { DiImage } from "components/molecules/DiImage";
import { grey, blue, standardColors } from "styles/colors";
import { ReactComponent as PlaceholderImage } from "img/icons/profile-placeholder.svg";
import ReactTooltip from "../../atoms/ReactTooltip";

const hr = `1px solid ${grey.light}`;

const CardContainer = styled.div`
  background: ${grey.lightest};
  border-radius: 4px;
  min-width: 1000px;
  padding: 20px;
`;

const CardInnerContainer = styled.div`
  text-align: left;
`;

const CardHeader = styled.div`
  display: flex;
  height: auto;
  gap: 8px;
`;

const SourceImage = styled(DiImage)`
  object-fit: cover;
  height: 56px;
  width: 56px;
  border-radius: 8px;
`;

const PlaceholderIcon = styled(PlaceholderImage)`
  object-fit: cover;
  height: 56px;
  width: 56px;
  border-radius: 8px;
`;

const CountryFlag = styled(Flag)`
  width: 31px;
  img {
    width: 24px;
    height: 16px;
    margin: 0;
    margin-top: 1px;
    margin-right: 5px;
    border-radius: 2px;
  }
`;

const GridCell = styled.div`
  padding: 16px;
  border: 1px solid ${grey.panel};
`;

const riskIconsSectionStyles = {
  display: "flex",
  "justify-content": "space-between",
  "border-top": "none",
  "padding-bottom": "4px",
  "padding-top": "4px"
};

const PersonaCardRiskIcons = styled(GridCell)`
  grid-area: risk-icons;
  ${riskIconsSectionStyles};
  border-right: none;
`;

const CardTextContainer = styled.div<{ enableTruncation: boolean }>`
  padding-top: 14px;
  margin-top: 8px;
  height: auto;
  border-top: ${hr};
  color: black;
  font-size: ${fontSize.sm};
  // font-family: ${family.interSemiBold};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ enableTruncation }) =>
    enableTruncation ? 3 : "none"};
  overflow: ${({ enableTruncation }) =>
    enableTruncation ? "hidden" : "visible"};
  text-overflow: ${({ enableTruncation }) =>
    enableTruncation ? "ellipsis" : "none"};
`;

const SimpleContainer = styled.div<{
  width?: "auto" | "100%";
  direction?: "column" | "row";
  justifyContent?: "space-between" | "space-around";
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-around"};
  height: auto;
  width: ${({ width }) => width || "auto"};
  flex-direction: ${({ direction }) => direction || "row"};
`;

const Title = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  line-height: 1.38;
  margin-right: 4px;
`;

const Label = styled.div`
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  line-height: 1.38;
  margin-right: 4px;
`;

const Tooltip = styled(ReactTooltip)`
  display: flex;
`;

const ExpandButton = styled.button`
  color: ${blue.link};
  border: 0px;
  text-decoration: underline;
  background-color: transparent;
  padding: 10px 0 0;
  font-size: ${fontSize.sm};
  display: flex;
  align-items: center;
  gap: 3px;

  &:focus {
    outline: none;
  }
`;

const ViewReportButton = styled.button`
  color: ${grey.dark};
  border: 1px solid ${grey.darkish};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  border-radius: 6px;
  background-color: ${standardColors.white};
  padding: 3px 5px;
  font-size: small;
  display: flex;
  gap: 3px;
  height: fit-content;
  align-items: center;
  margin-left: auto;

  &:focus {
    outline: none;
  }
`;

const ChevronDown = styled(ChevronDownSvg)`
  --icon-width-height: 1rem;
  width: var(--icon-width-height);
  height: var(--icon-width-height);
  stroke: ${blue.link};
`;

const ChevronUp = styled(ChevronUpSvg)`
  --icon-width-height: 1rem;
  width: var(--icon-width-height);
  height: var(--icon-width-height);
  stroke: ${blue.link};
`;

const EyeIcon = styled(Eye)`
  width: 18px;
  height: 18px;
`;

export interface MainEntityTagProps {
  backgroundColor: string;
}

// Using styled-components (or you can use regular CSS)
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; // Allows wrapping if the tags overflow
  gap: 8px; // Optional: adds space between the tags
  margin-top: 16px;
`;

const S = {
  Title,
  Label,
  Tooltip,
  SimpleContainer,
  CardContainer,
  CardInnerContainer,
  CardHeader,
  PersonaCardRiskIcons,
  SourceImage,
  CardTextContainer,
  ExpandButton,
  ViewReportButton,
  ChevronDown,
  ChevronUp,
  PlaceholderIcon,
  CountryFlag,
  EyeIcon,
  TagsContainer
};

export default S;
