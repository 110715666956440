/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OverallScore } from "../models/OverallScore";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class RiskScoresService {
  /**
   * Get Overall Score
   * used to fetch the overall score for a report.
   * @returns OverallScore Success
   * @throws ApiError
   */
  public static getV1ReportsScore({
    reportId
  }: {
    /**
     * Report ID
     */
    reportId: string;
  }): CancelablePromise<OverallScore> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/reports/{reportId}/score",
      path: {
        reportId: reportId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Update Overall Score
   * Used to update the overall score for a report.
   * @returns void
   * @throws ApiError
   */
  public static putV1ReportsScore({
    reportId
  }: {
    /**
     * Report ID
     */
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/v1/reports/{reportId}/score",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        500: `Internal server error`
      }
    });
  }
}
