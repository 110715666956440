import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import Heading from "components/atoms/Heading";
import { blue, grey, standardColors } from "styles/colors";
import { ReactComponent as BuildingIcon } from "img/building.svg";
import { fontSize } from "styles/fonts";
import { Zap } from "react-feather";

const StatusHeading = styled(Heading)`
  color: ${standardColors.white};
  padding-left: 0.5rem;
`;

const Building = styled(BuildingIcon)`
  transform: scale(0.875);
`;

const LoadingBar = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 104%;
  height: 104%;
  border-radius: 50%;
  border: 4px solid ${blue.xapienDark};
  border-left-color: ${blue.blueDiamond};
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AvatarIconContainer = styled.div`
  position: relative;
  border-radius: 50%;
`;

const FailedReportTooltip = styled.div`
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  font-size: ${fontSize.sm};
`;

const FailedReportTooltipBody = styled.p`
  margin: 0;
`;

const ContactSupportLink = styled.a`
  color: ${blue.icon} !important;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
`;

/** classNameOverrides */

const card = css`
  cursor: pointer;

  &:hover {
    outline: 2px solid ${blue.xapienLight};
  }
`;

const cardUpdating = css`
  cursor: pointer;

  &:hover {
    outline: 2px solid rgba(40, 228, 250, 0.4);
  }
`;

const SolidZap = styled(Zap)`
  fill: ${grey.dark};
  height: 16px;
  width: 16px;
  margin-left: 4px;
`;

const GreyedOutZap = styled(Zap)`
  color: ${grey.ghost};
  height: 16px;
  width: 16px;
  margin-left: 4px;
`;

const S = {
  StatusHeading,
  Building,
  LoadingBar,
  AvatarIconContainer,
  FailedReportTooltip,
  FailedReportTooltipBody,
  ContactSupportLink,
  SolidZap,
  GreyedOutZap
};

export const classNameOverrides = {
  card,
  cardUpdating
};

export default S;
