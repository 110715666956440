import React, { ReactNode } from "react";
import Chip from "components/atoms/Chip";
import { ChipSize, ChipVariant } from "components/atoms/Chip/types";

import { CardVariant } from "components/molecules/Card/types";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import Skeleton from "components/atoms/Skeleton";

import { ReportStatus } from "api/reports/types";
import S from "./styles";

export interface Props {
  title?: string;
  hasIndicator?: boolean;
  showCardIndicators?: boolean;
  subtitle?: ReactNode;
  overview?: React.ReactNode;
  variant?: CardVariant;
  loading?: boolean;
  status?: ReportStatus;
  icon?: ReactNode;
}

const getStatusIcon = (status: ReportStatus) => {
  switch (status) {
    case ReportStatus.failed: {
      return <S.FailedIcon />;
    }
    case ReportStatus.running:
    case ReportStatus.updating: {
      return <S.InProgressIcon />;
    }
    case ReportStatus.queued: {
      return <S.QueuedIcon />;
    }
    default: {
      return null;
    }
  }
};

const getStatusLabel = (status: ReportStatus) => {
  switch (status) {
    case ReportStatus.failed: {
      return "We hit a snag";
    }
    case ReportStatus.running:
    case ReportStatus.updating: {
      return "In progress...";
    }
    case ReportStatus.queued: {
      return "Scheduled for processing";
    }
    default: {
      return status;
    }
  }
};

const ListCardHeader = ({
  title,
  hasIndicator,
  showCardIndicators,
  subtitle,
  overview,
  loading,
  status,
  variant = CardVariant.default,
  icon
}: Props) => {
  return loading ? (
    <S.Header>
      <S.AvatarAndTitle>
        <Avatar avatarSize={AvatarSize.Large} loading />
        <S.TitlesContainer>
          <Skeleton width="30%" height="26px" />
          <Skeleton width="100%" height="18px" />
        </S.TitlesContainer>
      </S.AvatarAndTitle>
    </S.Header>
  ) : (
    <S.Header>
      {showCardIndicators && (
        <S.IndicatorContainer>
          <S.Indicator variant={variant} isVisible={hasIndicator} />
        </S.IndicatorContainer>
      )}
      <S.AvatarAndTitle>
        {icon}
        <S.TitlesContainer>
          {title && (
            <S.CustomHeading level={5} variant={variant}>
              {title}
            </S.CustomHeading>
          )}
          {subtitle && <S.Subtitle variant={variant}>{subtitle}</S.Subtitle>}
        </S.TitlesContainer>

        {status && status !== ReportStatus.ready && (
          <Chip
            label={
              <S.StatusContainer>
                {getStatusIcon(status)} {getStatusLabel(status)}
              </S.StatusContainer>
            }
            variant={ChipVariant.Outlined}
            size={ChipSize.Small}
            colorVariant={variant}
          />
        )}
      </S.AvatarAndTitle>
      {overview}
    </S.Header>
  );
};

export default ListCardHeader;
