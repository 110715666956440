import { styled } from "@linaria/react";

import { NavLink as ReactNavLink } from "react-router-dom";

import { standardColors, grey, blue } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { ReactComponent as CompanyLogo } from "img/logo.svg";
import ButtonNew from "components/atoms/ButtonNew";

import theme from "theme";

const GlobalNavigationBar = styled.nav`
  font-family: ${family.interRegular};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Logo = styled(CompanyLogo)`
  path {
    fill: ${standardColors.white};
  }
`;

const NavItems = styled.div`
  margin-left: -13px
  display: flex;
  flex: 1;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NewReport = styled(ButtonNew)`
  padding: 7px 17px;

  &:not(:hover, :focus) {
    background-color: ${theme.alternativePrimaryColor ?? blue.xapienLight};
    color: ${theme.button.primary.color};
  }
`;

const NavLink = styled(ReactNavLink)`
  padding: 7px 17px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ selected }) => (selected ? standardColors.white : grey.rule)};
  font-size: ${fontSize.sm};
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${standardColors.white};
  }

  &::after {
    content: "";
    position: relative;
    top: 0.5rem;
    height: ${({ selected }) => (selected ? "1.5px" : "1px")};
    width: 1.5rem;
    background-color: ${standardColors.white};
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    border-radius: 100px;
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const NavItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const LogoNavLink = styled(NavLink)`
  margin-right: auto;
  margin-top: 2px;
  padding: 0;

  &:after {
    width: 0;
  }
`;

const S = {
  GlobalNavigationBar,
  Logo,
  NavItems,
  NavLink,
  NewReport,
  NavItemContainer,
  LogoNavLink
};

export default S;
