import { styled } from "@linaria/react";

// @ts-ignore
import theme from "theme";
import { hexToRgb } from "styles/hexToRgb";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(255, 255, 255, 0) 55%,
    ${() => {
        const { r, g, b } = hexToRgb(theme.backgroundColor);
        return `rgba(${r}, ${g}, ${b}, 1)`;
      }}
      65%,
    ${() => {
        const { r, g, b } = hexToRgb(theme.backgroundColor);
        return `rgba(${r}, ${g}, ${b}, 1)`;
      }}
      100%
  );
`;

const Container = styled.div`
  position: relative;
  width: 190px;
  height: 190px;
`;

const S = {
  Container,
  Overlay
};

export default S;
