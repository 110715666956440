/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RiskFactorName {
  SanctionsRiskFactorName = "sanctions",
  WatchlistsRiskFactorName = "watchlists",
  FinancialCrimeRiskFactorName = "financial_crime",
  LegalAndComplianceRiskFactorName = "legal_and_compliance",
  ProceedingsRiskFactorName = "proceedings",
  StateOwnershipRiskFactorName = "state_ownership",
  ESGRiskFactorName = "esg",
  RelatedPartiesRiskFactorName = "related_parties",
  OtherRiskFactorName = "other",
  LocationsRiskFactorName = "locations",
  PEPsRiskFactorName = "peps",
  IncorporationDateRiskFactorName = "incorporation_date",
  CountryOfIncorporationRiskFactorName = "country_of_incorporation",
  BusinessSectorRiskFactorName = "business_sector",
  IntegrityCheckSpecialInterestRiskFactorName = "integrity_check_special_interest",
  IntegrityCheckSpecialInterestRCARiskFactorName = "integrity_check_special_interest_rca",
  IntegrityCheckSanctionsRCARiskFactorName = "integrity_check_sanctions_rca",
  IntegrityCheckWatchlistsRCARiskFactorName = "integrity_check_watchlists_rca",
  IntegrityCheckPEPsRCARiskFactorName = "integrity_check_peps_rca"
}
