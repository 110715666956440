import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import EntitiesApi, { EntityStatus } from "api/entities";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import EntitySection from "components/organisms/EntitySection";
import RelatedPartiesEntitySectionHelpText from "components/molecules/RelatedPartiesEntitySectionHelpText";
import useEntityList, {
  EntityListContextProvider
} from "util/hooks/useEntityList";
import useRiskService from "util/hooks/useRiskService";
import { RiskServiceStatus } from "util/hooks/useRiskService/types";

interface ReportState {
  reportId: string;
  state: EntityStatus;
}

const RelatedPartiesEntitySection = () => {
  const enquiryId = useEnquiryId();
  const { reload: reloadRisk, status: riskStatus } = useRiskService();
  const [_, setReportStates] = useState<ReportState[]>([]);
  const {
    state,
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    updateDetails
  } = useEntityList();
  const { search } = useLocation();

  useEffect(() => {
    updateDetails({
      title: `
        We have identified the parties related to your subject based on data from Moody's which will include any UBOs, Shareholders and Directors
        found. In this section, you will be able to run reports and view the key findings from our research.`,
      label: "Ultimate Beneficial Owner threshold",
      value: "25%",
      helpText: <RelatedPartiesEntitySectionHelpText />,
      commissionedEntityListTitle: "Related Parties Reports",
      entityListTitle: "Related Parties identified"
    });

    const api = new EntitiesApi();

    api
      .getRelatedParties({ enquiryId })
      .then(({ response }) => {
        if (response) {
          updateCommissionedEntityList(response.commissionedEntityList);
          updateEntityList(response.entityList);
          updateFilters(response.entityFilters);
        }
      })
      .catch(() => {});
  }, [
    updateDetails,
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    search,
    enquiryId
  ]);

  useEffect(() => {
    const commissionedReports = state.commissionedEntityList ?? [];
    const newStatuses = commissionedReports
      .map(report => {
        if (report.status) {
          return { reportId: report.id, state: report.status };
        }
        return null;
      })
      .filter(Boolean) as ReportState[];

    setReportStates(prevStatues => {
      prevStatues.forEach(({ reportId, state: oldState }) => {
        const newState = newStatuses.find(s => s.reportId === reportId)?.state;
        if (
          oldState === EntityStatus.inProgress &&
          newState === EntityStatus.done &&
          riskStatus !== RiskServiceStatus.Loading
        ) {
          reloadRisk();
        }
      });

      return newStatuses;
    });
  }, [state, riskStatus, reloadRisk]);

  return <EntitySection title="Related parties" />;
};

const RelatedPartiesResearch = () => {
  return (
    <EntityListContextProvider>
      <RelatedPartiesEntitySection />
    </EntityListContextProvider>
  );
};

export default RelatedPartiesResearch;
