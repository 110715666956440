import { styled } from "@linaria/react";
import { grey, purple } from "styles/colors";
import ButtonNew from "components/atoms/ButtonNew";

const Button = styled(ButtonNew)`
  background: ${purple.lighter};
  color: ${grey.dark};
  width: fit-content;
  min-width: 100px;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${purple.light};
    color: ${grey.dark};
  }

  &:disabled {
    background-color: ${grey.light};
  }
`;

const S = {
  Button
};

export default S;
