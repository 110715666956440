import { styled } from "@linaria/react";
import { green, grey, red } from "styles/colors";
import { family, size, lineHeight, headingLetterSpacing } from "styles/fonts";

export interface ContainerProps {
  hasRisk: boolean;
  active: boolean;
}

const IconContainer = styled.div<{ isRca: boolean }>`
  margin-bottom: 6px;
  width: ${({ isRca }) => (isRca ? 82 : 56)}px;
  height: 56px;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 3px;
  align-items: center;
  flex-direction: column;
  width: 102px;
  height: 126px;
  justify-content: center;
  background: ${({ active }) => (active ? green.hover : "transparent")};
  border-radius: 12px;
  color: ${({ hasRisk }) => (hasRisk ? red.directRiskFill : grey.mid)};

  &:hover {
    cursor: ${({ active }) => (active ? "pointer" : "auto")};
  }

  ${IconContainer} > svg {
    path {
      fill: ${({ hasRisk }) => (hasRisk ? red.directRiskFill : grey.mid)};
    }
  }
`;

const Title = styled.h5`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  text-align: center;
  font-family: ${family.interSemiBold};
  font-size: ${size.sm};
  font-style: normal;
  font-weight: 600;
  padding: 0 4px;
  letter-spacing: ${headingLetterSpacing.lg};
  margin-bottom: 0;
`;

const Text = styled.div`
  text-align: center;
  font-kerning: none;
  font-family: ${family.interRegular};
  font-size: ${size["2xs"]};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight["2xl"]};
  margin-bottom: 0;
`;

const DetailsLink = styled.button`
  border: 0;
  outline: none;
  background: none;
  color: #007aff;
  text-align: center;
  font-family: ${family.interRegular};
  font-size: ${size["2xs"]};
  font-style: normal;
  font-weight: 500;
  line-height: ${lineHeight["2xl"]};
  letter-spacing: ${headingLetterSpacing.lg};
  text-decoration-line: underline;
  padding: 0;
  cursor: pointer;
`;

const S = {
  Text,
  Title,
  Container,
  DetailsLink,
  IconContainer
};

export default S;
