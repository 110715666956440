import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const EntityCount = styled.div`
  font-size: ${fontSize["2xl"]};
`;

const S = {
  Container,
  EntityCount
};

export default S;
