import { styled } from "@linaria/react";
import { grey, purple, standardColors } from "styles/colors";

const IconWrapper = styled.div<{
  variant?: "small" | "medium";
  isDisabled?: boolean;
}>`
  background: ${({ isDisabled }) =>
    (isDisabled && grey.midLight) || purple.darker};
  color: ${standardColors.white};
  border-radius: 50%;
  width: ${({ variant }) =>
    !variant || variant === "small" ? "18px" : "32px"};
  height: ${({ variant }) =>
    !variant || variant === "small" ? "18px" : "32px"};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const S = {
  IconWrapper
};

export default S;
