import React, { useState, type FC } from "react";
import config from "config";
import { EntityStatus } from "api/entities";
import Heading from "components/atoms/Heading";
import Popover from "components/atoms/Popover";
import { standardColors } from "styles/colors";

import S from "./styles";

interface Props {
  status?: EntityStatus;
}

const Content = () => {
  return (
    <S.Container>
      <S.ContentInfoIcon />
      <S.Content>
        <Heading level={5}>
          <strong>We hit a snag</strong>
        </Heading>
        <p>
          We’ve run into some trouble generating the report. If the issue
          persists, please contact{" "}
          <a href={`mailto:${config.supportEmail}`}>Customer Success</a>.
        </p>
      </S.Content>
    </S.Container>
  );
};

const EntityCardStatus: FC<Props> = ({ status }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const handleTogglePopover = () => {
    setIsPopoverVisible(prev => !prev);
  };

  if (status !== EntityStatus.failed) return null;

  return (
    <Popover
      isOpenOverride={isPopoverVisible}
      hideArrow
      maxWidth="448px"
      arrowColor={standardColors.white}
      content={<Content />}
    >
      <S.InfoIcon onClick={handleTogglePopover} />
    </Popover>
  );
};

export default EntityCardStatus;
