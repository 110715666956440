import React, { useContext } from "react";
import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { ForReviewSection } from "./ForReviewSection";

const ForReviewInformation = React.forwardRef((props, ref) => {
  const { enabled: diagnosticsModeEnabled } = useContext(
    DiagnosticsModeContext
  );

  if (isPDX && !diagnosticsModeEnabled) {
    return null;
  }

  return (
    <ForReviewSection
      {...props}
      ref={ref}
      isDisregarded
      description="These individuals might be your subject"
    />
  );
});

export default ForReviewInformation;
