import React, { useContext } from "react";
import {
  MinusPlusIcon,
  MinusPlusIconType
} from "components/atoms/MinusPlusIcon";
import { isPDX } from "static-config";
import useViewerMode from "util/hooks/useViewerMode";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";

import S from "./styles";

export enum MuteRiskButtonType {
  minus,
  plus,
  undo
}

interface MuteRiskButtonProps {
  type: MuteRiskButtonType;
  onClick: () => void;
}

export const MuteRiskButton = ({ type, onClick }: MuteRiskButtonProps) => {
  const { isViewerModeEnabled } = useViewerMode();
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  const isMutingDisabled =
    isViewerModeEnabled || (isPDX && !diagnosticsModeEnabled);

  if (type === MuteRiskButtonType.undo) {
    return (
      <S.Button
        type={ButtonType.Filled}
        size={ButtonSize.Small}
        disabled={isMutingDisabled}
        onClick={onClick}
        text="Undo"
      />
    );
  }

  const leadingIcon = () => {
    const iconType =
      type === MuteRiskButtonType.minus
        ? MinusPlusIconType.minus
        : MinusPlusIconType.plus;

    return <MinusPlusIcon type={iconType} isDisabled={isMutingDisabled} />;
  };

  const buttonText =
    type === MuteRiskButtonType.plus ? "Mark as risk" : "Mark as not risk";

  return (
    <S.Button
      text={buttonText}
      IconLeading={leadingIcon}
      type={ButtonType.Filled}
      size={ButtonSize.Small}
      disabled={isMutingDisabled}
      onClick={onClick}
    />
  );
};
