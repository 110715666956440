import React, { FC, useState, useRef, useEffect, ReactNode } from "react";

import { AnimatePresence } from "framer-motion";

import Flag from "components/atoms/Flag";
import Chip from "components/atoms/Chip";
import { ChipVariant, ChipSize } from "components/atoms/Chip/types";
import WithInspector from "components/organisms/WithInspector";

import { RiskLevel } from "api/risk";

import { type InformationSource } from "api/report/report-types";

import S from "./styles";

export enum BasicDetailsType {
  text = "text",
  country = "country",
  address = "address",
  stock = "stock"
}

interface BasicDetailsRisk {
  level: RiskLevel;
  reason: string;
}

const riskConfig = {
  [RiskLevel.HighRiskLevel]: {
    label: "High risk",
    chipVariant: ChipVariant.FilledRed
  },
  [RiskLevel.MediumHighRiskLevel]: {
    label: "Medium high risk",
    chipVariant: ChipVariant.FilledRed
  },
  [RiskLevel.MediumRiskLevel]: {
    label: "Medium risk",
    chipVariant: ChipVariant.FilledMid
  },
  [RiskLevel.MediumLowRiskLevel]: {
    label: "Medium low risk",
    chipVariant: ChipVariant.FilledMid
  },
  [RiskLevel.LowRiskLevel]: {
    label: "Low risk",
    chipVariant: ChipVariant.FilledMid
  },
  [RiskLevel.NoneRiskLevel]: {
    label: "No risk",
    chipVariant: ChipVariant.FilledMid
  }
};

interface StockItem {
  name: string;
  sources: InformationSource[];
  ticker?: string;
  startDate?: {
    day: number;
    month: number;
    monthShort: string;
    year: number;
  };
  endDate?: {
    day: number;
    month: number;
    monthShort: string;
    year: number;
  };
}

interface BasicDetailsStock {
  title: string;
  itemType: BasicDetailsType.stock;
  items: StockItem[];
  risk?: BasicDetailsRisk;
}

interface CountryItem {
  code: string;
  name: string;
  sources: InformationSource[];
}

interface AddressItem {
  values: string[];
  sources: InformationSource[];
}

interface TextItem {
  value: string;
  label?: string;
  sources: InformationSource[];
}

interface BasicDetailsText {
  title: string;
  itemType: BasicDetailsType.text;
  items: TextItem[];
  risk?: BasicDetailsRisk;
}

interface BasicDetailsCountry {
  title: string;
  itemType: BasicDetailsType.country;
  items: CountryItem[];
  risk?: BasicDetailsRisk;
}

interface BasicDetailsAddress {
  title: string;
  itemType: BasicDetailsType.address;
  items: AddressItem[];
  risk?: BasicDetailsRisk;
}

export type BasicDetail =
  | BasicDetailsText
  | BasicDetailsCountry
  | BasicDetailsAddress
  | BasicDetailsStock;

const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW = 3;

const DetailsItem: FC<BasicDetail> = ({ items, itemType, title, risk }) => {
  const [showAll, setShowAll] = useState(false);
  const hasRisk = risk && risk.level !== RiskLevel.NoneRiskLevel;
  const hasHighRisk =
    risk &&
    risk.level !== RiskLevel.NoneRiskLevel &&
    risk.level !== RiskLevel.LowRiskLevel &&
    risk.level !== RiskLevel.MediumLowRiskLevel;

  const getNumberOfItemsToShow = () => {
    switch (itemType) {
      case BasicDetailsType.text:
        return DEFAULT_NUMBER_OF_ITEMS_TO_SHOW;
      case BasicDetailsType.stock:
      case BasicDetailsType.address:
      case BasicDetailsType.country:
      default:
        return 1;
    }
  };

  const numberOfItemsToShow = getNumberOfItemsToShow();

  const itemsToShow = showAll ? items : items.slice(0, numberOfItemsToShow);

  const renderInspectorTopSection = (value: string) => {
    return (
      <S.InspectorTopSection>
        {hasRisk && (
          <Chip
            size={ChipSize.Medium}
            variant={riskConfig[risk.level].chipVariant}
            label={riskConfig[risk.level].label}
          />
        )}
        {value}
        {hasRisk && risk.reason && (
          <S.InspectorTopSectionSubtitle>
            {risk.reason}
          </S.InspectorTopSectionSubtitle>
        )}
      </S.InspectorTopSection>
    );
  };

  const renderItems = () => {
    if (items.length === 0)
      return <S.DetailsItemValue>Not identified</S.DetailsItemValue>;

    switch (itemType) {
      case BasicDetailsType.country: {
        return (itemsToShow as CountryItem[]).map(({ code, name, sources }) => (
          <WithInspector
            key={`DetailsItemValue-${name}`}
            sources={sources}
            popoverTitle={title}
            topSectionElement={renderInspectorTopSection(name)}
            display="inline-block"
          >
            <S.DetailsItemFlagValue riskLevel={risk?.level}>
              <Flag size="sm" code={code} showCodeString={false} />
              <S.DetailsItemValueInline>
                {name}
                {hasHighRisk && <S.RiskIcon />}
              </S.DetailsItemValueInline>
            </S.DetailsItemFlagValue>
          </WithInspector>
        ));
      }
      case BasicDetailsType.address: {
        return (itemsToShow as AddressItem[]).map(({ values, sources }) => (
          <S.DetailsItemLargeValue key={`DetailsItemValue-${items.join("")}`}>
            <WithInspector sources={sources} popoverTitle={title}>
              {values.map(value => (
                <div key={`DetailsItemValueAddress-${value}`}>{value}</div>
              ))}
            </WithInspector>
          </S.DetailsItemLargeValue>
        ));
      }
      case BasicDetailsType.stock: {
        return (itemsToShow as StockItem[]).map(
          ({ name, ticker, startDate, endDate, sources }) => (
            <WithInspector
              key={`DetailsItemValue-${name}`}
              sources={sources}
              topSectionElement={name}
              popoverTitle={title}
              display="inline-block"
            >
              <S.DetailsItemLargeValue>
                <div>{name}</div>
                {ticker && (
                  <S.DetailsItemTagValue>
                    <strong>{ticker}</strong>
                  </S.DetailsItemTagValue>
                )}

                {startDate && (
                  <div>
                    {startDate.monthShort} {startDate.year} -{" "}
                    {endDate
                      ? `${endDate.monthShort} ${endDate.year}`
                      : "Present"}
                  </div>
                )}
              </S.DetailsItemLargeValue>
            </WithInspector>
          )
        );
      }
      default: {
        return (itemsToShow as TextItem[]).map(({ value, label, sources }) => (
          <WithInspector
            key={`DetailsItemValue-${value}`}
            sources={sources}
            topSectionElement={renderInspectorTopSection(label || value)}
            popoverTitle={title}
            display="inline-block"
          >
            <S.DetailsItemValue riskLevel={risk?.level}>
              {value}
              {hasHighRisk && <S.RiskIcon />}
            </S.DetailsItemValue>
          </WithInspector>
        ));
      }
    }
  };

  const onToggleItemsDisplay = () => setShowAll(prev => !prev);

  return (
    <S.DetailsItem>
      <S.DetailsItemHeading level={5}>{title}</S.DetailsItemHeading>
      <S.DetailsItemValues>
        <AnimatePresence initial={false}>{renderItems()}</AnimatePresence>
      </S.DetailsItemValues>
      {items.length > numberOfItemsToShow && (
        <S.ToggleItemsButton onClick={onToggleItemsDisplay}>
          {showAll ? "Show fewer" : `Show all ${items.length}`}
        </S.ToggleItemsButton>
      )}
    </S.DetailsItem>
  );
};

interface Props {
  title: string;
  details: BasicDetail[][];
}

const DetailsSection = ({ children }: { children: ReactNode }) => {
  const [detailsContainerHeight, setDetailsContainerHeight] = useState<
    undefined | number
  >(undefined);
  const detailsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (detailsContainerRef?.current && !detailsContainerHeight) {
      setDetailsContainerHeight(detailsContainerRef.current.offsetHeight + 5);
    }
  }, [detailsContainerRef, detailsContainerHeight]);

  return (
    <S.DetailsSection ref={detailsContainerRef} height={detailsContainerHeight}>
      {children}
    </S.DetailsSection>
  );
};

const BasicDetails: FC<Props> = ({ title, details }) => {
  return (
    <S.Container>
      <S.Heading level={5}>{title}</S.Heading>

      <S.DetailsContainer>
        {details.map((detailItems, index) => (
          <DetailsSection key={`DetailsSection-${detailItems.length}-${index}`}>
            {detailItems.map(detail => (
              <DetailsItem key={`DetailsItem-${detail.title}`} {...detail} />
            ))}
          </DetailsSection>
        ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default BasicDetails;
