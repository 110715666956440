import React from "react";

import S from "./styles";

const EntityListEmpty = () => {
  return (
    <S.Container>
      No related parties were identified<S.EntityCount>0</S.EntityCount>
    </S.Container>
  );
};

export default EntityListEmpty;
