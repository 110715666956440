import { styled } from "@linaria/react";
import { grey, red, standardColors } from "styles/colors";
import { family, fontSize, lineHeight } from "styles/fonts";

const RiskLevelTag = styled.div<{ showAsRisk?: boolean }>`
  display: flex;
  flex-shrink: 0;
  padding: 2px 6px;
  border-radius: 6px;
  align-items: center;
  font-size: ${fontSize.xs};
  color: ${standardColors.white};
  background: ${({ showAsRisk }) =>
    showAsRisk ? red.directRiskFill : grey.mid};
  font-family: ${family.interDisplay};
  line-height: ${lineHeight["2xl"]};
`;

const S = { RiskLevelTag };

export default S;
