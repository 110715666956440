import React, { useCallback, useEffect, type FC } from "react";

import { EntityStatus, type Entity } from "api/entities";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import EntityCardAvatar from "components/atoms/EntityCardAvatar";
import EntityInfoList from "components/molecules/EntityInfoList";
import RiskIcons from "components/molecules/RiskIcons";
import EntityCardSummary from "components/molecules/EntityCardSummary";
import EntityCardStatus from "components/molecules/EntityCardStatus";
import useEntityList from "util/hooks/useEntityList";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { NewReportProps } from "util/hooks/useEntityList/types";
import EntitiesApi from "api/entities";

import S from "./styles";
import { statusVariants } from "./utils";

const EntityCardExtended: FC<
  Entity & { onNewReport?: (props: NewReportProps) => void }
> = ({
  id,
  entityType,
  title,
  subtitle,
  riskFactors,
  lists,
  sources,
  status,
  enquiryUrl,
  summary
}) => {
  const enquiryId = useEnquiryId();
  const onViewReport = () => {
    window.open(enquiryUrl);
  };
  const { updateCommissionedEntity } = useEntityList();

  const pollStatus = useCallback(() => {
    setTimeout(() => {
      const api = new EntitiesApi();

      api
        .getCommissionedParty({ enquiryId, relatedPartyId: id })
        .then(({ response }) => {
          if (response && response.status === status) {
            pollStatus();
            return;
          }

          if (response) {
            updateCommissionedEntity(response);
          }
        });
    }, 5000);
  }, [status, enquiryId, id, updateCommissionedEntity]);

  useEffect(() => {
    if (status === EntityStatus.done || status === EntityStatus.failed) {
      return;
    }

    pollStatus();
  }, [status, pollStatus]);

  const variant = status
    ? statusVariants[status]
    : statusVariants[EntityStatus.done];

  const isLoading =
    status === EntityStatus.inProgress || status === EntityStatus.queued;

  return (
    <S.Container
      borderColor={variant.containerBorderColor}
      borderWidth={variant.containerBorderWidth}
    >
      <EntityCardAvatar entityType={entityType} status={status} />

      <S.DetailsContent>
        <S.Title>
          <strong>{title}</strong>
        </S.Title>
        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

        {lists.map(list => (
          <EntityInfoList
            key={`EntityCardList-${id}-${list.type}`}
            {...list}
            sources={sources}
          />
        ))}
      </S.DetailsContent>

      <S.VerticalDivider />

      <S.RiskContent>
        <S.Title
          color={variant.titleColor}
          className={isLoading ? "loading" : ""}
        >
          <strong>Risk summaries</strong>
        </S.Title>

        <RiskIcons id={id} riskFactors={riskFactors ?? {}} />

        <S.Actions>
          <ButtonNew
            type={ButtonType.FilledLight}
            size={ButtonSize.Small}
            text={variant.viewReportButtonText}
            onClick={onViewReport}
            IconLeading={variant.viewReportButtonIcon}
            disabled={variant.viewReportButtonDisabled || !enquiryUrl}
          />
          <EntityCardStatus status={status} />
        </S.Actions>
      </S.RiskContent>

      <S.FullContent>
        <S.Title
          color={variant.titleColor}
          className={isLoading ? "loading" : ""}
        >
          <strong>Executive summary</strong>
        </S.Title>

        <EntityCardSummary summary={summary} status={status} />
      </S.FullContent>
    </S.Container>
  );
};

export default EntityCardExtended;
