import { apm } from "@elastic/apm-rum";
import { Idam_Contracts_Reports_SearchRequest } from "api/idam";
import { GroupsService } from "api/portal";
import { Report } from "api/reports";
import { parseReportItem } from "api/reports/utils";
import { SearchResult } from "api/search";
import { CollectionListView } from "components/molecules/CollectionListControls";
import {
  CollectionInputType,
  CollectionListState,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";

export const GROUP_REPORTS_COLLECTION_ID = "group-reports";

export default class GroupReports {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: GROUP_REPORTS_COLLECTION_ID,
          title: "Reports",
          items: [],
          status: CollectionStatus.stale,
          totalItemCount: 0,
          view: CollectionListView.list,
          offset: 0,
          limit: 16,
          order: "desc",
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          },
          hidden: false,
          pollingEnabled: true
        }
      ]
    };
  }

  async list({
    limit,
    offset,
    userId,
    filterByGroupId
  }: {
    limit: number;
    offset: number;
    userId: string;
    filterByGroupId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    if (!filterByGroupId) {
      return { items: [], totalItemCount: 0 };
    }

    try {
      const { reports, total: totalItemCount } =
        await GroupsService.postOrganisationsGroupsReportsSearch({
          groupId: filterByGroupId,
          requestBody: { start: offset, limit }
        });

      if (!reports) return { items: [], totalItemCount: 0 };

      const items = reports
        .filter(Boolean)
        .map(report => parseReportItem(userId, report));

      return { items, totalItemCount: totalItemCount ?? reports.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search({
    query,
    offset,
    limit,
    searchTags,
    userId,
    filterByGroupId
  }: {
    query: string;
    offset: number;
    limit: number;
    searchTags?: SearchResult[];
    userId: string;
    filterByGroupId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    const filters =
      searchTags && searchTags.length > 0
        ? searchTags.reduce(
            (acc: { [key: string]: boolean | string[] }, tag) => {
              switch (tag.queryType) {
                case "filter": {
                  acc[tag.id] = true;
                  return acc;
                }

                case "users": {
                  if (typeof acc.users === "object") {
                    acc.users = [...acc.users, tag.id];
                    return acc;
                  }
                  acc.users = [tag.id];

                  return acc;
                }

                case "groups": {
                  if (typeof acc.groups === "object") {
                    acc.groups = [...acc.groups, tag.id];
                    return acc;
                  }
                  acc.groups = [tag.id];

                  return acc;
                }

                default: {
                  return acc;
                }
              }
            },
            {}
          )
        : {};

    const requestBody: Idam_Contracts_Reports_SearchRequest = {
      ...filters,
      queryString: query,
      start: offset,
      limit
    };

    if (!filterByGroupId) {
      return { items: [], totalItemCount: 0 };
    }

    try {
      const { reports, total: totalItemCount } =
        await GroupsService.postOrganisationsGroupsReportsSearch({
          groupId: filterByGroupId,
          requestBody
        });

      if (!reports) {
        return { items: [], totalItemCount: 0 };
      }

      const items = reports.map(report => parseReportItem(userId, report));

      return { items, totalItemCount: totalItemCount ?? items.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }
}
