import { type ReactNode } from "react";
import { InformationSource } from "api/report/report-types";

export enum EntityStatus {
  queued = "queued",
  inProgress = "inProgress",
  failed = "failed",
  done = "done"
}

export enum EntityType {
  organisation = "organisation",
  person = "person"
}

export enum InfoListType {
  tags = "tags",
  locations = "locations",
  roles = "roles",
  dates = "dates"
}

export interface TagItem {
  id: string;
  value: string;
}

export interface LocationItem {
  id: string;
  value: string;
}

export interface DateItem {
  id: string;
  value: string;
}

export interface RoleItem {
  id: string;
  title: string;
  company?: string;
  durationInMonths?: number;
  dateFrom?: Date;
  dateTo?: Date;
}

export interface TagList {
  type: InfoListType.tags;
  items: TagItem[];
}

export interface LocationList {
  type: InfoListType.locations;
  items: LocationItem[];
}

export interface DateList {
  type: InfoListType.dates;
  items: DateItem[];
}

export interface RoleList {
  type: InfoListType.roles;
  items: RoleItem[];
}

export type InfoList = TagList | RoleList | LocationList | DateList;

export interface Entity {
  id: string;
  hasReportableData: boolean;
  entityType: EntityType;
  title: string;
  lists: InfoList[];
  sources: InformationSource[];
  subtitle?: string;
  summary?: string;
  enquiryUrl?: string;
  riskFactors?: Record<string, boolean>;
  status?: EntityStatus;
}

export interface Filter {
  label: string;
  count: number;
  filterBy: RegExp;
}

export type FilterList = Filter[];

export type EntityList = Entity[];

export interface EntityListDetails {
  label: string;
  value: string;
  helpText: ReactNode;
  title: string;
  commissionedEntityListTitle: string;
  entityListTitle: string;
}
