import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import SectionFooter from "components/atoms/SectionFooter";
import { ReactComponent as DirectorshipsIconSvg } from "img/icons/related-orgs-icon.svg";
import { ReactComponent as Globe } from "img/icons/globe-icon.svg";
import { ReactComponent as SocialReach } from "img/icons/social-reach.svg";
import { ReactComponent as QuotesIconSvg } from "img/icons/quotes-icon.svg";
import Button from "components/atoms/Button";
import Flag from "components/atoms/Flag";
import SourceList from "components/molecules/SourceList";

import { grey, standardColors, red, green } from "styles/colors";
import { fontSize, family } from "styles/fonts";

/* eslint-disable max-len */
const PersonalDetailsContainer = styled.div`
  display: grid;
  grid-template-areas:
    "details-1 details-1 details-2 details-2 online-presence online-presence reach reach"
    "describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others";
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
`;
/* eslint-enable max-len */

const GridCell = styled.div`
  padding: 16px;
  background-color: ${standardColors.white};
  border: 1px solid ${grey.panel};
`;

const PersonImage = styled(GridCell)`
  position: relative;
  grid-area: person-image;
  border-top: none;
  padding: 8px;
  border-right: none;
  border-left: ${({ isImagePresent }) =>
    isImagePresent ? "none" : `2px solid ${standardColors.white}`};
  border-bottom: 1px solid ${grey.disabled};
  width: 142px;
`;

const Details1 = styled(GridCell)`
  grid-area: details-1;
`;

const Details2 = styled(GridCell)`
  grid-area: details-2;
`;

const OnlinePresence = styled(GridCell)`
  grid-area: online-presence;
  min-height: 280px;
`;

const Reach = styled(GridCell)`
  grid-area: reach;
`;

const PersonDescription = styled(GridCell)`
  grid-area: describe-by-others;
`;

const ReachTitle = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding-bottom: 4px;
`;

const ScaleItem = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 9px;
`;

const ScaleIconContainer = styled.div`
  position: relative;
`;

const ScaleHeader = styled.div`
  font-size: ${fontSize.sm};
  padding: 0 4px;
  padding-left: 6px;
  color: ${({ color }) => color};
  font-family: ${family.interSemiBold};
`;

const ScaleDetail = styled.div`
  font-size: ${fontSize.sm};
  color: ${({ color }) => color ?? "initial"};

  span {
    font-family: ${family.interSemiBold};
    font-weight: 600;
    padding: 0 4px;
  }
`;

const ScaleSubtext = styled.div`
  font-size: ${fontSize.sm};
  color: ${({ color }) => color ?? grey.mid};
`;

const iconStyles = {
  width: "30px",
  height: "30px"
};

const DirectorshipsIcon = styled(DirectorshipsIconSvg)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const CountriesIcon = styled(Globe)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const SocialReachIcon = styled(SocialReach)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const DescriptionTitleContainer = styled.div`
  display: inline-flex;
  align-items: flex-end;
  font-size: ${fontSize.sm};
`;

const DescriptionTitle = styled.div`
  color: ${grey.mid};
`;

const DescriptionContainer = styled.div`
  font-size: ${fontSize.sm};
  margin-bottom: 0;
  overflow-y: auto;
`;

const QuotesIcon = styled(QuotesIconSvg)`
  float: left;
  margin: 5px 10px 0 0;

  path {
    fill: ${({ color }) => color};
  }
`;

const ScaleSubtextWithInfoIcon = styled(ScaleSubtext)`
  display: flex;
  align-items: center;
`;

const PersonalDetailsContent = styled.div`
  background-color: ${grey.panel};
  padding: 16px 16px 16px 16px;
  height: 100%;

  dt {
    margin-bottom: 0;
  }
`;

const CustomSectionFooter = styled(SectionFooter)`
  border-top: 1px solid ${standardColors.white};
`;

const BioText = styled.span`
  margin-right: 5px;
`;

const SocialReachTooltipList = styled.ul`
  padding-top: 8px;
  padding-left: 25px;
  margin: 0;
`;

const NoSocialPresenceLabel = styled.p`
  margin: 0;
  padding-bottom: 8px;
`;

const SmallSocialReachIconInfographic = styled.div`
  display: flex;
  align-items: center;

  ${SocialReachIcon} {
    height: 35px;
    width: 35px;
    padding-bottom: 0;
  }

  ${ScaleHeader} {
    padding: 0 5px;
  }
`;

const RiskCountIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  min-width: 21px;
  border-radius: 50%;
  font-size: ${fontSize["2xs"]};
  background-color: ${red.directRisk};
  line-height: 17px;
  color: ${standardColors.white};
  border: 2px solid ${grey.panel};
  padding: ${({ isMaxTextLength }) => (isMaxTextLength ? "2px" : "0")};
  padding-left: ${({ isMaxTextLength }) => (isMaxTextLength ? "3px" : "1px")};
  position: absolute;
  bottom: -6px;
  right: -3px;

  &:hover {
    cursor: pointer;
  }
`;

const TooltipContent = styled.div`
  text-align: left;
  padding: 8px;
  max-height: 325px;
  overflow-y: auto;
  width: 240px;
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding-top: 5px;
`;

const RiskyCountries = styled.div`
  margin-bottom: 8px;
  line-height: 1.3;
  font-size: ${fontSize.sm};
`;

const RiskAssigners = styled.div`
  display: flex;
  padding-top: 8px;
  gap: 8px;
`;

const OfficershipCard = styled.div`
  background-color: ${grey.panel};
  border-radius: 6px;
  padding: 16px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const Name = styled.div`
  family: ${family.interSemiBold};
  height: 20px;
  line-height: 20px;
  width: 100%;
`;

const CompanyLocation = styled.div`
  color: ${grey.mid};
`;

const RoleDate = styled.span`
  color: ${({ isOngoing }) => (isOngoing ? green.secondary : grey.dark)};
`;

const RoleDuration = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const Officerships = styled.div`
  margin-top: 6px;
  font-size: ${fontSize.sm};
`;

const CompanyDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PersonDetails = styled.div`
  font-size: ${fontSize.sm};
  border-top: 1px solid ${grey.rule};
  padding-top: 10px;
  margin-top: 12px;
`;

const CompanyDetailsLeftContent = styled.div`
  display: flex;
  max-width: 55%;
`;

const DetailsContainer = styled.div`
  margin-top: -4px;
  width: 100%;
`;

const Role = styled.div`
  color: ${grey.mid};
`;

const OfficershipRole = styled.div`
  & + & {
    margin-top: 4px;
  }
`;

const SectionLinkButton = styled(Button)`
  border-bottom: 1px solid ${grey.mid} !important;
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm} !important;
  line-height: 13px;
`;

const SectionTitle = styled.h1`
  font-family: ${family.interSemiBold};
  font-size: ${fontSize.sm};
  font-weight: inherit;
  color: ${grey.mid};
  margin-bottom: 4px;
`;

const CountryFlag = styled(Flag)`
  width: 30px;
  img {
    width: 24px !important;
    height: 18px !important;
    margin: 0;
    margin-right: 8px !important;
    border-radius: 2px !important;
  }
`;

const NationalityContainer = styled.div`
  display: flex;
`;

const RelatedCountryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const List = styled.ul`
  margin-top: 11px !important;
  padding-top: 14px !important;
  padding-left: 0 !important;
  border-top: 1px solid ${grey.rule};
  list-style: none;
`;

const ListItem = styled.li`
  font-size: ${fontSize.sm};
  & + & {
    margin-top: 12px;
  }
`;

const NoneIdentified = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  font-style: italic;
`;

const SectionLinkText = styled.span`
  white-space: nowrap;
`;

const CustomSourceList = styled(SourceList)`
  overflow: inherit;
`;

const IndirectExplainer = styled.div`
  padding-top: 8px;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
`;

/** classNameOverrides */

const termClassName = css`
  margin: 0;
  min-height: 22px;
  line-height: 20px;
`;

const detailClassName = css`
  min-height: 22px;
  line-height: 20px;
`;

const locationRiskTag = css`
  border-radius: 11px;
  height: 23px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  opacity: 1 !important;
  background-color: ${red.directRisk} !important;

  & > svg {
    margin-top: 0px;
    margin-right: -3px;
  }
`;

export const classNameOverrides = {
  termClassName,
  detailClassName,
  locationRiskTag
};

const S = {
  PersonalDetailsContainer,
  PersonImage,
  Details1,
  Details2,
  OnlinePresence,
  Reach,
  PersonDescription,
  PersonalDetailsContent,
  CustomSectionFooter,
  BioText,
  ReachTitle,
  ScaleItem,
  ScaleIconContainer,
  ScaleHeader,
  ScaleSubtext,
  ScaleDetail,
  DirectorshipsIcon,
  CountriesIcon,
  SocialReachIcon,
  DescriptionContainer,
  QuotesIcon,
  DescriptionTitleContainer,
  DescriptionTitle,
  ScaleSubtextWithInfoIcon,
  SocialReachTooltipList,
  NoSocialPresenceLabel,
  SmallSocialReachIconInfographic,
  RiskCountIcon,
  TooltipBody,
  TooltipContent,
  TooltipHeader,
  RiskyCountries,
  RiskAssigners,
  OfficershipCard,
  Name,
  CompanyLocation,
  RoleDate,
  RoleDuration,
  Officerships,
  CompanyDetails,
  PersonDetails,
  CompanyDetailsLeftContent,
  DetailsContainer,
  Role,
  OfficershipRole,
  SectionLinkButton,
  SectionTitle,
  CountryFlag,
  NationalityContainer,
  RelatedCountryHeader,
  List,
  ListItem,
  NoneIdentified,
  SectionLinkText,
  CustomSourceList,
  IndirectExplainer,
  TopBar
};

export default S;
