// TODO: This file is littered with for loops, but also this file is far too complex to realistically refactor and fix this.
/* eslint-disable no-restricted-syntax */
import React, { useMemo, memo, forwardRef } from "react";

import { Section } from "pages/report/Section";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { formatUncertainDate, openPersonaReport } from "util/personaUtils";
import { PersonaCard } from "components/molecules/PersonaCard";
import DropdownButton from "components/molecules/DropdownButton";
import S from "../../Report/PersonReport/styles";
import { SortFilterBar } from "../WebAndMedia/SortFilterBar";

const FilterIds = Object.freeze({
  RiskOnly: "risk_only",
  RiskCategory: "risk_category",
  Organisation: "organisation",
  ConfidenceCategory: "confidence_category",
  Person: "person",
  Location: "location",
  Date: "date",
  Language: "language",
  SourceType: "source_type",
  DirectSubjectMention: "direct_subject_mention",
  IndirectSources: "indirect_sources"
});

const EntityTypes = Object.freeze({
  Organisation: "organisation",
  Location: "location",
  Person: "person"
});

export const ForReviewSection = memo(
  forwardRef((props, ref) => {
    const {
      title,
      isDisregarded,
      expandable,
      description,
      noResultsText = "No results found",
      sortedPersonas,
      xiSummaries,
      enquiryId,
      personaFilter,
      setPersonaFilter,
      personaCounts,
      personaSections
    } = props;

    // checklist for new filters:
    // 1. Add new state here, use printable state
    // 2. Add filter to list of filters, add new filter id, add relevant state to list of dependencies for filter list useMemo
    // (2b.) Add heirachy calculations if categorical, see similar examples
    // 3. Adjust canReset and reset too account for and set state respectively
    // 4. Pass state (/heirachy) and state setting function down to filter bar
    // 5. Implement control in a component rendered by the filter bar

    // remember to use the title in the printable state keys, otherwise the filters will affect other sections

    const [organisationsToUseArray, setOrganisationsToUseArray] =
      usePrintableReportState(`organisations-to-use-${title}`, new Set());
    const organisationsToUse = useMemo(
      () => new Set(organisationsToUseArray),
      [organisationsToUseArray]
    );
    const setOrganisationsToUse = useMemo(
      () => os => setOrganisationsToUseArray([...os]),
      [setOrganisationsToUseArray]
    );

    const [locationsToUseArray, setLocationsToUseArray] =
      usePrintableReportState(`locations-to-use-${title}`, new Set());
    const locationsToUse = useMemo(
      () => new Set(locationsToUseArray),
      [locationsToUseArray]
    );
    const setLocationsToUse = useMemo(
      () => os => setLocationsToUseArray([...os]),
      [setLocationsToUseArray]
    );

    const [peopleToUseArray, setPeopleToUseArray] = usePrintableReportState(
      `people-to-use-${title}`,
      new Set()
    );
    const peopleToUse = useMemo(
      () => new Set(peopleToUseArray),
      [peopleToUseArray]
    );
    const setPeopleToUse = useMemo(
      () => os => setPeopleToUseArray([...os]),
      [setPeopleToUseArray]
    );

    const [languagesToUseArray, setLanguagesToUseArray] =
      usePrintableReportState(`languages-to-use-${title}`, new Set());
    const languagesToUse = useMemo(
      () => new Set(languagesToUseArray),
      [languagesToUseArray]
    );
    const setLanguagesToUse = useMemo(
      () => os => setLanguagesToUseArray([...os]),
      [setLanguagesToUseArray]
    );

    const [dateBounds, setDateBounds] = usePrintableReportState(
      `date-bounds-${title}`,
      {}
    );

    // filters are set up like this so that later on things can calculate numbers of sources and such using them.
    // It's quite tricky logic otherwise!

    const filters = useMemo(() => {
      const organisationFilter = persona =>
        !organisationsToUse ||
        organisationsToUse.size === 0 ||
        (persona.entityTags &&
          persona.entityTags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Organisation &&
              tag.entity.id !== undefined &&
              organisationsToUse.has(tag.entity.id)
          ));

      const locationFilter = persona =>
        !locationsToUse ||
        locationsToUse.size === 0 ||
        (persona.entityTags &&
          persona.entityTags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Location &&
              tag.entity.id !== undefined &&
              locationsToUse.has(tag.entity.id)
          ));
      const personFilter = persona =>
        !peopleToUse ||
        peopleToUse.size === 0 ||
        (persona.entityTags &&
          persona.entityTags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Person &&
              tag.entity.id !== undefined &&
              peopleToUse.has(tag.entity.id)
          ));
      const languagesFilter = source =>
        !languagesToUse ||
        languagesToUse.size === 0 ||
        (source.detectedLanguage
          ? languagesToUse.has(source.detectedLanguage.toLowerCase())
          : languagesToUse.has("english"));

      return [
        {
          id: FilterIds.Organisation,
          filter: organisationFilter
        },
        {
          id: FilterIds.Person,
          filter: personFilter
        },
        {
          id: FilterIds.Location,
          filter: locationFilter
        },
        {
          id: FilterIds.Language,
          filter: languagesFilter
        }
      ];
    }, [organisationsToUse, locationsToUse, peopleToUse, languagesToUse]);

    // sourceToPotentialCategories is optional (so if the behaviour of the filter depends on other settings it can be custom)
    const buildCategorySourceCountMap = useMemo(
      () =>
        (
          sourceToCategories,
          filterId,
          { sourceToPotentialCategories, categoryToDisplay, categoryToKey }
        ) => {
          const countMap = new Map();
          const otherFilters = filters.filter(filter => filter.id !== filterId); // filter the filters to exclude this one
          const sourcesAffected = sortedPersonas.filter(s =>
            otherFilters.every(filter => filter.filter(s))
          );
          for (const persona of sourcesAffected) {
            // filter (but not with this filter)
            const categoriesInSourceCurrent = [
              ...new Set(
                sourceToCategories(persona).filter(
                  c => c !== undefined && c !== null
                )
              )
            ];
            for (const category of categoriesInSourceCurrent) {
              const key = categoryToKey ? categoryToKey(category) : category;
              if (key === undefined || key === null) {
                console.error("Null sort-filter category key", {
                  category,
                  persona
                });
              } else {
                const display = categoryToDisplay
                  ? categoryToDisplay(category)
                  : category;
                const value = countMap.has(key) && countMap.get(key);
                const currentAvailable =
                  ((value && value.currentAvailable) || 0) + 1;
                countMap.set(key, {
                  ...(value || { key, display }),
                  currentAvailable
                });
              }
            }
          }

          for (const source of sortedPersonas) {
            // filter (but not with this filter)
            const categoriesInSourcePotential = [
              ...new Set(
                (sourceToPotentialCategories || sourceToCategories)(
                  source
                ).filter(c => c !== undefined && c !== null)
              )
            ];
            for (const category of categoriesInSourcePotential) {
              const key = categoryToKey ? categoryToKey(category) : category;
              if (key === undefined || key === null) {
                console.error("Null sort-filter category key", {
                  category,
                  source
                });
              } else {
                const display = categoryToDisplay
                  ? categoryToDisplay(category)
                  : category;
                const value = countMap.has(key) && countMap.get(key);
                const potentialAvailable =
                  ((value && value.potentialAvailable) || 0) + 1;
                countMap.set(key, {
                  ...(value || { key, display }),
                  potentialAvailable
                });
              }
            }
          }
          return countMap;
        },
      [filters, sortedPersonas]
    );

    const startBuildFilterData = performance.now();

    const entityTagsOfType = useMemo(
      () => type => persona =>
        (persona.entityTags || [])
          .filter(t => t.entity && t.entity.type === type)
          .map(t => t.entity),
      []
    );
    const organisationsInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Organisation),
      [entityTagsOfType]
    );

    const locationsInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Location),
      [entityTagsOfType]
    );
    const peopleInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Person),
      [entityTagsOfType]
    );

    const entityToDisplay = e => e && e.text;
    const entityToKey = e => e && e.id;
    const organisations = useMemo(
      () =>
        buildCategorySourceCountMap(
          organisationsInSource,
          FilterIds.Organisation,
          { categoryToDisplay: entityToDisplay, categoryToKey: entityToKey }
        ),
      [buildCategorySourceCountMap, organisationsInSource]
    );

    const locations = useMemo(
      () =>
        buildCategorySourceCountMap(locationsInSource, FilterIds.Location, {
          categoryToDisplay: entityToDisplay,
          categoryToKey: entityToKey
        }),
      [buildCategorySourceCountMap, locationsInSource]
    );
    const people = useMemo(
      () =>
        buildCategorySourceCountMap(peopleInSource, FilterIds.Person, {
          categoryToDisplay: entityToDisplay,
          categoryToKey: entityToKey
        }),
      [buildCategorySourceCountMap, peopleInSource]
    );
    const languages = useMemo(
      () =>
        buildCategorySourceCountMap(
          s => (s.detectedLanguage ? [s.detectedLanguage] : ["English"]),
          FilterIds.Language,
          { categoryToKey: l => l.toLowerCase() }
        ),
      [buildCategorySourceCountMap]
    );

    const buildFilterDataDuractionMs = performance.now() - startBuildFilterData;
    if (buildFilterDataDuractionMs > 100) {
      console.warn(
        "Building filter data duration",
        `${buildFilterDataDuractionMs}ms`,
        title
      );
    }

    const canReset =
      organisationsToUse.size > 0 ||
      peopleToUse.size > 0 ||
      locationsToUse.size > 0 ||
      (dateBounds && (dateBounds.startDate || dateBounds.endDate)) ||
      languagesToUse.size > 0;

    const resetAll = useMemo(
      () => () => {
        setOrganisationsToUse(new Set());
        setLocationsToUse(new Set());
        setPeopleToUse(new Set());
        setLanguagesToUse(new Set());
      },
      [
        setOrganisationsToUse,
        setLocationsToUse,
        setPeopleToUse,
        setLanguagesToUse
      ]
    );

    const filteringAndSortingStart = performance.now();

    const personasToUse = useMemo(() => {
      return sortedPersonas
        .map(persona => ({
          ...persona,
          show: filters.every(filter => filter.filter(persona))
        }))
        .filter(persona => persona.show);
    }, [sortedPersonas, filters]);

    const sortingAndFilteringDuration =
      performance.now() - filteringAndSortingStart;
    if (sortingAndFilteringDuration > 100) {
      console.warn(
        "Sorting and filtering duration",
        `${sortingAndFilteringDuration}ms`,
        title
      );
    }

    const sectionCount = useMemo(
      () => personasToUse.filter(p => p.show).length,
      [personasToUse]
    );

    const potentialSourceCount = sortedPersonas.length;
    const potentialSectionCount = sortedPersonas.length;

    const barProps = {
      organisations,
      organisationsToUse,
      setOrganisationsToUse,
      locations,
      locationsToUse,
      setLocationsToUse,
      people,
      peopleToUse,
      setPeopleToUse,
      canReset,
      resetAll,
      dateBounds,
      setDateBounds,
      languages,
      languagesToUse,
      setLanguagesToUse,
      potentialSourceCount,
      isDisregarded,
      riskCategories: new Map()
    };

    const renderData = () => {
      return (
        <S.CardsContainer>
          {personasToUse.map(persona => {
            const imageKey = persona.facePhotoIds
              ? `${persona.facePhotoIds.imageId}/faces/${persona.facePhotoIds.faceId}`
              : "default-image-path";
            const formattedDOB = formatUncertainDate(persona.doB);

            return (
              <PersonaCard
                key={persona.id}
                personaId={persona.id}
                imageKey={imageKey}
                xiSummary={xiSummaries[persona.id]}
                name={persona.title}
                dob={formattedDOB ? `Born ${formattedDOB}` : `Unknown DoB`}
                handleClick={
                  persona.hasPersonaReport
                    ? () => openPersonaReport(enquiryId, persona.id)
                    : undefined
                }
                nationalities={persona.nationalities}
                numberOfDres={persona.numberOfDres}
                topEntityTags={persona.topEntityTags || []}
              />
            );
          })}
        </S.CardsContainer>
      );
    };

    const renderNoResultsSection = () => {
      if (potentialSectionCount <= 0) {
        return noResultsText;
      }
      if (sectionCount <= 0) {
        return "No results - try removing some filters";
      }

      return null;
    };

    return (
      <Section
        {...{ sectionCount, potentialSectionCount, title }}
        {...props}
        expandable={expandable}
        ref={ref}
        className="source-section"
        shouldUnmountOnCollapse
      >
        {description && (
          <p className="report-section-description sourcing-section-description">
            {description}
          </p>
        )}
        <S.SimpleContainer justifyContent="flex-end">
          <DropdownButton label={personaFilter}>
            {personaSections.map(section => {
              return (
                <S.OptionsDropdownMenuItem
                  key={section}
                  onClick={() => setPersonaFilter(section)}
                >
                  {`${section} ${personaCounts[section]}`}
                </S.OptionsDropdownMenuItem>
              );
            })}
          </DropdownButton>
        </S.SimpleContainer>
        <SortFilterBar {...props} {...barProps} />
        {renderData()}
        {potentialSectionCount <= 0 || sectionCount <= 0 ? (
          <div className="sourcing-section-no-results">
            {renderNoResultsSection()}
          </div>
        ) : null}
      </Section>
    );
  })
);
