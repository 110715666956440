import { styled } from "@linaria/react";

import { ReactComponent as DownChevronSvg } from "img/down-chevron.svg";

import { fontSize, family, headingLetterSpacing } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const SectionHeaderOuterContainer = styled.div`
  position: sticky;
  top: 54px;
  z-index: 7;
  background: ${grey.panel};

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.lg};
  letter-spacing: ${headingLetterSpacing.xl};
  font-family: ${family.interDisplay};
  border-radius: ${({ className }) =>
    (className && className.match("isExpanded")) ||
    (className && !className.match("isContractable"))
      ? "0px"
      : "10px"};
  background-color: ${grey.faint};
  color: ${standardColors.black};
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 0px;
  height: 44px !important;
  cursor: ${({ className }) =>
    className && className.match("isContractable") ? "pointer" : "default"};
  transition: 0.2s border-radius;

  @media (max-width: 768px) {
    padding-left: 43px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

const SectionHeaderRightSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SectionCount = styled.div`
  font-size: ${fontSize.xl};
  color: ${grey.dark};
  display: inline-block;
  margin-top: 2px;
`;

const SectionCollapseIcon = styled(DownChevronSvg)`
  height: 10px;
  width: 16px;
  position: relative;
  top: 2px;
  margin-left: 20px;
  transform: ${({ className }) =>
    className === "isExpanded" ? "rotate(0deg)" : "rotate(-90deg)"};
  margin-top: ${({ className }) =>
    className === "isExpanded" ? "-4px" : "-1px"};

  path {
    fill: ${grey.dark};
  }
`;

const S = {
  SectionHeaderContainer,
  SectionHeaderRightSideContainer,
  SectionCount,
  SectionCollapseIcon,
  SectionHeaderOuterContainer
};

export default S;
