import React, { MouseEventHandler, ReactNode } from "react";

import { isPDX } from "static-config";
import { RiskFactorName } from "api/risk";
import useRiskService from "util/hooks/useRiskService";
import { RiskLevelTag } from "components/atoms/RiskLevelTag";
import { RiskMuteCover } from "components/molecules/RiskMuteCover";
import { RiskReviewLabel } from "components/atoms/RiskReviewLabel";
import { RiskServiceStatus } from "util/hooks/useRiskService/types";
import { RISK_FACTOR_CONFIG, RISK_REVIEW_CONFIG } from "util/riskService";
import {
  MuteRiskButton,
  MuteRiskButtonType
} from "components/molecules/MuteRiskButton";

import S from "./styles";

interface RiskIconCardProps {
  factor: RiskFactorName;
  isMuted?: boolean;
  isAiGenerated?: boolean;
  unconfirmedSourceCount?: number;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  children: ReactNode;
}

const getFooterSectionName = (factor: RiskFactorName) => {
  switch (factor) {
    case RiskFactorName.SanctionsRiskFactorName:
    case RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName:
    case RiskFactorName.WatchlistsRiskFactorName:
    case RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName:
    case RiskFactorName.PEPsRiskFactorName:
    case RiskFactorName.IntegrityCheckPEPsRCARiskFactorName:
      return "Screening section";
    case RiskFactorName.StateOwnershipRiskFactorName:
      return "Group or Parent section";
    case RiskFactorName.RelatedPartiesRiskFactorName:
      return "Related parties section";
    default:
      return "Insights section";
  }
};

export const RiskIconCard = ({
  factor,
  isMuted,
  isAiGenerated = false,
  unconfirmedSourceCount = 0,
  onMouseEnter,
  onMouseLeave,
  children
}: RiskIconCardProps) => {
  const { status, onMute, getRiskDataByFactorName } = useRiskService();

  const riskData = getRiskDataByFactorName(factor);
  const isLoading = status === RiskServiceStatus.MutingRiskIcon;
  const hasForReview = unconfirmedSourceCount > 0;

  const {
    svg: Icon,
    title,
    description,
    getConfirmedTitle
  } = RISK_FACTOR_CONFIG[factor];

  if (!riskData) return null;

  const { level, summary } = riskData;
  const cardTitle = getConfirmedTitle?.(summary.length) ?? title;

  const { getSubjectText } =
    factor === RiskFactorName.WatchlistsRiskFactorName ||
    factor === RiskFactorName.SanctionsRiskFactorName ||
    factor === RiskFactorName.PEPsRiskFactorName
      ? RISK_REVIEW_CONFIG[factor]
      : { getSubjectText: () => "" };

  return (
    <S.Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {(isMuted || isLoading) && <RiskMuteCover factor={factor} />}
      <S.Header>
        <S.IconContainer>
          <Icon />
        </S.IconContainer>
        <S.Title>{cardTitle}</S.Title>
        {hasForReview && (
          <S.ReviewLabel>
            <RiskReviewLabel
              count={unconfirmedSourceCount}
              isFlagged
              isForReview
            />
          </S.ReviewLabel>
        )}
      </S.Header>
      <S.Description>
        {!isPDX && <RiskLevelTag riskLevel={level} />}
        <span>{description}</span>
      </S.Description>
      <S.CardBody>
        <S.Content>{children}</S.Content>
        {isAiGenerated && (
          <S.DisclaimerContainer>
            <S.DisclaimerStars />
            <S.DisclaimerText>AI-Generated Summary</S.DisclaimerText>
          </S.DisclaimerContainer>
        )}
        <MuteRiskButton
          type={MuteRiskButtonType.minus}
          onClick={() => onMute(factor, true)}
        />
        {hasForReview && (
          <S.Disclaimer>
            Additionally, we have also identified{" "}
            <span>{getSubjectText(unconfirmedSourceCount)}</span>, where there
            was insufficient information to confirm or discard them as your
            subject.
          </S.Disclaimer>
        )}
      </S.CardBody>
      <S.Footer>
        For more information, review the {getFooterSectionName(factor)}.
      </S.Footer>
    </S.Card>
  );
};
