import { styled } from "@linaria/react";
import {
  family,
  fontSize,
  headingLetterSpacing,
  lineHeight,
  size
} from "styles/fonts";
import { grey, red, standardColors } from "styles/colors";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${standardColors.white};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 506px;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  gap: 16px;
`;

const Title = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplayMedium};
  font-weight: bold;
  line-height: ${lineHeight.md};
`;

const Description = styled.div`
  color: ${grey.darkish};
  background: ${grey.panelLight};
  padding: 12px 20px;
  font-size: ${fontSize["2xs"]};
  line-height: ${lineHeight["2xl"]};
  font-family: ${family.interDisplayMedium};
  letter-spacing: ${headingLetterSpacing.xl};
  font-weight: bold;
`;

const Footer = styled.div`
  color: ${grey.dark};
  padding: 12px 20px;
  font-size: ${size.sm};
  font-family: ${family.interLight};
  background: ${grey.panelLight};
`;

const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: ${grey.panelLight};
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${grey.dark};
    }
  }
`;

const Text = styled.div<{ boldTextIsRisk?: boolean }>`
  color: ${grey.dark};
  font-size: ${size.sm};
  font-family: ${family.interLight};
  line-height: ${lineHeight.lg};

  span {
    color: ${({ boldTextIsRisk }) =>
      boldTextIsRisk ? red.directRiskOutline : grey.dark};
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 26px 20px 20px;
`;

const S = {
  Card,
  Text,
  Title,
  Header,
  Footer,
  Description,
  TextContainer,
  IconContainer
};

export default S;
