import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/tenant";
import { Idam_Contracts_Tenants_CreateOrganisationRequest_SamlConfigData } from "api/idam";

const useCreateOrg = () => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const TenantApi = new Api();

  const createOrganisation = async (
    orgName: string,
    admins: string[],
    users: string[],
    orgDescription?: string,
    metadataUrl?: string | null,
    metadata?: string | null
  ) => {
    dispatch({ type: RequestActions.SendRequest });

    let samlConfig:
      | Idam_Contracts_Tenants_CreateOrganisationRequest_SamlConfigData
      | undefined;
    if (metadataUrl) {
      samlConfig = { metadataUrl };
    } else if (metadata) {
      samlConfig = { metadata };
    }

    const { status, message } = await TenantApi.createOrganisation(
      orgName,
      orgDescription,
      users,
      admins,
      samlConfig
    );

    if (!status) {
      dispatch({
        type: RequestActions.SetError,
        errorMessage: message
      });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const reset = () => {
    dispatch({ type: RequestActions.Reset });
  };

  return { fetching, error, errorMessage, createOrganisation, reset };
};

export default useCreateOrg;
