import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { fontSize, lineHeight } from "styles/fonts";

const Container = styled.div`
  display: flex;
  color: ${grey.dark};
  padding: 16px;
  gap: 16px;
`;

const Heading = styled.div`
  font-size: ${fontSize["2xl"]};
  line-height: ${lineHeight.xs};
`;
const S = {
  Container,
  Heading
};

export default S;
