import { styled } from "@linaria/react";

import { standardColors, grey, purple, blue } from "styles/colors";
import { family, fontSize } from "styles/fonts";
import { ReactComponent as ExitSvgIcon } from "img/icons/exit-icon.svg";
import PopoverComponent from "components/atoms/Popover";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

const InspectorContainer = styled.div<{ display: string }>`
  display: ${props => props.display};
`;

const InspectorInnerContainer = styled.div<{
  display: string;
  highlightBorderRadius: string;
  highlightPadding: string;
  showXiReactToolTip: boolean;
  hideHighlight?: boolean;
}>`
  color: ${grey.dark};
  width: 100%;
  display: ${props => props.display};
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: ${props => props.highlightBorderRadius};
  line-height: 20px;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ showXiReactToolTip, hideHighlight }) => {
      if (hideHighlight) {
        return "transparent";
      }
      const [color, opacity] = showXiReactToolTip
        ? [isPDX ? theme.primaryColor : purple.dark, 0.1]
        : [blue.boxShadow, 0.2];
      const { r, g, b } = hexToRgb(color);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }};
    box-shadow: ${({ showXiReactToolTip, highlightPadding, hideHighlight }) => {
      if (hideHighlight) {
        return "transparent";
      }
      const [color, opacity] = showXiReactToolTip
        ? [isPDX ? theme.primaryColor : purple.dark, 0.1]
        : [blue.boxShadow, 0.2];
      const { r, g, b } = hexToRgb(color);
      return `0 0 1px ${highlightPadding} rgba(${r}, ${g}, ${b}, ${opacity})`;
    }};
    cursor: pointer;
  }
  &:hover:has(> [class*="SourcingPrompt"]) {
    > [class*="SourcingPrompt"] {
      opacity: 1;
    }
  }
`;

const Popover = styled(PopoverComponent)<{ display: string }>`
  display: ${props => props.display} !important;

  /* Fixes issue whereby invoking the popover removes inline styling on the tooltip wrapper */
  & + div {
    display: inline;
  }
`;

const TooltipBody = styled.div<{ isShowingHeader: boolean }>`
  min-height: 100px;
  width: 460px;
  background-color: ${grey.panel};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: ${({ isShowingHeader }) =>
    isShowingHeader ? "0" : "10px"};
  border-top-right-radius: ${({ isShowingHeader }) =>
    isShowingHeader ? "0" : "10px"};
`;

const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
  width: 460px;
  background: ${grey.light};
`;

const TooltipHeaderTitle = styled.div`
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: ${family.interSemiBold};
  color: ${standardColors.black};
`;

const ExitButton = styled.button`
  outline: none !important;
  background: none;
  border: none;
  margin-right: 12px;
  padding: 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px rgb(255 255 255 / 33%);
  }
`;

const ExitIcon = styled(ExitSvgIcon)`
  path {
    fill: ${standardColors.black};
  }

  &:hover {
    path {
      fill: ${grey.dark};
    }
  }
`;

const Rule = styled.div`
  border-bottom: 1px solid ${grey.rule};
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
`;

const SourceDescription = styled.div`
  text-align: left;
  padding-top: 10px;
`;

const Subtext = styled.div`
  width: 460px;
  background-color: ${grey.light};
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  padding: 12px 16px 12px 16px;
  min-height: 66px;

  hr {
    margin-top: 0;
  }
`;

const S = {
  InspectorContainer,
  InspectorInnerContainer,
  TooltipBody,
  TooltipHeader,
  TooltipHeaderTitle,
  ExitButton,
  ExitIcon,
  Rule,
  Popover,
  RightMenu,
  SourceDescription,
  Subtext
};

export default S;
