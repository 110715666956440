// TODO: This file is littered with for loops, but also this file is far too complex to realistically refactor and fix this.
/* eslint-disable no-restricted-syntax */
import React, { useMemo, memo, useContext, forwardRef } from "react";
import ErrorBoundary from "util/ErrorBoundary";

import { Section } from "pages/report/Section";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { Features, useFeatureEnabled } from "services/features";
import MediaSourceCard from "components/molecules/MediaSourceCard";

import { PersonaIdContext } from "util/hooks/usePersonaId";
import { SourceGroup } from "./SourceGroup";
import { SortFilterBar } from "./SortFilterBar";
import { SourceStack } from "./SourceStack";
import { SortKeys } from "./SortKeys";

const onSourceError = e => {
  console.error("Error showing source", { e });
};

const SourceErrorComponent = () => {
  return <div>There was an error displaying this source.</div>;
};

const sum = xs => xs.reduce((a, b) => a + b, 0);

const FilterIds = Object.freeze({
  RiskOnly: "risk_only",
  RiskCategory: "risk_catgory",
  Organisation: "organisation",
  ConfidenceCategory: "confidence_category",
  Person: "person",
  Location: "location",
  Date: "date",
  Language: "language",
  SourceType: "source_type",
  DirectSubjectMention: "direct_subject_mention",
  IndirectSources: "indirect_sources"
});

const EntityTypes = Object.freeze({
  Organisation: "organisation",
  Location: "location",
  Person: "person"
});

export const SourceSection = memo(
  forwardRef((props, ref) => {
    const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
    const {
      sourceGroups,
      title,
      isOnlyRisk,
      isDisregarded,
      expandable,
      description,
      noResultsText = "No results found"
    } = props;

    // checklist for new filters:
    // 1. Add new state here, use printable state
    // 2. Add filter to list of filters, add new filter id, add relevant state to list of dependencies for filter list useMemo
    // (2b.) Add heirachy calculations if categorical, see similar examples
    // 3. Adjust canReset and reset too account for and set state respectively
    // 4. Pass state (/heirachy) and state setting function down to filter bar
    // 5. Implement control in a component rendered by the filter bar

    // remember to use the title in the printable state keys, otherwise the filters will affect other sections

    const [riskCategoriesToUse, setRiskCategoriesToUseArray] =
      usePrintableReportState(`risk-categories-to-use-${title}`, []);
    const setRiskCategoriesToUse = useMemo(
      () => s => setRiskCategoriesToUseArray([...s]),
      [setRiskCategoriesToUseArray]
    );
    const riskCategoriesToUseSet = useMemo(
      () => new Set(riskCategoriesToUse || []),
      [riskCategoriesToUse]
    );

    const [showOnlyRisk, setShowOnlyRisk] = usePrintableReportState(
      `show-only-risk-${title}`,
      false
    );
    const showBroaderRiskDefault = !isOnlyRisk;
    const [showBroaderRisk, setShowBroaderRisk] = usePrintableReportState(
      `show-broader-risk-${title}`,
      showBroaderRiskDefault
    );
    const [showOnlyDatedContent, setShowOnlyDatedContent] =
      usePrintableReportState(`show-only-dated-content-${title}`, false);

    const [organisationsToUseArray, setOrganisationsToUseArray] =
      usePrintableReportState(`organisations-to-use-${title}`, new Set());
    const organisationsToUse = useMemo(
      () => new Set(organisationsToUseArray),
      [organisationsToUseArray]
    );
    const setOrganisationsToUse = useMemo(
      () => os => setOrganisationsToUseArray([...os]),
      [setOrganisationsToUseArray]
    );

    const personaId = useContext(PersonaIdContext);
    const [confidenceCategoriesToUseArray, setConfidenceCategoriesToUseArray] =
      usePrintableReportState(
        `confidence-categories-to-use-${title}`,
        personaId ? new Set(["included"]) : new Set()
      );
    const confidenceCategoriesToUse = useMemo(
      () => new Set(confidenceCategoriesToUseArray),
      [confidenceCategoriesToUseArray]
    );
    const setConfidenceCategoriesToUse = useMemo(
      () => os => setConfidenceCategoriesToUseArray([...os]),
      [setConfidenceCategoriesToUseArray]
    );

    const [locationsToUseArray, setLocationsToUseArray] =
      usePrintableReportState(`locations-to-use-${title}`, new Set());
    const locationsToUse = useMemo(
      () => new Set(locationsToUseArray),
      [locationsToUseArray]
    );
    const setLocationsToUse = useMemo(
      () => os => setLocationsToUseArray([...os]),
      [setLocationsToUseArray]
    );

    const [peopleToUseArray, setPeopleToUseArray] = usePrintableReportState(
      `people-to-use-${title}`,
      new Set()
    );
    const peopleToUse = useMemo(
      () => new Set(peopleToUseArray),
      [peopleToUseArray]
    );
    const setPeopleToUse = useMemo(
      () => os => setPeopleToUseArray([...os]),
      [setPeopleToUseArray]
    );

    const [languagesToUseArray, setLanguagesToUseArray] =
      usePrintableReportState(`languages-to-use-${title}`, new Set());
    const languagesToUse = useMemo(
      () => new Set(languagesToUseArray),
      [languagesToUseArray]
    );
    const setLanguagesToUse = useMemo(
      () => os => setLanguagesToUseArray([...os]),
      [setLanguagesToUseArray]
    );

    const [dateBounds, setDateBounds] = usePrintableReportState(
      `date-bounds-${title}`,
      {}
    );

    const [sortKey, setSortKey] = usePrintableReportState(
      `sort-key-${title}`,
      SortKeys.OriginalOrder
    );
    const useDirectSourcesFeatureEnabled = useFeatureEnabled(
      Features.FilterTangentialSources
    );
    const [directSourcesOnly, setDirectSourcesOnly] = usePrintableReportState(
      `direct-sources-only-${title}`,
      useDirectSourcesFeatureEnabled && !isDisregarded
    );
    const [indirectSourcesOnly, setIndirectSourcesOnly] =
      usePrintableReportState(`indirect-sources-only-${title}`, false);

    const [sourceTypesToUseArray, setSourceTypesToUseArray] =
      usePrintableReportState(`source-types-to-use-${title}`, new Set());
    const sourceTypesToUse = useMemo(
      () => new Set(sourceTypesToUseArray),
      [sourceTypesToUseArray]
    );
    const setSourceTypesToUse = useMemo(
      () => os => setSourceTypesToUseArray([...os]),
      [setSourceTypesToUseArray]
    );

    const nonNullSources = useMemo(
      () =>
        sourceGroups
          .flatMap(
            g =>
              g.sources ||
              (g.sourceStacks &&
                g.sourceStacks.flatMap(stack => stack.sources)) ||
              []
          )
          .filter(s => s),
      [sourceGroups]
    );
    const confidencePresent = useMemo(
      () =>
        sourceGroups.some(g =>
          (g.sourceStacks || []).some(stack =>
            (stack.sources || []).some(
              source =>
                source.confidence !== undefined && source.confidence !== null
            )
          )
        ),
      [sourceGroups]
    );

    // filters are set up like this so that later on things can calculate numbers of sources and such using them.
    // It's quite tricky logic otherwise!

    const filters = useMemo(() => {
      const showOnlyRiskFilter = source => {
        if (showBroaderRisk && isOnlyRisk) {
          return true;
        }
        if (showOnlyRisk || isOnlyRisk) {
          return (
            source.tags &&
            source.tags.some(
              t =>
                t.riskCategoriesOld &&
                t.riskCategoriesOld.length &&
                (showBroaderRisk || !t.isNotCloseToSubject)
            )
          );
        }
        return true;
      };
      const riskCategoryFilter = source =>
        !riskCategoriesToUseSet ||
        riskCategoriesToUseSet.size === 0 ||
        (source.tags &&
          source.tags.some(
            tag =>
              tag &&
              tag.riskCategoriesOld &&
              tag.riskCategoriesOld.some(cat =>
                riskCategoriesToUseSet.has(cat)
              ) &&
              (showBroaderRisk || !tag.isNotCloseToSubject)
          ));
      const organisationFilter = source =>
        !organisationsToUse ||
        organisationsToUse.size === 0 ||
        (source.tags &&
          source.tags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Organisation &&
              tag.entity.id !== undefined &&
              organisationsToUse.has(tag.entity.id)
          ));

      const confidenceCategoryFilter = source =>
        !confidenceCategoriesToUse ||
        confidenceCategoriesToUse.size === 0 ||
        (source.sourceConfidence &&
          confidenceCategoriesToUse.has(source.sourceConfidence.toLowerCase()));

      const locationFilter = source =>
        !locationsToUse ||
        locationsToUse.size === 0 ||
        (source.tags &&
          source.tags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Location &&
              tag.entity.id !== undefined &&
              locationsToUse.has(tag.entity.id)
          ));
      const personFilter = source =>
        !peopleToUse ||
        peopleToUse.size === 0 ||
        (source.tags &&
          source.tags.some(
            tag =>
              tag.entity &&
              tag.entity.type === EntityTypes.Person &&
              tag.entity.id !== undefined &&
              peopleToUse.has(tag.entity.id)
          ));
      const dateFilter = source => {
        if (!source.publicationDateFull) {
          return !showOnlyDatedContent;
        }
        if (!dateBounds) {
          return true;
        }
        if (source.publicationDateFull) {
          const time = new Date(source.publicationDateFull).getTime();
          if (dateBounds.startDate && time < dateBounds.startDate.getTime()) {
            return false;
          }
          if (dateBounds.endDate && time > dateBounds.endDate.getTime()) {
            return false;
          }
          return true;
        }

        return false;
      };
      const languagesFilter = source =>
        !languagesToUse ||
        languagesToUse.size === 0 ||
        (source.detectedLanguage
          ? languagesToUse.has(source.detectedLanguage.toLowerCase())
          : languagesToUse.has("english"));
      const sourceTypesFilter = source => {
        if (!sourceTypesToUse || sourceTypesToUse.size === 0) {
          return true;
        }
        const sourceType =
          source.sourceType &&
          source.sourceType.length &&
          source.sourceType.join("/");
        const extendedSourceType = sourceType
          ? sourceType + (source.provider ? `/${source.provider}` : "")
          : source.provider;
        return extendedSourceType && sourceTypesToUse.has(extendedSourceType);
      };
      const directSourceOnlyFilter = source =>
        directSourcesOnly ? source.hasDirectSubjectMention : true;
      const indirectSourceOnlyFilter = source =>
        indirectSourcesOnly ? !source.hasDirectSubjectMention : true;
      return [
        {
          id: FilterIds.RiskOnly,
          filter: showOnlyRiskFilter
        },
        {
          id: FilterIds.RiskCategory,
          filter: riskCategoryFilter
        },
        {
          id: FilterIds.Organisation,
          filter: organisationFilter
        },
        {
          id: FilterIds.ConfidenceCategory,
          filter: confidenceCategoryFilter
        },
        {
          id: FilterIds.Person,
          filter: personFilter
        },
        {
          id: FilterIds.Location,
          filter: locationFilter
        },
        {
          id: FilterIds.Date,
          filter: dateFilter
        },
        {
          id: FilterIds.Language,
          filter: languagesFilter
        },
        {
          id: FilterIds.SourceType,
          filter: sourceTypesFilter
        },
        {
          id: FilterIds.DirectSubjectMention,
          filter: directSourceOnlyFilter
        },
        {
          id: FilterIds.IndirectSources,
          filter: indirectSourceOnlyFilter
        }
      ];
    }, [
      showBroaderRisk,
      isOnlyRisk,
      showOnlyRisk,
      riskCategoriesToUseSet,
      organisationsToUse,
      confidenceCategoriesToUse,
      locationsToUse,
      peopleToUse,
      dateBounds,
      showOnlyDatedContent,
      languagesToUse,
      sourceTypesToUse,
      directSourcesOnly,
      indirectSourcesOnly
    ]);

    const buildHeirachicalCategorySourceCountMap = useMemo(
      () =>
        (
          sourceToCategories,
          filterId,
          {
            sourceToPotentialCategories,
            categoryTierToKey,
            categoryTierToDisplay
          }
        ) => {
          const countMap = new Map();
          const otherFilters = filters.filter(filter => filter.id !== filterId); // filter the filters to exclude this one
          const sourcesAffected = nonNullSources.filter(s =>
            otherFilters.every(filter => filter.filter(s))
          );
          const uncategorisedCurrent = 0;
          for (const source of sourcesAffected) {
            // filter (but not with this filter)
            const categoriesInSourceCurrent = [
              ...new Set(
                sourceToCategories(source).filter(
                  c => c !== undefined && c !== null
                )
              )
            ];
            for (const category of categoriesInSourceCurrent) {
              let innerMap = countMap;
              let currentCategory = category || [];
              const keysSoFar = [];
              while (currentCategory.length > 0) {
                const [head, ...tail] = currentCategory;
                const key = categoryTierToKey ? categoryTierToKey(head) : head;
                const fullKey = keysSoFar.concat([key]).join("/");
                if (key === undefined || key === null) {
                  console.error("Null sort-filter category tier key", {
                    head,
                    category,
                    key,
                    source
                  });
                } else {
                  const display = categoryTierToDisplay
                    ? categoryTierToDisplay(head)
                    : head;
                  const value = innerMap.has(key) && innerMap.get(key);
                  const currentAvailable =
                    ((value && value.currentAvailable) || 0) + 1;
                  const children = (value && value.children) || new Map();
                  const currentAvailableUncategorised =
                    ((value &&
                      value.uncategorisedCounts &&
                      value.uncategorisedCounts.currentAvailable) ||
                      0) + 1;
                  const uncategorisedCounts = {
                    ...((value && value.uncategorisedCounts) || {}),
                    ...(tail && tail.length
                      ? {}
                      : { currentAvailable: currentAvailableUncategorised })
                  };
                  innerMap.set(key, {
                    ...(value || { key, display, fullKey, children }),
                    currentAvailable,
                    uncategorisedCounts
                  });
                  innerMap = children; // travel down tree
                }
                keysSoFar.push(key);
                currentCategory = tail;
              }
            }
          }

          const uncategorisedPotential = 0;
          for (const source of nonNullSources) {
            const categoriesInSourcePotential = [
              ...new Set(
                (sourceToPotentialCategories || sourceToCategories)(
                  source
                ).filter(c => c !== undefined && c !== null)
              )
            ];

            for (const category of categoriesInSourcePotential) {
              let innerMap = countMap;
              let currentCategory = category || [];
              const keysSoFar = [];

              while (currentCategory.length > 0) {
                const [head, ...tail] = currentCategory;
                const key = categoryTierToKey ? categoryTierToKey(head) : head;
                const fullKey = keysSoFar.concat([key]).join("/");
                if (key === undefined || key === null) {
                  console.error("Null sort-filter category tier key", {
                    head,
                    category,
                    key,
                    source
                  });
                } else {
                  const display = categoryTierToDisplay
                    ? categoryTierToDisplay(head)
                    : head;
                  const value = innerMap.has(key) && innerMap.get(key);
                  const potentialAvailable =
                    ((value && value.potentialAvailable) || 0) + 1;
                  const children = (value && value.children) || new Map();
                  const potentialAvailableUncategorised =
                    ((value &&
                      value.uncategorisedCounts &&
                      value.uncategorisedCounts.potentialAvailable) ||
                      0) + 1;
                  const uncategorisedCounts = {
                    ...((value && value.uncategorisedCounts) || {}),
                    ...(tail && tail.length
                      ? {}
                      : { potentialAvailable: potentialAvailableUncategorised })
                  };
                  innerMap.set(key, {
                    ...(value || { key, display, fullKey, children }),
                    potentialAvailable,
                    uncategorisedCounts
                  });
                  innerMap = children; // travel down tree
                }
                keysSoFar.push(key);
                currentCategory = tail;
              }
            }
          }

          return {
            children: countMap,
            uncategorisedCounts: {
              currentAvailable: uncategorisedCurrent,
              potentialAvailable: uncategorisedPotential
            }
          };
        },
      [nonNullSources, filters]
    );

    // sourceToPotentialCategories is optional (so if the behaviour of the filter depends on other settings it can be custom)
    const buildCategorySourceCountMap = useMemo(
      () =>
        (
          sourceToCategories,
          filterId,
          { sourceToPotentialCategories, categoryToDisplay, categoryToKey }
        ) => {
          const countMap = new Map();
          const otherFilters = filters.filter(filter => filter.id !== filterId); // filter the filters to exclude this one
          const sourcesAffected = nonNullSources.filter(s =>
            otherFilters.every(filter => filter.filter(s))
          );
          for (const source of sourcesAffected) {
            // filter (but not with this filter)
            const categoriesInSourceCurrent = [
              ...new Set(
                sourceToCategories(source).filter(
                  c => c !== undefined && c !== null
                )
              )
            ];
            for (const category of categoriesInSourceCurrent) {
              const key = categoryToKey ? categoryToKey(category) : category;
              if (key === undefined || key === null) {
                console.error("Null sort-filter category key", {
                  category,
                  source
                });
              } else {
                const display = categoryToDisplay
                  ? categoryToDisplay(category)
                  : category;
                const value = countMap.has(key) && countMap.get(key);
                const currentAvailable =
                  ((value && value.currentAvailable) || 0) + 1;
                countMap.set(key, {
                  ...(value || { key, display }),
                  currentAvailable
                });
              }
            }
          }

          for (const source of nonNullSources) {
            // filter (but not with this filter)
            const categoriesInSourcePotential = [
              ...new Set(
                (sourceToPotentialCategories || sourceToCategories)(
                  source
                ).filter(c => c !== undefined && c !== null)
              )
            ];
            for (const category of categoriesInSourcePotential) {
              const key = categoryToKey ? categoryToKey(category) : category;
              if (key === undefined || key === null) {
                console.error("Null sort-filter category key", {
                  category,
                  source
                });
              } else {
                const display = categoryToDisplay
                  ? categoryToDisplay(category)
                  : category;
                const value = countMap.has(key) && countMap.get(key);
                const potentialAvailable =
                  ((value && value.potentialAvailable) || 0) + 1;
                countMap.set(key, {
                  ...(value || { key, display }),
                  potentialAvailable
                });
              }
            }
          }
          return countMap;
        },
      [filters, nonNullSources]
    );

    const dates = useMemo(
      () =>
        nonNullSources
          .filter(
            s =>
              s.publicationDateFull &&
              filters
                .filter(filter => filter.id !== FilterIds.Date)
                .every(filter => filter.filter(s))
          )
          .map(s => {
            return { date: new Date(s.publicationDateFull), value: 1 };
          }),
      [filters, nonNullSources]
    );
    const potentialDates = useMemo(
      () =>
        nonNullSources.map(s => new Date(s.publicationDateFull)).filter(d => d),
      [nonNullSources]
    );

    const startBuildFilterData = performance.now();

    const riskCategoriesInSource = useMemo(
      () => source =>
        (source.tags || [])
          .filter(t => showBroaderRisk || !t.isNotCloseToSubject)
          .flatMap(t => t.riskCategoriesOld),
      [showBroaderRisk]
    );
    const entityTagsOfType = useMemo(
      () => type => source =>
        (source.tags || [])
          .filter(t => t.entity && t.entity.type === type)
          .map(t => t.entity),
      []
    );
    const organisationsInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Organisation),
      [entityTagsOfType]
    );
    const sourceConfidenceInSource = useMemo(
      () => source => {
        // If confidence is null or undefined, return an empty array
        return source.sourceConfidence ? [source.sourceConfidence] : [];
      },
      []
    );
    const locationsInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Location),
      [entityTagsOfType]
    );
    const peopleInSource = useMemo(
      () => entityTagsOfType(EntityTypes.Person),
      [entityTagsOfType]
    );

    const riskCategories = useMemo(
      () =>
        buildCategorySourceCountMap(
          riskCategoriesInSource,
          FilterIds.RiskCategory,
          {
            sourceToPotentialCategories: source =>
              source.tags &&
              source.tags.flatMap(tag => tag && tag.riskCategoriesOld)
          }
        ),
      [buildCategorySourceCountMap, riskCategoriesInSource]
    );
    const entityToDisplay = e => e && e.text;
    const entityToKey = e => e && e.id;
    const organisations = useMemo(
      () =>
        buildCategorySourceCountMap(
          organisationsInSource,
          FilterIds.Organisation,
          { categoryToDisplay: entityToDisplay, categoryToKey: entityToKey }
        ),
      [buildCategorySourceCountMap, organisationsInSource]
    );
    const confidenceCategories = useMemo(
      () =>
        buildCategorySourceCountMap(
          sourceConfidenceInSource,
          FilterIds.ConfidenceCategory,
          { categoryToKey: c => c.toLowerCase() }
        ),
      [buildCategorySourceCountMap, sourceConfidenceInSource]
    );
    const locations = useMemo(
      () =>
        buildCategorySourceCountMap(locationsInSource, FilterIds.Location, {
          categoryToDisplay: entityToDisplay,
          categoryToKey: entityToKey
        }),
      [buildCategorySourceCountMap, locationsInSource]
    );
    const people = useMemo(
      () =>
        buildCategorySourceCountMap(peopleInSource, FilterIds.Person, {
          categoryToDisplay: entityToDisplay,
          categoryToKey: entityToKey
        }),
      [buildCategorySourceCountMap, peopleInSource]
    );
    const languages = useMemo(
      () =>
        buildCategorySourceCountMap(
          s => (s.detectedLanguage ? [s.detectedLanguage] : ["English"]),
          FilterIds.Language,
          { categoryToKey: l => l.toLowerCase() }
        ),
      [buildCategorySourceCountMap]
    );
    const sourceTypes = useMemo(
      () =>
        buildHeirachicalCategorySourceCountMap(
          s =>
            s.sourceType && s.sourceType.length
              ? [s.sourceType.concat(s.provider ? [s.provider] : [])]
              : s.provider
              ? [[s.provider]]
              : [],
          FilterIds.SourceType,
          {}
        ),
      [buildHeirachicalCategorySourceCountMap]
    );

    const buildFilterDataDuractionMs = performance.now() - startBuildFilterData;
    if (buildFilterDataDuractionMs > 100) {
      console.warn(
        "Building filter data duration",
        `${buildFilterDataDuractionMs}ms`,
        title
      );
    }

    const canReset =
      riskCategoriesToUseSet.size > 0 ||
      showOnlyRisk ||
      organisationsToUse.size > 0 ||
      confidenceCategoriesToUse.size > 0 ||
      peopleToUse.size > 0 ||
      locationsToUse.size > 0 ||
      (dateBounds && (dateBounds.startDate || dateBounds.endDate)) ||
      showOnlyDatedContent ||
      languagesToUse.size > 0 ||
      sourceTypesToUse.size > 0 ||
      (useDirectSourcesFeatureEnabled && !directSourcesOnly) ||
      (useDirectSourcesFeatureEnabled &&
        diagnosticsModeEnabled &&
        indirectSourcesOnly) ||
      showBroaderRisk !== showBroaderRiskDefault ||
      sortKey !== SortKeys.OriginalOrder;

    const resetAll = useMemo(
      () => () => {
        setShowOnlyRisk(false);
        setRiskCategoriesToUse(new Set());
        setShowBroaderRisk(showBroaderRiskDefault);
        setOrganisationsToUse(new Set());
        setConfidenceCategoriesToUse(new Set());
        setLocationsToUse(new Set());
        setPeopleToUse(new Set());
        setDateBounds({});
        setShowOnlyDatedContent(false);
        setLanguagesToUse(new Set());
        setSortKey(SortKeys.OriginalOrder);
        setSourceTypesToUse(new Set());
        setDirectSourcesOnly(useDirectSourcesFeatureEnabled && !isDisregarded);
        setIndirectSourcesOnly(false);
      },
      [
        setShowOnlyRisk,
        setRiskCategoriesToUse,
        setShowBroaderRisk,
        showBroaderRiskDefault,
        setOrganisationsToUse,
        setConfidenceCategoriesToUse,
        setLocationsToUse,
        setPeopleToUse,
        setDateBounds,
        setShowOnlyDatedContent,
        setLanguagesToUse,
        setSortKey,
        setSourceTypesToUse,
        setDirectSourcesOnly,
        useDirectSourcesFeatureEnabled,
        setIndirectSourcesOnly,
        isDisregarded
      ]
    );

    const groupNotEmpty = g =>
      (g.sources || []).some(s => s && s.show) ||
      (g.sourceStacks || []).some(stack => stack && stack.show);

    const getConfidenceForStack = stack => {
      const firstSource = (stack.sources || [])[0];
      if (firstSource) {
        return firstSource.confidence || 0;
      }
      return 0;
    };

    const getConfidenceForGroup = g => {
      const firstStack = (g.sourceStacks || [])[0];
      if (!firstStack) {
        return 0;
      }
      return getConfidenceForStack(firstStack);
    };

    // TODO: move compareGroups and compareStacks into useMemo
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    const compareGroups = (ga, gb) =>
      getConfidenceForGroup(ga) - getConfidenceForGroup(gb);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    const compareStacks = (sa, sb) =>
      getConfidenceForStack(sa) - getConfidenceForStack(sb);

    const filteringAndSortingStart = performance.now();

    const sourceGroupsToUse = useMemo(() => {
      const result = sourceGroups
        .map(group => {
          const stacks = group.sourceStacks
            .map(stack => {
              const sourcesInStack = stack.sources.map(stackSource => ({
                ...stackSource,
                isAdjacentContent: group.isAdjacentContent,
                show:
                  stackSource &&
                  filters.every(filter => filter.filter(stackSource))
              })); // filter
              return { sources: sourcesInStack };
            })
            .map(stack => ({
              ...stack,
              show: stack.sources.some(stackSource => stackSource.show)
            }));
          const sourcesInGroup = stacks.flatMap(stack => stack.sources);
          return { sourceStacks: stacks, sources: sourcesInGroup };
        })
        .map(group => ({ ...group, show: groupNotEmpty(group) }));

      return result;
    }, [sourceGroups, filters]);

    const sourceGroupsToUseSorted = useMemo(() => {
      if (sortKey === SortKeys.Confidence) {
        return sourceGroupsToUse
          .map(group => {
            const stacks = [...group.sourceStacks].sort((sa, sb) =>
              isDisregarded ? compareStacks(sa, sb) : -compareStacks(sa, sb)
            );
            const sourcesInGroup = stacks.flatMap(stack => stack.sources);
            return { sourceStacks: stacks, sources: sourcesInGroup };
          })
          .sort((groupA, groupB) =>
            isDisregarded
              ? compareGroups(groupA, groupB)
              : -compareGroups(groupA, groupB)
          );
      }
      return sourceGroupsToUse;
    }, [
      sortKey,
      sourceGroupsToUse,
      isDisregarded,
      compareStacks,
      compareGroups
    ]);

    const sortingAndFilteringDuration =
      performance.now() - filteringAndSortingStart;
    if (sortingAndFilteringDuration > 100) {
      console.warn(
        "Sorting and filtering duration",
        `${sortingAndFilteringDuration}ms`,
        title
      );
    }

    const sectionCount = sum(
      sourceGroupsToUse.map(g =>
        g.sourceStacks
          ? sum(
              g.sourceStacks.map(
                source =>
                  source.sources.filter(
                    subSource => subSource && subSource.show
                  ).length
              )
            )
          : g.sources.length
      )
    );
    const potentialSourceCount = nonNullSources.length;
    const potentialSectionCount = nonNullSources.length;

    const barProps = {
      riskCategories,
      riskCategoriesToUseSet,
      setRiskCategoriesToUse,
      organisations,
      organisationsToUse,
      setOrganisationsToUse,
      confidenceCategories,
      confidenceCategoriesToUse,
      setConfidenceCategoriesToUse,
      locations,
      locationsToUse,
      setLocationsToUse,
      people,
      peopleToUse,
      setPeopleToUse,
      showBroaderRisk,
      setShowBroaderRisk,
      showBroaderRiskDefault,
      canReset,
      resetAll,
      showOnlyRisk,
      setShowOnlyRisk,
      dates,
      potentialDates,
      dateBounds,
      setDateBounds,
      showOnlyDatedContent,
      setShowOnlyDatedContent,
      languages,
      languagesToUse,
      setLanguagesToUse,
      potentialSourceCount,
      sortKey,
      setSortKey,
      confidencePresent,
      sourceTypes,
      sourceTypesToUse,
      setSourceTypesToUse,
      sourceGroups,
      directSourcesOnly,
      setDirectSourcesOnly,
      isDisregarded,
      indirectSourcesOnly,
      setIndirectSourcesOnly
    };

    const renderData = () =>
      (sourceGroupsToUseSorted || []).map(group => {
        if (
          group.sourceStacks
            ? group.sourceStacks.length > 1
            : group.sources.length > 1
        ) {
          const sourceIds = (
            group.sourceStacks
              ? group.sourceStacks.flatMap(stack => stack.sources)
              : group.sources
          ).map(source => source.sourceId);

          return (
            <ErrorBoundary
              key={sourceIds.join("+")}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <SourceGroup {...group} showAssessment={!isOnlyRisk} />
            </ErrorBoundary>
          );
        }
        if (group.sourceStacks && group.sourceStacks.length) {
          const sourceStack = group.sourceStacks[0];

          const sourceIds = sourceStack.sources
            .filter(s => s && s.sourceId)
            .map(s => s.sourceId);
          return (
            <ErrorBoundary
              key={sourceIds.join("+")}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <SourceStack
                isDisregarded={isDisregarded}
                {...sourceStack}
                showAssessment={!isOnlyRisk}
              />
            </ErrorBoundary>
          );
        }
        if (group.sources && group.sources.length) {
          const source = group.sources[0];
          const key =
            group.sources &&
            group.sources
              .filter(s => s)
              .map(s => s.sourceId)
              .join("+");
          return (
            <ErrorBoundary
              key={key}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <MediaSourceCard
                isDisregarded={isDisregarded}
                {...source}
                showAssessment={!isOnlyRisk}
              />
            </ErrorBoundary>
          );
        }
        console.error(
          `Empty sources for group ${group.title} - will be ignored`
        );
        return null;
      });

    const renderNoResultsSection = () => {
      if (potentialSectionCount <= 0) {
        return noResultsText;
      }
      if (sectionCount <= 0) {
        return "No results - try removing some filters";
      }

      return null;
    };

    return (
      <Section
        {...{ sectionCount, potentialSectionCount, title }}
        {...props}
        expandable={expandable}
        ref={ref}
        className="source-section"
        shouldUnmountOnCollapse
      >
        {description && (
          <p className="report-section-description sourcing-section-description">
            {description}
          </p>
        )}
        <SortFilterBar {...props} {...barProps} />
        {renderData()}
        {potentialSectionCount <= 0 || sectionCount <= 0 ? (
          <div className="sourcing-section-no-results">
            {renderNoResultsSection()}
          </div>
        ) : null}
      </Section>
    );
  })
);
