import { useCallback, useEffect, useMemo, useState } from "react";
import { apm } from "@elastic/apm-rum";
import {
  ReportsService,
  RiskSummary,
  RiskSummaryTopic,
  ShareTokenService
} from "../api/insights";
import {
  RiskSummariesContextValue,
  RiskSummariesStatus
} from "./hooks/useRiskSummaries/types";
import { useEnquiryId } from "./hooks/useEnquiryId";
import { isPDX } from "../static-config";

function riskSummariesCall(
  enquiryId: string,
  token: string | null,
  personaId: string | null
) {
  if (personaId) {
    if (token) {
      return ShareTokenService.getReportsPersonasRiskSummaries({
        reportId: enquiryId,
        personaId,
        shareToken: token
      });
    }
    return ReportsService.getReportsPersonasRiskSummaries({
      reportId: enquiryId,
      personaId
    });
  }
  if (token) {
    return ShareTokenService.getReportsRiskSummaries({
      reportId: enquiryId,
      shareToken: token
    });
  }
  return ReportsService.getReportsRiskSummaries({ reportId: enquiryId });
}

export function usePersonaRiskSummariesContextValue(
  personaId: string
): RiskSummariesContextValue {
  const enquiryId = useEnquiryId();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");

  const [allSummaries, setAllSummaries] = useState(new Array<RiskSummary>());

  const [hasRiskSummaries, setHasRiskSummaries] = useState(false);

  const [status, setStatus] = useState(
    isPDX ? RiskSummariesStatus.Loaded : RiskSummariesStatus.Loading
  );
  const getSummary = useCallback(
    (topic: RiskSummaryTopic) =>
      allSummaries.find(summary => summary.topic === topic),
    [allSummaries]
  );
  const updateRiskByTopic = useCallback(
    (topic: RiskSummaryTopic, muted: boolean) => {
      setStatus(RiskSummariesStatus.MutingRiskIcon);
      ReportsService.patchReportsPersonasRiskSummariesMuted({
        reportId: enquiryId,
        personaId,
        topic,
        requestBody: {
          muted
        }
      })
        .then(() => {
          setStatus(RiskSummariesStatus.Loaded);
          setAllSummaries(
            allSummaries.map(summary =>
              summary.topic === topic
                ? { ...summary, muted: muted ? new Date().toISOString() : null }
                : summary
            )
          );
        })
        .catch(err => {
          apm.captureError(err);
          setStatus(RiskSummariesStatus.Error);
        });
    },
    [allSummaries, enquiryId, personaId]
  );

  // Load the content

  useEffect(() => {
    riskSummariesCall(enquiryId, token, personaId)
      .then(r => {
        setAllSummaries(r);
        setStatus(RiskSummariesStatus.Loaded);
        setHasRiskSummaries(true);
      })
      .catch(() => {
        setStatus(RiskSummariesStatus.Error);
      });
  }, [personaId, enquiryId, token]);

  const value: RiskSummariesContextValue = useMemo(
    () => ({
      hasRiskSummaries,
      status,
      getSummary,
      updateRiskByTopic
    }),
    [hasRiskSummaries, getSummary, status, updateRiskByTopic]
  );

  return value;
}
