import React, { type FC } from "react";

import { type RoleItem } from "api/entities";
import { type InformationSource } from "api/report/report-types";

import WithInspector from "components/organisms/WithInspector";

import S from "./styles";

interface Props {
  items: RoleItem[];
  sources: InformationSource[];
}

const EntityRoles: FC<Props> = ({ items, sources }) => {
  if (items.length > 0) {
    const {
      title: currentRoleTitle,
      durationInMonths,
      dateFrom: currentRoleDateFrom,
      dateTo: currentRoleDateTo
    } = items[0];

    const formatDate = (date?: Date) => {
      return date?.toLocaleString("default", {
        month: "short",
        year: "numeric"
      });
    };

    const formatDuration = (duration: number) => {
      if (duration >= 12) {
        const years = Math.floor(duration / 12);
        return `${years} year${years > 1 ? "s" : ""}`;
      }
      return `${duration} month${duration > 1 ? "s" : ""}`;
    };

    const formatCurrentRoleDate = (dateFrom?: Date, dateTo?: Date) => {
      if (dateFrom && dateTo) {
        const durationString = durationInMonths
          ? `(${formatDuration(durationInMonths)})`
          : "";
        return `${formatDate(dateFrom)} to ${formatDate(
          dateTo
        )} ${durationString}`;
      }

      if (dateFrom && !dateTo) {
        const durationString = durationInMonths
          ? `(${formatDuration(durationInMonths)})`
          : "";
        return `Since ${formatDate(dateFrom)} ${durationString}`;
      }

      if (!dateFrom && dateTo) {
        return `Until ${formatDate(dateTo)}`;
      }

      return "No date identified";
    };

    return (
      <WithInspector
        sources={sources}
        topSectionElement={
          <S.RoleItemList>
            {items.map(({ title, dateFrom, dateTo }) => (
              <S.RoleItem key={title}>
                <S.CurrentRole>{title}</S.CurrentRole>
                <S.OtherRoles>
                  {formatDate(dateFrom)}
                  {dateTo && dateFrom && " - "}
                  {formatDate(dateTo)}
                </S.OtherRoles>
              </S.RoleItem>
            ))}
          </S.RoleItemList>
        }
      >
        <S.Roles>
          <S.CurrentRole>{currentRoleTitle}</S.CurrentRole>
          <S.OtherRoles>
            {items.length > 1 && `+${items.length - 1} role`}
            {items.length > 2 && "s"}{" "}
            {formatCurrentRoleDate(currentRoleDateFrom, currentRoleDateTo)}
          </S.OtherRoles>
        </S.Roles>
      </WithInspector>
    );
  }

  return null;
};

export default EntityRoles;
