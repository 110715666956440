import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";
import { AlertCircle } from "react-feather";

import { red, purple, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

import { ReactComponent as AskXapienButtonIconSvg } from "img/insights-icons/ask-xapien-large.svg";

import { isPDX } from "static-config";

// @ts-ignore
import theme from "theme";

export interface ContentSectionProps {
  height: string;
  isPrintMode: boolean;
}

// Important is needed as Menu item is styling svg colours itself
const ErrorIcon = styled(AlertCircle)`
  color: ${red.directRiskFill} !important;
`;

const LoadingIcon = styled(Spinner)`
  width: 16px;
  height: 16px;
`;

const ContentContainer = styled.div`
  border-bottom-right-radius: 10px;
  background: ${standardColors.white};
  color: ${standardColors.black};
  flex: 1;
  position: relative;

  @media (max-width: 768px) {
    border-bottom-left-radius: 10px;
  }
`;

const ContentSection = styled.div<ContentSectionProps>`
  display: flex;
  flex-direction: column;
  -webkit-transform: translate3d(0, 0, 0);
  height: ${({ height, isPrintMode }) => {
    if (isPrintMode) {
      return "auto";
    }

    return height;
  }};
  overflow-y: auto;
  max-height: none;

  > div:first-child {
    display: flex;
    flex-direction: column;
  }

  [class*="DJDisclaimer"] {
    flex: 0;
    display: grid;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 144px);
  }
`;

const ContentNodeContainer = styled.div`
  padding: 24px 24px 24px 44px;

  @media (max-width: 768px) {
    padding: 15px 20px;
  }
`;

const InsightsChatContainer = styled.div`
  padding: 0 24px 0 44px;
`;

const AskXapienButton = styled.button`
  margin-bottom: 25px;
  border: 0;
  border-radius: 100px;
  padding: 8px 16px 8px 10px;
  gap: 0.3rem;
  color: ${standardColors.black};
  font-size: ${fontSize.sm};
  font-family: ${family.interDisplay};
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: ${() => {
    const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.dark);
    return `rgba(${r}, ${g}, ${b}, 0.1)`;
  }};

  &:hover {
    background: ${() => {
      const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.dark);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    }};
  }

  &:focus {
    outline: none;
  }

  span {
    color: ${isPDX ? theme.primaryColor : purple.darker};
    text-transform: capitalize;
  }
`;

const AskXapienButtonIcon = styled(AskXapienButtonIconSvg)`
  rect {
    fill: ${isPDX ? theme.primaryColor : purple.darker};
  }
`;

const Divider = styled.hr`
  margin: 50px 0 25px;
`;

const EducationalModalWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const S = {
  ErrorIcon,
  LoadingIcon,
  AskXapienButton,
  AskXapienButtonIcon,
  Divider,
  ContentContainer,
  ContentSection,
  ContentNodeContainer,
  InsightsChatContainer,
  EducationalModalWrapper
};

export default S;
