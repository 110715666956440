import dayjs from "dayjs";

import type {
  Report as RawReport,
  Subsection as RawSubsection,
  Element as RawElement
} from "api/insights";

import {
  DivContentNode,
  H1ContentNode,
  H2ContentNode,
  H3ContentNode,
  H5ContentNode,
  InsightReport,
  InsightReportSection,
  TextContentNode,
  PContentNode,
  ContentNode
} from "api/insight-reports/types";

import { isPDX } from "static-config";

const EXECUTIVE_SUMMARY_TITLE = "Executive Summary";

const getSubtitleSectionNode = (section: RawSubsection) =>
  section.created_by && section.date_created
    ? ({
        id: section.id,
        nodeType: "h5",
        value: "",
        content: [
          {
            id: section.id,
            value: `${section.created_by} asked this at ${dayjs(
              section.date_created
            )
              .local()
              .format("HH:mm DD/MM/YYYY")}`,
            nodeType: "text"
          } as TextContentNode
        ]
      } as H5ContentNode)
    : undefined;

const getTitleSectionNode = (section: RawSubsection) =>
  section.title
    ? ({
        id: section.id,
        nodeType: "h2",
        value: "",
        content: [
          {
            id: section.id,
            value: section.title,
            nodeType: "text"
          } as TextContentNode
        ]
      } as H2ContentNode)
    : undefined;

const getTitleElementNode = (element: RawElement) =>
  element.title
    ? ({
        id: element.id,
        nodeType: "h3",
        value: "",
        content: [
          {
            id: element.id,
            value: element.title,
            nodeType: "text"
          } as TextContentNode
        ]
      } as H3ContentNode)
    : undefined;

const getTextElementNodes = (section: RawSubsection, element: RawElement) =>
  element.segments && element.segments.length > 0
    ? element.segments.map(segment => {
        const supportingSentences =
          segment.sources.length > 0
            ? segment.sources.map(source => ({
                text: source.extract.text,
                wamSourceIds: source.wam_source_ids,
                supportLevel: source.support_level,
                contradictionStrength: source.contradiction_strength,
                supportStrength: source.support_strength
              }))
            : [];
        return {
          id: element.id,
          nodeType: "text",
          value: segment.text,
          isUnknown: !!section.is_unknown,
          supportLevel: segment.support_level,
          supportingSentences
        } as TextContentNode;
      })
    : [
        {
          id: element.id,
          nodeType: "text",
          value: element.body,
          isUnknown: !!section.is_unknown
        } as TextContentNode
      ];

const getSectionContentNodes = (section: RawSubsection) =>
  section.elements.flatMap(element => {
    const titleElementNode = getTitleElementNode(element);
    const textElementNodes: TextContentNode[] = getTextElementNodes(
      section,
      element
    );

    const paragraphContentNode: PContentNode = {
      id: element.id,
      nodeType: "p",
      isSourced: !!section.is_sourced,
      content: textElementNodes
    };

    if (titleElementNode) {
      return {
        id: element.id,
        nodeType: "div",
        value: "",
        content: [titleElementNode, paragraphContentNode] as ContentNode[]
      } as DivContentNode;
    }
    return paragraphContentNode;
  });

export const convertRawReport = (
  reportId: string,
  rawReport: RawReport
): InsightReport => {
  const { sections } = rawReport;

  const reportSections = Object.entries(sections)
    .map(
      ([
        _index,
        { ordinal, id, icon, title, subsections, signpost, disclaimer }
      ]) => {
        const disclaimerContentNode = {
          id: `${id}-disclaimer`,
          nodeType: "p",
          isSourced: false,
          isUnknown: true,
          content: [
            {
              id: `${id}-disclaimer-text`,
              nodeType: "text",
              value: disclaimer,
              isUnknown: true,
              isDisclaimer: true
            } as TextContentNode
          ]
        } as PContentNode;

        const isHighRiskLocations = title === "High-Risk Locations";
        // LLM-439 introduced some hacky changes to how we position the XI section disclaimer here
        // These changes will be revisted in the ticket LLM-545.

        const subsectionsContent = subsections.flatMap(section => {
          const titleSectionNode = getTitleSectionNode(section);
          const subtitleSectionNode = getSubtitleSectionNode(section);
          const sectionContentNodes = getSectionContentNodes(section);

          if (section.title === "---RELATED PARTIES---") {
            sectionContentNodes.push(disclaimerContentNode);
            // LLM-439: Detected Related Parties part for the section, so added disclaimer
            // NB: as per LLM-439, if no Related Parties part is detected, the disclaimer is SKIPPED
            // (apart from the High-Risk Locations section, in which case we always add the disclaimer below the section title)
          }

          const subsectionDisclaimerContentNode =
            !!section.disclaimer &&
            ({
              id: `${id}-disclaimer`,
              nodeType: "p",
              isSourced: false,
              isUnknown: true,
              isDisclaimer: true,
              content: [
                {
                  id: `${id}-disclaimer-text`,
                  nodeType: "text",
                  value: section.disclaimer,
                  isUnknown: true,
                  isDisclaimer: true
                } as TextContentNode
              ]
            } as PContentNode);

          const divContentNode = {
            id: section.id,
            nodeType: "div",
            content: [
              titleSectionNode,
              subtitleSectionNode,
              ...sectionContentNodes,
              subsectionDisclaimerContentNode
            ].filter(Boolean) as ContentNode[]
          } as DivContentNode;

          return [divContentNode];
        });

        const signpostContentNode =
          !!signpost &&
          ({
            id: `${id}-signpost`,
            nodeType: "p",
            isSourced: false,
            isUnknown: true,
            content: [
              {
                id: `${id}-signpost-text`,
                nodeType: "text",
                value: signpost,
                isUnknown: true
              } as TextContentNode
            ]
          } as PContentNode);

        const titleContentNode = {
          id,
          nodeType: "div",
          value: "",
          content: [
            {
              id,
              nodeType: "h1",
              title,
              value: "",
              content: [
                { id, value: title, nodeType: "text" } as TextContentNode
              ]
            } as H1ContentNode,
            signpostContentNode,
            // LLM-439: temporarily (hackily) including the disclaimer below the section title for the High-Risk Locations section
            isHighRiskLocations ? disclaimerContentNode : undefined,
            ...subsectionsContent
            // LLM-439: temporarily (hackily) removing the section disclaimer from its usual spot.
            // disclaimerContentNode
          ].filter(Boolean)
        } as DivContentNode;

        const section = {
          slug: `${icon}-${ordinal}`,
          ordinal,
          id,
          title,
          icon,
          content: [titleContentNode]
        };

        return section;
      }
    )
    .reduce(
      (
        acc: (InsightReportSection & { ordinal: number })[],
        curr: InsightReportSection & { ordinal: number }
      ) => {
        const index = acc.findIndex(item => curr.ordinal < item.ordinal);
        if (index !== -1) {
          acc.splice(index, 0, curr);
        } else {
          acc.push(curr);
        }
        return acc;
      },
      []
    )
    .map(section => {
      const { ordinal: _, ...sectionWithoutOrdinal } = section;

      return sectionWithoutOrdinal;
    });

  const summary = reportSections.find(x => x.title === EXECUTIVE_SUMMARY_TITLE);
  const modifiedSummary = summary && {
    ...summary,
    title: isPDX ? "Summary" : summary.title
  };

  const allSections = ([modifiedSummary] as any[])
    .concat(
      reportSections.filter(
        section => section.title !== EXECUTIVE_SUMMARY_TITLE
      )
    )
    .filter(Boolean) as InsightReportSection[];

  const report: InsightReport = {
    id: reportId,
    subject: "unknown", // TODO: Pull this from main report?
    sections: allSections
  };

  return report;
};
