import React from "react";

import { RiskFactorName } from "api/risk-service";
import useRiskService from "util/hooks/useRiskService";
import { RiskServiceStatus } from "util/hooks/useRiskService/types";
import {
  MuteRiskButton,
  MuteRiskButtonType
} from "components/molecules/MuteRiskButton";

import {
  MinusPlusIcon,
  MinusPlusIconType
} from "components/atoms/MinusPlusIcon";

import S from "./styles";

interface RiskMuteCoverProps {
  factor: RiskFactorName;
}

export const RiskMuteCover = ({ factor }: RiskMuteCoverProps) => {
  const { status, onMute } = useRiskService();
  const isLoading = status === RiskServiceStatus.MutingRiskIcon;

  return (
    <S.DiscardedCover>
      {isLoading ? (
        <S.Spinner />
      ) : (
        <>
          <MinusPlusIcon type={MinusPlusIconType.minus} variant="medium" />
          <S.DiscardedCoverHeading level={4}>
            Marked as not risk
          </S.DiscardedCoverHeading>
          <MuteRiskButton
            type={MuteRiskButtonType.undo}
            onClick={() => onMute(factor, false)}
          />
        </>
      )}
    </S.DiscardedCover>
  );
};
