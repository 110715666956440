import { styled } from "@linaria/react";
import { DropdownItem } from "reactstrap";
import { blue, grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const SimpleContainer = styled.div<{
  width?: "auto" | "100%";
  direction?: "column" | "row";
  justifyContent?: "space-between" | "space-around" | "flex-end";
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-around"};
  height: auto;
  width: ${({ width }) => width || "auto"};
  flex-direction: ${({ direction }) => direction || "row"};
`;

const ReportOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  width: 100%;

  background-color: ${grey.panel};
  min-height: -webkit-max-content; /*Needed for Safari to show the background at the right length*/

  > * {
    width: 100%;
  }
  .report-inner-container {
    flex-grow: 1;
    padding: 0 25px;
  }
`;

const CollectionNavigationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const OptionsDropdownMenuItem = styled(DropdownItem)`
  padding: 8px;
  color: ${grey.dark};
  font-size: ${fontSize.sm} !important;
  font-family: ${family.interLight};
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:focus {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.icon);
      return `rgba(${r}, ${g}, ${b}, 0.15)`;
    }};
  }
  &:disabled {
    opacity: 0.3;
    color: ${standardColors.white};
  }
  &:focus {
    outline: 2px solid ${blue.icon};
    outline-offset: -2px;
  }
  &:active {
    color: ${grey.dark};
  }
`;

const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column; /* Stack cards vertically */
  gap: 16px; /* Optional: Adds spacing between cards */
  padding: 16px;
`;

const S = {
  SimpleContainer,
  ReportOuterContainer,
  OptionsDropdownMenuItem,
  CollectionNavigationContainer,
  CardsContainer
};

export default S;
