import React, { useState } from "react";
import { FormattedText } from "components/atoms/FormattedText";

import S from "./styles";

interface ListCardEntryProps {
  title?: string;
  subtitle?: string;
  countryCode?: string;
  values: string[];
  bulleted: boolean;
}

export const ListCardEntry = ({
  title,
  subtitle,
  countryCode,
  values,
  bulleted
}: ListCardEntryProps) => {
  const canBeCollapsed = !bulleted && values.length > 2;
  const [collapsed, setCollapsed] = useState(canBeCollapsed);

  const handleToggleCollapse = () => {
    if (values.length <= 2) {
      return;
    }

    setCollapsed(prev => !prev);
  };

  return (
    <S.Container>
      {!!title && (
        <S.Title onClick={handleToggleCollapse}>
          {canBeCollapsed ? <S.Arrow right={collapsed} /> : null}
          {!!countryCode && (
            <S.CountryFlag
              size="sm"
              code={countryCode}
              showCodeString={false}
            />
          )}
          <FormattedText text={title} />
        </S.Title>
      )}
      {!collapsed && (
        <S.Content canBeCollapsed={canBeCollapsed}>
          {!!subtitle && (
            <S.Text>
              <FormattedText text={subtitle} />
            </S.Text>
          )}
          {bulleted ? (
            <S.BulletedList>
              {values.map((value, index) => (
                <li key={`ListCardItem-${value}-${index}`}>
                  <FormattedText text={value} />
                </li>
              ))}
            </S.BulletedList>
          ) : (
            values.map((value, index) => (
              <S.Text key={`ListCardItem-${value}-${index}`}>
                <FormattedText text={value} />
              </S.Text>
            ))
          )}
        </S.Content>
      )}
    </S.Container>
  );
};
