import React, { useState, type FC } from "react";

import { type Entity } from "api/entities";

import ButtonNew from "components/atoms/ButtonNew";
import NewReportModal from "components/organisms/NewReportModal";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import EntityCardAvatar from "components/atoms/EntityCardAvatar";
import EntityInfoList from "components/molecules/EntityInfoList";
import { NewReportProps } from "util/hooks/useEntityList/types";
import { type SubjectType } from "api/enquiry/types";
import useViewerMode from "util/hooks/useViewerMode";

import S from "./styles";

const EntityCard: FC<
  Entity & { onNewReport?: (props: NewReportProps) => void }
> = ({
  id,
  entityType,
  title,
  subtitle,
  hasReportableData,
  lists,
  sources,
  onNewReport
}) => {
  const { isViewerModeEnabled } = useViewerMode();
  const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(false);

  const onCloseNewReportModal = () => setIsNewReportModalOpen(false);
  const onOpenNewReportModal = () => setIsNewReportModalOpen(true);

  const canStartNewReport = !isViewerModeEnabled && hasReportableData;

  return (
    <S.Container isActive={hasReportableData}>
      <EntityCardAvatar entityType={entityType} />

      <S.Content>
        <S.Title>
          <strong>{title}</strong>
        </S.Title>
        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

        {lists.map(list => (
          <EntityInfoList
            key={`EntityCardList-${id}-${list.type}`}
            {...list}
            sources={sources}
          />
        ))}

        <S.Actions>
          <ButtonNew
            type={ButtonType.OutlinedLight}
            size={ButtonSize.Small}
            text="New report"
            disabled={!canStartNewReport}
            onClick={onOpenNewReportModal}
          />
        </S.Actions>
      </S.Content>

      <NewReportModal
        id={id}
        label={title}
        subjectType={entityType as unknown as SubjectType}
        contexts={[]}
        isOpen={isNewReportModalOpen}
        onNewReport={onNewReport}
        onClose={onCloseNewReportModal}
      />
    </S.Container>
  );
};

export default EntityCard;
