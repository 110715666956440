import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useRef,
  useState
} from "react";

import Popover from "components/atoms/Popover";
import { standardColors } from "styles/colors";
import { RiskSummaryTopic } from "api/insights";

import { getRiskSummaryConfig } from "util/riskSummary";
import useRiskSummaries from "util/hooks/useRiskSummaries";
import { RiskSummaryTooltip } from "components/molecules/RiskSummaryTooltip";

import S from "./styles";

export interface RiskSummaryInfoGraphicProps {
  topic: RiskSummaryTopic;
  summary?: string;
  unidentifiedText?: string;
  disclaimer?: boolean;
  hideRisk?: boolean;
  isRiskIdentified?: boolean;
}

const RiskSummaryInfoGraphic: FC<RiskSummaryInfoGraphicProps> = ({
  topic,
  disclaimer = true,
  summary,
  unidentifiedText,
  hideRisk = false,
  isRiskIdentified = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { getSummary, updateRiskByTopic } = useRiskSummaries();
  const closeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const muteRiskIcon = !!getSummary(topic)?.muted;

  const setRiskIconMuteStatus = (muted: boolean) => {
    updateRiskByTopic(topic, muted);
  };

  const preventClose = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  }, []);

  const initiateCloseTooltip = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => setIsOpen(false), 400);
  }, []);

  const riskIdentified = (!!summary && !hideRisk) || isRiskIdentified;
  const showInfoGraphicAsRisk = riskIdentified && !muteRiskIcon;

  const handleMouseEnter = () => {
    if (riskIdentified) {
      setIsHovered(true);
    }

    if (isOpen) {
      preventClose();
    }
  };

  const handleMouseLeave = () => {
    if (riskIdentified) {
      setIsHovered(false);
    }

    if (isOpen) {
      initiateCloseTooltip();
    }
  };

  const handleMouseLeaveTooltip: MouseEventHandler = () => {
    initiateCloseTooltip();
  };

  const getInfographicLabel = () => {
    if (showInfoGraphicAsRisk) {
      return "Identified";
    }
    if (muteRiskIcon) {
      return "Marked as not risk";
    }
    return unidentifiedText ?? "Not identified";
  };

  const { title, icon: Icon } = getRiskSummaryConfig(topic);

  const content = (
    <S.Container
      hasRisk={showInfoGraphicAsRisk}
      active={isHovered || isOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setIsOpen(prev => !prev)}
    >
      <S.IconContainer>
        <Icon />
      </S.IconContainer>
      <S.Title>{title}</S.Title>
      {isHovered && riskIdentified ? (
        <S.DetailsLink>See details</S.DetailsLink>
      ) : (
        <S.Text>{getInfographicLabel()}</S.Text>
      )}
    </S.Container>
  );

  if (!riskIdentified) {
    return content;
  }

  return (
    <Popover
      maxWidth="100%"
      isOpenOverride={isOpen}
      arrowColor={standardColors.white}
      arrowSize="22px"
      hideArrow
      content={
        summary && (
          <RiskSummaryTooltip
            topic={topic}
            summary={summary ?? ""}
            hasDisclaimer={disclaimer}
            handleMute={setRiskIconMuteStatus}
            onMouseEnter={preventClose}
            onMouseLeave={handleMouseLeaveTooltip}
          />
        )
      }
      alignment="bottom"
    >
      {content}
    </Popover>
  );
};

export default RiskSummaryInfoGraphic;
