import { styled } from "@linaria/react";
import { red } from "styles/colors";

const Label = styled.div<{ showInRed?: boolean }>`
  color: ${({ showInRed }) => (showInRed ? red.directRiskOutline : "inherit")};
`;

const S = { Label };

export default S;
