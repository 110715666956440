import React, { FC } from "react";
import { RiskIcon } from "components/atoms/RiskIcon";
import { RiskIconWrapper } from "components/molecules/RiskIconWrapper";
import { RiskFactor, RiskFactorName, RiskLevel, RiskSection } from "api/risk";

import NoRiskCard from "components/atoms/NoRiskCard";
import useRiskService from "util/hooks/useRiskService";
import { SnagCard } from "components/molecules/SnagCard";
import { FormattedText } from "components/atoms/FormattedText";
import { RiskServiceStatus } from "util/hooks/useRiskService/types";
import { RiskReviewCard } from "components/molecules/RiskReviewCard";
import { RiskSummaryCard } from "components/molecules/RiskSummaryCard";
import { OverallRiskCard } from "components/molecules/OverallRiskCard";
import { MuteRiskSnagCard } from "components/molecules/MuteRiskSnagCard";
import { SECTION_NAME_MAP, mapBucketToRiskLevel } from "util/riskService";
import { OverallRiskScoreIcon } from "components/atoms/OverallRiskScoreIcon";
import { RiskFrameworkSection } from "components/organisms/RiskFrameworkSection";

import S from "./styles";

const getIconsForSection = (
  riskFactors: RiskFactor[],
  hasMutingErrored: boolean
) => {
  return riskFactors.map(
    ({ name, level, isFlagged, summary, isMuted, unconfirmedSourceCount }) => {
      const RiskIconComponent = (
        <RiskIcon
          key={name}
          factor={name}
          isMuted={isMuted}
          isFlagged={isFlagged}
          unconfirmedSourceCount={unconfirmedSourceCount}
        />
      );

      const showForReviewCard =
        !isFlagged &&
        unconfirmedSourceCount &&
        (name === RiskFactorName.SanctionsRiskFactorName ||
          name === RiskFactorName.WatchlistsRiskFactorName ||
          name === RiskFactorName.PEPsRiskFactorName);

      const showNoRiskCard =
        level === RiskLevel.NoneRiskLevel && !showForReviewCard;

      return (
        <RiskIconWrapper
          key={name}
          tooltipAlignment={showNoRiskCard ? "top" : "bottom"}
          renderTooltipContent={(onMouseEnter, onMouseLeave) => {
            if (showNoRiskCard) {
              return (
                <NoRiskCard
                  name={name}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              );
            }

            if (hasMutingErrored) {
              return (
                <MuteRiskSnagCard
                  factor={name}
                  isMuted={isMuted}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              );
            }

            if (showForReviewCard) {
              return (
                <RiskReviewCard
                  factor={name}
                  unconfirmedSourceCount={unconfirmedSourceCount}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              );
            }

            return (
              <RiskSummaryCard
                name={name}
                summaries={summary!}
                unconfirmedSourceCount={unconfirmedSourceCount}
                isMuted={isMuted}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            );
          }}
        >
          {RiskIconComponent}
        </RiskIconWrapper>
      );
    }
  );
};

export interface RiskFrameworkProps {
  showOverallScore?: boolean;
}

export const RiskFramework: FC<RiskFrameworkProps> = ({ showOverallScore }) => {
  const { status, riskData, overallScoreData } = useRiskService();

  if (status === RiskServiceStatus.NotLoaded) return null;

  if (status === RiskServiceStatus.Error)
    return (
      <S.FrameworkContainer>
        <SnagCard />
      </S.FrameworkContainer>
    );

  const riskIconSections = [
    RiskSection.screeningFactors,
    RiskSection.webAndMediaFactors
  ] as const;

  const riskLevel = mapBucketToRiskLevel(overallScoreData.bucket);

  const cardContentText = overallScoreData.summary[0]?.content ?? "";
  const hasMutingErrored = status === RiskServiceStatus.MutingRiskError;
  const isRecalculatingOverallScore = [
    RiskServiceStatus.Loading,
    RiskServiceStatus.MutingRiskIcon,
    RiskServiceStatus.LoadingOverallScore
  ].includes(status);

  const OverallScoreIcon = cardContentText ? (
    <RiskIconWrapper
      renderTooltipContent={(onMouseEnter, onMouseLeave) => (
        <OverallRiskCard
          level={riskLevel}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <FormattedText text={cardContentText} />
        </OverallRiskCard>
      )}
    >
      <OverallRiskScoreIcon
        score={riskLevel}
        isWrapped
        isRecalculating={isRecalculatingOverallScore}
      />
    </RiskIconWrapper>
  ) : (
    <OverallRiskScoreIcon
      score={riskLevel}
      isRecalculating={isRecalculatingOverallScore}
    />
  );

  const carouselOverallScoreSection = (
    <RiskFrameworkSection
      key="overallScore"
      iconData={[]}
      title={SECTION_NAME_MAP.overallScore}
      icons={[OverallScoreIcon]}
    />
  );

  const [screeningSection, webAndMediaSection] = riskIconSections.map(
    sectionKey => {
      return (
        <RiskFrameworkSection
          key={sectionKey}
          title={SECTION_NAME_MAP[sectionKey]}
          iconData={riskData[sectionKey]}
          icons={getIconsForSection(riskData[sectionKey], hasMutingErrored)}
          isCarousel={sectionKey === RiskSection.webAndMediaFactors}
        />
      );
    }
  );

  return (
    <S.FrameworkContainer>
      {!!showOverallScore && carouselOverallScoreSection}
      {screeningSection}
      {webAndMediaSection}
    </S.FrameworkContainer>
  );
};
