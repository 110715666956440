import { type FC } from "react";
import { RiskFactorName, RiskLevel, RiskSection } from "api/risk";
import { ReactComponent as ESGSvg } from "img/icons/esg-icon.svg";
import { ReactComponent as RiskFlag } from "img/icons/flag-icon.svg";
import { ReactComponent as MajorCrimesSvg } from "img/icons/law-icon.svg";
import { ReactComponent as FinCrimeRiskFlag } from "img/icons/fin-crime.svg";
import { ReactComponent as SanctionsSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as LocationRiskSvg } from "img/icons/location-risk.svg";
import { ReactComponent as RelatedPartiesSvg } from "img/icons/related-parties.svg";
import { ReactComponent as PepsSvg, ReactComponent as StateOwnedSvg } from "img/icons/state-owned.svg";
import { ReactComponent as SpecialInterestSvg } from "img/icons/special-interest-icon.svg";
import { ReactComponent as RCAPepsSvg } from "themes/projectdx/img/icons/rca-peps-icon.svg";
import { ReactComponent as RCASanctionSvg } from "themes/projectdx/img/icons/rca-sanctions-icon.svg";
import { ReactComponent as RCAWatchlistSvg } from "themes/projectdx/img/icons/rca-watchlists-icon.svg";
import { ReactComponent as RCASpecialInterestSvg } from "themes/projectdx/img/icons/rca-specialinterest-icon.svg";
import highRiskAnimation from "animations/overallRiskScore/highRiskLoader.json";
import lowRiskAnimation from "animations/overallRiskScore/lowRiskLoader.json";
import { grey, red } from "styles/colors";
export { hasRiskIconsInRange } from "./commonUtils";
export const makePlural = (word: string, count: number, suffix: string = "s") => `${word}${count > 1 ? suffix : ""}`;
export const OVERALL_RISK_CONFIG = {
  [RiskLevel.HighRiskLevel]: {
    color: red.directRiskFill,
    label: "High",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumHighRiskLevel]: {
    color: red.directRiskFill,
    label: "Medium high",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumRiskLevel]: {
    color: red.directRiskFill,
    label: "Medium",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumLowRiskLevel]: {
    color: grey.darkish,
    label: "Medium low",
    animationData: lowRiskAnimation
  },
  [RiskLevel.LowRiskLevel]: {
    color: grey.darkish,
    label: "Low",
    animationData: lowRiskAnimation
  },
  [RiskLevel.NoneRiskLevel]: {
    color: grey.darkish,
    label: "Not identified",
    animationData: lowRiskAnimation
  }
};
export const RISK_LEVEL_CONFIG = {
  [RiskLevel.HighRiskLevel]: {
    title: "High overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumHighRiskLevel]: {
    title: "Medium high overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumRiskLevel]: {
    title: "Medium overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumLowRiskLevel]: {
    title: "Medium low overall risk",
    color: grey.darkish
  },
  [RiskLevel.LowRiskLevel]: {
    title: "Low overall risk",
    color: grey.darkish
  },
  [RiskLevel.NoneRiskLevel]: {
    title: "Not identified",
    color: grey.darkish
  }
};
export const getCardTitle = (type: RiskFactorName, numOfSummaries: number) => {
  const isPlural = numOfSummaries > 1;
  const pluralSuffix = isPlural ? "s" : "";
  switch (type) {
    case RiskFactorName.WatchlistsRiskFactorName:
    case RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName:
      return `${numOfSummaries} Confirmed watchlist${pluralSuffix}`;
    case RiskFactorName.SanctionsRiskFactorName:
    case RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName:
      return `${numOfSummaries} Confirmed sanction${pluralSuffix}`;
    case RiskFactorName.StateOwnershipRiskFactorName:
      return `${isPlural ? `${numOfSummaries} ` : ""} State ownership ${isPlural ? "vehicles " : ""} confirmed`;
    case RiskFactorName.PEPsRiskFactorName:
    case RiskFactorName.IntegrityCheckPEPsRCARiskFactorName:
      return "PEPs";
    case RiskFactorName.FinancialCrimeRiskFactorName:
      return "Financial Crime";
    case RiskFactorName.LegalAndComplianceRiskFactorName:
      return "Legal & Compliance";
    case RiskFactorName.ProceedingsRiskFactorName:
      return "Proceedings";
    case RiskFactorName.ESGRiskFactorName:
      return "ESG";
    case RiskFactorName.OtherRiskFactorName:
      return "Other";
    case RiskFactorName.IntegrityCheckSpecialInterestRiskFactorName:
    case RiskFactorName.IntegrityCheckSpecialInterestRCARiskFactorName:
      return "Special Interest";
    default:
      return "";
  }
};
export const mapBucketToRiskLevel = (bucket: string) => {
  switch (bucket.toLocaleLowerCase()) {
    case "high":
      return RiskLevel.HighRiskLevel;
    case "medium_high":
      return RiskLevel.MediumHighRiskLevel;
    case "medium":
      return RiskLevel.MediumRiskLevel;
    case "medium_low":
      return RiskLevel.MediumLowRiskLevel;
    case "low":
      return RiskLevel.LowRiskLevel;
    default:
      return RiskLevel.NoneRiskLevel;
  }
};
export const SECTION_NAME_MAP: Record<RiskSection, string> = {
  [RiskSection.overallScore]: "Overall risk",
  [RiskSection.screeningFactors]: "Screening risk",
  [RiskSection.webAndMediaFactors]: "Web and media risk",
  [RiskSection.keyDetailsFactors]: "Key details factors"
};
const secondTextSuffix = "where there was insufficient information to confirm or discard them as your subject.";
export const RISK_REVIEW_CONFIG = {
  [RiskFactorName.SanctionsRiskFactorName]: {
    label: "sanction",
    description: "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities.",
    firstText: "We have **not** confirmed your subject as sanctioned.",
    getSecondText: (count: number) => `However, we identified **${count} ${makePlural("subject", count)} with a similar name on sanction lists**, ${secondTextSuffix}`,
    getSubjectText: (count: number) => `${count} ${makePlural("subject", count)} with a similar name on sanction lists`
  },
  [RiskFactorName.WatchlistsRiskFactorName]: {
    label: "watchlist",
    firstText: "We have **not** found your subject on a watchlist.",
    getSecondText: (count: number) => `However, we identified **${count} ${makePlural("subject", count)} with a similar name on watchlists**, ${secondTextSuffix}`,
    getSubjectText: (count: number) => `${count} ${makePlural("subject", count)} with a similar name on watchlists`
  },
  [RiskFactorName.PEPsRiskFactorName]: {
    label: "PEP",
    firstText: "We have **not** confirmed your subject as a PEP.",
    getSecondText: (count: number) => `However, we identified **${count} ${makePlural("subject", count)} with a similar name who are PEPs**, ${secondTextSuffix}`,
    getSubjectText: (count: number) => `${count} ${makePlural("subject", count)} with a similar name who are PEPs`
  }
};
export const RISK_FACTOR_CONFIG: Record<string, {
  activeTooltip: string;
  description: string;
  getConfirmedTitle?: (count: number) => string;
  inactiveTooltip: string;
  key: string;
  order?: number;
  showOnSummary: boolean;
  svg: FC;
  title: string;
}> = {
  business_sector: {
    activeTooltip: "Business sector flagged for review",
    description: "TBD",
    inactiveTooltip: "Business sector",
    key: "business_sector",
    showOnSummary: false,
    svg: RiskFlag,
    title: "Business sector"
  },
  country_of_incorporation: {
    activeTooltip: "Country of incorporation flagged for review",
    description: "TBD",
    inactiveTooltip: "Country of incorporation",
    key: "country_of_incorporation",
    showOnSummary: false,
    svg: RiskFlag,
    title: "Country of incorporation"
  },
  esg: {
    activeTooltip: "ESG risk flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "ESG",
    key: "esg",
    order: 7,
    showOnSummary: true,
    svg: ESGSvg,
    title: "ESG"
  },
  financial_crime: {
    activeTooltip: "Financial crime risk flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "Financial Crime",
    key: "financial_crime",
    order: 3,
    showOnSummary: true,
    svg: FinCrimeRiskFlag,
    title: "Financial crime"
  },
  incorporation_date: {
    activeTooltip: "Incorporation date flagged for review",
    description: "TBD",
    inactiveTooltip: "Incorporation date",
    key: "incorporation_date",
    showOnSummary: false,
    svg: RiskFlag,
    title: "Incorporation date"
  },
  integrity_check_peps_rca: {
    activeTooltip: "We have identified your subject as a PEP",
    description: "A Politically Exposed Person (PEP) is an individual entrusted with a prominent public function (or their immediate family).",
    inactiveTooltip: "PEPs",
    key: "integrity_check_peps_rca",
    showOnSummary: false,
    svg: RCAPepsSvg,
    title: "PEPs"
  },
  integrity_check_sanctions_rca: {
    activeTooltip: "We have identified the subject as sanctioned",
    description: "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities.",
    inactiveTooltip: "Sanctions",
    key: "integrity_check_sanctions_rca",
    showOnSummary: false,
    svg: RCASanctionSvg,
    title: "Sanctions"
  },
  integrity_check_special_interest: {
    activeTooltip: "We have identified your subject as special interest",
    description: "A Special Interest Person encompasses individuals implicated in specific types of criminal activity.",
    inactiveTooltip: "Special interest",
    key: "integrity_check_special_interest",
    showOnSummary: false,
    svg: SpecialInterestSvg,
    title: "Special interest"
  },
  integrity_check_special_interest_rca: {
    activeTooltip: "We have identified your subject as special interest",
    description: "A Special Interest Person encompasses individuals implicated in specific types of criminal activity.",
    inactiveTooltip: "Special interest",
    key: "integrity_check_special_interest_rca",
    showOnSummary: false,
    svg: RCASpecialInterestSvg,
    title: "Special interest RCA"
  },
  integrity_check_watchlists_rca: {
    activeTooltip: "We have identified the subject on a watchlist",
    description: "Watchlists are databases of individuals known or reasonably suspected of being involved in fraudulent or illegal activities.",
    inactiveTooltip: "Watchlists",
    key: "integrity_check_watchlists_rca",
    showOnSummary: true,
    svg: RCAWatchlistSvg,
    title: "Watchlists"
  },
  legal_and_compliance: {
    activeTooltip: "Legal & compliance risk flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "Legal & Compliance",
    key: "legal_and_compliance",
    order: 4,
    showOnSummary: true,
    svg: MajorCrimesSvg,
    title: "Legal & compliance"
  },
  locations: {
    activeTooltip: "High risk locations risk flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "Locations",
    key: "locations",
    order: 5,
    showOnSummary: true,
    svg: LocationRiskSvg,
    title: "Locations"
  },
  other: {
    activeTooltip: "Other controversy flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "Other controversy",
    key: "other",
    order: 9,
    showOnSummary: true,
    svg: RiskFlag,
    title: "Other controversy"
  },
  peps: {
    activeTooltip: "We have identified your subject as a PEP",
    description: "A Politically Exposed Person (PEP) is an individual entrusted with a prominent public function (or their immediate family).",
    getConfirmedTitle: (count: number) => `${count} confirmed ${makePlural("PEP", count)}`,
    inactiveTooltip: "PEPs",
    key: "peps",
    showOnSummary: true,
    svg: PepsSvg,
    title: "PEPs"
  },
  proceedings: {
    activeTooltip: "Proceedings flagged for review",
    description: "The risk level for this factor has been predefined for your organisation.",
    inactiveTooltip: "Proceedings",
    key: "proceedings",
    order: 8,
    showOnSummary: true,
    svg: MajorCrimesSvg,
    title: "Proceedings"
  },
  rca: {
    activeTooltip: "RCA flagged for review",
    description: "TBD",
    inactiveTooltip: "RCA",
    key: "rca",
    showOnSummary: true,
    svg: RiskFlag,
    title: "RCA"
  },
  related_parties: {
    activeTooltip: "Related Parties risk flagged for review",
    description: "The risk level is determined by the highest level of risk identified.",
    inactiveTooltip: "Related parties",
    key: "related_parties",
    order: 6,
    showOnSummary: true,
    svg: RelatedPartiesSvg,
    title: "Related parties"
  },
  sanctions: {
    activeTooltip: "We have identified the subject as sanctioned",
    description: "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities.",
    getConfirmedTitle: (count: number) => `${count} confirmed ${makePlural("sanction", count)}`,
    inactiveTooltip: "Sanctions",
    key: "sanctions",
    order: 0,
    showOnSummary: true,
    svg: SanctionsSvg,
    title: "Sanctions"
  },
  state_ownership: {
    activeTooltip: "We have identified the subject as state owned",
    description: "Related organisations where a government or state holds significant control.",
    getConfirmedTitle: (count: number) => `${count} confirmed ${makePlural("state ownership", count)}`,
    inactiveTooltip: "State Ownership",
    key: "state_ownership",
    order: 2,
    showOnSummary: true,
    svg: StateOwnedSvg,
    title: "State ownership"
  },
  watchlists: {
    activeTooltip: "We have identified the subject on a watchlist",
    description: "Watchlists are databases of organisation's known or reasonably suspected of being involved in fraudulent or illegal activities.",
    getConfirmedTitle: (count: number) => `${count} confirmed ${makePlural("watchlist", count)}`,
    inactiveTooltip: "Watchlists",
    key: "watchlists",
    order: 1,
    showOnSummary: true,
    svg: WatchlistsSvg,
    title: "Watchlists"
  }
};