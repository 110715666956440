/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetOverviewResponse } from "../models/GetOverviewResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class RiskOverviewService {
  /**
   * Get Risk Overview
   * Used to fetch an overview of risk data for a report.
   * @returns GetOverviewResponse Success
   * @throws ApiError
   */
  public static getV1ReportsOverview({
    reportId
  }: {
    /**
     * Report ID
     */
    reportId: string;
  }): CancelablePromise<GetOverviewResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/reports/{reportId}/overview",
      path: {
        reportId: reportId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Risk factors not found`,
        500: `Internal server error`
      }
    });
  }
}
