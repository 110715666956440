import React, { FormEvent, useState } from "react";
import { Lock, Check } from "react-feather";

import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import TitleCard from "components/molecules/TitleCard";
import {
  PasswordState,
  getPasswordState,
  getInvalidPasswordReason
} from "util/passwordValidation";

import {
  useRegistration,
  RegistrationActions
} from "util/hooks/useRegistration";

import { RegistrationStatus } from "util/hooks/useRegistration/types";

import S from "./styles";

const PasswordFields = () => {
  const [passwordState, setPasswordState] = useState<PasswordState>(
    PasswordState.invalid
  );
  const { state, dispatch } = useRegistration();

  const onChange = (field: string, value: string) => {
    setPasswordState(
      getPasswordState({
        field: field === "password" ? field : "passwordConfirmation",
        value,
        state: {
          password: state.password,
          passwordConfirmation: state.confirmationPassword
        }
      })
    );

    dispatch({ type: RegistrationActions.updateField, field, value });
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch({ type: RegistrationActions.registrationSubmitted });
  };

  const onBack = (event: FormEvent) => {
    event.preventDefault();
    dispatch({ type: RegistrationActions.editFields });
  };

  const onTermsAcceptedChange = () => {
    dispatch({
      type: RegistrationActions.updateTermsAccepted,
      value: !state.termsAccepted
    });
  };

  const canSubmit =
    state.termsAccepted &&
    passwordState === PasswordState.valid &&
    !!state.password &&
    !!state.confirmationPassword;

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        <TitleCard
          title={`${state.firstName} ${state.lastName}`}
          subtitle={state.email}
          rightIcon={<Lock />}
        />

        <S.Description>
          <S.Title level={6}>Choose your password</S.Title>
        </S.Description>

        <S.Subtitle>
          It must be at least 10 characters and contain at least one of each of
          the following: capital letter, lower case letter, number and symbol.
        </S.Subtitle>

        <S.Inputs>
          <Input
            inputType="password"
            placeholder="Choose your password"
            onChange={(password: string) => onChange("password", password)}
            value={state.password}
            autoComplete="new-password"
          />
          <Input
            inputType="password"
            placeholder="Confirm your password"
            onChange={(confirmationPassword: string) =>
              onChange("confirmationPassword", confirmationPassword)
            }
            value={state.confirmationPassword}
            autoComplete="new-password"
          />
        </S.Inputs>

        {passwordState === PasswordState.valid ? (
          <S.PasswordValid>
            Password strength: Strong <Check />
          </S.PasswordValid>
        ) : (
          <S.PasswordInvalid>
            {getInvalidPasswordReason(passwordState)}
          </S.PasswordInvalid>
        )}

        <S.Divider />

        <S.CheckboxLabel>
          <input type="checkbox" onChange={onTermsAcceptedChange} />
          <span>
            I have read and accept the{" "}
            <S.Link
              href="https://xapien.com/wp-content/uploads/2023/07/Xapien-User-Agreement.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </S.Link>
          </span>
        </S.CheckboxLabel>

        <S.Subtitle>
          We may send you occasional relevant product update and marketing
          emails. You may opt out of these at any time.
        </S.Subtitle>

        <S.ButtonsContainer>
          <ButtonNew
            onClick={onBack}
            type={ButtonType.Outlined}
            size={ButtonSize.Medium}
            text="Back"
          />
          <ButtonNew
            onClick={onSubmit}
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            text={
              state.status === RegistrationStatus.registrationSubmitted
                ? "Submitting..."
                : "Go"
            }
            disabled={!canSubmit}
          />
        </S.ButtonsContainer>
      </S.Container>
    </form>
  );
};

export default PasswordFields;
