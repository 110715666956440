import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { apm } from "@elastic/apm-rum";
import { useParams } from "react-router-dom";
import {
  OverviewResponse,
  RiskFactorName,
  fetchOverallScore,
  fetchRiskOverview,
  updateRiskIconMuteConfig
} from "api/risk";
import RiskServiceContext from "./context";
import { RiskServiceContextValue, RiskServiceStatus } from "./types";
import {
  updateRiskServiceDataForMuting,
  updateRiskServiceDataForScoreChange
} from "./utils";

interface RiskServiceProviderProps {
  children: ReactNode;
}

const initialRiskData: OverviewResponse = {
  overallScore: {
    bucket: "none",
    score: 0,
    summary: []
  },
  keyDetailsFactors: [],
  screeningFactors: [],
  webAndMediaFactors: []
};

export const RiskServiceProvider = ({ children }: RiskServiceProviderProps) => {
  const [status, setStatus] = useState(RiskServiceStatus.NotLoaded);
  const [riskServiceData, setRiskServiceData] =
    useState<OverviewResponse>(initialRiskData);

  const { enquiryId: reportId } = useParams();
  const { overallScore: overallScoreData, ...riskData } = riskServiceData;

  const loadOverview = useCallback(() => {
    if (!reportId) return;

    setStatus(RiskServiceStatus.Loading);

    fetchRiskOverview(reportId)
      .then(({ status: responseStatus, response }) => {
        if (responseStatus && !!response) {
          setRiskServiceData(response);
          setStatus(RiskServiceStatus.Loaded);
        } else {
          setStatus(RiskServiceStatus.Error);
        }
      })
      .catch(err => {
        apm.captureError(err);
        console.error("Unable to load Risk Service Data", err);
        setStatus(RiskServiceStatus.Error);
      });
  }, [reportId]);

  useEffect(() => {
    loadOverview();
  }, [loadOverview]);

  const updateRiskByTopic = useCallback(
    (name: RiskFactorName, mute: boolean) => {
      if (!reportId) return;

      setStatus(RiskServiceStatus.MutingRiskIcon);

      updateRiskIconMuteConfig(reportId, name, mute)
        .then(result => {
          if (!result.status) {
            throw new Error(result.message);
          }
          setRiskServiceData(updateRiskServiceDataForMuting(name, mute));
          setStatus(RiskServiceStatus.LoadingOverallScore);

          return new Promise(resolve => {
            setTimeout(resolve, 1300);
          });
        })
        .then(() => fetchOverallScore(reportId))
        .then(result => {
          if (!result.status) {
            throw new Error(result.message);
          }
          if (result.response) {
            setRiskServiceData(
              updateRiskServiceDataForScoreChange(result.response)
            );
          }
          setStatus(RiskServiceStatus.Loaded);
        })
        .catch(err => {
          apm.captureError(err);
          console.error("Unable to mute a Risk Icon", err);
          setStatus(RiskServiceStatus.MutingRiskError);
        });
    },
    [reportId]
  );

  const getRiskDataByFactorName = useCallback(
    (factorName: RiskFactorName) => {
      return [
        ...riskData.screeningFactors,
        ...riskData.webAndMediaFactors
      ].find(data => {
        return data.name === factorName;
      });
    },
    [riskData]
  );

  const value: RiskServiceContextValue = useMemo(
    () => ({
      status,
      riskData,
      overallScoreData,
      getRiskDataByFactorName,
      onMute: updateRiskByTopic,
      reload: loadOverview
    }),
    [
      status,
      riskData,
      overallScoreData,
      updateRiskByTopic,
      getRiskDataByFactorName,
      loadOverview
    ]
  );

  return (
    <RiskServiceContext.Provider value={value}>
      {children}
    </RiskServiceContext.Provider>
  );
};
