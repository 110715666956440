import React, { MouseEventHandler } from "react";

import config from "config";
import { RiskFactorName } from "api/risk-service";
import useRiskService from "util/hooks/useRiskService";
import { ReactComponent as ArrowCW } from "img/arrow-cw.svg";
import {
  MuteRiskButton,
  MuteRiskButtonType
} from "components/molecules/MuteRiskButton";

import S from "./styles";

interface SnagCardProps {
  factor: RiskFactorName;
  isMuted: boolean;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const MuteRiskSnagCard = ({
  factor,
  isMuted,
  onMouseEnter,
  onMouseLeave
}: SnagCardProps) => {
  const { onMute } = useRiskService();

  return (
    <S.Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.CardBody>
        <S.IconContainer>
          <ArrowCW />
        </S.IconContainer>
        <S.ColumnContainer>
          <S.Title>We hit a snag</S.Title>
          <S.Text>
            We could not update the risk status.{" "}
            <span>Please try again by clicking below.</span> If the issue
            persists, please contact{" "}
            <a href={`mailto:${config.supportEmail}`}>Customer Success</a>
          </S.Text>
          <MuteRiskButton
            type={isMuted ? MuteRiskButtonType.plus : MuteRiskButtonType.minus}
            onClick={() => onMute(factor, !isMuted)}
          />
        </S.ColumnContainer>
      </S.CardBody>
    </S.Card>
  );
};
