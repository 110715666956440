import { FetchResult } from "api/types";
import dayjs from "dayjs";
import { apm } from "@elastic/apm-rum";
import {
  CollectionListState,
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";
import { getErrorMessage } from "api/util";
import {
  Idam_Contracts_Enums_GroupUserRole,
  Idam_Contracts_Enums_OrganisationRole,
  Idam_Contracts_Enums_UsagePeriod,
  Idam_Contracts_Organisations_RequestInviteRequest,
  Idam_Contracts_Organisations_UpdateOrganisationPreferencesRequest,
  IdentityOrganisationApiService
} from "api/idam";
import {
  Organisation,
  OrganisationDetails,
  OrganisationPreferences,
  OrganisationUsage,
  OrganisationUser,
  Project
} from "./types";

export type { Organisation } from "./types";

export default class Organisations {
  async getUserOrganisationDetails(): Promise<OrganisationDetails> {
    try {
      const result = await IdentityOrganisationApiService.getOrganisations();
      return {
        organisationId: result.organisationId!,
        name: result.name!,
        description: result.description ?? ""
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { organisationId: "", name: "", description: "" };
    }
  }

  async setUserOrganisationDetails({
    description
  }: {
    description: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = { description };

      await IdentityOrganisationApiService.putOrganisations({
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async downloadUsage(): Promise<FetchResult<string>> {
    try {
      const requestBody = {
        usagePeriod:
          Idam_Contracts_Enums_UsagePeriod.REPORTS_RUN_SINCE_ORGANISATION_CREATION
      };
      const response =
        await IdentityOrganisationApiService.getOrganisationsUsageExport(
          requestBody
        );

      if (response && response.url) {
        return { status: true, response: response.url };
      }

      return { status: false };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getOrganisationUsage(): Promise<FetchResult<OrganisationUsage>> {
    try {
      const response =
        await IdentityOrganisationApiService.getOrganisationsUsage();
      const result = {
        reportsRunPastYear: response.reportsRunPastYear!,
        reportsRunPast30Days: response.reportsRunPast30Days!,
        reportsRunPast7Days: response.reportsRunPast7Days!,
        reportsRunSinceOrgCreated: response.reportsRunSinceOrganisationCreation!
      };

      Object.entries(result).forEach(([key, value]) => {
        if (value === undefined || value === null) {
          throw new Error(`Usage field was null/missing: ${key}`);
        }
      });

      return {
        status: true,
        response: result
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e),
        response: {
          reportsRunPastYear: 0,
          reportsRunPast30Days: 0,
          reportsRunPast7Days: 0,
          reportsRunSinceOrgCreated: 0
        }
      };
    }
  }

  async getOrganisationUsers(): Promise<OrganisationUser[]> {
    try {
      const response =
        await IdentityOrganisationApiService.getOrganisationsUsers();

      return response.map(user => ({
        userId: user.userId ?? "",
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        email: user.email ?? "",
        jobTitle: user.jobTitle ?? "",
        role: user.role ?? Idam_Contracts_Enums_OrganisationRole.USER,
        groups:
          user.groups?.map(group => ({
            groupId: group.groupId ?? "",
            name: group.name ?? "",
            role: group.role ?? Idam_Contracts_Enums_GroupUserRole.STANDARD
          })) ?? [],
        permissions: user.permissions ?? {}
      }));
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return [];
    }
  }

  async sendAccountRequest(
    reportId: string,
    email: string,
    name: string
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_RequestInviteRequest = {
        reportId,
        email,
        name
      };

      await IdentityOrganisationApiService.postOrganisationsInviteRequest({
        requestBody
      });

      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrganisationPreferences(): Promise<
    FetchResult<OrganisationPreferences>
  > {
    try {
      const response =
        await IdentityOrganisationApiService.getOrganisationsPreferences();

      /*

      TODO:
        * Customer success assistance

      */
      return {
        status: true,
        response: {
          letUsersOnboardOtherUsers:
            response.letUsersOnboardOtherUsers ?? false,
          projectReferencesEnabled: response.projectReferencesEnabled ?? false,
          mfaEnabled: response.mfaEnabled ?? false
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async setOrganisationPreferences(
    preferences: OrganisationPreferences
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_UpdateOrganisationPreferencesRequest =
        {
          letUsersOnboardOtherUsers: preferences.letUsersOnboardOtherUsers,
          projectReferencesEnabled: preferences.projectReferencesEnabled,
          mfaEnabled: preferences.mfaEnabled
        };
      await IdentityOrganisationApiService.putOrganisationsPreferences({
        requestBody
      });

      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrgUserDetails(
    userId: string
  ): Promise<FetchResult<OrganisationUser>> {
    try {
      const response =
        await IdentityOrganisationApiService.getOrganisationsUsers1({ userId });

      return {
        status: true,
        response: {
          userId: response.userId!,
          firstName: response.firstName ?? "",
          lastName: response.lastName ?? "",
          email: response.email ?? "",
          jobTitle: response.jobTitle ?? "",
          role: response.role
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getProjectReferences(): Promise<FetchResult<Project[]>> {
    try {
      const response =
        await IdentityOrganisationApiService.getOrganisationsProjects();

      return {
        status: true,
        response: response.map(project => ({
          projectId: project.projectId!,
          reference: project.reference!,
          description: project.description ?? ""
        }))
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: "organisations",
          title: "Organisations",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.list,
          totalItemCount: 0,
          pollingEnabled: true,
          items: [],
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: true,
          input: {
            type: CollectionInputType.list
          }
        }
      ]
    };
  }

  async list({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    limit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offset
  }: {
    id: string;
    limit: number;
    offset: number;
  }): Promise<{ items: Organisation[]; totalItemCount: number }> {
    try {
      const items = Array.from({ length: COLLECTION_ITEMS_PER_PAGE }).map(
        (_, i) => ({
          organisationId: `law-firm-${i}`,
          id: `law-firm-${i}`,
          icon: "",
          tags: [],
          permissions: { canView: true, canEdit: true, canDelete: true },
          title: `Law firm ${i + 1}`,
          name: `Law firm ${i + 1}`,
          description: "Midwest corporate law firm",
          renewalDate: dayjs()
            .add(30 + i, "days")
            .toISOString(),
          usage: {
            reportsRunPast7Days: 2 + i,
            reportsRunPast30Days: 22 + i,
            reportsRunPastYear: 222 + i,
            reportsRunSinceOrgCreated: 2222 + i
          }
        })
      );

      return {
        items,
        totalItemCount: items.length
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }
}
