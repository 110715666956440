/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchReportResultSearchResponse } from "../models/SearchReportResultSearchResponse";
import type { SearchRequest } from "../models/SearchRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class GroupsService {
  /**
   * @returns SearchReportResultSearchResponse OK
   * @throws ApiError
   */
  public static postOrganisationsGroupsReportsSearch({
    groupId,
    requestBody
  }: {
    groupId: string;
    requestBody?: SearchRequest;
  }): CancelablePromise<SearchReportResultSearchResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/groups/{groupId}/reports/search",
      path: {
        groupId: groupId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }
}
