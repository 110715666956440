import React, { type ReactNode } from "react";
import type { CardVariant } from "components/molecules/Card/types";

export enum ChipSize {
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE"
}

export enum ChipVariant {
  Outlined = "OUTLINED",
  OutlinedLight = "OUTLINED_LIGHT",
  FilledDark = "FILLED_DARK",
  FilledMid = "FILLED_MID",
  FilledLight = "FILLED_LIGHT",
  FilledRed = "FILLED_RED",
  FilledOrange = "FILLED_ORANGE"
}

export interface Props {
  size: ChipSize;
  onDelete?: React.MouseEventHandler<SVGElement>;
  label: ReactNode;
  variant: ChipVariant;
  colorVariant?: CardVariant;
}
