/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from "../models/JsonNode";
import type { SearchReportResultSearchResponse } from "../models/SearchReportResultSearchResponse";
import type { SearchRequest } from "../models/SearchRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ReportService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteReports({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns JsonNode OK
   * @throws ApiError
   */
  public static getReportsNotes({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<Record<string, JsonNode>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/notes",
      path: {
        reportId: reportId
      }
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static putReportsNotes({
    reportId,
    requestBody
  }: {
    reportId: string;
    requestBody?: Record<string, JsonNode>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/notes",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns JsonNode OK
   * @throws ApiError
   */
  public static getReportsNotes1({
    reportId,
    shareToken
  }: {
    reportId: string;
    shareToken: string;
  }): CancelablePromise<Record<string, JsonNode>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/{shareToken}/notes",
      path: {
        reportId: reportId,
        shareToken: shareToken
      }
    });
  }

  /**
   * @returns SearchReportResultSearchResponse OK
   * @throws ApiError
   */
  public static postReportsSearch({
    requestBody
  }: {
    requestBody?: SearchRequest;
  }): CancelablePromise<SearchReportResultSearchResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/search",
      body: requestBody,
      mediaType: "application/json"
    });
  }
}
