import React, { FC, FormEvent, MouseEventHandler, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Input from "components/atoms/Input";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import { isPDX } from "static-config";

import {
  useAuthentication,
  AuthenticationActions
} from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import LoginHeader from "components/molecules/LoginHeader";
import S from "./styles";

interface Props {
  onForgottenPasswordClick: () => void;
}

const LoginForm: FC<Props> = ({ onForgottenPasswordClick }) => {
  const { state, dispatch } = useAuthentication();
  const [loading, setLoading] = useState(false);

  const inLoadingState =
    state.status === AuthenticationStatus.authenticating ||
    state.status === AuthenticationStatus.fetchingLoginInfo;

  const onEmailChange = (email: string) =>
    dispatch({ type: AuthenticationActions.updateEmail, email });

  const onPasswordChange = (password: string) =>
    dispatch({ type: AuthenticationActions.updatePassword, password });

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!state.email && !state.email.trim().length) {
      setLoading(false);

      dispatch({
        type: AuthenticationActions.unauthenticated,
        error: "Please enter your email address."
      });
    }

    if (
      state.status === AuthenticationStatus.passwordRequired &&
      !state.password &&
      !state.password.trim().length
    ) {
      setLoading(false);

      dispatch({
        type: AuthenticationActions.unauthenticated,
        error: "Please enter your password."
      });
    }

    setLoading(true);

    dispatch({ type: AuthenticationActions.verify });
    setLoading(false);
  };

  const onDismissError: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch({ type: AuthenticationActions.dismissError });
  };

  const showPasswordField =
    !!state.password || state.status === AuthenticationStatus.passwordRequired;

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        <LoginHeader subtitle="Please sign in" />

        <S.Inputs isPDX={isPDX}>
          {state.error && (
            <ErrorBanner text={state.error} onClick={onDismissError} />
          )}

          <Input
            inputType="email"
            placeholder="Email"
            onChange={onEmailChange}
            value={state.email}
            autoComplete="email"
          />
          <AnimatePresence>
            {showPasswordField && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <Input
                  inputType="password"
                  placeholder="Password"
                  onChange={onPasswordChange}
                  value={state.password}
                  autoComplete="current-password"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </S.Inputs>
        <S.Link onClick={onForgottenPasswordClick}>
          Forgotten your password?
        </S.Link>

        <S.SignInButton
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={inLoadingState}
          text={loading || inLoadingState ? "Signing in" : "Go"}
        />
      </S.Container>
    </form>
  );
};

export default LoginForm;
