import React, { Fragment, MouseEventHandler } from "react";

import { FormattedText } from "components/atoms/FormattedText";
import { ListCardEntry } from "components/atoms/ListCardEntry";
import { ScoreCardEntry } from "components/atoms/ScoreCardEntry";
import { RiskIconCard } from "components/molecules/RiskIconCard";
import { SourceCardEntry } from "components/atoms/SourceCardEntry";
import { RiskFactorName, RiskSummary, RiskSummaryType } from "api/risk";

interface RiskSummaryCardProps {
  name: RiskFactorName;
  summaries: RiskSummary[];
  unconfirmedSourceCount: number;
  isMuted?: boolean;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

/**
 * This card's sole responsibility is to show risk summary related
 * cards, meaning this doesn't include the overall score card
 */
export const RiskSummaryCard = ({
  name,
  summaries,
  unconfirmedSourceCount,
  isMuted = false,
  onMouseEnter,
  onMouseLeave
}: RiskSummaryCardProps) => {
  const isAiGenerated = !!summaries.find(x => x.isAiGenerated);

  return (
    <RiskIconCard
      factor={name}
      unconfirmedSourceCount={unconfirmedSourceCount}
      isAiGenerated={isAiGenerated}
      isMuted={isMuted}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {summaries.map((summary, index) => {
        const {
          type,
          title: cardTitle,
          subtitle,
          content,
          countryCode,
          values
        } = summary;
        const key = `${type}-${cardTitle}-${index}`;

        if (type === RiskSummaryType.SourceRiskSummaryType) {
          return (
            <SourceCardEntry
              key={key}
              title={cardTitle ?? ""}
              subtitle={subtitle ?? ""}
              content={content}
            />
          );
        }

        if (type === RiskSummaryType.ScoreRiskSummaryType) {
          return (
            <ScoreCardEntry
              key={key}
              title={cardTitle ?? ""}
              subtitle={subtitle ?? ""}
              countryCode={countryCode ?? ""}
              content={content}
            />
          );
        }

        if (
          type === RiskSummaryType.ListRiskSummaryType ||
          type === RiskSummaryType.BulletedListRiskSummaryType
        ) {
          return (
            <ListCardEntry
              key={key}
              title={cardTitle ?? ""}
              subtitle={subtitle ?? ""}
              countryCode={countryCode ?? ""}
              values={values ?? []}
              bulleted={type === RiskSummaryType.BulletedListRiskSummaryType}
            />
          );
        }

        return (
          <Fragment key={key}>
            {summary.title && <FormattedText text={summary.title} />}
            {summary.subtitle && <FormattedText text={summary.subtitle} />}
            {summary.content && <FormattedText text={summary.content} />}
          </Fragment>
        );
      })}
    </RiskIconCard>
  );
};
