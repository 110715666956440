import { useContext } from "react";
import RiskSummariesContext from "./context";

const useRiskService = () => {
  const context = useContext(RiskSummariesContext);
  if (!context) {
    throw new Error("Can't use risk service hook outside of the provider");
  }

  return context;
};

export default useRiskService;
