import { grey } from "styles/colors";
import { styled } from "@linaria/react";
import { family, fontSize } from "styles/fonts";

const SectionContainer = styled.div<{ isCarousel?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ isCarousel }) => (isCarousel ? "1 1 auto" : "0 0 auto")};
  min-width: 0px;
  background: white;
  padding: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  margin: 4px;
  font-weight: bold;
  color: ${grey.mid};
  align-items: center;
  font-size: ${fontSize.sm};
  justify-content: space-between;
  font-family: ${family.interDisplay};
`;

const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FixedIconContainer = styled.div<{ showMultipleIcons?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 auto;
  gap: ${({ showMultipleIcons }) => (showMultipleIcons ? 16 : 0)}px;
  justify-content: ${({ showMultipleIcons }) =>
    showMultipleIcons ? "space-between" : "center"};
`;

const Icon = styled.div`
  font-size: 2rem; /* Icon size */
  padding: 12px 0;
`;

const S = {
  Icon,
  Title,
  HeaderContainer,
  FixedIconContainer,
  SectionContainer
};

export default S;
