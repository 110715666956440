import React, { useEffect, useState, forwardRef, ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { SectionIcon as SectionIconType } from "api/insights";

import {
  shouldShowTimeline,
  useInsightsTimeline
} from "util/hooks/useInsightsTimeline";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";

import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import useViewerMode from "util/hooks/useViewerMode";

import { isPDX } from "static-config";

import DisabledInsightsModal from "components/molecules/DisabledInsightsModal";
import useObservability from "util/hooks/useObservability";
import useIsMobile from "util/hooks/useIsMobile";

import S from "./styles";
import AddNewSectionButton from "./AddNewSectionButton";
import SectionIcon from "./SectionIcon";

const TIMELINE_SLUG = "timeline";

const MenuItemContent = ({
  status,
  id,
  removedSectionId,
  removedSubsectionId,
  removedElementId,
  children
}: {
  status: InsightReportStatus;
  id: string;
  removedSectionId?: string;
  removedSubsectionId?: string;
  removedElementId?: string;
  children: ReactNode;
}) => {
  const [hasBeenRemoved, setHasBeenRemoved] = useState(false);

  useEffect(() => {
    if (
      id === removedSectionId &&
      !removedSubsectionId &&
      !removedElementId &&
      status === InsightReportStatus.removingReportSectionSuccess
    ) {
      setTimeout(() => {
        setHasBeenRemoved(true);
      }, 1600);
    }
  }, [status, removedSectionId, removedSubsectionId, removedElementId, id]);

  return (
    <AnimatePresence initial={false}>
      {!hasBeenRemoved && (
        <S.AnimatedMenuItemContent
          exit={{
            opacity: 0,
            height: 0,
            paddingTop: "0",
            paddingBottom: "0",
            overflow: "hidden"
          }}
          animate={{
            opacity: 1,
            height: "44px",
            paddingTop: "13px",
            paddingBottom: "13px"
          }}
          initial={{ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }}
        >
          {children}
        </S.AnimatedMenuItemContent>
      )}
    </AnimatePresence>
  );
};

const Menu = forwardRef<HTMLUListElement>((_props, ref) => {
  const { startTrackingClick, stopTrackingClick } = useObservability();
  const { state, dispatch } = useInsightReport();
  const { isViewerModeEnabled } = useViewerMode();
  const { state: timelineState } = useInsightsTimeline();
  const isPrintMode = usePrintModeEnabled();
  const isMobile = useIsMobile();

  const showTimeline = shouldShowTimeline(timelineState, isPrintMode);
  const canAddNewSection = !isViewerModeEnabled && !isMobile;
  const addSectionButtonDisabled = isPDX;

  const [isDisabledInsightsModalOpen, setIsDisabledInsightsModalOpen] =
    useState(false);

  const onAddSection = () => {
    const event = startTrackingClick("Add section button clicked");

    dispatch({
      type: InsightReportActions.addNewSection,
      section: {
        id: uuidv4(),
        icon: SectionIconType.FILE,
        subsections: [],
        ordinal: (state.report?.sections?.length ?? 0) + 1
      }
    });

    stopTrackingClick(event);
  };

  const updateActiveSectionSlug = (slug: string) => {
    dispatch({
      type: InsightReportActions.updateActiveSectionSlug,
      slug
    });
  };

  const onMenuItemClick = (name: string, callback: () => void) => {
    const event = startTrackingClick(`${name} section clicked`);

    callback();

    stopTrackingClick(event);
  };

  const handleMouseEnter = () => {
    if (addSectionButtonDisabled) {
      setTimeout(() => {
        setIsDisabledInsightsModalOpen(true);
      }, 500);
    }
  };

  const { report, activeSectionSlug } = state;

  const firstSection = report?.sections.length ? report?.sections[0] : null;

  const sections = report?.sections.filter(
    section => section.slug !== firstSection?.slug
  );

  if (isMobile && !state.showMenu) return null;

  return (
    <motion.div
      initial={isMobile ? { left: -100 } : {}}
      animate={{ left: 0 }}
      style={isMobile ? { position: "absolute", height: "100%" } : {}}
    >
      <S.Menu ref={ref}>
        {firstSection && (
          <S.MenuItem
            key={`MenuItem-${firstSection.slug}`}
            active={firstSection.id === activeSectionSlug}
            onClick={() => {
              dispatch({ type: InsightReportActions.toggleMenu });
              onMenuItemClick(firstSection?.title ?? "Summary", () =>
                updateActiveSectionSlug(firstSection.id)
              );
            }}
          >
            <S.MenuItemContent>
              <SectionIcon iconType={firstSection.icon} />
              <span title={firstSection.title}>{firstSection.title}</span>
            </S.MenuItemContent>
          </S.MenuItem>
        )}
        {showTimeline && (
          <S.MenuItem
            active={activeSectionSlug === TIMELINE_SLUG}
            onClick={() => {
              updateActiveSectionSlug(TIMELINE_SLUG);
            }}
          >
            <S.MenuItemContent>
              <SectionIcon iconType={TIMELINE_SLUG} />
              <span title="Timeline">Media Events Timeline</span>
            </S.MenuItemContent>
          </S.MenuItem>
        )}
        {sections &&
          sections.map(({ title, icon, id }) => (
            <S.MenuItem
              key={`MenuItem-${id}`}
              active={id === activeSectionSlug}
              onClick={() => {
                dispatch({ type: InsightReportActions.toggleMenu });
                onMenuItemClick(title ?? "Unknown", () =>
                  updateActiveSectionSlug(id)
                );
              }}
            >
              <MenuItemContent
                status={state.status}
                id={id}
                removedSectionId={state.sectionIdToRemove}
                removedSubsectionId={state.subsectionIdToRemove}
                removedElementId={state.elementIdToRemove}
              >
                <SectionIcon iconType={icon} />
                <span>{title || "New section"}</span>
              </MenuItemContent>
            </S.MenuItem>
          ))}
        {canAddNewSection && <S.MenuItemDivider />}
        {addSectionButtonDisabled && isDisabledInsightsModalOpen && (
          <S.WarningPopover
            onMouseLeave={() => setIsDisabledInsightsModalOpen(false)}
          >
            <DisabledInsightsModal
              title="Adding sections"
              onClose={() => setIsDisabledInsightsModalOpen(false)}
            />
          </S.WarningPopover>
        )}
        {canAddNewSection && (
          <div onMouseEnter={handleMouseEnter}>
            <AddNewSectionButton
              disabled={addSectionButtonDisabled}
              onClick={onAddSection}
              status={state.status}
            />
          </div>
        )}
      </S.Menu>
    </motion.div>
  );
});

export default Menu;
