import { apm } from "@elastic/apm-rum";
import {
  RiskFactorsService,
  RiskOverviewService,
  OverallScore as ApiOverallScore,
  RiskFactor as ApiRiskFactor,
  RiskLevel,
  GetOverviewResponse,
  RiskFactorName,
  RiskScoresService
} from "api/risk-service";
import { FetchResult } from "api/types";
import { hasErrorCode } from "util/hasErrorCode";

export { type RiskSummary, RiskSummaryType, RiskLevel } from "api/risk-service";

export { RiskFactorName } from "api/risk-service";

export type OverviewResponse = {
  overallScore: OverallScore;
  screeningFactors: Array<RiskFactor>;
  webAndMediaFactors: Array<RiskFactor>;
  keyDetailsFactors: Array<RiskFactor>;
};

export enum RiskSection {
  overallScore = "overallScore",
  screeningFactors = "screeningFactors",
  webAndMediaFactors = "webAndMediaFactors",
  keyDetailsFactors = "keyDetailsFactors"
}

export type RiskData = Pick<
  OverviewResponse,
  | RiskSection.screeningFactors
  | RiskSection.webAndMediaFactors
  | RiskSection.keyDetailsFactors
>;

export type OverallScoreData = OverviewResponse["overallScore"];

export type OverallScore = Required<ApiOverallScore>;

export interface RiskFactor extends Required<ApiRiskFactor> {
  isMuted: boolean;
  isFlagged: boolean;
}

const transformApiOverallScore = (
  overallScore?: ApiOverallScore
): OverallScore => {
  const { bucket, score, summary } = overallScore || {};

  const overallScoreData: OverallScoreData = {
    bucket: bucket ?? "none",
    score: score ?? 0,
    summary: summary ?? []
  };

  return overallScoreData;
};

const transformRequestData = (
  result: GetOverviewResponse
): OverviewResponse => {
  const { overallScore } = result;

  const overallScoreData = transformApiOverallScore(overallScore);

  const riskSections: Array<keyof RiskData> = [
    RiskSection.screeningFactors,
    RiskSection.webAndMediaFactors,
    RiskSection.keyDetailsFactors
  ];

  const transformedRiskData = riskSections.reduce((acc, currentKey) => {
    if (!result[currentKey]) {
      return { ...acc, [currentKey]: [] };
    }

    const updatedRiskFactor: RiskFactor[] = result[currentKey]!.map(
      riskFactor => {
        const {
          name,
          summary: riskFactorSummary,
          dateMuted,
          flagged,
          level,
          sources,
          unconfirmedSourceCount
        } = riskFactor;

        const nameAsRiskFactor = name || RiskFactorName.OtherRiskFactorName;

        return {
          ...riskFactor,
          isMuted: !!dateMuted,
          isFlagged: !!flagged,
          name: nameAsRiskFactor,
          dateMuted: dateMuted || "",
          flagged: !!flagged,
          level: level || RiskLevel.NoneRiskLevel,
          sources: sources || [],
          summary: riskFactorSummary || [],
          unconfirmedSourceCount: unconfirmedSourceCount ?? 0
        };
      }
    );

    return { ...acc, [currentKey]: updatedRiskFactor };
  }, {} as RiskData);

  return {
    overallScore: overallScoreData,
    ...transformedRiskData
  };
};

export const fetchRiskOverview = async (
  reportId: string
): Promise<FetchResult<OverviewResponse>> => {
  try {
    const response = await RiskOverviewService.getV1ReportsOverview({
      reportId
    });

    if (!response) {
      throw new Error("No response from risk overview fetch");
    }

    const transformedRiskData = transformRequestData(response);

    return {
      status: true,
      response: transformedRiskData
    };
  } catch (e: unknown) {
    apm.captureError(e as Error);

    console.error(e);

    if (hasErrorCode(e)) {
      return { status: false, message: e.body.error };
    }

    return { status: false, message: "Failed to fetch risk overview" };
  }
};

export const updateRiskIconMuteConfig = async (
  reportId: string,
  name: string,
  mute: boolean
): Promise<FetchResult> => {
  try {
    await RiskFactorsService.patchV1ReportsFactorsMute({
      reportId,
      name,
      request: {
        mute
      }
    });
    return { status: true };
  } catch (e: unknown) {
    apm.captureError(e as Error);
    console.error(e);
    if (hasErrorCode(e)) {
      return { status: false, message: e.body.error };
    }
    return { status: false, message: "Failed to update risk icon mute config" };
  }
};

export const fetchOverallScore = async (
  reportId: string
): Promise<FetchResult<OverallScore>> => {
  try {
    const response = await RiskScoresService.getV1ReportsScore({
      reportId
    });

    return { status: true, response: transformApiOverallScore(response) };
  } catch (e: unknown) {
    apm.captureError(e as Error);
    console.error(e);
    if (hasErrorCode(e)) {
      return { status: false, message: e.body.error };
    }
    return { status: false, message: "Failed to fetch overall score" };
  }
};
