import React, { MouseEventHandler, ReactNode } from "react";

import { RiskLevel } from "api/risk";
import { RISK_LEVEL_CONFIG } from "util/riskService";
import { ReactComponent as SpecialInterestIconSvg } from "img/icons/special-interest-icon.svg";

import S from "./styles";

interface OverallRiskCardProps {
  level: RiskLevel;
  children: ReactNode;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const OverallRiskCard = ({
  level,
  children,
  onMouseEnter,
  onMouseLeave
}: OverallRiskCardProps) => {
  const { color, title } = RISK_LEVEL_CONFIG[level];

  return (
    <S.Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.CardBody>
        <S.IconContainer color={color}>
          <SpecialInterestIconSvg />
        </S.IconContainer>
        <S.ColumnContainer>
          <S.Title color={color}>{title}</S.Title>
          {children}
        </S.ColumnContainer>
      </S.CardBody>
    </S.Card>
  );
};
