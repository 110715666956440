import { styled } from "@linaria/react";
import { grey, green } from "styles/colors";
import { ReactComponent as MapPinSvg } from "img/icons/map-icon.svg";

const Locations = styled.div`
  padding: 4px 0;
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${grey.mid};
`;

const LocationIcon = styled(MapPinSvg)`
  min-width: 12px;
  min-height: 18px;
  max-width: 12px;
  max-height: 18px;
`;

const LocationItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const LocationItem = styled.div`
  border: 1px solid ${green.xapienGreen};
  border-radius: 50px;
  padding: 2px 12px;
`;

const S = {
  Locations,
  LocationIcon,
  LocationItemList,
  LocationItem
};

export default S;
