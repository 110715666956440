// config that is determined at compile time - hence safe to access from Linaria

export const Tenants = Object.freeze({
  projectDX: "projectdx",
  mintz: "mintz",
  default: "default"
});

export const tenant = process.env.REACT_APP_TENANT;
export const stageUniqueName = process.env.REACT_APP_STAGE;
export const isTenant = (tenantType: string): boolean => tenant === tenantType;
export const isPDX = isTenant(Tenants.projectDX);
export const xapienInsightsBaseUrl =
  process.env.REACT_APP_XAPIEN_INSIGHTS_BASE_URL;
