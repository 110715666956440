import React, { FC, useState, useEffect } from "react";
import { useReactFlow, Position, Node } from "reactflow";
import Popover from "components/atoms/Popover";
import { UBOHandle, UBONodeWrapper } from "components/atoms/UBO";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import UBOProfileCard from "components/molecules/UBOProfileCard";
import NewReportModal from "components/organisms/NewReportModal";
import type { UboReportNode } from "api/ubo-reports";
import { UboEntityType } from "api/ubo-reports";
import {
  PersonContextType,
  OrganisationContextType,
  SubjectType
} from "api/enquiry/types";
import useViewerMode from "util/hooks/useViewerMode";
import { getConnectedNodes } from "util/ubo/getConnectedNodes";

import S from "./styles";

interface Props {
  id: string;
  data: UboReportNode["data"];
}

const getIconComponent = (type: UboEntityType) => {
  if (type === UboEntityType.person) {
    return <S.PersonIcon />;
  }
  return <S.OrganisationIcon />;
};

const DataNode: FC<Props> = ({ data, id }) => {
  const { getNode, getEdges } = useReactFlow();
  const { isViewerModeEnabled } = useViewerMode();
  const [parentNode, setParentNode] = useState<undefined | Node>(undefined);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { type, label, sources, integratedPercentage } = data;
  const subjectType =
    type === UboEntityType.company
      ? SubjectType.Organisation
      : SubjectType.Person;
  const isBusiness = type === UboEntityType.company;

  const onCloseReportModal = () => setIsReportModalOpen(false);
  const onOpenReportModal = () => setIsReportModalOpen(true);

  useEffect(() => {
    if (parentNode) return;

    const connectedNodes = getConnectedNodes({ id, getNode, getEdges });

    if (connectedNodes?.length === 1) {
      setParentNode(connectedNodes[0]);
    }
  }, [setParentNode, parentNode, id, getNode, getEdges]);

  const getContexts = () => {
    if (isBusiness) {
      return sources.map(source => ({
        type: OrganisationContextType.WebAddress,
        value: source.url
      }));
    }

    if (!parentNode) return [];

    return [
      {
        type:
          parentNode.data.type === "Company"
            ? PersonContextType.Organisation
            : PersonContextType.Person,
        value: parentNode.data.label
      }
    ];
  };

  const contexts = getContexts();

  return (
    <>
      <UBOHandle isHidden type="source" position={Position.Top} />
      <UBONodeWrapper>
        <Popover
          alignment="right"
          position="fixed"
          trigger="click"
          content={
            <UBOProfileCard
              data={data}
              isBusiness={isBusiness}
              subjectType={subjectType}
              contexts={contexts}
            />
          }
        >
          <S.LabelContainer>
            {getIconComponent(type)}
            <S.Label>{label}</S.Label>
            {integratedPercentage ? (
              <S.SubLabel>{integratedPercentage}% Total Ownership</S.SubLabel>
            ) : null}
          </S.LabelContainer>
        </Popover>
        <ButtonNew
          disabled={isViewerModeEnabled}
          onClick={onOpenReportModal}
          type={ButtonType.OutlinedLight}
          size={ButtonSize.Medium}
          text="New report"
        />
      </UBONodeWrapper>
      <UBOHandle isHidden type="target" position={Position.Bottom} />
      <NewReportModal
        label={label}
        subjectType={subjectType}
        contexts={contexts}
        isOpen={isReportModalOpen}
        onClose={onCloseReportModal}
      />
    </>
  );
};

export default DataNode;
