import { styled } from "@linaria/react";

const Text = styled.div`
  > span {
    font-weight: 700;
  }
`;

const S = {
  Text
};

export default S;
