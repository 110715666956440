import React from "react";

import { RiskLevel } from "api/risk-service";
import { OVERALL_RISK_CONFIG } from "util/riskService";
import S from "./styles";

interface RiskLevelTagProps {
  riskLevel: RiskLevel;
}

export const RiskLevelTag = ({ riskLevel }: RiskLevelTagProps) => {
  const { label } = OVERALL_RISK_CONFIG[riskLevel];
  const showAsRisk = [
    RiskLevel.HighRiskLevel,
    RiskLevel.MediumHighRiskLevel,
    RiskLevel.MediumRiskLevel
  ].includes(riskLevel);
  return (
    <S.RiskLevelTag showAsRisk={showAsRisk}>{`${label} risk`}</S.RiskLevelTag>
  );
};
