import React from "react";
import { FormattedText } from "components/atoms/FormattedText";

import S from "./styles";

interface ScoreCardEntryProps {
  title: string;
  subtitle: string;
  content?: string;
  countryCode: string;
}

export const ScoreCardEntry = ({
  title,
  subtitle,
  content,
  countryCode
}: ScoreCardEntryProps) => {
  return (
    <S.Container>
      <S.Title>
        <S.CountryFlag size="sm" code={countryCode} showCodeString={false} />
        {title}
      </S.Title>
      <S.Text>
        <FormattedText text={subtitle} />
        {!!content && <FormattedText text={content} />}
      </S.Text>
    </S.Container>
  );
};
