import React, { FC, ReactNode } from "react";
import { apm } from "@elastic/apm-rum";

// @ts-ignore
import theme from "theme";

import { ChevronLeft } from "react-feather";
import { Outlet } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

import config from "config";
import GlobalNavigationBar from "components/organisms/GlobalNavigationBar";
import ErrorBoundary from "util/ErrorBoundary";
import GlobalErrorScreen from "pages/GlobalErrorScreen";
import { PrintStateUrlStateExtractor } from "util/hooks/usePrintableState";
import { AuthenticationContextProvider } from "util/hooks/useAuthentication";
import { UserPreferencesContextProvider } from "util/hooks/useUserPreferences/provider";
import { UserSettingsContextProvider } from "util/hooks/useUserSettings/provider";
import { UserOrganisationProvider } from "util/hooks/useUserOrganisation";
import ProductFruitsTour from "util/ProductFruitsTour";
import { OrganisationPreferencesContextProvider } from "util/hooks/useOrganisationPreferences/provider";
import useHubAdminRole from "util/hooks/useHubAdminRole";
import { HUB_ASSUMED_ROLE_TOKEN_KEY } from "util/hooks/useHubAdminRole/provider";

import S from "./styles";

const ShellProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AuthenticationContextProvider
      loginPath="/login"
      ssoCallbackPath="/sso/callback"
    >
      <OrganisationPreferencesContextProvider>
        <UserSettingsContextProvider>
          <UserPreferencesContextProvider>
            <UserOrganisationProvider>
              <ProductFruitsTour />
              <PrintStateUrlStateExtractor>
                {children}
              </PrintStateUrlStateExtractor>
            </UserOrganisationProvider>
          </UserPreferencesContextProvider>
        </UserSettingsContextProvider>
      </OrganisationPreferencesContextProvider>
    </AuthenticationContextProvider>
  );
};

const HubAdminBanner = () => {
  const { isAuthenticatedAsHubAdmin, setIsAuthenticatedAsHubAdmin } =
    useHubAdminRole();

  const onBackToHubClick = async () => {
    try {
      sessionStorage.removeItem(HUB_ASSUMED_ROLE_TOKEN_KEY);
      setIsAuthenticatedAsHubAdmin(false);
      await signOut();
      window.location.href = "/hub";
    } catch (error) {
      apm.captureError(error as Error);
      console.error("error signing out: ", error);
    }
  };

  if (!isAuthenticatedAsHubAdmin) return null;

  return (
    <S.InfoBanner>
      You are acting as a Customer Success assistant in this account
      <S.BackLink onClick={onBackToHubClick}>
        <ChevronLeft />
        Back to Xapien Hub
      </S.BackLink>
    </S.InfoBanner>
  );
};

const PortalShell = () => {
  return (
    <ErrorBoundary
      FallbackComponent={GlobalErrorScreen}
      onError={(error: unknown, errorInfo: unknown) => {
        apm.captureError(error as Error);
        console.error(error, errorInfo);
      }}
    >
      <ShellProviders>
        <S.Shell id="shellOuter" tenant={config.tenant}>
          <HubAdminBanner />
          <GlobalNavigationBar />
          <S.Main>
            <Outlet />
          </S.Main>
          {theme.poweredBy?.logo ? (
            <S.PoweredBy>{theme.poweredBy.logo}</S.PoweredBy>
          ) : null}
        </S.Shell>
      </ShellProviders>
    </ErrorBoundary>
  );
};

export default PortalShell;
