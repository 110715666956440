import { grey } from "styles/colors";
import { styled } from "@linaria/react";
import Flag from "components/atoms/Flag";
import { family, fontSize, lineHeightPx } from "styles/fonts";

import { ReactComponent as ArrowSvg } from "img/icons/chevron.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  line-height: ${lineHeightPx["20px"]};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
`;

const Content = styled.div<{ canBeCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: ${({ canBeCollapsed }) => (canBeCollapsed ? "30px" : "0")};
`;

const Text = styled.div`
  font-family: ${family.interLight};
  font-size: ${fontSize.sm};
  line-height: ${lineHeightPx["20px"]};
  color: ${grey.dark};
`;

const CountryFlag = styled(Flag)`
  width: 32px;

  img,
  img:hover {
    width: 24px;
    height: 16px;
    margin: 0;
    margin-right: 5px;
    border-radius: 2px;
  }
`;

const Arrow = styled(ArrowSvg)<{ right: boolean }>`
  width: 16px;
  height: 16px;
  transform: rotate(${({ right }) => (right ? 0 : 90)}deg);
  margin-right: 8px;
`;

const BulletedList = styled.ul`
  margin: 0;
  padding: 0 0 0 18px;
`;

const S = {
  Container,
  Title,
  Text,
  Content,
  Arrow,
  CountryFlag,
  BulletedList
};

export default S;
