import { v4 as uuidv4 } from "uuid";
import { type model_RelatedParty } from "api/related-parties";

export const getEntityTagItems = ({
  relationships
}: {
  relationships: model_RelatedParty["relationships"];
}) => {
  const relationshipTags =
    relationships?.map(relationship => ({
      id: uuidv4(),
      value: relationship
    })) ?? [];

  return [...relationshipTags];
};
