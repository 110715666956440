import { styled } from "@linaria/react";

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
`;

const Icon = styled.div`
  font-size: 2rem; /* Icon size */
  padding: 12px 0;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const S = { Icon, IconContainer, IconWrapper };

export default S;
