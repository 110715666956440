import { styled } from "@linaria/react";
import { standardColors, blue } from "styles/colors";

const UnreadIcon = styled.div`
  background: ${standardColors.white};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const ReadIcon = styled.div`
  background: ${blue.panel};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const S = {
  UnreadIcon,
  ReadIcon
};

export default S;
