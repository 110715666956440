import { styled } from "@linaria/react";
import { Info as InfoIcon } from "react-feather";
import Heading from "components/atoms/Heading";
import { CardVariant } from "components/molecules/Card/types";

import { grey, blue, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
`;

const Indicator = styled.div<{ variant: CardVariant; isVisible?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${grey.disabled};
  background: ${({ variant, isVisible }) => {
    if (!isVisible) return "transparent";

    return variant === CardVariant.dark ? standardColors.white : blue.panel;
  }};
`;

const Subtitle = styled.div<{ variant: CardVariant }>`
  display: flex;
  gap: 5px;
  margin: 0;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
  max-width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px;

  @media (max-width: 768px) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const AvatarAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${blue.xapienDark};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitlesContainer = styled.div`
  max-width: 17rem;
  width: 17rem;
`;

const CustomHeading = styled(Heading)<{ variant: CardVariant }>`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: none;
  overflow: hidden;
  hyphens: auto;

  color: ${({ variant }) =>
    variant === CardVariant.dark ? standardColors.white : blue.xapienDark};
`;

const QueuedIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${blue.xapienBrandBlue};
  border-radius: 50%;
`;

const InProgressIcon = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid ${blue.xapienLight};
  border-radius: 50%;
`;

const FailedIcon = styled(InfoIcon)`
  width: 10px;
  height: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const S = {
  StatusContainer,
  QueuedIcon,
  InProgressIcon,
  FailedIcon,
  AvatarAndTitle,
  Subtitle,
  Header,
  TitlesContainer,
  CustomHeading,
  IndicatorContainer,
  Indicator
};

export default S;
