/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { dto_RegenerateReportRequest } from "../models/dto_RegenerateReportRequest";
import type { models_GetEnquiryResponse } from "../models/models_GetEnquiryResponse";
import type { models_LegacyState } from "../models/models_LegacyState";
import type { models_QueueEnquiryRequest } from "../models/models_QueueEnquiryRequest";
import type { models_QueueEnquiryResponse } from "../models/models_QueueEnquiryResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../../enquiryReportServiceCustom/request";

export class EnquiryService {
  /**
   * Queue an enquiry
   * Queues an enquiry on the system
   * @returns models_QueueEnquiryResponse OK
   * @throws ApiError
   */
  public static postV1Enquiry({
    request
  }: {
    /**
     * Queue enquiry request
     */
    request: models_QueueEnquiryRequest;
  }): CancelablePromise<models_QueueEnquiryResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/enquiry",
      body: request,
      errors: {
        400: `Invalid request payload`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Get an enquiry's metadata and associations
   * Retrieves the status of an enquiry
   * @returns models_GetEnquiryResponse OK
   * @throws ApiError
   */
  public static getV1Enquiry({
    enquiryId
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
  }): CancelablePromise<models_GetEnquiryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/enquiry/{enquiry_id}",
      path: {
        enquiry_id: enquiryId
      },
      errors: {
        400: `Invalid Enquiry ID`,
        404: `Enquiry not found`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Get an enquiry's legacy state
   * Retrieves the legacy state of an enquiry
   * @returns models_LegacyState OK
   * @throws ApiError
   */
  public static getV1EnquiryLegacyState({
    enquiryId
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
  }): CancelablePromise<models_LegacyState> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/enquiry/{enquiry_id}/legacy-state",
      path: {
        enquiry_id: enquiryId
      },
      errors: {
        400: `Invalid Enquiry ID`,
        404: `Enquiry not found`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Regenerate an enquiry
   * Regenerates an enquiry using user assertions
   * @returns any Accepted
   * @throws ApiError
   */
  public static postV1EnquiryRegenerate({
    enquiryId,
    request
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
    /**
     * Request
     */
    request: dto_RegenerateReportRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/enquiry/{enquiry_id}/regenerate",
      path: {
        enquiry_id: enquiryId
      },
      body: request,
      errors: {
        400: `Invalid Enquiry ID`,
        404: `Enquiry not found`,
        500: `Internal server error`
      }
    });
  }
}
