/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExecutiveSummaryResponse } from "../models/ExecutiveSummaryResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ExecutiveSummariesService {
  /**
   * Get the Executive Summary for an Insights Report
   * An endpoint to retrieve the Executive Summary for an Insights Report.
   * @returns ExecutiveSummaryResponse Success
   * @throws ApiError
   */
  public static getReportsExecutiveSummary({
    reportId
  }: {
    /**
     * ID of report
     */
    reportId: string;
  }): CancelablePromise<ExecutiveSummaryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/executive-summary",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get the Executive Summary for an Insights Report
   * An endpoint to retrieve the Executive Summary for an Insights Report.
   * @returns ExecutiveSummaryResponse Success
   * @throws ApiError
   */
  public static getReportsPersonasExecutiveSummary({
    reportId,
    personaId
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * ID of persona
     */
    personaId: string;
  }): CancelablePromise<ExecutiveSummaryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/personas/{personaId}/executive-summary",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get the Executive Summary for an Insights Report
   * An endpoint to retrieve the Executive Summary for an Insights Report.
   * @returns ExecutiveSummaryResponse Success
   * @throws ApiError
   */
  public static getReportsExecutiveSummary1({
    reportId,
    shareToken
  }: {
    /**
     * ID of report
     */
    reportId: string;
    shareToken: string;
  }): CancelablePromise<ExecutiveSummaryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/executive-summary/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get the Executive Summary for an Insights Report
   * An endpoint to retrieve the Executive Summary for an Insights Report.
   * @returns ExecutiveSummaryResponse Success
   * @throws ApiError
   */
  public static getReportsPersonasExecutiveSummary1({
    reportId,
    personaId,
    shareToken
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * ID of persona
     */
    personaId: string;
    shareToken: string;
  }): CancelablePromise<ExecutiveSummaryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/personas/{personaId}/executive-summary/{shareToken}",
      path: {
        reportId: reportId,
        personaId: personaId,
        shareToken: shareToken
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }
}
