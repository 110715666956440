import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { family, fontSize, lineHeightPx } from "styles/fonts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  line-height: ${lineHeightPx["20px"]};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
`;

const Subtitle = styled.div`
  font-family: ${family.interLight};
  font-size: ${fontSize.sm};
  line-height: ${lineHeightPx["20px"]};
  color: ${grey.dark};
`;

const Content = styled.div`
  font-family: ${family.interLight};
  font-size: ${fontSize.sm};
  line-height: ${lineHeightPx["20px"]};
  color: ${grey.mid};
`;

const S = { Container, Title, Subtitle, Content };

export default S;
