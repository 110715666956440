import { apm } from "@elastic/apm-rum";
import { IdentityTenantApiService } from "api/idam";
import { SearchReportResult } from "api/portal";

import { Report } from "api/reports";
import { parseReportItem } from "api/reports/utils";
import { CollectionListView } from "components/molecules/CollectionListControls";
import {
  CollectionInputType,
  CollectionListState,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";

export const ORGANISATION_USER_REPORTS_COLLECTION = "organisation-user-reports";

export default class Reports {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: ORGANISATION_USER_REPORTS_COLLECTION,
          title: "Reports",
          items: [],
          status: CollectionStatus.stale,
          totalItemCount: 0,
          view: CollectionListView.list,
          offset: 0,
          limit: 16,
          order: "desc",
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          },
          hidden: false,
          pollingEnabled: true
        }
      ]
    };
  }

  async list({
    id: _id,
    limit,
    offset,
    filters: _filters,
    filterByUserId
  }: {
    id: string;
    limit: number;
    offset: number;
    filters?: string[];
    filterByUserId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    try {
      if (!filterByUserId) {
        return { items: [], totalItemCount: 0 };
      }

      const { reports, total: totalItemCount } =
        await IdentityTenantApiService.getTenantsUsersReports({
          userId: filterByUserId,
          start: offset,
          limit
        });

      if (!reports) return { items: [], totalItemCount: 0 };

      const items = reports
        .filter(Boolean)
        .map(report =>
          parseReportItem(filterByUserId, report as SearchReportResult)
        );

      return { items, totalItemCount: totalItemCount ?? reports.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }
}
