import { styled } from "@linaria/react";

const Container = styled.div<{ isCollapsed?: boolean }>`
  max-height: ${({ isCollapsed }) => (isCollapsed ? "238px" : "none")};
  overflow-y: ${({ isCollapsed }) => (isCollapsed ? "hidden" : "auto")};
`;

const Divider = styled.hr`
  margin: 0;
`;

const S = {
  Container,
  Divider
};

export default S;
