import { createContext } from "react";

import type { InsightReportSection } from "api/insight-reports/types";

import type {
  Section as RawSection,
  Subsection as RawSubsection
} from "api/insights";

import type { InsightReportState, InsightReportAction } from "./types";
import { InsightReportActions, InsightReportStatus } from "./types";

import {
  convertRawReport,
  addToSectionBySectionId,
  updateRawReportSectionTitle
} from "./utils";

const HIDE_SOURCING_EXPLAINER_LOCAL_STORAGE_KEY = "hide_xi_sourcing_explainer";
export const initialState: InsightReportState = {
  activeSectionSlug: "overview",
  status: InsightReportStatus.idle,
  showMenu: false,
  report: undefined,
  rawReport: undefined,
  errorMessage: undefined,
  fetchedSourcesNodeIds: [],
  isShowingSourcingExplainer:
    window.localStorage.getItem(HIDE_SOURCING_EXPLAINER_LOCAL_STORAGE_KEY) !==
    "true"
};

export const insightReportReducer = (
  state: InsightReportState,
  action: InsightReportAction
) => {
  switch (action.type) {
    case InsightReportActions.toggleMenu: {
      return {
        ...state,
        showMenu: !state.showMenu
      };
    }
    case InsightReportActions.errorAddingNewSection: {
      return {
        ...state,
        status: InsightReportStatus.errorAddingNewSection
      };
    }
    case InsightReportActions.addNewSectionSuccess: {
      return {
        ...state,
        status: InsightReportStatus.editingTitle
      };
    }
    case InsightReportActions.addNewSection: {
      if (!state.report) return state;
      if (!state.rawReport) return state;

      const sections = [...state.rawReport.sections, action.section];

      const rawReport = { ...state.rawReport, sections };

      const report = convertRawReport(state.report.id, rawReport);

      const activeSection = report.sections.find(
        section => section.id === action.section.id
      );

      const activeSectionSlug = activeSection
        ? activeSection.id
        : state.activeSectionSlug;

      return {
        ...state,
        status: InsightReportStatus.addingNewSection,
        report,
        rawReport,
        activeSectionSlug,
        updatedTitleSectionId: action.section.id,
        previousSectionSlug: state.activeSectionSlug
      };
    }
    case InsightReportActions.addToReportSection: {
      if (!state.rawReport || !state.report?.id) return state;

      const updatedRawReport = addToSectionBySectionId(
        state.rawReport,
        action.sectionId,
        action.subsection
      );

      const report = convertRawReport(state.report.id, updatedRawReport);

      return {
        ...state,
        report,
        rawReport: updatedRawReport,
        status: InsightReportStatus.idle
      };
    }
    case InsightReportActions.updateActiveSectionSlug: {
      return {
        ...state,
        activeSectionSlug: action.slug,
        previousSectionSlug: state.activeSectionSlug
      };
    }
    case InsightReportActions.showPreviousSectionSlug: {
      if (!state.previousSectionSlug) {
        return {
          ...state,
          activeSectionSlug: "overview"
        };
      }

      return {
        ...state,
        activeSectionSlug: state.previousSectionSlug
      };
    }
    case InsightReportActions.removeReportSection: {
      return {
        ...state,
        status: InsightReportStatus.removingReportSection,
        sectionIdToRemove: action.sectionId
      };
    }
    case InsightReportActions.removeReportSubSection: {
      return {
        ...state,
        status: InsightReportStatus.removingReportSubSection,
        sectionIdToRemove: action.sectionId,
        subsectionIdToRemove: action.subSectionId
      };
    }
    case InsightReportActions.removeReportSectionSuccess: {
      return {
        ...state,
        status: InsightReportStatus.removingReportSectionSuccess
      };
    }
    case InsightReportActions.moveSubSection: {
      return {
        ...state,
        status: InsightReportStatus.movingSubSection,
        currentSectionId: action.sectionId,
        currentSubSectionId: action.subSectionId,
        destinationSectionId: action.destinationSectionId
      };
    }
    case InsightReportActions.moveSubsectionSuccess: {
      return {
        ...state,
        status: InsightReportStatus.movingSubsectionSuccess
      };
    }
    case InsightReportActions.reorderSubSections: {
      return {
        ...state,
        status: InsightReportStatus.reorderingSubSections,
        reorderingSectionId: action.sectionId,
        reorderingSubsectionId: action.subsectionId,
        reorderingSubsectionDirection: action.direction
      };
    }
    case InsightReportActions.reorderSubSectionsSuccess: {
      return {
        ...state,
        status: InsightReportStatus.reorderingSubSectionsSuccess
      };
    }
    case InsightReportActions.removeReportElement: {
      return {
        ...state,
        status: InsightReportStatus.removingReportElement,
        sectionIdToRemove: action.sectionId,
        subsectionIdToRemove: action.subSectionId,
        elementIdToRemove: action.elementId
      };
    }
    case InsightReportActions.fetchReport: {
      return { ...state, status: InsightReportStatus.fetching };
    }
    case InsightReportActions.updateReport: {
      const report = convertRawReport(action.reportId, action.rawReport);
      const activeSectionSlug = action.activeSectionSlug
        ? action.activeSectionSlug
        : report.sections.find(
            (section: InsightReportSection) =>
              section.id === state.activeSectionSlug
          )
        ? state.activeSectionSlug
        : report.sections[0].id;

      return {
        ...state,
        activeSectionSlug,
        status: InsightReportStatus.idle,
        report,
        rawReport: action.rawReport,
        sectionIdToRemove: undefined,
        subsectionIdToRemove: undefined,
        elementIdToRemove: undefined,
        errorMessage: undefined,
        reorderingSectionId: undefined,
        reorderingSubsectionId: undefined,
        reorderingSubsectionDirection: undefined
      };
    }
    case InsightReportActions.errorFetchingReport: {
      return {
        ...state,
        status: InsightReportStatus.errorFetchingReport,
        errorMessage: action.errorMessage
      };
    }
    case InsightReportActions.errorFetchingSources: {
      return {
        ...state,
        status: InsightReportStatus.errorFetchingSources,
        errorFetchingSourcesId: action.errorFetchingSourcesId
      };
    }
    case InsightReportActions.updatingFetchingSources: {
      const nodeIds = new Set(state.fetchedSourcesNodeIds);
      nodeIds.add(action.nodeId);

      return {
        ...state,
        status: InsightReportStatus.fetchingSources,
        fetchedSourcesNodeIds: [...nodeIds]
      };
    }
    case InsightReportActions.updateReportSubSection: {
      if (!state.report) return state;
      if (!state.rawReport) return state;

      const topSection = state.rawReport.sections.find((s: RawSection) =>
        s.subsections.find(
          (sub: RawSubsection) => sub.id === action.subsectionId
        )
      );

      if (!topSection) return state;

      const updatedSubSections = topSection.subsections.map(
        (sub: RawSubsection) =>
          sub.id === action.subsectionId ? action.subSection : sub
      );

      const updatedSection = { ...topSection, subsections: updatedSubSections };

      const updatedRawReport = {
        ...state.rawReport,
        sections: state.rawReport.sections.map((s: RawSection) =>
          s.id === updatedSection.id ? updatedSection : s
        )
      };

      // @ts-ignore TODO: Fix this after merging this :)
      const report = convertRawReport(state.report.id, updatedRawReport);

      return {
        ...state,
        report,
        rawReport: updatedRawReport,
        status: InsightReportStatus.idle
      };
    }
    case InsightReportActions.hideSourcingExplainer: {
      window.localStorage.setItem(
        HIDE_SOURCING_EXPLAINER_LOCAL_STORAGE_KEY,
        "true"
      );
      return { ...state, isShowingSourcingExplainer: false };
    }
    case InsightReportActions.removeReport: {
      return {
        ...state,
        rawReport: undefined,
        status: InsightReportStatus.removed
      };
    }
    case InsightReportActions.regenerateReport: {
      return {
        ...state,
        status: InsightReportStatus.regeneratingReport
      };
    }
    case InsightReportActions.startEditingTitle: {
      return {
        ...state,
        status: InsightReportStatus.editingTitle,
        updatedTitleSectionId: action.sectionId,
        updatedTitleSubsectionId: action.subsectionId,
        updatedTitleElementId: action.elementId
      };
    }
    case InsightReportActions.stopEditingTitle: {
      if (!state.report) return state;
      if (!state.rawReport) return state;
      if (!state.updatedTitleSectionId) return state;

      const {
        rawReport,
        updatedTitle: title,
        updatedTitleSectionId: sectionId,
        updatedTitleSubsectionId: subsectionId,
        updatedTitleElementId: elementId
      } = state;

      const updatedRawReport = updateRawReportSectionTitle({
        rawReport,
        title: title || "New section",
        sectionId,
        subsectionId,
        elementId
      });
      const report = convertRawReport(state.report.id, updatedRawReport);

      return {
        ...state,
        status: InsightReportStatus.savingTitle,
        rawReport: updatedRawReport,
        report
      };
    }
    case InsightReportActions.resetEditingTitle: {
      return {
        ...state,
        status: InsightReportStatus.idle,
        updatedTitleSectionId: undefined,
        updatedTitleSubsectionId: undefined,
        updatedTitleElementId: undefined,
        updatedTitle: undefined
      };
    }
    case InsightReportActions.updateTitle: {
      return {
        ...state,
        updatedTitle: action.title
      };
    }
    default:
      return state;
  }
};

export const InsightReportContext = createContext({
  state: initialState,
  dispatch: (_action: InsightReportAction) => {}
});
