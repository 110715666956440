import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { blue, grey, purple, standardColors } from "styles/colors";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${standardColors.white};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 478px;
  position: relative;
  overflow: hidden;
`;

const IconContainer = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  color: ${purple.darker};
  background: ${grey.panelLight};

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${grey.panelLight};
    }
  }
`;

const CardBody = styled.div`
  display: flex;
  gap: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding: 24px 24px 20px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-size: ${fontSize.lg};
  font-weight: bold;
  margin: 0;
`;

const Text = styled.div`
  span {
    font-weight: bold;
  }

  a {
    color: ${blue.link};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

const S = {
  Card,
  Text,
  Title,
  CardBody,
  IconContainer,
  ColumnContainer
};

export default S;
