import React, { ReactNode, RefObject } from "react";

import S from "./styles";

interface RiskIconContainerProps {
  icons: ReactNode[];
  gap?: number;
  flexWrap?: "nowrap" | "unset";
  transform?: string;
  transition?: string;
  paddingLeft?: number;
  justifyContent?: string;
  containerRef?: RefObject<HTMLDivElement>;
  iconRef?: RefObject<HTMLDivElement>;
}

export const RiskIconContainer = ({
  icons,
  gap,
  flexWrap = "unset",
  transform,
  transition,
  paddingLeft,
  justifyContent,
  containerRef,
  iconRef
}: RiskIconContainerProps) => (
  <S.IconWrapper ref={containerRef}>
    <S.IconContainer
      style={{
        transform,
        transition,
        paddingLeft,
        justifyContent,
        flexWrap,
        gap
      }}
    >
      {icons.map((icon, idx) => (
        <S.Icon key={idx} ref={idx === 0 ? iconRef : null}>
          {icon}
        </S.Icon>
      ))}
    </S.IconContainer>
  </S.IconWrapper>
);
