export const AssessmentOptions = Object.freeze({
  NoUserAssessment: "NoUserAssessment",
  RightPerson: "RightPerson",
  WrongPerson: "WrongPerson"
});

export const userAssessmentMapping = {
  confirmed: AssessmentOptions.RightPerson,
  discarded: AssessmentOptions.WrongPerson
};
