/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReportContextType {
  PERSON = "Person",
  ORGANISATION = "Organisation",
  SOCIAL_PROFILE = "SocialProfile",
  WEB_ADDRESS = "WebAddress",
  FREETEXT = "Freetext"
}
