import { tenant, Tenants } from "static-config";
import { ResourcesConfig } from "aws-amplify";
import { stage, qa, diDemo } from "./services/stage";

export const devOptions = {
  newExpander: true,
  pdfExportOriginUrl: process.env.REACT_APP_local_pdf_export_origin_url
};

export const tenantConfig = {
  [Tenants.projectDX]: {
    name: "Dow Jones Integrity Check",
    supportEmail: "dj-integritycheck@xapien.com",
    baseUrl: {
      prod: "integritycheck.xapien.com",
      stage: (stg: string) => `${stg}.integritycheck.xapien.com`
    },
    tenantIds: {
      "di-demo": "8e507211-3fbe-488b-9f2d-20fd06a337e6",
      qa: "63ee2c1b-ff85-45d4-8038-399d260d420a",
      devel: "3a914e61-eb77-4647-8b65-ace88f212602",
      default: "ef14c0fe-1f85-4df2-8246-bbe709a15003"
    }
  },
  [Tenants.mintz]: {
    name: "Verity by Mintz Group",
    supportEmail: "support@xapien.com",
    baseUrl: {
      prod: "verity.xapien.com",
      stage: (stg: string) => `${stg}.verity.xapien.com`
    },
    tenantIds: {
      "di-demo": "f71ff321-4ad8-4a93-a51b-4d5bd9ed8257",
      qa: "9a2c50c4-a5f2-4d56-bda5-12cbc252d210",
      devel: "f71ff321-4ad8-4a93-a51b-4d5bd9ed8257",
      default: "ef14c0fe-1f85-4df2-8246-bbe709a15003"
    }
  },
  default: {
    name: "Xapien",
    supportEmail: "support@xapien.com",
    baseUrl: {
      prod: "portal.xapien.com",
      stage: (stg: string) => `portal.${stg}.app.xapien.com`
    },
    tenantIds: {
      "di-demo": "a852c7d6-9f2c-4705-ba96-3951412f72d0",
      qa: "437019bf-5065-42ef-8e14-32bab77e9317",
      devel: "ef14c0fe-1f85-4df2-8246-bbe709a15003",
      default: "ef14c0fe-1f85-4df2-8246-bbe709a15003"
    }
  }
};

export const getCurrentTenantConfig = () => {
  return (
    tenantConfig[tenant as keyof typeof tenantConfig] || tenantConfig.default
  );
};

const getEnvironmentUrl = () => {
  const environmentUrl = "app.xapien.com";
  return `${stage}.${environmentUrl}`;
};

const getPortalCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_qLwzTveQN";
    case qa:
      return "us-west-2_vPaBAqzDJ";
    default:
      return "us-west-2_A29go6fav";
  }
};

const getPortalCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "ge02tm49k4neb769djnf1epl";
    case qa:
      return "1oou8rift2o9uhrki1m2itafev";
    default:
      return "22d2chdq3m14jhu0o5pk8nklpb";
  }
};

const getPortalCognitoAuthUrl = () => `auth.identity.${stage}.xapien.com`;

const getPortalRedirectUrls = () => {
  if (window.location.host === "localhost") {
    return ["http://localhost:3000/sso/callback"];
  }

  const currentConfig = getCurrentTenantConfig();
  const baseUrl =
    stage === diDemo
      ? currentConfig.baseUrl.prod
      : currentConfig.baseUrl.stage(stage);

  return [`https://${baseUrl}/sso/callback`];
};

const getPortalRedirectSignOutUrls = () => {
  if (window.location.host === "localhost") {
    return ["http://localhost:3000"];
  }

  const currentConfig = getCurrentTenantConfig();
  const baseUrl =
    stage === diDemo
      ? currentConfig.baseUrl.prod
      : currentConfig.baseUrl.stage(stage);

  return [`https://${baseUrl}`];
};

export const getSearchAssistRedirectUrl = () => {
  const currentConfig = getCurrentTenantConfig();
  const baseUrl =
    stage === diDemo
      ? currentConfig.baseUrl.prod
      : currentConfig.baseUrl.stage(stage);

  return `https://search.${baseUrl}`;
};

const getHubCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_YW5Phsc3i";
    case qa:
      return "us-west-2_jQYo2Fn3W";
    default:
      return "us-west-2_YPIDG2MsT";
  }
};

const getHubCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "3cbka941k4d2cojhd1od3ksqsv";
    case qa:
      return "1u12jb57h2kmun9ltj7cq57bd0";
    default:
      return "5lqcg0mcvqpjm4t393luo6q004";
  }
};

const getHubCognitoAuthUrl = () => `tenant-auth.identity.${stage}.xapien.com`;

const getIdamApiUrl = () => `https://api.identity.${stage}.xapien.com`;

const getPortalApiUrl = () => `https://api.portal.${stage}.xapien.com`;

const getInsightsApiUrl = () => `https://api.insights.${stage}.xapien.com`;

const getTenantId = () => {
  const currentConfig = getCurrentTenantConfig();
  return (
    currentConfig.tenantIds[stage as keyof typeof currentConfig.tenantIds] ??
    currentConfig.tenantIds.default
  );
};

const getApmServerUrl = () => {
  switch (stage) {
    case "di-demo":
      return "https://5c8c0ace641742958f1418befeca2440.apm.us-west-2.aws.cloud.es.io:443";
    default:
      return "https://a02c30a2f0ad468382c815f60a8c5757.apm.us-west-2.aws.cloud.es.io:443";
  }
};

export const emailAddressSwitchBasedOnTenant = () =>
  getCurrentTenantConfig().supportEmail;

const getTenantName = () => getCurrentTenantConfig().name;

const config = {
  stage,
  tenantId: getTenantId(),
  tenantName: getTenantName(),
  skipWaitingScreen: false,
  inDebugMode: false,
  preparingReportPollIntervalMs: 3000,
  diagnosticIntervalMs: 3000,
  diagnosticSlowerIntervalMs: 20000,
  environmentUrl: getEnvironmentUrl(),
  tenant: process.env.REACT_APP_TENANT ?? "default",
  useNewLogin: process.env.REACT_APP_USE_NEW_LOGIN,
  amplify: {
    portal: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getPortalCognitoUserPoolId(),
        userPoolWebClientId: getPortalCognitoWebAppClientId(),
        oauth: {
          domain: getPortalCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: getPortalRedirectUrls(),
          redirectSignOut: getPortalRedirectSignOutUrls(),
          responseType: "code"
        }
      }
    },
    hub: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getHubCognitoUserPoolId(),
        userPoolWebClientId: getHubCognitoWebAppClientId(),
        oauth: {
          domain: getHubCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: getPortalRedirectUrls(),
          redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT,
          responseType: "code"
        }
      }
    }
  },
  isApmEnabled: !!process.env.REACT_APP_APM_ENABLED,
  apmServerUrl: getApmServerUrl(),
  idamApiUrl: getIdamApiUrl(),
  portalApiUrl: getPortalApiUrl(),
  insightsApiUrl: getInsightsApiUrl(),
  supportEmail: emailAddressSwitchBasedOnTenant()
};

type AmplifyConfig = {
  portal: ResourcesConfig;
  hub: ResourcesConfig;
};

export const amplifyConfig: AmplifyConfig = {
  portal: {
    Auth: {
      Cognito: {
        userPoolClientId: getPortalCognitoWebAppClientId(),
        userPoolId: getPortalCognitoUserPoolId(),
        loginWith: {
          oauth: {
            domain: getPortalCognitoAuthUrl(),
            scopes: ["email", "profile", "openid"],
            redirectSignIn: getPortalRedirectUrls(),
            redirectSignOut: getPortalRedirectSignOutUrls(),
            responseType: "code"
          }
        }
      }
    }
  },
  hub: {
    Auth: {
      Cognito: {
        userPoolClientId: getHubCognitoWebAppClientId(),
        userPoolId: getHubCognitoUserPoolId(),
        loginWith: {
          oauth: {
            domain: getHubCognitoAuthUrl(),
            scopes: ["email", "profile", "openid"],
            redirectSignIn: [process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_IN!],
            redirectSignOut: [process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT!],
            responseType: "code"
          }
        }
      }
    }
  }
};

export default config;
