/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RiskSummaryType {
  CardRiskSummaryType = "card",
  SourceRiskSummaryType = "source",
  ScoreRiskSummaryType = "score",
  ListRiskSummaryType = "list",
  BulletedListRiskSummaryType = "bulleted_list",
  InlineRiskSummaryType = "inline"
}
