import React, { useEffect } from "react";

import { inject, observer } from "mobx-react";

import PreparingReport from "pages/report/PreparingReport";
import { useNavigate, useParams } from "react-router-dom";
import { useInsightReport } from "util/hooks/useInsightReport";
import { GetReportErrorCodes } from "api/insight-reports";
import { observe } from "mobx";

const PreparingReportContainer = props => {
  const { enquiryStore, diagnosticsStore } = props;

  const { enquiryId } = useParams();
  const navigate = useNavigate();
  const {
    state: { errorMessage, report: insightsReport }
  } = useInsightReport();

  const isLoadingInsights =
    !insightsReport && errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR;

  useEffect(() => {
    enquiryStore.setCurrentEnquiryId(enquiryId);

    const dispose = observe(diagnosticsStore, change => {
      let isReady = false;

      if (
        change.name === "enquiryOverview" &&
        change.oldValue &&
        !change.oldValue.reportReady &&
        change.newValue?.reportReady
      ) {
        isReady = true;
      }

      if (
        ["loaded", "notFound"].includes(change.name) &&
        change.object.loaded &&
        change.object.notFound
      ) {
        isReady = true;
      }

      if (!change.object.notFound && isReady && !isLoadingInsights) {
        navigate(`/report/${enquiryId}`);
      }
    });

    window.scrollTo(0, 0);

    return dispose;
  }, [enquiryStore, diagnosticsStore, navigate, enquiryId, isLoadingInsights]);

  return (
    <PreparingReport
      messages={diagnosticsStore.messages}
      notFound={diagnosticsStore.notFound}
      hasError={diagnosticsStore.hasError}
      unauthorised={diagnosticsStore.unauthorised}
      enquiryId={enquiryId}
      isLoadingInsights={isLoadingInsights}
    />
  );
};

export default inject(
  "enquiryStore",
  "diagnosticsStore",
  "reportStore"
)(observer(PreparingReportContainer));
