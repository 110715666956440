import React, { FC, useContext, useReducer } from "react";

import { reportStore } from "state/Store";
import { isDevel, isQa } from "services/stage";

import {
  Copy,
  MessageCircle,
  CheckCircle,
  HelpCircle,
  Trash2
} from "react-feather";

import type { InsightReport } from "api/insight-reports";

import {
  InsightReportActions,
  useInsightReport
} from "util/hooks/useInsightReport";
import { useSubjectName } from "util/hooks/useSubjectName";
import useInsightsFeatures from "util/hooks/useInsightsFeatures";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import {
  renderAsPlainText,
  renderAsRichText,
  copyToClipboard
} from "components/organisms/InsightReport/utils";
import ProvideFeedbackModal from "components/organisms/InsightReport/ProvideFeedbackModal";
import RemoveFromReportModal from "components/organisms/InsightReport/RemoveFromReportModal";

import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import S from "./styles";

interface Props {
  report?: InsightReport;
}

interface State {
  isCopied: boolean;
  isProvidingFeedback: boolean;
  isRemovingFromReport: boolean;
}

enum Actions {
  updateIsCopied = "updateIsCopied",
  updateIsProvidingFeedback = "updateIsProvidingFeedback",
  updateIsRemovingFromReport = "updateIsRemovingFromReport"
}

interface UpdateIsProvidingFeedbackAction {
  type: Actions.updateIsProvidingFeedback;
  value: boolean;
}

interface UpdateIsCopiedAction {
  type: Actions.updateIsCopied;
  value: boolean;
}

interface UpdateIsRemovingFromReportAction {
  type: Actions.updateIsRemovingFromReport;
  value: boolean;
}

type Action =
  | UpdateIsCopiedAction
  | UpdateIsProvidingFeedbackAction
  | UpdateIsRemovingFromReportAction;

const initialState: State = {
  isCopied: false,
  isProvidingFeedback: false,
  isRemovingFromReport: false
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.updateIsCopied: {
      return { ...state, isCopied: action.value };
    }
    case Actions.updateIsProvidingFeedback: {
      return { ...state, isProvidingFeedback: action.value };
    }
    case Actions.updateIsRemovingFromReport: {
      return { ...state, isRemovingFromReport: action.value };
    }
    default: {
      return state;
    }
  }
};

const MainMenuControl: FC<Props> = ({ report }) => {
  let canEdit = false;
  const {
    reportMeta: {
      // TODO: Remove this when reportStore has types
      // @ts-ignore reportStore is not typed :sob:
      permissions
    }
  } = reportStore;

  if (permissions) {
    canEdit = permissions.canEdit;
  }

  const subjectName = useSubjectName();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { toggleTimelineRiskColors, toggleTimelineRiskTags } =
    useInsightsFeatures();
  const { dispatch: insightsDispatch } = useInsightReport();
  const isDiagnosticsMode = useContext(DiagnosticsModeContext).enabled;

  const { isCopied, isProvidingFeedback, isRemovingFromReport } = state;

  const onCopy = () => {
    dispatch({ type: Actions.updateIsCopied, value: true });

    const richText = report
      ? report.sections
          .flatMap(section => section.content)
          .map(content => renderAsRichText(content))
          .join("")
      : "";

    const plainText = report
      ? report.sections
          .flatMap(section => section.content)
          .map(content => renderAsPlainText(content))
          .join("")
      : "";

    copyToClipboard({ richText, plainText });

    setTimeout(() => {
      dispatch({ type: Actions.updateIsCopied, value: false });
    }, 2000);
  };

  const onProvideFeedback = () => {
    dispatch({
      type: Actions.updateIsProvidingFeedback,
      value: !isProvidingFeedback
    });
  };

  const onRemoveFromReport = () => {
    dispatch({
      type: Actions.updateIsRemovingFromReport,
      value: !isRemovingFromReport
    });
  };

  const onRegenerateReport = () => {
    insightsDispatch({
      type: InsightReportActions.regenerateReport
    });
  };

  const canDelete = canEdit && !isPDX;

  return (
    <S.MainMenuContainer>
      <Popover
        alignment="bottom-end"
        trigger="click"
        content={
          <Menu>
            <MenuItem
              IconLeading={isCopied ? CheckCircle : Copy}
              text={isCopied ? "Copied!" : "Copy all to clipboard"}
              onMenuItemClick={onCopy}
            />
            <MenuItem
              IconLeading={MessageCircle}
              text="Provide feedback"
              onMenuItemClick={onProvideFeedback}
            />
            {(isDevel || isQa) && (
              <>
                <MenuItem
                  IconLeading={HelpCircle}
                  text="Toggle Risk Colors"
                  onMenuItemClick={toggleTimelineRiskColors}
                />
                <MenuItem
                  IconLeading={HelpCircle}
                  text="Toggle Risk Tags"
                  onMenuItemClick={toggleTimelineRiskTags}
                />
              </>
            )}
            {isDiagnosticsMode && (
              <MenuItem
                IconLeading={HelpCircle}
                text="Regenerate"
                onMenuItemClick={onRegenerateReport}
              />
            )}
            {canDelete && (
              <MenuItem
                isDeleteAction
                IconLeading={Trash2}
                text="Delete from report"
                onMenuItemClick={onRemoveFromReport}
              />
            )}
          </Menu>
        }
      >
        <S.MenuControl aria-label="Insights Main Menu Control" />
      </Popover>

      {report && (
        <ProvideFeedbackModal
          isOpen={isProvidingFeedback}
          onToggle={onProvideFeedback}
          context={[{ key: "Report", value: subjectName }]}
        />
      )}

      <RemoveFromReportModal
        isOpen={isRemovingFromReport}
        onToggleOpen={onRemoveFromReport}
      />
    </S.MainMenuContainer>
  );
};

export default MainMenuControl;
