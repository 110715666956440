import React from "react";

import useEntityList from "util/hooks/useEntityList";
import S from "./styles";

const EntityListDetails = () => {
  const {
    state: { details },
    filteredEntityList,
    filteredCommissionedEntityList
  } = useEntityList();

  const totalEntityListCount =
    (filteredEntityList?.length ?? 0) +
    (filteredCommissionedEntityList?.length ?? 0);

  return (
    <S.Container>
      <div>{details?.title}</div>
      <S.Heading>{totalEntityListCount}</S.Heading>
    </S.Container>
  );
};

export default EntityListDetails;
