import { observable, action, runInAction, makeObservable } from "mobx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Diagnostics from "../api/diagnostics/Diagnostics";

dayjs.extend(utc);

function msToTime(duration) {
  let seconds = parseInt((duration / 1000) % 60, 10);
  let minutes = parseInt((duration / (1000 * 60)) % 60, 10);
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

  hours = hours > 0 ? (hours < 10 ? `0${hours}` : hours) : "";
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours + (hours.length > 0 ? ":" : "") + minutes}:${seconds}`;
}

class DiagnosticsStore {
  constructor(sharedUserInterface, diagnosticsApi) {
    this.sharedUserInterface = sharedUserInterface;
    this.diagnosticsApi =
      diagnosticsApi ||
      new Diagnostics(state =>
        runInAction(() => {
          this.sharedUserInterface.apiCallInProgress = state;
        })
      );
    this.enquiryOverview = observable.object({});
    this.messages = observable.array([]);
    this.statusColumns = observable.array([
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null])
    ]);
    this.currentStatusText = observable.object({});
    this.loaded = false;
    this.enquiryId = null;
    this.timeTaken = null;
    this.baselineMs = null;
    this.notFound = false;
    this.unauthorised = false;
    this.hasError = false;
    this.isOutage = false;

    this._refresh = this._refresh.bind(this);
    this.calculateTimeTaken = this.calculateTimeTaken.bind(this);
    this.waitingOnResponse = false;

    makeObservable(this, {
      enquiryOverview: observable,
      messages: observable,
      statusColumns: observable,
      currentStatusText: observable,
      loaded: observable,
      enquiryId: observable,
      overview: action,
      timeTaken: observable,
      notFound: observable,
      isOutage: observable
    });
  }

  reset() {
    this.enquiryId = null;
    this.enquiryOverview = null;
    this.messages = [];
    this.statusColumns = [
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null]),
      observable.array([null, null, null, null])
    ];
    this.currentStatusText = observable.object({});
    this.loaded = false;
    this.timeTaken = null;
    this.baselineMs = null;
    this.notFound = false;
  }

  overview(enquiryId, reset) {
    this.waitingOnResponse = true;

    if (reset === undefined || reset) {
      this.reset();
    }

    return new Promise((resolve, reject) => {
      const timeoutMillis = 30000;
      const id = setTimeout(() => {
        console.error(
          `Did not recieve diagnostics update within ${
            timeoutMillis / 1000
          } seconds, will allow another request to be made`
        );
        runInAction(() => {
          this.waitingOnResponse = false;
        });
        reject();
      }, timeoutMillis);

      this.diagnosticsApi.overview(enquiryId).then(
        overview => {
          if (
            !overview.enquiryId ||
            overview.enquiryId === "00000000-0000-0000-0000-000000000000"
          ) {
            runInAction(() => {
              this.notFound = true;
              this.loaded = true;
            });
            resolve();
            return;
          }

          const flat = [...overview.stateStatusList].slice(
            1,
            overview.stateStatusList.length - 1
          );
          const currentIndex =
            overview.stateStatusList.findIndex(
              x => x.state === overview.currentState
            ) - 1;
          flat.forEach((x, i) => {
            flat[i] = {
              ...x,
              isCurrent: i === currentIndex,
              isDone: i < currentIndex
            };
          });
          const statusColumns = [
            observable.array(flat.slice(0, 4)),
            observable.array(flat.slice(4, 8)),
            observable.array(this.padArrayToLength(flat.slice(8), 4, null))
          ];
          clearTimeout(id);
          runInAction(() => {
            this.waitingOnResponse = false;
            this.loaded = true;
            this.enquiryOverview = overview;
            this.messages = [overview.statusMessage];
            this.statusColumns = statusColumns;
            this.currentStatusText = overview.currentStateName;
            this.isOutage = overview.isOutage;
          });

          if (this.timeTaken === null) {
            this.calculateTimeTaken();
          }
          resolve();
        },
        e => {
          runInAction(() => {
            this.waitingOnResponse = false;
            if (e.status === 404) {
              this.notFound = true;
            }
            if (e.status === 401) {
              this.unauthorised = true;
            }
            if (e.status !== 404 && e.status !== 401) {
              this.hasError = true;
            }
            this.loaded = true;
          });
          reject(e);
        }
      );
    });
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} length
   * @param {any} fill
   */
  padArrayToLength(arr, length, fill) {
    if (arr.length < length) {
      return Array(length)
        .fill(fill)
        .map((e, i) => (i < arr.length ? arr[i] : e));
    }
    return arr;
  }

  calculateTimeTaken(timerRunningMs) {
    if (!this.enquiryOverview || !this.enquiryOverview.timestampStartedUtc) {
      return;
    }

    if (this.baselineMs === null) {
      if (
        !this.enquiryOverview.timestampCompletedUtc ||
        dayjs.utc(this.enquiryOverview.timestampCompletedUtc).year() === 1
      ) {
        this.baselineMs = dayjs
          .utc()
          .diff(dayjs.utc(this.enquiryOverview.timestampStartedUtc), "ms");
      } else {
        this.baselineMs = dayjs
          .utc(dayjs.utc(this.enquiryOverview.timestampCompletedUtc))
          .diff(dayjs.utc(this.enquiryOverview.timestampStartedUtc), "ms");
      }
      // can end up with a negative time due to clock sync between UI and client
      if (this.baselineMs < 0) {
        this.baselineMs = 0;
      }
    }

    let timeTakenMs = this.baselineMs;
    if (
      !this.enquiryOverview.timestampCompletedUtc ||
      dayjs.utc(this.enquiryOverview.timestampCompletedUtc).year() === 1
    ) {
      if (timerRunningMs) {
        timeTakenMs += timerRunningMs;
      }
    }

    const formattedTime = msToTime(timeTakenMs);

    runInAction(() => {
      this.timeTaken = formattedTime;
    });
  }

  _refresh() {
    if (this.enquiryOverview !== null) {
      this.overview(this.enquiryOverview.enquiryId, false);
    }
  }
}

export default DiagnosticsStore;
