import { styled } from "@linaria/react";
import { zIndex } from "styles/zIndex";
import Heading from "components/atoms/Heading";
import ButtonNew from "components/atoms/ButtonNew";
import { Spinner as ReactSpinner } from "reactstrap";
import { family, size, lineHeight } from "styles/fonts";
import { ReactComponent as SparklesSvg } from "img/icons/sparkles.svg";
import { blue, grey, red, yellow, purple, standardColors } from "styles/colors";

export interface ContainerProps {
  hasRisk: boolean;
  active: boolean;
}

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DisclaimerStars = styled(SparklesSvg)`
  width: 9px;
  fill: ${yellow.amaStars};
`;

const DisclaimerText = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-family: ${family.interSemiBold};
  font-size: ${size["2xs"]};
  font-weight: 600;
  line-height: ${lineHeight.md};
  color: ${grey.dark};
`;

const TooltipContainer = styled.div`
  background-color: ${grey.lightest};
  border-radius: 20px;
  color: ${grey.dark};
  width: 490px;
`;

const TooltipBody = styled.div`
  display: flex;
  padding: 24px 24px 12px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const TooltipFooter = styled.div`
  position: relative;
  z-index: ${zIndex.l7};
  padding: 12px 24px;
  background: ${grey.panel};
  border-radius: 0px 0px 12px 12px;
  margin-bottom: 0;
  color: ${blue.xapienDark};
  width: 100%;
  font-family: ${family.interRegular};
  font-size: ${size.sm};
  font-weight: 500;
  line-height: ${lineHeight.lg};
  > a {
    color: ${blue.link};
    text-decoration: underline;
  }
`;

const TooltipIcon = styled.div<{ hasError?: boolean }>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: ${({ hasError }) =>
    hasError ? grey.panel : red.directRiskOpaque};
  box-shadow: ${({ hasError }) =>
    hasError ? "none" : "0px 0px 1px 1px rgba(0, 0, 0, 0.25)"};
  border-radius: ${({ hasError }) => (hasError ? "50%" : "9px")};

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TooltipTitle = styled.h6<{ hasError?: boolean }>`
  color: ${({ hasError }) => (hasError ? grey.dark : red.directRisk)};
  font-family: ${family.interSemiBold};
  font-size: ${size.lg};
  font-weight: 700;
  line-height: ${lineHeight.lg};
  margin-bottom: 0;
`;

const TooltipText = styled.p`
  font-family: ${family.interRegular};
  font-size: ${size.sm};
  line-height: ${lineHeight.xl};
  margin-bottom: 0;

  > span {
    font-family: ${family.interSemiBold};
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: ${blue.link};
  }
`;

const ActionButton = styled(ButtonNew)`
  background: ${purple.lighter};
  color: ${grey.dark};
  width: fit-content;
  min-width: 100px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${purple.light};
    color: ${grey.dark};
  }

  &:disabled {
    background-color: ${grey.light};
  }
`;

const IconWrapper = styled.div<{
  variant?: "small" | "medium";
  isDisabled?: boolean;
}>`
  background: ${({ isDisabled }) =>
    (isDisabled && grey.midLight) || purple.darker};

  color: ${standardColors.white};
  border-radius: 50%;
  width: ${({ variant }) =>
    !variant || variant === "small" ? "18px" : "32px"};
  height: ${({ variant }) =>
    !variant || variant === "small" ? "18px" : "32px"};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const DiscardedCover = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
`;

const DiscardedCoverHeading = styled(Heading)`
  color: ${purple.darker};
  font-weight: 500;
`;

const Spinner = styled(ReactSpinner)`
  width: 21px;
  height: 21px;
  border-width: 2px;
`;

const S = {
  DisclaimerContainer,
  DisclaimerStars,
  DisclaimerText,
  TooltipContainer,
  TooltipBody,
  TooltipFooter,
  TooltipIcon,
  TooltipContent,
  TooltipTitle,
  TooltipText,
  ActionButton,
  IconWrapper,
  DiscardedCover,
  DiscardedCoverHeading,
  Spinner
};

export default S;
