import React, { FC, useState, MouseEvent } from "react";
import {
  TagId,
  ReportCollectionId,
  ReportStatus,
  ReportType
} from "api/reports/types";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import ReportCardMenu from "components/organisms/ReportCardMenu";
import { CardVariant } from "components/molecules/Card/types";
import { CollectionListView } from "components/molecules/CollectionListControls";
import type { Report } from "api/reports";
import useMarkReportRead from "util/hooks/useMarkReportRead";
import CardSkeleton from "components/atoms/CardSkeleton";
import { collectionCardComponents } from "util/collectionCardComponents";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";
import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import useIsMobile from "util/hooks/useIsMobile";
import { useAuthentication } from "util/hooks/useAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";
import { ShareReportContextProvider } from "util/hooks/useShareReport/provider";
import { grey } from "styles/colors";
import { formatCardTags } from "util/formatCardTags";
import Popover from "components/atoms/Popover";
import { ReactComponent as LegalEntityIcon } from "img/icons/legal-entity-icon.svg";
import { ReactComponent as LegalEntityFailedIcon } from "img/icons/legal-entity-failed-icon.svg";
import { ReactComponent as PersonIcon } from "img/icons/person-icon.svg";
import { ReactComponent as PersonFailedIcon } from "img/icons/person-failed-icon.svg";
import Heading from "components/atoms/Heading";
import RiskIcons from "components/molecules/RiskIcons";
import ReactTooltip from "components/atoms/ReactTooltip";
import config from "config";

import { formatDate, formatTime } from "util/common";
import MonitoringModal from "../MonitoringModal";

import S, { classNameOverrides } from "./styles";

dayjs.extend(customParseFormat);

interface Props {
  item?: Report;
  view: CollectionListView;
  showCardIndicators?: boolean;
  loading?: boolean;
  variant: CardVariant;
  id: string;
}

const Overview: FC<{
  item: Report;
  view: CollectionListView;
}> = ({ item, view }) => {
  if (view === CollectionListView.list) {
    if ("status" in item && item.status !== ReportStatus.ready) {
      return null;
    }

    return (
      <RiskIcons
        id={item.id}
        riskFactors={item.riskFactors}
        iconBackgroundColor={grey.panel}
      />
    );
  }

  if ("status" in item && item.status !== ReportStatus.ready) {
    return (
      <S.StatusHeading level={6}>
        {item.status.charAt(0).toUpperCase() + item.status.substr(1)}
      </S.StatusHeading>
    );
  }

  if ("status" in item && item.status !== ReportStatus.ready) {
    return null;
  }

  return (
    <RiskIcons
      id={item.id}
      riskFactors={item.riskFactors}
      iconBackgroundColor={grey.panel}
    />
  );
};

const getCardVariant = (item: Report, defaultVariant: CardVariant) => {
  if (
    item.status === ReportStatus.updating ||
    item.status === ReportStatus.running ||
    item.status === ReportStatus.failed
  ) {
    return CardVariant.dark;
  }

  return defaultVariant;
};

const ReportCard = ({
  item,
  view,
  loading,
  showCardIndicators,
  variant: defaultVariant,
  id
}: Props) => {
  const {
    state: {
      permissions: { canStartLegalEntityReports }
    }
  } = useAuthentication();
  const { markRead, markUnread } = useMarkReportRead();
  const { dispatch } = useCollectionList();
  const [isMonitoringModalOpen, setIsMonitoringModalOpen] = useState(false);
  const {
    state: { status: hubAuthenticationStatus }
  } = useHubAuthentication();
  const isMobile = useIsMobile();

  const launchMonitoringModal = (e: MouseEvent) => {
    e.stopPropagation();
    setIsMonitoringModalOpen(true);
  };

  const getZapIcon = () => {
    if (!item) {
      return null;
    }

    const canEditMonitoring =
      item.permissions.canEditAdverseMediaMonitoring ||
      item.permissions.canEditSanctionMonitoring;

    if (!canEditMonitoring) {
      return null;
    }

    if (item.monitoring.adverseMedia || item.monitoring.sanctions) {
      return <S.SolidZap onClick={e => launchMonitoringModal(e)} />;
    }

    return <S.GreyedOutZap onClick={e => launchMonitoringModal(e)} />;
  };

  const { C } = collectionCardComponents[view];

  if (loading) {
    return <CardSkeleton view={view} />;
  }

  if (!item) {
    return null;
  }

  const isFailed = item.status === ReportStatus.failed;
  const isReady = item.status === ReportStatus.ready;
  const isLegalEntity = item.type === ReportType.LegalEntity;
  const isOrganisation = item.type === ReportType.Organisation;

  const variant = getCardVariant(item, defaultVariant);

  const onMarkRead = () => {
    markRead(item.id);

    dispatch({
      type: CollectionListActions.updateCollectionItem,
      id: ReportCollectionId.Recent,
      item: { ...item, isUnread: false }
    });
  };

  const onMarkUnread = () => {
    markUnread(item.id);

    dispatch({
      type: CollectionListActions.updateCollectionItem,
      id: ReportCollectionId.Recent,
      item: { ...item, isUnread: true }
    });
  };

  const onCardClick = () => {
    if (loading || !item.permissions.canView) {
      return;
    }

    if (hubAuthenticationStatus === HubAuthenticationStatus.authenticated) {
      if (item.viewerModeUrl) {
        window.open(item.viewerModeUrl, "_blank", "noopener,noreferrer");
      }

      return;
    }

    if (isReady) {
      if (isMobile) {
        window.open(`/insight-report/${item.id}`);
      } else {
        window.open(`/report/${item.id}`);
      }
      if (id === ReportCollectionId.Unread) {
        onMarkRead();
      }
    } else {
      window.open(`/report/preparing/${item.id}`);
    }
  };

  const getCardStyles = () => {
    if (!loading) {
      if (isReady) {
        return classNameOverrides.cardUpdating;
      }

      return classNameOverrides.card;
    }
    return "";
  };

  const getCardIcon = () => {
    if (isLegalEntity) {
      return isFailed ? <LegalEntityFailedIcon /> : <LegalEntityIcon />;
    }

    if (isOrganisation) {
      return isFailed ? <S.Building /> : <S.Building />;
    }

    return isFailed ? <PersonFailedIcon /> : <PersonIcon />;
  };

  const reportTags = formatCardTags(
    item.tags,
    canStartLegalEntityReports ? [] : [TagId.ResearchType]
  );

  return (
    <>
      <Popover
        distance={view === CollectionListView.grid ? -100 : -30}
        interactive
        maxWidth="326px"
        content={
          <S.FailedReportTooltip>
            <Heading level={6}>Failed report</Heading>
            <S.FailedReportTooltipBody>
              Xapien could not run this report. Please contact the Customer
              Success team for assistance.
            </S.FailedReportTooltipBody>
            <S.ContactSupportLink href={`mailto:${config.supportEmail}`}>
              Contact Customer Success
            </S.ContactSupportLink>
          </S.FailedReportTooltip>
        }
        disableHideOnClip={undefined}
        className={undefined}
        style={{
          width: view === CollectionListView.list ? "100%" : "",
          display: "flex"
        }}
        disabled={!isFailed}
      >
        <C.Card
          onClick={onCardClick}
          variant={variant}
          control={
            <ShareReportContextProvider reportId={item.id}>
              <ReportCardMenu
                permissions={item.permissions}
                reportType={item.type}
                subject={item.title}
                context={item.context}
                imageSrc={item.imageSrc}
                id={item.id}
                authorId={item.authorId}
                authorName={item.author}
                authorEmail={item.authorEmail}
                isUnread={item.isUnread}
                onMarkRead={onMarkRead}
                onMarkUnread={onMarkUnread}
                onOpenInViewerMode={onCardClick}
                monitoringIcon={getZapIcon}
                viewerModeUrl={item.viewerModeUrl}
                onMonitoringClick={() => setIsMonitoringModalOpen(true)}
              />
            </ShareReportContextProvider>
          }
          className={getCardStyles()}
        >
          <C.CardHeader
            variant={variant}
            title={item.title}
            showCardIndicators={showCardIndicators}
            hasIndicator={item.isUnread}
            subtitle={item.context}
            status={item.status}
            overview={<Overview item={item} view={view} />}
            icon={
              <S.AvatarIconContainer>
                {item.imageSrc ? (
                  <Avatar
                    avatarSize={AvatarSize.Large}
                    imgSrc={item.imageSrc}
                  />
                ) : (
                  <Avatar
                    avatarSize={AvatarSize.Large}
                    hasBorder
                    background={isFailed ? grey.rule : undefined}
                  >
                    {getCardIcon()}
                  </Avatar>
                )}
                {!isReady && !isFailed ? <S.LoadingBar /> : null}
              </S.AvatarIconContainer>
            }
          />
          <C.CardBody
            variant={variant}
            subtitle={
              item.projectReference ? (
                <ReactTooltip
                  disabled={false}
                  layoutPosition={undefined}
                  interactive
                  tooltip="This is the project reference."
                  open={undefined}
                  className={undefined}
                  style={undefined}
                >{`#${item.projectReference}`}</ReactTooltip>
              ) : undefined
            }
            tags={reportTags}
          />
          <C.CardFooter
            variant={variant}
            datetime={`${formatTime(item.createdAt, true)} • ${formatDate(
              item.createdAt,
              true
            )}`}
            author={item.author}
          >
            {getZapIcon()}
          </C.CardFooter>
        </C.Card>
      </Popover>

      {isMonitoringModalOpen && (
        <MonitoringModal
          isOpen={isMonitoringModalOpen}
          toggleOpen={() => setIsMonitoringModalOpen(prev => !prev)}
          id={item.id}
          collectionId={id}
          subject={item.title}
          context={item.context}
          status={item.status}
          typeOfOrganisation={item.type}
          showMonitoring={item.permissions.canEditAdverseMediaMonitoring}
          enteringAdverseMediaState={item.monitoring.adverseMedia}
          enteringSanctionsState={item.monitoring.sanctions}
          showScreening={item.permissions.canEditSanctionMonitoring}
        />
      )}
    </>
  );
};

export default ReportCard;
