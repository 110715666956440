import React from "react";
import LandingPage from "components/molecules/LandingPage";

import LandingCarousel from "components/molecules/LandingCarousel";

const ThemedLogin = () => {
  return (
    <LandingPage>
      <LandingCarousel />
    </LandingPage>
  );
};

export default ThemedLogin;
