import dayjs from "dayjs";

export const getDurationInMonths = (dateFrom?: Date, dateTo?: Date) => {
  if (dateFrom && dateTo) {
    return dayjs(dateTo).diff(dayjs(dateFrom), "month");
  }

  if (dateFrom) {
    return dayjs().diff(dayjs(dateFrom), "month");
  }

  return undefined;
};
