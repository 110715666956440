import React from "react";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import { ReportCollectionId } from "api/reports/types";

import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";

import S from "./styles";

const ShowUnreadButton = ({
  filters,
  onClick
}: {
  filters: string[];
  onClick: (filter: string) => void;
}) => {
  const filter = "unread";
  const isActive = filters.includes(filter);
  const handleOnClick = () => onClick(filter);

  return (
    <ButtonNew
      onClick={handleOnClick}
      IconLeading={isActive ? S.ReadIcon : S.UnreadIcon}
      type={isActive ? ButtonType.Filled : ButtonType.Outlined}
      size={ButtonSize.Small}
      text="Unread"
    />
  );
};

const ReportFilters = () => {
  const {
    state: { collections },
    dispatch
  } = useCollectionList();

  const filters = collections.length > 0 ? collections[0].filters ?? [] : [];

  const onToggleFilter = (filter: string) => {
    const updatedFilters = filters.includes(filter)
      ? filters.filter(f => f !== filter)
      : [...filters, filter];

    dispatch({
      type: CollectionListActions.updateCollectionFilters,
      id: ReportCollectionId.Recent,
      filters: updatedFilters
    });
  };

  return <ShowUnreadButton filters={filters} onClick={onToggleFilter} />;
};

export default ReportFilters;
