import http from "services/http";
import endpoints from "services/endpoints";
import { mutateToLowerCamelCase } from "util/common";
import {
  ReportService,
  OpenAPI,
  Idam_Contracts_Enums_ReportState as IdamReportState
} from "api/idam";
import { EnquiryService } from "api/enquiry-report-service";
import { apm } from "@elastic/apm-rum";
import { getErrorMessage } from "api/util";
import { isDevel, stage } from "services/stage";

const getToken = async () => {
  if (typeof OpenAPI.TOKEN === "function") {
    const jwt = await OpenAPI.TOKEN();
    return jwt;
  }
  return OpenAPI.TOKEN;
};

export default class Proposal {
  constructor(inProgress) {
    this.inProgress = inProgress;
  }

  async exportToPdf(
    enquiryId,
    shareToken,
    stateId,
    paginate,
    callback,
    errorCallback
  ) {
    const payload = {
      reportId: enquiryId,
      shareToken,
      stateId,
      paginate
    };

    const socket = new WebSocket(
      `wss://api.pdf.${stage}.xapien.com/report_pdf?Authorization=Bearer%20${await getToken()}`
    );

    socket.addEventListener("open", () => {
      let hasResponse = false;

      socket.send(JSON.stringify(payload));

      socket.addEventListener("message", evt => {
        hasResponse = true;

        try {
          // If data can be parsed as JSON, assume it is an error.
          const data = JSON.parse(evt.data);

          errorCallback(data.message);
        } catch {
          callback(evt.data);
        }
      });

      socket.addEventListener("close", () => {
        if (!hasResponse) {
          errorCallback("WebSocket closed before a response was received");
        }
      });
    });

    socket.addEventListener("error", () => {
      errorCallback("WebSocket Failed");
    });
  }

  async get(enquiryId, personaId, shareToken, isForPDFExport) {
    this.inProgress(true);
    let reportMeta = {};
    try {
      if (isDevel && personaId) {
        reportMeta = await ReportService.getReportsPersonas2({
          reportId: enquiryId,
          personaId
        });
      } else if (shareToken) {
        reportMeta = await ReportService.getReports1({
          reportId: enquiryId,
          token: shareToken,
          isForPdfExport: isForPDFExport
        });
      } else {
        reportMeta = await ReportService.getReports({
          reportId: enquiryId
        });
      }

      // If the report is still in progress, it won't have a report to download from
      // S3, so we return early to prevent an error and allow the report page to
      // redirect the user to the Preparing page.
      if (reportMeta.state === IdamReportState.IN_PROGRESS) {
        return [{}, reportMeta];
      }

      const documentResponse = await fetch(new Request(reportMeta.reportUrl));
      const reportDocument = await http.processResultRaw(documentResponse);
      const report = this.mutateLowerCamelCaseObjectRecursively(reportDocument);
      // TODO: backend are restructuring this, we'll need to handle the "imageUrl" field instead.
      this.inProgress(false);
      return [report, reportMeta];
    } catch (error) {
      apm.captureError(error);
      this.inProgress(false);
      if (error.status === 204) {
        return null;
      }
      throw error;
    }
  }

  mutateLowerCamelCaseObjectRecursively(obj) {
    const mutated = mutateToLowerCamelCase(obj);
    return mutated;
  }

  list() {
    const url = `${endpoints.listReports}`;
    return new Promise((resolve, reject) => {
      this.inProgress(true);
      http.post(url).then(
        reports => {
          this.inProgress(false);
          resolve(reports);
        },
        error => {
          this.inProgress(false);
          if (error.status === 204) {
            resolve(null);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  async regenerate(enquiryId, userAssessments) {
    this.inProgress(true);
    try {
      await EnquiryService.postV1EnquiryRegenerate({
        enquiryId,
        request: { userAssessments }
      });
    } finally {
      this.inProgress(false);
    }
  }

  async generateERReport(enquiryId) {
    try {
      return {
        result: await http.post(endpoints.regenerateReport, {
          EnquiryId: enquiryId,
          UserAssessments: {},
          RunEntityResolution: true
        }),
        status: true,
        message: null
      };
    } catch (error) {
      apm.captureError(error);
      console.error(error);
      return { status: false, message: getErrorMessage(error) };
    }
  }

  async saveReportChanges(enquiryId, changes) {
    try {
      return {
        result: await http.post(endpoints.setRiskAssertions, {
          EnquiryId: enquiryId,
          RiskAssertions: changes
        }),
        hasErrored: false,
        error: null
      };
    } catch (error) {
      apm.captureError(error);
      console.error(error);
      return { hasErrored: true, error };
    }
  }
}
