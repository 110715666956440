/* eslint-disable max-len */
import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { Spinner as ReactSpinner } from "reactstrap";

import { family, fontSize } from "styles/fonts";
import { green, grey, red, blue, standardColors } from "styles/colors";

import { ReactComponent as EmployeeCard } from "img/icons/employee-icon.svg";
import { ReactComponent as SocialReach } from "img/icons/social-reach.svg";
import { ReactComponent as RelatedOrgs } from "img/icons/related-orgs-icon.svg";
import { ReactComponent as Globe } from "img/icons/globe-icon.svg";
import { ReactComponent as InformationSvgIcon } from "img/icons/information-icon.svg";
import { ReactComponent as WebPlaceHolderImage } from "img/icons/website-placeholder.svg";
import { ReactComponent as QuotesIconSvg } from "img/icons/quotes-icon.svg";
import {
  ReactComponent as Building,
  ReactComponent as OrgSvg
} from "img/building.svg";
import ReactTooltip from "components/atoms/ReactTooltip";
import InfoIcon from "components/atoms/InfoIcon";
import SectionFooter from "components/atoms/SectionFooter";
import InfoSummaryRowComponent from "components/molecules/InfoSummaryRow";
import CommonButton from "components/atoms/Button";
import Flag from "components/atoms/Flag";
import { DiImage } from "components/molecules/DiImage";
import SourceList from "components/molecules/SourceList";

const OrganisationOverviewSection = styled.div`
  color: ${grey.dark};
  margin-bottom: 20px;
`;

const OverviewContainer = styled.div`
  display: grid;
  grid-template-areas:
    "org-scale org-profiles org-details-1 org-details-2"
    "org-summary org-summary org-description org-description";
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const Topbar = styled.div`
  display: flex;
  flex-direction: row;
`;

const OverviewCell = styled.div`
  background-color: ${standardColors.white};
  border-left: 1px solid ${grey.disabled};
  padding: 16px;

  &:first-of-type {
    border-left: none;
  }
`;

const OrgLogoContainer = styled.div`
  width: 128px;
  padding: 16px;
  background-color: ${standardColors.white};
  border-left: 1px solid ${grey.disabled};
  border-bottom: 1px solid ${grey.disabled};
`;

const OrgLogo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: none;
  padding-top: 10px;
`;

const OrgLogoImageContainer = styled.div`
  object-fit: contain;
  height: 88px;
`;

const OrgDetails1Cell = styled(OverviewCell)`
  grid-area: org-details-1;
`;

const OrgDetails2Cell = styled(OverviewCell)`
  grid-area: org-details-2;
  overflow-y: auto;
`;

const OrgPresence = styled(OverviewCell)`
  grid-area: org-scale;
  position: relative;
`;

const OrgScaleItem = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 9px;
`;

const iconStyles = {
  width: "30px",
  height: "30px"
};

const EmployeeCardIcon = styled(EmployeeCard)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const RelatedOrgsIcon = styled(RelatedOrgs)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const GlobeIcon = styled(Globe)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const SocialReachIcon = styled(SocialReach)`
  ${iconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const GraphicSubtext = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin: 0;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrgScaleDetail = styled.div`
  font-size: ${fontSize.sm};
  color: ${({ color }) => color ?? "initial"};

  span {
    font-family: ${family.interSemiBold};
    font-weight: 600;
    padding: 0 4px;
  }
`;

const OrgProfiles = styled(OverviewCell)`
  grid-area: org-profiles;
  overflow-y: auto;
  padding-right: 11px;
`;

const OrgSummary = styled(OverviewCell)`
  grid-area: org-summary;
  justify-content: center;

  /* Ensures the Inspector is anchored to this element
    Prevents the Inspector from pointing at the centre
    of the content, which can look strange when the content
    overflows.
  */
  position: relative;

  p {
    font-size: ${fontSize.sm};
    margin: 0;
  }
`;

const OrgSummaryContainer = styled.div`
  max-height: 170px;
  overflow-y: auto;
`;

const LinkToSummary = styled.a`
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm} !important;
  min-width: 0;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    border-color: ${grey.mid};
  }
`;

const OrgDescription = styled(OverviewCell)`
  grid-area: org-description;

  p {
    font-size: ${fontSize.sm};
    margin-bottom: 0;
  }
`;

const OrgDescriptionContainer = styled.div`
  font-size: ${fontSize.sm};
  margin-bottom: 0;
  height: 170px;
  overflow-y: auto;
`;

const QuotesIcon = styled(QuotesIconSvg)`
  float: left;
  margin: 5px 10px 0 0;

  path {
    fill: ${props => props.color};
  }
`;

const OrgDescriptionText = styled.span`
  &:hover {
    cursor: ${props => (props.isClamped ? "pointer" : "default")};
  }
`;

const SectionTitle = styled.div`
  font-family: ${family.interSemiBold};
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin: 0;
`;

const OrgScaleTitle = styled(SectionTitle)`
  margin-bottom: 4px;
`;

const OnlineProfilesTitle = styled(SectionTitle)`
  margin-bottom: 4px;
`;

const OrgDescriptionTitleContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const OrgDescriptionTitle = styled.p`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  margin: 0;
  max-width: 220px;
  display: inline;
`;

const ShowAllButton = styled(CommonButton)`
  color: ${grey.mid} !important;
  font-size: ${fontSize.xs} !important;
  min-width: 0;
  padding-top: 8px !important;

  &:hover,
  &:focus {
    border-color: ${grey.mid} !important;
  }
`;

const OrgDuration = styled.span`
  color: ${grey.mid};
`;

const HQContainer = styled.div`
  display: flex;
`;

const JurisdictionContainer = styled.div`
  display: flex;
`;

const CustomFlag = styled(Flag)`
  width: 31px;
  img {
    width: 24px !important;
    height: 16px !important;
    margin: 0;
    margin-top: 1px !important;
    margin-right: 5px !important;
    border-radius: 2px !important;
  }
`;

const InformationIcon = styled(InformationSvgIcon)`
  height: 20px;
  width: 20px;
  padding-left: 2px;
`;

const PopoverHomepageImage = styled(DiImage)`
  padding-right: 6px;
`;

const ImageLoadingSpinner = styled(ReactSpinner)`
  color: ${green.xapienGreen};
  position: absolute;
`;

const HomepageLink = styled.a`
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm};
  &:hover {
    color: ${blue.icon} !important;
  }
`;

const OnlineProfilesContainer = styled.div`
  max-height: 140px;
  overflow-y: auto;
  padding-right: 5px;
`;

const TooltipContent = styled.div`
  text-align: left;
  padding: 2px;
  max-height: 325px;
  overflow-y: auto;
  width: 240px;
`;

const DescriptionSentenceSeparator = styled.span`
  white-space: pre;
  margin: 0 2px;
`;

const DescriptorSentenceContainer = styled.div`
  display: inline;

  &:last-child {
    ${DescriptionSentenceSeparator} {
      margin: 0;
    }
  }
`;

const DescriptorSources = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};

  a {
    color: ${grey.mid};
  }

  a:hover {
    color: ${grey.dark};
  }
`;

const DescriptionSourceContainer = styled.div`
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const NoDate = styled.i`
  color: ${grey.mid};
`;

const RoleDuration = styled.span`
  color: ${grey.mid};
`;

const Info = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  white-space: nowrap;
`;

const Founders = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const WebsitePlaceholder = styled(WebPlaceHolderImage)`
  max-width: 224px;
  max-height: 120px;
  border-radius: 3px;
`;

const SocialReachTooltipList = styled.ul`
  padding-top: 8px;
  padding-left: 25px;
  margin: 0;
`;

const NoSocialPresenceLabel = styled.p`
  margin: 0;
  padding-bottom: 8px;
`;

const SummaryText = styled.p`
  word-break: break-word;

  &:hover {
    cursor: ${props => (props.isClamped ? "pointer" : "initial")};
  }
`;

const PlaceholderText = styled.p`
  font-style: italic;
  color: ${grey.ghost};
`;

const SummaryTooltipContent = styled(TooltipContent)`
  width: 400px;
`;

const InfographicContainer = styled.div`
  position: relative;
  margin: auto;
  width: fit-content;
`;

const OrgScaleIconContainer = styled.div`
  position: relative;
`;

const RiskCountIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  min-width: 21px;
  bottom: -6px;
  right: -3px;
  border-radius: 50%;
  font-size: ${fontSize["2xs"]};
  background-color: ${red.directRisk};
  line-height: 17px;
  color: ${standardColors.white};
  border: 2px solid ${grey.panel};
  padding: ${props => (props.isMaxTextLength ? "2px" : "0")};

  &:hover {
    cursor: pointer;
  }
`;

const RiskTooltipBody = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding-top: 5px;
`;

const RiskList = styled.div`
  margin-bottom: 8px;
  line-height: 1.3;
  font-size: ${fontSize.sm};
`;

const Risks = styled.div`
  color: ${grey.mid};
`;

const RiskAssignerText = styled.span`
  color: ${grey.mid};
`;

const StreetviewImage = styled(DiImage)`
  border-radius: 3px;
`;

const Tooltip = styled(ReactTooltip)`
  /* Needed for a bug where the tooltip pushes the text content of "How they are described" down */
  & + div {
    display: inline;
  }
`;

const FounderPill = styled.div`
  text-align: left;
`;

const Duration = styled.div`
  color: ${props =>
    props.isSelected ? standardColors.white : grey.mid} !important;
  font-size: ${fontSize.sm};

  ${NoDate} {
    color: ${props => (props.isSelected ? standardColors.white : grey.mid)};
  }
`;

const FounderContainer = styled.div`
  padding-bottom: 6px;
`;

const SmallEmployeeIconInfographic = styled.div`
  display: flex;
  align-items: center;

  ${EmployeeCardIcon} {
    height: 35px;
    width: 35px;
    padding-bottom: 0;
  }
`;

const SmallSocialReachIconInfographic = styled.div`
  display: flex;
  align-items: center;

  ${SocialReachIcon} {
    height: 35px;
    width: 35px;
    padding-bottom: 0;
  }
`;

const HomepageProfile = styled.div`
  padding: 2px 4px;
`;

const BuildingIcon = styled(Building)`
  path {
    fill: ${standardColors.white};
  }
`;

const BuildingIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${grey.light};
  height: 96px;
  width: 96px;
  border-radius: 3px;

  > ${BuildingIcon} {
    transform: scale(2);
  }
`;

const MediaIconsContainer = styled.div`
  padding: ${props => (!props.isInspectorEnabled ? "3px 4px" : 0)};
`;

const SocialReachInfoIcon = styled(InfoIcon)`
  margin-bottom: 3px;
`;

const BulletPoint = styled.span`
  height: 4px;
  width: 4px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${grey.dark};
  margin-top: 3px;
  margin-bottom: 3px;
`;

const SourceContainer = styled.div`
  max-height: 275px;
  overflow: auto;
`;

const CountryFlag = styled(Flag)`
  width: 30px;
  img {
    width: 24px !important;
    height: 18px !important;
    margin: 0;
    margin-right: 8px !important;
    border-radius: 2px !important;
  }
`;

const Country = styled.div`
  display: flex;
`;

const InspectorContent = styled.div`
  max-height: 175px;
  overflow: auto;
  margin-right: -5px;
  padding-right: 5px;
`;

const StateOwnedCompany = styled.div`
  &:not(:first-of-type) {
    border-top: 1px solid ${grey.rule};
    margin-top: 11px;
    padding-top: 2px;
  }
`;

const RiskCountry = styled.div`
  &:not(:first-of-type) {
    border-top: 1px solid ${grey.rule};
    margin-top: 8px;
    padding-top: 8px;
  }
`;

const CountryName = styled.div`
  font-size: ${fontSize.sm};
  padding-bottom: 4px;
`;

const CompaniesList = styled.div`
  padding-left: ${props => (props.isSingleItem ? 0 : "8px")};
`;

const CompanyField = styled.div`
  padding-left: 11px;
  text-indent: -12px;
`;

const RelatedCountryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RiskAssigners = styled.div`
  display: flex;
  padding-top: 8px;
  gap: 8px;
`;

const CustomSectionFooter = styled(SectionFooter)`
  border-top: 1px solid ${grey.disabled} !important;
`;

const ExplainerText = styled.div`
  font-size: ${fontSize.sm};
`;

const OrgIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: ${green.xapienGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const OrgIcon = styled(OrgSvg)`
  transform: scale(0.65);
`;

const InfoSummaryRow = styled(InfoSummaryRowComponent)`
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  ul {
    padding: 0 14px;
    margin: 0;
  }
`;

const SectionLinkButton = styled(CommonButton)`
  border-bottom: 1px solid ${grey.mid} !important;
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm} !important;
  line-height: 13px;
`;

const List = styled.ul`
  margin-top: 11px !important;
  padding-top: 14px !important;
  padding-left: 0 !important;
  border-top: 1px solid ${grey.rule};
  list-style: none;
`;

const ListItem = styled.li`
  font-size: ${fontSize.sm};
  & + & {
    margin-top: 12px;
  }
`;

const CompanyLocation = styled.div`
  color: ${grey.mid};
`;

const RiskIconsSectionTitle = styled(SectionTitle)`
  margin-bottom: 4px;
`;

const OrgLogoSectionTitle = styled(SectionTitle)`
  margin-bottom: 4px;
`;

const NoneIdentified = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  font-style: italic;
`;

const StateOwnedCompanies = styled.div`
  overflow: auto;
  height: 100%;
`;

const CustomSourceList = styled(SourceList)`
  overflow: inherit;
`;

const IndirectExplainer = styled.div`
  padding-top: 8px;
`;

/* Classname Overrides */
const dataField = css`
  dt {
    color: ${grey.dark};
    margin: 0;
  }

  dd {
    margin-bottom: 8px;
  }
`;

const orgLogoImage = css`
  border-radius: 3px;
  height: 88px;
  width: 94px;
  object-fit: contain;
`;

const locationRiskTag = css`
  border-radius: 11px;
  height: 23px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  opacity: 1 !important;
  background-color: ${red.directRisk} !important;

  & > svg {
    margin-top: 0px;
    margin-right: -3px;
  }
`;

const S = {
  OrganisationOverviewSection,
  OverviewContainer,
  OrgDetails1Cell,
  OrgDetails2Cell,
  OrgPresence,
  SocialReachIcon,
  RelatedOrgsIcon,
  GlobeIcon,
  EmployeeCardIcon,
  GraphicSubtext,
  OrgProfiles,
  OrgSummary,
  OrgDescription,
  SectionTitle,
  OrgScaleTitle,
  OnlineProfilesTitle,
  ShowAllButton,
  LinkToSummary,
  OrgDuration,
  HQContainer,
  CustomFlag,
  JurisdictionContainer,
  InformationIcon,
  ImageLoadingSpinner,
  PopoverHomepageImage,
  HomepageLink,
  OnlineProfilesContainer,
  DescriptorSentenceContainer,
  DescriptorSources,
  TooltipContent,
  NoDate,
  RoleDuration,
  Info,
  Founders,
  WebsitePlaceholder,
  SocialReachTooltipList,
  NoSocialPresenceLabel,
  SummaryText,
  PlaceholderText,
  SummaryTooltipContent,
  InfographicContainer,
  RiskCountIcon,
  RiskTooltipBody,
  RiskList,
  Risks,
  RiskAssignerText,
  StreetviewImage,
  QuotesIcon,
  OrgDescriptionTitleContainer,
  OrgDescriptionTitle,
  OrgDescriptionContainer,
  OrgDescriptionText,
  DescriptionSourceContainer,
  OrgSummaryContainer,
  Tooltip,
  FounderPill,
  Duration,
  DescriptionSentenceSeparator,
  FounderContainer,
  SmallEmployeeIconInfographic,
  SmallSocialReachIconInfographic,
  HomepageProfile,
  OrgLogo,
  OrgLogoContainer,
  OrgLogoImageContainer,
  OrgScaleItem,
  OrgScaleIconContainer,
  OrgScaleDetail,
  BuildingIconContainer,
  BuildingIcon,
  MediaIconsContainer,
  SocialReachInfoIcon,
  BulletPoint,
  SourceContainer,
  CountryFlag,
  Country,
  InspectorContent,
  StateOwnedCompany,
  RiskCountry,
  CountryName,
  CompaniesList,
  CompanyField,
  RelatedCountryHeader,
  RiskAssigners,
  CustomSectionFooter,
  ExplainerText,
  OrgIconContainer,
  OrgIcon,
  InfoSummaryRow,
  SectionLinkButton,
  List,
  ListItem,
  CompanyLocation,
  RiskIconsSectionTitle,
  OrgLogoSectionTitle,
  NoneIdentified,
  StateOwnedCompanies,
  CustomSourceList,
  IndirectExplainer,
  Topbar
};

export const classNameOverrides = {
  orgLogoImage,
  dataField,
  locationRiskTag
};

export default S;
