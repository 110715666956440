import React from "react";

// @ts-ignore
import ThemedLogin from "ThemedLogin";

const Login = () => {
  return <ThemedLogin />;
};

export default Login;
