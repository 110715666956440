import React, { useState } from "react";
import Heading from "components/atoms/Heading";
import { AnimatePresence, motion } from "framer-motion";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";

import { CollectionSearchContextProvider } from "util/hooks/useCollectionSearch";
import { CollectionListContextProvider } from "util/hooks/useCollectionList";
import useUserSettings from "util/hooks/useUserSettings";

import CollectionSearch from "components/organisms/CollectionSearch";
import CollectionList from "components/organisms/CollectionList";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { CardVariant } from "components/molecules/Card/types";
import UserCard from "components/organisms/UserCard";
import { useAuthentication } from "util/hooks/useAuthentication";
import LoadingPage from "components/molecules/LoadingPage";

import Api from "api/users";

import S from "./styles";

const Users = () => {
  const [showAdminsOnly, setShowAdminsOnly] = useState(false);
  const {
    state: {
      isPermissionsLoaded,
      permissions: { canListUsers, canInviteUsers }
    }
  } = useAuthentication();
  const { toggleInviteUserModal } = useUserSettings();

  const navigate = useNavigate();

  if (!isPermissionsLoaded) {
    return <LoadingPage />;
  }

  if (!canListUsers) {
    navigate("/");
    return <LoadingPage />;
  }

  return (
    <CollectionSearchContextProvider Api={Api}>
      <CollectionListContextProvider Api={Api}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <S.ViewContainer>
              <S.ViewInnerContainer>
                <CollectionSearch placeholder="Search users..." />
                <CollectionList
                  filterItemsFn={
                    showAdminsOnly
                      ? item =>
                          !!item.tags &&
                          item.tags.some(tag => tag.name === "Admin")
                      : undefined
                  }
                  showCount
                  cardVariant={CardVariant.dark}
                  navigationComponent={
                    <Heading level={4}>
                      {showAdminsOnly ? "Manage admins" : "Manage users"}
                    </Heading>
                  }
                  createItemComponent={
                    <ButtonNew
                      IconLeading={Plus}
                      type={ButtonType.Outlined}
                      size={ButtonSize.Small}
                      text="Invite new user"
                      disabled={!canInviteUsers}
                      onClick={toggleInviteUserModal}
                    />
                  }
                  toggles={[
                    <ButtonNew
                      type={ButtonType.Outlined}
                      size={ButtonSize.Small}
                      text="Show admins only"
                      onClick={() => setShowAdminsOnly(prev => !prev)}
                      selected={showAdminsOnly}
                    />
                  ]}
                  CardComponent={UserCard}
                />
              </S.ViewInnerContainer>
            </S.ViewContainer>
          </motion.div>
        </AnimatePresence>
      </CollectionListContextProvider>
    </CollectionSearchContextProvider>
  );
};

export default Users;
