import { styled } from "@linaria/react";

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 445px;
  padding: 40px 0;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const S = {
  InnerContainer,
  OuterContainer
};

export default S;
