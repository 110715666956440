/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RiskLevel {
  HighRiskLevel = "high",
  MediumHighRiskLevel = "medium_high",
  MediumRiskLevel = "medium",
  MediumLowRiskLevel = "medium_low",
  LowRiskLevel = "low",
  NoneRiskLevel = "none"
}
