import { styled } from "@linaria/react";
import { hexToRgb } from "styles/hexToRgb";
import { purple } from "styles/colors";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";
import { zIndex } from "styles/zIndex";

const ContextMenuContainer = styled.div`
  position: relative;
  border-radius: 1px;
  padding: 0;
  transition: opacity 0.2s ease;

  @media (min-width: 768px) {
    &:hover {
      &:after {
        content: "";
        position: absolute;
        top: -6px;
        left: -10px;
        width: calc(100% + 10px);
        height: calc(100% - 5px);
        border-radius: 4px;
        pointer-events: none;
        background-color: ${() => {
          const { r, g, b } = hexToRgb(
            isPDX ? theme.primaryColor : purple.darker
          );
          return `rgba(${r}, ${g}, ${b}, 0.07)`;
        }};
        z-index: -1;
      }
      > [class*="ContextMenuControl"] {
        opacity: 1;
      }
    }

    &:hover:has(> [class*="ContextMenuControl"]:hover) {
      > [class*="ContextMenuControl"] {
        opacity: 1;
      }
    }

    &:hover:has([class*="ContextMenuContainer"]:hover),
    &:hover:has([class*="InspectorInnerContainer"]:hover) {
      &:after {
        display: none;
      }
      > [class*="ContextMenuControl"] {
        opacity: 0;
      }
    }
  }
`;

const ContextMenuControl = styled.div`
  position: absolute;
  right: 0;
  top: -7px;
  opacity: 0;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: ${zIndex.l1};
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: -15px;
`;

const S = {
  ContextMenuContainer,
  ContextMenuControl,
  ModalWrapper
};

export default S;
