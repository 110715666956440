import { styled } from "@linaria/react";
import { zIndex } from "styles/zIndex";
import { purple, grey } from "styles/colors";
import Heading from "components/atoms/Heading";
import { Spinner as ReactSpinner } from "reactstrap";

const DiscardedCoverHeading = styled(Heading)`
  color: ${purple.darker};
  font-weight: bold;
`;

const DiscardedCover = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
`;

const Spinner = styled(ReactSpinner)`
  color: ${grey.dark};
  width: 21px;
  height: 21px;
  border-width: 2px;
`;

const S = { Spinner, DiscardedCover, DiscardedCoverHeading };

export default S;
