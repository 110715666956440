import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { RiskLevel } from "api/risk";
import { OVERALL_RISK_CONFIG } from "util/riskService";
import { ReactComponent as SpecialInterestIconSvg } from "img/icons/special-interest-icon.svg";

import S from "./styles";

interface OverallRiskScoreIconProps {
  score: RiskLevel;
  isWrapped?: boolean;
  isRecalculating?: boolean;
}

export const OverallRiskScoreIcon = ({
  score,
  isWrapped = false,
  isRecalculating = false
}: OverallRiskScoreIconProps) => {
  const [isAnimating, setAnimating] = useState(false);
  const [animationStart, setAnimationStart] = useState<number | null>();
  const { color, label, animationData } = OVERALL_RISK_CONFIG[score];

  useEffect(() => {
    if (isRecalculating) {
      setAnimating(true);
      setAnimationStart(Date.now());

      return () => {};
    }

    if (isAnimating && animationStart) {
      const minDuration = 1500;
      const elapsed = Date.now() - animationStart;
      const remainingTime = Math.max(minDuration - elapsed, 0);

      const timeout = setTimeout(() => {
        setAnimating(false);
        setAnimationStart(null);
      }, remainingTime);

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [isRecalculating, isAnimating, animationStart]);

  const icon = isAnimating ? (
    <Lottie animationData={animationData} />
  ) : (
    <SpecialInterestIconSvg />
  );

  return (
    <S.Container color={color} active={isWrapped}>
      <S.IconContainer>{icon}</S.IconContainer>
      <S.Title>{isAnimating ? "Recalculating..." : label}</S.Title>
      <S.EmptyPlaceholder />
    </S.Container>
  );
};
