import { styled } from "@linaria/react";
import { grey, blue, standardColors } from "styles/colors";

import { ChevronRight } from "react-feather";
import { fontSize } from "styles/fonts";

import { CardVariant } from "components/molecules/Card/types";

const NestedCardContainer = styled.div<{ isFirstCard: boolean }>`
  position: relative;
  padding-left: 3rem;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
  }

  &:after,
  &:before {
    opacity: 1;
    content: "";
    position: absolute;
    animation: fadeIn 0.7s ease-in-out;
  }

  &:after {
    border-top: 1px solid ${standardColors.white};
    top: 50%;
    left: 2rem;
    width: 1rem;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:before {
    border-left: 1px solid ${standardColors.white};
    top: ${({ isFirstCard }) => (isFirstCard ? "-16px" : "-52px")};
    left: 2rem;
    height: ${({ isFirstCard }) => (isFirstCard ? "52px" : "88px")};

    @media (max-width: 768px) {
      display: none;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const NestedCardToggle = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  background: ${grey.disabled};
  color: ${blue.xapienDark};
  padding: 3px 14px;
  border-radius: 0 0 6px 6px;
  font-size: ${fontSize.sm};
  align-items: center;
  margin-top: -5px;
  border-top: 5px solid ${grey.panel};
`;

const CardWithNestedCardsContainer = styled.div`
  width: 100%;
`;

const ChevronIcon = styled(ChevronRight)<{ isActive: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 0.75rem;
  transform: ${({ isActive }) => (isActive ? "rotate(90deg)" : "rotate(0)")};
  transition: all 0.2s ease;
`;

const UnreadIndicator = styled.div<{ variant: CardVariant }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ variant }) => {
    return variant === CardVariant.dark ? standardColors.white : blue.panel;
  }};
`;

const S = {
  NestedCardContainer,
  NestedCardToggle,
  CardWithNestedCardsContainer,
  ChevronIcon,
  UnreadIndicator
};

export default S;
