import {
  SearchReportResult,
  ReportState,
  ReportGroup,
  ReportType as PortalReportType
} from "api/portal";
import { isLegalEntityReport } from "util/isLegalEntityReport";
import { ReportStatus, ReportType, Tag, TagId, Report } from "./types";

export const formatGroupName = (name?: string | null) => {
  if (!name) {
    return null;
  }

  let formattedName = name;

  if (name.charAt(0) !== "@") {
    formattedName = `@${formattedName}`;
  }

  return formattedName;
};

export const mapReportStateToStatus = (state?: ReportState): ReportStatus => {
  const reportStateToStatusMapping: Record<ReportState, ReportStatus> = {
    [ReportState.IN_PROGRESS]: ReportStatus.running,
    [ReportState.DONE]: ReportStatus.ready,
    [ReportState.FAILED]: ReportStatus.failed,
    [ReportState.REGENERATING]: ReportStatus.updating,
    [ReportState.DELETED]: ReportStatus.deleted
  };

  if (!state) return ReportStatus.ready;

  return reportStateToStatusMapping[state];
};

export const mapReportType = (
  type?: PortalReportType,
  isLegalEntity: boolean = false
) => {
  const reportTypeMapping: Record<PortalReportType, ReportType> = {
    [PortalReportType.ORGANISATION]: ReportType.Organisation,
    [PortalReportType.PERSON]: ReportType.Person
  };

  if (isLegalEntity) return ReportType.LegalEntity;

  if (!type) return ReportType.Person;

  return reportTypeMapping[type];
};

const getResearchTypeTag = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.LegalEntity: {
      return { id: TagId.ResearchType, name: "Legal entity" };
    }
    case ReportType.Person: {
      return { id: TagId.ResearchType, name: "Person" };
    }
    case ReportType.Organisation:
    default: {
      return { id: TagId.ResearchType, name: "General research" };
    }
  }
};

export const buildReportCardTags = ({
  currentUserId,
  reportOwnerUserId,
  groupsReportIsPartOf,
  reportType
}: {
  currentUserId: string;
  reportOwnerUserId: string;
  groupsReportIsPartOf: ReportGroup[];
  reportType: ReportType;
}): Tag[] => {
  const tags = [];

  const reportTypeTag = getResearchTypeTag(reportType);

  tags.push(reportTypeTag);

  if (currentUserId !== reportOwnerUserId) {
    tags.push({ id: TagId.SharingStatus, name: "Shared" });
  }

  groupsReportIsPartOf?.forEach(({ name }) => {
    tags.push({
      id: TagId.SharedGroup,
      name: formatGroupName(name) ?? ""
    });
  });

  return tags;
};

export const parseReportItem = (
  userId: string,
  {
    reportId,
    imageUrl,
    displayName,
    groups,
    contexts,
    startTime,
    state,
    owner,
    project,
    permissions,
    monitoring,
    read,
    children,
    riskFactors,
    type
  }: SearchReportResult
): Report => {
  // TODO: The endpoint isn't returning legal entity type so this is the only way determine if the source has sayari.
  const isLegalEntityReportType = isLegalEntityReport(contexts);
  const context = isLegalEntityReportType ? "" : contexts?.join(", ") || "";

  const reportType = mapReportType(type, isLegalEntityReportType);

  const author =
    owner?.firstName || owner?.lastName
      ? `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`
      : "unknown";

  const tags = buildReportCardTags({
    currentUserId: userId,
    reportOwnerUserId: owner?.userId!,
    groupsReportIsPartOf: groups!,
    reportType
  });

  return {
    id: reportId ?? "unknown",
    imageSrc: imageUrl ?? "",
    status: mapReportStateToStatus(state),
    isUnread: !read,
    title: displayName ?? "unknown",
    author,
    authorId: owner?.userId ?? "",
    authorEmail: owner?.email ?? "",
    createdAt: startTime ?? "",
    context,
    projectReference: project?.reference ?? undefined,
    permissions: {
      canView: !!permissions?.canView,
      canEdit: !!permissions?.canEdit,
      canDelete: !!permissions?.canDelete,
      canViewSharingPreferences: !!permissions?.canViewSharingPreferences,
      canEditSharingPreferences: !!permissions?.canEditSharingPreferences,
      canViewShareLink: !!permissions?.canViewShareLink,
      canGenerateShareLink: !!permissions?.canGenerateShareLink,
      canDeleteShareLink: !!permissions?.canDeleteShareLink,
      canTransferOwnership: !!permissions?.canTransferOwnership,
      canExport: !!permissions?.canExport,
      canViewAdverseMediaMonitoring:
        !!permissions?.canViewAdverseMediaMonitoring,
      canEditAdverseMediaMonitoring:
        !!permissions?.canEditAdverseMediaMonitoring,
      canViewSanctionMonitoring: !!permissions?.canViewSanctionMonitoring,
      canEditSanctionMonitoring: !!permissions?.canEditSanctionMonitoring
    },
    monitoring: {
      adverseMedia: !!monitoring?.adverseMedia,
      sanctions: !!monitoring?.sanctions
    },
    tags,
    riskFactors: riskFactors ?? {},
    children:
      children?.map(childReport => parseReportItem(userId, childReport)) ?? [],
    type: reportType
  };
};
