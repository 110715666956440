import React, { useCallback, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import {
  HelpCircle,
  MessageCircle,
  Users,
  UserPlus,
  Mail,
  List,
  Plus
} from "react-feather";
import { ReactComponent as Gears } from "img/icons/gears.svg";
import { signOut } from "aws-amplify/auth";
import { getSearchAssistRedirectUrl } from "config";
import { isPDX } from "static-config";

import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import useUserSettings from "util/hooks/useUserSettings";
import {
  AuthenticationActions,
  useAuthentication
} from "util/hooks/useAuthentication";
import { useUserOrganisation } from "util/hooks/useUserOrganisation";
import useHubAdminRole from "util/hooks/useHubAdminRole";
import { HUB_ASSUMED_ROLE_TOKEN_KEY } from "util/hooks/useHubAdminRole/provider";
import S from "./styles";

const ProfileMenu = ({
  setIsContactAdminModalOpen,
  setIsProfileMenuOpen,
  setIsManageOrganisationModalOpen
}: {
  setIsContactAdminModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsProfileMenuOpen: Dispatch<SetStateAction<boolean>>;
  setIsManageOrganisationModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isAuthenticatedAsHubAdmin, setIsAuthenticatedAsHubAdmin } =
    useHubAdminRole();
  const {
    state: {
      userDetails: { firstName, lastName, organisationRole }
    },
    toggleSettingsModal,
    toggleInviteUserModal
  } = useUserSettings();
  const {
    dispatch,
    state: {
      permissions: {
        canInviteUsers,
        canListUsers,
        canUpdateOrganisation,
        canStartLegalEntityReports
      }
    }
  } = useAuthentication();

  const toggleContactAdminModal = () => {
    setIsContactAdminModalOpen(prev => !prev);
  };
  const { details: userOrganisationDetails } = useUserOrganisation();
  const navigate = useNavigate();

  const openManageOrganisationModal = useCallback(
    () => setIsManageOrganisationModalOpen(true),
    [setIsManageOrganisationModalOpen]
  );

  const onLogout = async () => {
    try {
      sessionStorage.removeItem(HUB_ASSUMED_ROLE_TOKEN_KEY);
      setIsAuthenticatedAsHubAdmin(false);
      await signOut();
      dispatch({ type: AuthenticationActions.unauthenticated });
      setIsProfileMenuOpen(false);
      // @ts-ignore
      window.productFruits?.services?.destroy();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const handleNewReportClick = () => {
    if (canStartLegalEntityReports && !isPDX) {
      window.location.href = getSearchAssistRedirectUrl();
    } else {
      navigate("/search");
    }
  };

  return (
    <S.MenuWrapper>
      <S.ProfileHeader>
        <S.ProfileHeaderLeft
          maxWidth={organisationRole === "Admin" ? "125px" : "100%"}
        >
          <S.UserName>
            {firstName} {lastName}
          </S.UserName>
          <strong>
            {userOrganisationDetails.loaded ? userOrganisationDetails.name : ""}
          </strong>
          {isAuthenticatedAsHubAdmin && (
            <S.AssumedRoleLabel>You are a CS assistant</S.AssumedRoleLabel>
          )}
        </S.ProfileHeaderLeft>
        {organisationRole === "Admin" && !isAuthenticatedAsHubAdmin && (
          <S.AdminLabel>Admin</S.AdminLabel>
        )}
      </S.ProfileHeader>
      <Menu>
        <MenuItem
          showOnMobile
          text="New report"
          IconLeading={Plus}
          onMenuItemClick={handleNewReportClick}
        />
        <MenuItem
          showOnMobile
          text="Reports"
          IconLeading={List}
          onMenuItemClick={() => {
            navigate("/reports");
          }}
        />
        <MenuItem
          showOnMobile
          text="Groups"
          IconLeading={Users}
          onMenuItemClick={() => {
            navigate("/groups");
          }}
          showSeparator
        />
        {canInviteUsers && (
          <MenuItem
            showOnDesktop
            text="Invite new user"
            IconLeading={UserPlus}
            onMenuItemClick={() => {
              toggleInviteUserModal();
              setIsProfileMenuOpen(false);
            }}
          />
        )}
        {organisationRole !== "Admin" && (
          <MenuItem
            showOnMobile
            showOnDesktop
            text="Contact an admin"
            IconLeading={Mail}
            onMenuItemClick={() => {
              toggleContactAdminModal();
              setIsProfileMenuOpen(false);
            }}
            showSeparator
          />
        )}
        {canListUsers && (
          <MenuItem
            showOnDesktop
            text="Manage users"
            IconLeading={Users}
            onMenuItemClick={() => {
              navigate("/users");
              setIsProfileMenuOpen(false);
            }}
          />
        )}
        {canUpdateOrganisation && (
          <MenuItem
            showOnDesktop
            text="Organisation settings"
            onMenuItemClick={openManageOrganisationModal}
            IconLeading={Gears}
            showSeparator
          />
        )}
        <MenuItem
          showOnDesktop
          showOnMobile
          text="My settings"
          IconLeading={Gears}
          onMenuItemClick={toggleSettingsModal}
        />
        <MenuItem
          showOnDesktop
          showOnMobile
          text="Help Centre"
          IconLeading={HelpCircle}
          onMenuItemClick={() => {
            window.open("https://3h9peqg3kel3il6.productfruits.help/en");
          }}
        />
        <MenuItem
          showOnDesktop
          text="Feedback"
          IconLeading={MessageCircle}
          onMenuItemClick={() => {
            window?.productFruits?.api?.feedback?.showModal({
              top: "84px",
              right: "270px"
            });
          }}
          showSeparator
        />
        <MenuItem
          showOnDesktop
          showOnMobile
          text="Log out"
          onMenuItemClick={onLogout}
        />
      </Menu>
    </S.MenuWrapper>
  );
};

export default ProfileMenu;
