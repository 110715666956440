import React, { useContext, useEffect, useState } from "react";

import config from "config";
import { SUBJECT_CONTEXTS } from "pages/define/utils";
import { ReportLoadingStatus } from "state/ReportStore";

import PageTitle from "components/atoms/PageTitle";
import Loading from "components/atoms/Loading";
import RegenerateReportBar from "components/molecules/RegenerateReportBar";
import NoReport from "components/organisms/NoReport";

import { GlobalAssertionsProviderWrapper } from "util/hooks/useAssertionsContext";
import { constructSearchUrl } from "util/constructSearchUrl";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { REPORT_TYPES } from "util/reportTypes";
import useViewerMode from "util/hooks/useViewerMode";
import useMarkReportRead from "util/hooks/useMarkReportRead";
import { isLegalEntityReport } from "util/isLegalEntityReport";

import { ReactComponent as ViewerModeIcon } from "img/icons/menu/reportPage/icon_viewer_mode.svg";

import { isPDX } from "static-config";
import { useInsightReport } from "util/hooks/useInsightReport";
import { GetReportErrorCodes } from "api/insight-reports";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import PersonReport from "./PersonReport";
import OrganisationReport from "./OrganisationReport";
import LegalEntityReport from "./LegalEntityReport";
import S, { classNameOverrides } from "./styles";

const Report = props => {
  const {
    report,
    enquiryId,
    loadingStatus,
    shellContainerRef,
    assessments,
    setAssessments,
    setAssessment,
    setCollapsed,
    sectionCollapseStates,
    imageSelection,
    setImageSelection,
    isReportRegenerationOpen,
    nameOnlySearch,
    enquiryStore,
    reportStore,
    userAssessmentStore
  } = props;
  const { isViewerModeEnabled, isSharedReportLink } = useViewerMode();
  const { markRead } = useMarkReportRead();
  const [reportContexts, setReportContexts] = useState([]);
  const [reportSubjectType, setReportSubjectType] = useState();
  const [subject, setSubject] = useState();
  const { permissions, reportMeta } = reportStore;
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext)?.enabled;

  useEffect(() => {
    if (loadingStatus === ReportLoadingStatus.success) {
      markRead(enquiryId);
    }
  }, [loadingStatus, enquiryId, markRead]);

  // If the contexts are present in the JSON, then use it. Otherwise,
  // fetch from the enquiry endpoint.
  useEffect(() => {
    if (
      typeof report.reportMetadata?.contexts[0] === "object" &&
      report.reportMetadata?.contexts[0] !== null &&
      report.reportMetadata?.subjectType &&
      report.subject
    ) {
      setReportContexts(report.reportMetadata.contexts);
      setReportSubjectType(report.reportMetadata.subjectType);
      setSubject(report.subject);
    } else {
      if (isSharedReportLink) return;
      if (reportMeta) {
        setReportContexts(reportMeta.contexts ?? []);
        setReportSubjectType(reportMeta.subject?.type);
        setSubject(reportMeta.subject?.item);
      }
    }
  }, [
    enquiryId,
    enquiryStore,
    report.reportMetadata?.contexts,
    report.reportMetadata?.subjectType,
    report.subject,
    reportMeta,
    isSharedReportLink
  ]);

  const printModeEnabled = usePrintModeEnabled();

  // Backwards compat for contexts that are an array of strings vs. objects
  const contextValues = reportContexts?.map(context => {
    if (typeof context === "object" && context !== null) {
      // Map to nicer presentable type
      const presentableSubjectType =
        SUBJECT_CONTEXTS?.[reportSubjectType.toLowerCase()]?.[
          context.type.charAt(0).toLowerCase() + context.type.slice(1)
        ]?.name;

      return `${context.item} (${presentableSubjectType})`;
    }
    return context;
  });
  const contextString = contextValues?.join(", ");

  const subjectCouldNotBeIdentified =
    report.reportMetadata?.reportIssues?.includes(
      "SubjectCouldNotBeIdentified"
    );

  const [
    savedSubjectCouldNotBeIdentified,
    setSavedSubjectCouldNotBeIdentified
  ] = useState(subjectCouldNotBeIdentified);

  const [isSystemAlertVisible, setIsSystemAlertVisible] = useState(
    subjectCouldNotBeIdentified
  );

  // Ensure that we use the latest status of report issues to determine
  // whether we show the alert or not.
  if (savedSubjectCouldNotBeIdentified !== subjectCouldNotBeIdentified) {
    setSavedSubjectCouldNotBeIdentified(subjectCouldNotBeIdentified);
    setIsSystemAlertVisible(subjectCouldNotBeIdentified);
  }

  const toggleSystemAlert = () => {
    setIsSystemAlertVisible(prev => !prev);
  };

  // for survey
  useEffect(() => {
    window.enquiryId = enquiryId;
    window.subject = subject;
    window.context = contextString;
  }, [enquiryId, subject, contextString]);

  const user = config.getUserEmail;
  useEffect(() => {
    window.user = user;
  }, [user]);

  const surveyContentElement = document.getElementById("surveyContent");
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (surveyContentElement) {
      if (printModeEnabled) {
        surveyContentElement.style.display = "none";
      } else {
        surveyContentElement.style.display = "initial";
      }
      return () => {
        surveyContentElement.style.display = "none";
      };
    }
  }, [surveyContentElement, printModeEnabled]);

  const {
    state: { errorMessage, report: insightsReport, status: insightsStatus }
  } = useInsightReport();

  const isLoadingInsights =
    !insightsReport &&
    (errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR ||
      insightsStatus === InsightReportStatus.idle ||
      insightsStatus === InsightReportStatus.fetching);

  const renderInitial = () => {
    if (loadingStatus === ReportLoadingStatus.initial) {
      return <div />;
    }
    return null;
  };

  const renderLoading = () => {
    if (loadingStatus === ReportLoadingStatus.loading || isLoadingInsights) {
      return (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      );
    }
    return null;
  };

  const renderNoReport = () => {
    if (diagnosticsModeEnabled) return null;
    const isError = loadingStatus === ReportLoadingStatus.error;
    const isDeleted = loadingStatus === ReportLoadingStatus.deleted;
    const isInsightsError =
      errorMessage === GetReportErrorCodes.FAILED_GENERATING_ERROR;
    if (isError || isDeleted || isInsightsError) {
      return (
        <NoReport
          subject={subject}
          contextString={contextString}
          contextValues={contextValues}
          enquiryId={enquiryId}
          isError={isError || isInsightsError}
        />
      );
    }
    return null;
  };

  const renderReportForType = () => {
    // made contexts nullable as prop not in persona reports.
    // TODO: When we have legal entity report type, remove this
    if (isLegalEntityReport(report.contexts?.[0]?.item)) {
      return (
        <LegalEntityReport
          shellContainerRef={shellContainerRef}
          assessments={assessments}
          setAssessments={setAssessments}
          setAssessment={setAssessment}
          setCollapsed={setCollapsed}
          sectionCollapseStates={sectionCollapseStates}
          imageSelection={imageSelection}
          setImageSelection={setImageSelection}
          report={report}
          isReportRegenerationOpen={isReportRegenerationOpen}
          nameOnlySearch={nameOnlySearch}
          contextsWithTypes={reportContexts}
          subjectType={reportSubjectType}
          permission={permissions}
        />
      );
    }

    switch (report?.reportMetadata?.subjectType) {
      case REPORT_TYPES.organisation:
        return (
          <OrganisationReport
            shellContainerRef={shellContainerRef}
            assessments={assessments}
            setAssessments={setAssessments}
            setAssessment={setAssessment}
            setCollapsed={setCollapsed}
            sectionCollapseStates={sectionCollapseStates}
            imageSelection={imageSelection}
            setImageSelection={setImageSelection}
            report={report}
            isReportRegenerationOpen={isReportRegenerationOpen}
            nameOnlySearch={nameOnlySearch}
            contextsWithTypes={reportContexts}
            subjectType={reportSubjectType}
            permission={permissions}
          />
        );
      case REPORT_TYPES.person:
      default:
        return (
          <PersonReport
            report={report}
            enquiryId={enquiryId}
            assessments={assessments}
            setAssessments={setAssessments}
            setAssessment={setAssessment}
            userAssessmentStore={userAssessmentStore}
            setCollapsed={setCollapsed}
            sectionCollapseStates={sectionCollapseStates}
            imageSelection={imageSelection}
            setImageSelection={setImageSelection}
            shellContainerRef={shellContainerRef}
            isReportRegenerationOpen={isReportRegenerationOpen}
            nameOnlySearch={nameOnlySearch}
            contextsWithTypes={reportContexts}
            subjectType={reportSubjectType}
            permission={permissions}
          />
        );
    }
  };

  const renderSystemAlertModal = () => {
    let searchUrl = "";

    if (reportContexts?.length) {
      searchUrl = constructSearchUrl({
        subject: report.reportMetadata?.subject,
        contextList: reportContexts?.map(context => {
          return {
            value: context.item,
            type:
              (context.type ?? "").slice(0, 1).toLowerCase() +
              (context.type ?? "").slice(1)
          };
        }),
        subjectType: reportSubjectType,
        nameOnlySearch,
        autorun: false
      });
    }

    return (
      <S.Modal
        isOpen={isSystemAlertVisible}
        toggle={toggleSystemAlert}
        contentClassName={classNameOverrides.modalContent}
        fade={false}
      >
        <S.ModalBody>
          <S.NoTraceRightContent>
            <S.Header>There&apos;s good news and bad news...</S.Header>
            <S.MainContent>
              Xapien checked thousands of websites, news articles and corporate
              records based on your search, but was not able to confidently link
              any of them to your subject.
              <S.SubHeader>
                In good news, it may not be the end of the road
              </S.SubHeader>
              {isPDX ? (
                <>
                  You can double-check your search, use another name they are
                  known by, or use different contexts.
                </>
              ) : (
                <>
                  You can use the context menu in the report to verify anything
                  you recognise and then re-generate your report. Alternatively,
                  you can double-check your search, use another name they are
                  known by, or use different contexts.
                </>
              )}
              <S.ExtraGuidanceText>
                If you still need help, our Customer Success team are on hand.
                Simply reach out using the feedback button on your report.
              </S.ExtraGuidanceText>
            </S.MainContent>
            <S.ActionButtons>
              <S.PrimaryBannerButton
                onClick={() => setIsSystemAlertVisible(false)}
              >
                Review the report
              </S.PrimaryBannerButton>
              <S.SecondaryBannerButton
                onClick={() => window.open(`/${searchUrl}`, "_blank")}
              >
                Try a different search
              </S.SecondaryBannerButton>
            </S.ActionButtons>
          </S.NoTraceRightContent>
        </S.ModalBody>
      </S.Modal>
    );
  };

  const renderReport = () => {
    return (
      <>
        <PageTitle title={report.subject ?? ""} />
        <S.ReportScrollContainer>
          {isViewerModeEnabled && isSharedReportLink && !printModeEnabled && (
            <S.ViewerModeHeader>
              <S.ViewerModeHeaderTitle>
                <ViewerModeIcon />
                <S.ViewerModeHeaderLabel>Viewer mode</S.ViewerModeHeaderLabel>
              </S.ViewerModeHeaderTitle>
            </S.ViewerModeHeader>
          )}
          <GlobalAssertionsProviderWrapper>
            <S.ReportOuterContainer
              // WARNING: We have to preserve this className as this
              // is used by PDF Export. It locates this div using Puppeteer
              className="report-outer-container"
              isInViewerMode={isViewerModeEnabled && isSharedReportLink}
            >
              <RegenerateReportBar
                reportStore={reportStore}
                enquiryStore={enquiryStore}
                userAssessmentStore={userAssessmentStore}
              />
              {renderReportForType()}
              {renderSystemAlertModal()}
            </S.ReportOuterContainer>
          </GlobalAssertionsProviderWrapper>
        </S.ReportScrollContainer>
      </>
    );
  };

  return (
    renderInitial() || renderLoading() || renderNoReport() || renderReport()
  );
};

export default Report;
