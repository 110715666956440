import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div<{
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
  cursor: ${({ onClick, isDisabled }) =>
    onClick && !isDisabled ? "pointer" : "auto"};
  border: 1px solid ${grey.dark};
  border-radius: 100px;
  padding: 2px 8px;
  display: flex;
  color: ${({ isActive }) => (isActive ? standardColors.white : grey.dark)};

  background: ${({ isActive }) =>
    isActive ? grey.dark : standardColors.white};

  font-size: ${fontSize.xs};
`;

const Content = styled.div``;

const RightAddon = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  border-left: 1px solid
    ${({ isActive }) => (isActive ? "transparent" : grey.rule)};
  margin-left: 6px;
  padding-left: ${({ isActive }) => (isActive ? 0 : "6px")};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const S = {
  Container,
  Content,
  RightAddon
};

export default S;
