import { styled } from "@linaria/react";

import { CardVariant } from "components/molecules/Card/types";

import { blue, grey, standardColors } from "styles/colors";

const ListCard = styled.article<{ variant: CardVariant }>`
  position: relative;
  min-height: 4.5rem;
  width: 100%;
  border-radius: 0.375rem;
  background-color: ${({ variant }) =>
    variant === CardVariant.dark ? blue.panel : grey.panel};

  padding: 0.75rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? standardColors.white : blue.xapienDark};
`;

const ListCardContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  margin-right: 2.25rem;
  gap: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`;

const ControlContainer = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 1.5rem;

  @media (max-width: 768px) {
    top: 0.75rem;
  }
`;

const Skeleton = styled.article`
  min-height: 4.5rem;
  width: 100%;
  border-radius: 0.375rem;
`;

const S = {
  ListCard,
  ListCardContent,
  ControlContainer,
  Skeleton
};

export default S;
